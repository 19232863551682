import { BanOrMute } from "src/app/core/models/enums";

export class BanPanel {
    ParentItem: any;
    BanType: number;
    BanOrMute: BanOrMute;
    BanMode: string;
    TextLimit: number = 500;
    UserId: number;
    UserName: string;
}

export class BanModel {
    ACILACAGI_TARIH: string;
    BANLANAN_UYE_ID: number;
    BANLAYAN_UYE_ID: number;
    KARALISTE: boolean;
    ODA_ID: number;
    SEBEP: string;
    TARIH: string;
    BAN_TIPI: string
}