import { LivekitService } from './../../../livekit/services/livekit.service';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { RoomService } from 'src/app/core/services/room.service';
import { RoomCamera, RoomCameraWatcher } from 'src/app/core/models/room';
import { RoomUserListService } from 'src/app/core/services/room-user-list-service';
import { Subscription } from 'rxjs';
import { DeviceService } from 'src/app/core/services/device.service';
import { Constants } from 'src/app/core/models/constants';
import { MainPanels, SikayetTipleri } from 'src/app/core/models/enums';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { faTimes, faExpandArrowsAlt, faCompressArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { UtilService } from 'src/app/core/services/util.service';
import { RoomUser } from 'src/app/core/models/room-user';
import { resolve } from 'path';
import { rejects } from 'assert';
import { faFlag } from '@fortawesome/free-regular-svg-icons';
import { ReportService } from 'src/app/core/services/report.service';

@Component({
  selector: 'app-room-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss', './camera.web.component.scss', './camera.mobile.component.scss']
})

export class RoomCameraComponent implements OnInit, OnDestroy {
  @ViewChild("videoContainer", { static: true }) videoContainer: ElementRef;
  @ViewChild("videoPanel", { static: true }) videoPanel: ElementRef<HTMLElement>;
  videoContainerId: string;
  videoPanelId: string;
  faTimes = faTimes;
  faFlag = faFlag;
  faExpandArrowsAlt = faExpandArrowsAlt;
  faCompressArrowsAlt = faCompressArrowsAlt;

  constants = Constants;
  mainPanels = MainPanels;
  videoPanelIsMaximized: boolean;
  roomCamera: RoomCamera;
  roomCameraWatchers: Array<RoomCameraWatcher> = [];
  roomPanelBounds: HTMLElement;

  roomUser: RoomUser;

  videoDevices: Array<MediaDeviceInfo> = [];
  selectedVideoDevice: MediaDeviceInfo;

  roomCameraWatcherAddedSubscription: Subscription;
  roomCameraWatcherRemovedSubscription: Subscription;
  roomUserUpdatedSubscription: Subscription;

  constructor(
    public roomService: RoomService,
    public roomUserListService: RoomUserListService,
    public deviceService: DeviceService,
    public panelManagerService: PanelManagerService,
    public utilService: UtilService,
    public indexedDBService: IndexedDBService,
    public reportService: ReportService,
    public livekitService: LivekitService
  ) {

    this.roomCameraWatcherAddedSubscription = this.roomService.roomCameraWatcherAdded.subscribe(userId => {
      this.addRoomCameraWatcher(userId);
    });

    this.roomCameraWatcherRemovedSubscription = this.roomService.roomCameraWatcherRemoved.subscribe(userId => {
      this.removeRoomCameraWatcher(userId);
    });

    this.roomUserUpdatedSubscription = this.roomUserListService.roomUserUpdated.subscribe((roomUser: RoomUser) => {

      this.addRoomCameraWatcher(roomUser.KullaniciId);
    })
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.roomCameraWatcherAddedSubscription)
      this.roomCameraWatcherAddedSubscription.unsubscribe();

    if (this.roomCameraWatcherRemovedSubscription)
      this.roomCameraWatcherRemovedSubscription.unsubscribe();

    if (this.roomUserUpdatedSubscription)
      this.roomUserUpdatedSubscription.unsubscribe();
  }



  set(roomCamera: RoomCamera, roomPanelBounds: HTMLElement) {

    this.videoContainerId = "room_video_" + roomCamera.roomId + "_" + roomCamera.userId;
    this.videoPanelId = "videopanel_" + roomCamera.roomId + "_" + roomCamera.userId;

    setTimeout(() => {
      this.roomCamera.videoContainer = this.videoContainer.nativeElement;
    });

    // this.roomUser = this.roomService.getRoomUserById(roomCamera.userId);
    this.roomUser = this.roomService.micUserList.find(u => u.KullaniciId === roomCamera.userId);

    this.roomPanelBounds = roomPanelBounds;
    this.roomCamera = roomCamera;

    this.videoPanel.nativeElement.parentElement.setAttribute("id", Constants.mainSwiperTag + '' + MainPanels.RoomVideo + '_' + roomCamera.userId);

  }

  stopCam() {
     this.roomService.stopCam(this.roomCamera.userId, this.roomService.currentRoom.Info?.ID, false, false);
  }

  addRoomCameraWatcher(userId: number) {
    // let roomuser: RoomUser = this.roomService.getRoomUserById(userId);
    let roomuser: RoomUser = this.roomService.micUserList.find(u => u.KullaniciId === userId);

    if (this.roomService.isUserVisible(roomuser.StateId, roomuser.RutbeDerece, roomuser.KullaniciId) === true && this.roomCamera.userId === this.indexedDBService.userFullInfo.KullaniciId) {
      if (roomuser) {
        if (this.roomCameraWatchers.find(f => f.userId === userId) === undefined)
          this.roomCameraWatchers.push(<RoomCameraWatcher>{
            userId: userId,
            userName: this.roomUserListService.userViewInRoom[userId].username
          });
      }
    }
    else {
      if (this.roomCameraWatchers.find(f => f.userId === userId))
        this.removeRoomCameraWatcher(roomuser.KullaniciId);
    }
  }

  removeRoomCameraWatcher(userId: number) {
    this.roomCameraWatchers = this.roomCameraWatchers.filter(f => f.userId !== userId);
  }


  complainUser() {
    this.reportService.header = this.roomCamera.userName;
    this.reportService.insertComplainRequest.ComplainType = SikayetTipleri.Kullanici;
    this.reportService.insertComplainRequest.ComplainingId = this.indexedDBService.userFullInfo.KullaniciId;
    this.reportService.insertComplainRequest.ComplainedId = this.roomCamera.userId;
    this.reportService.insertComplainRequest.Html = "";//this.message.messageHtml[0];
    this.reportService.reportPopupDisplay = true;
  }
}