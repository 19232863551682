<p-dialog class="updateStateMessage" [(visible)]="panelManagerService.controlOfRecordingPopupDisplay" [modal]="true"
    [baseZIndex]="10000" [responsive]="true" header="{{'record-options' | translate }}">

    <div style="text-align:center;">
        <div class="buttons">
            <div class="p-field-radiobutton">
                <p-radioButton (onClick)="isAudioOnly = false" name="groupname"
                    label="{{'record-all' | translate}}"></p-radioButton>
            </div>
            <div class="p-field-radiobutton">
                <p-radioButton (onClick)="isAudioOnly = true" name="groupname"
                    label="{{'record-only-audio' | translate}}"></p-radioButton>
            </div>
        </div>
    </div>
    <p-footer>
        <button pButton type="button" (click)="recordRoomTrack()" label="{{'Kaydı Başlat' | translate}}"
            class="ui-button-raised ui-button-info popup" style="width: 100%;"></button>
    </p-footer>
</p-dialog>