import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Friend, UserFullInfo } from 'src/app/core/models/user';
import { UtilService } from 'src/app/core/services/util.service';
import { ServiceInformation } from './serviceinformation.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { ChatMessageService } from './chatMessage.service';
import { DynamicMethodsSendService } from './dynamic-methods-send.service';
import { PanelManagerService } from './panel-manager.service';
import { FriendshipService } from './friendship.service';
import { FriendListType } from '../models/enums';
import { DynamicMethodsService } from './dynamic-methods.service';
import { ChatPanelProps } from '../models/chat-panel-props';
@Injectable({
  providedIn: 'root'
})
export class FriendListService extends ServiceInformation {

  friendshipRequestDeletedSource: Subject<Friend> = new Subject();
  friendshipRequestDeleted = this.friendshipRequestDeletedSource.asObservable();

  friendBlockedSource: Subject<Friend> = new Subject();
  friendBlocked = this.friendBlockedSource.asObservable();

  deleteFriendMessageHistorySource: Subject<ChatPanelProps> = new Subject();
  deleteFriendMessageHistory = this.deleteFriendMessageHistorySource.asObservable();
  
  constructor(
    private http: HttpClient,
    public utilService: UtilService,
    public messagePanelService: MessagePanelService,
    protected indexedDBService: IndexedDBService,
    private chatMessageService: ChatMessageService,
    private dynamicMethodsSendService: DynamicMethodsSendService,
    private panelManagerService: PanelManagerService,
    private dynamicMethodsService:DynamicMethodsService
  ) {
    super(messagePanelService, utilService, indexedDBService);
  }

  //#region  Get Block User Info
  public getBlockUserInfo(body): Observable<any> {
    body["ClientInfo"] = this.Get_ClientInfo();
    const endpoint = this.ServiceUrl + 'GetBlockUserInfo';
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }
  //#endregion

  //#region  Block User Operations  
  public blockUser(body): Observable<any> {
    const endpoint = this.ServiceUrl + 'api/friend/block';
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  callBlockUser(friendId, friendName) {
    let body = {
      'UserId': +this.indexedDBService.userFullInfo.KullaniciId,
      'BlockUserId': friendId
    };

    this.blockUser(body)
      .subscribe(guid => {
        if (this.utilService.isNullOrUndefined(guid)) {
          this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
          return;
        }
        // this.chatMessageService.sendUserSettingMessage(
        //   friendId,
        //   [this.dynamicMethodsSendService.UserBlockUnblock(this.indexedDBService.userFullInfo.KullaniciId, this.indexedDBService.userFullInfo.KullaniciAdi, true)],
        //   friendName
        // );

        let operationMessageForUserBlockUnblock = this.dynamicMethodsService.prepareUserSettingMessage(
          friendId,
          [this.dynamicMethodsSendService.UserBlockUnblock(this.indexedDBService.userFullInfo.KullaniciId, this.indexedDBService.userFullInfo.KullaniciAdi, true)],
          friendName
          )
        this.dynamicMethodsService.publishDynUserBlockUnblock(this.indexedDBService.userFullInfo.KullaniciId,friendId,operationMessageForUserBlockUnblock);
        
        let blockedUser: Friend = this.indexedDBService.Friends.AllFriends.find(f => f.KullaniciId === friendId);
        this.indexedDBService.Friends.AllFriends = this.indexedDBService.Friends.AllFriends.filter(f => f.KullaniciId !== friendId);
        this.indexedDBService.Friends.BlockedByMeFriends.push(blockedUser);

        this.indexedDBService.refreshFriendListAfterChanges();
        this.panelManagerService.removeP2PChatPanel(friendId);
        this.messagePanelService.ShowPopupMessageWithLocalization('[' + friendName + '] ' + this.utilService.getKeyWithTag("UserBlocked"), 2000);
      },
        error => {
          console.log(error);
        });
  }
  //#endregion

  //#region  UnBlock User Operations
  public unBlockUser(body): Observable<any> {
    const endpoint = this.ServiceUrl + 'api/friend/unblock';
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  callUnBlockUser(friendId, friendName): Promise<any> {
    let body = {
      'UserId': +this.indexedDBService.userFullInfo.KullaniciId,
      'BlockUserId': friendId
    };

    return new Promise((resolve, reject) => {
      this.unBlockUser(body)
        .subscribe(guid => {
          if (this.utilService.isNullOrUndefined(guid)) {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            return reject();
          }
          // this.chatMessageService.sendUserSettingMessage(
          //   friendId,
          //   [this.dynamicMethodsSendService.UserBlockUnblock(this.indexedDBService.userFullInfo.KullaniciId, this.indexedDBService.userFullInfo.KullaniciAdi, false)],
          //   friendName
          // );
          let operationMessageForUserBlockUnblock = this.dynamicMethodsService.prepareUserSettingMessage(
            friendId,
            [this.dynamicMethodsSendService.UserBlockUnblock(this.indexedDBService.userFullInfo.KullaniciId, this.indexedDBService.userFullInfo.KullaniciAdi, false)],
            friendName
            )
          this.dynamicMethodsService.publishDynUserBlockUnblock(this.indexedDBService.userFullInfo.KullaniciId,friendId,operationMessageForUserBlockUnblock);
         
          let unBlockedUser: Friend = this.indexedDBService.Friends.BlockedByMeFriends.find(f => f.KullaniciId === friendId);
          this.indexedDBService.Friends.BlockedByMeFriends = this.indexedDBService.Friends.BlockedByMeFriends.filter(f => f.KullaniciId !== friendId);
          this.indexedDBService.Friends.AllFriends.push(unBlockedUser);

          this.indexedDBService.refreshFriendListAfterChanges();
          this.messagePanelService.ShowPopupMessageWithLocalization('[' + friendName + '] ' + this.utilService.getKeyWithTag("UserBlockRemoved"), 2000);
        },
          error => {
            console.log(error);
            return reject();
          });
    })
  }
  //#endregion

  //#region  Remove Friend Operations  
  public removeFriend(body): Observable<any> {
    const endpoint = this.ServiceUrl + 'api/friend/remove';
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  callRemoveFriend(friendId, friendListType: FriendListType) {
    let body = {
      'UserId': +this.indexedDBService.userFullInfo.KullaniciId,
      'FriendId': friendId
    };

    this.removeFriend(body)
      .subscribe(response => {
        if (this.utilService.isNullOrUndefined(response) || response === false) {
          this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
          return;
        }

        if (response) {
          this.messagePanelService.ShowPopupMessageWithLocalization("successOperation", 2000);

          // this.chatMessageService.sendUserSettingMessage(
          //   friendId,
          //   [this.dynamicMethodsSendService.RemoveUserFromMessengerList(this.indexedDBService.userFullInfo.KullaniciId)]
          // );

          let operationMessageForRemoveUserFromMessengerList = this.dynamicMethodsService.prepareUserSettingMessage(friendId, [this.dynamicMethodsSendService.RemoveUserFromMessengerList(this.indexedDBService.userFullInfo.KullaniciId)])
          this.dynamicMethodsService.publishDynRemoveUserFromMessengerList(this.indexedDBService.userFullInfo.KullaniciId,friendId,operationMessageForRemoveUserFromMessengerList);


          switch (friendListType) {
            case FriendListType.Friends:
              this.panelManagerService.removeP2PChatPanel(friendId);
              this.indexedDBService.Friends.AllFriends = this.indexedDBService.Friends.AllFriends.filter(f => f.KullaniciId !== friendId);
              break;
            case FriendListType.IncomingFriends:
              this.indexedDBService.Friends.IncomingFriends = this.indexedDBService.Friends.IncomingFriends.filter(f => f.KullaniciId !== friendId);
              break;
            case FriendListType.OutgoingFriends:
              this.indexedDBService.Friends.OutgoingFriends = this.indexedDBService.Friends.OutgoingFriends.filter(f => f.KullaniciId !== friendId);
              break;
            default:
              break;
          }

          this.indexedDBService.refreshFriendListAfterChanges();
        }

      },
        error => {
          console.log(error);
        });

  }
  //#endregion

  //#region  Approve Friend Operations
  public approveFriendRequests(body): Observable<any> {
    const endpoint = this.ServiceUrl + 'api/friend/approve';
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  callAcceptRequest(friendId): Promise<any> {
    let body = {
      'UserId': +this.indexedDBService.userFullInfo.KullaniciId,
      'FriendId': friendId
    };

    return new Promise((resolve, reject) => {
      this.approveFriendRequests(body)
        .subscribe(response => {
          if (this.utilService.isNullOrUndefined(response) || response === false) {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            return;
          }

          if (response) {
            this.messagePanelService.ShowPopupMessageWithLocalization("successOperation", 2000);
            

            // this.chatMessageService.sendUserSettingMessage(
            //   friendId,
            //   [this.dynamicMethodsSendService.RefreshFriendListForOneUser(this.indexedDBService.userFullInfo.KullaniciId, this.indexedDBService.userFullInfo.StateId, false, false)]
            // );

            let operationMessageForRefreshFriendListForOneUser = this.dynamicMethodsService.prepareUserSettingMessage(friendId, [this.dynamicMethodsSendService.RefreshFriendListForOneUser(this.indexedDBService.userFullInfo.KullaniciId, this.indexedDBService.userFullInfo.StateId, false, false)])
            this.dynamicMethodsService.publishDynRefreshFriendListForOneUserP2PSetting(this.indexedDBService.userFullInfo.KullaniciId,friendId,operationMessageForRefreshFriendListForOneUser);

            let acceptedFriend: Friend = this.indexedDBService.Friends.IncomingFriends.find(f => f.KullaniciId === friendId);
            this.indexedDBService.Friends.IncomingFriends = this.indexedDBService.Friends.IncomingFriends.filter(f => f.KullaniciId !== friendId);
            this.indexedDBService.Friends.AllFriends.push(acceptedFriend);

            this.indexedDBService.refreshFriendListAfterChanges(friendId);
          }
        },
          error => {
            console.log(error);
            return reject();
          });
    });

  }
  //#endregion

}
