export const environment = {
  production: true,
  appVersion: '2.49.10-d5ce7406',
  checkVersion: true,
  builtAppHash: 'd5ce7406d0de27b67b6a92847f53593ced2b55fb',
  centrifugoWebsocketUrl: 'wss://centri.seslidunya.com/connection/websocket',
  jitsiurl: 'meetx.turkpages.com',
  webSiteUrl: 'https://web.seslidunya.com/',
  apiUrl: 'https://v2-be.seslidunya.com/',
  natsUrl: 'wss://centri.seslidunya.com',
  googleCientId: '590432653135-m4etmnl73b4h37pf4peqv3ik951fesm6.apps.googleusercontent.com',
  livekitUrl:   'wss://live-test.zumti.com',
  customerName: 'seslidunyacom',
  s3AccountUrl: 'https://d1sy3qwuhfvqo8.cloudfront.net/v2/',
};