import { Component, OnInit, Input, OnDestroy, AfterViewInit, ComponentFactoryResolver, ViewChild, ViewEncapsulation, HostListener } from '@angular/core';
import { UtilService } from 'src/app/core/services/util.service';
import { ChatMessageForBubble, ChatMessage, MessageRecords } from 'src/app/core/models/chat-message';
import { ChatMessageService } from 'src/app/core/services/chatMessage.service';
import { ChatPanelProps } from 'src/app/core/models/chat-panel-props';
import { ChatService } from 'src/app/core/services/chat.service';
import { Subscription } from 'rxjs';
import { BubbleDirective } from '../../bubble/bubble.directive';
import { BubbleComponent } from '../../bubble/bubble.component';
import { ScrollPanel } from 'primeng/scrollpanel';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { P2PService } from 'src/app/core/services/p2p.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { StateImagesByName } from 'src/app/core/models/state';
import { ChatType, MessageBubbleType, MessageType } from 'src/app/core/models/enums';
import { faArrowDown, faSpinner, faTruckLoading } from '@fortawesome/free-solid-svg-icons';
import { FriendshipService } from 'src/app/core/services/friendship.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { FriendListService } from 'src/app/core/services/friendlist.service';
import { Friend } from 'src/app/core/models/user';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';

@Component({
  selector: 'app-p2p-chat-bubble',
  templateUrl: './bubble.component.html',
  styleUrls: ['./bubble.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class P2PChatBubbleComponent implements OnInit, OnDestroy, AfterViewInit {

  messages: ChatMessageForBubble[];
  @Input() chatPanelProps: ChatPanelProps;
  @ViewChild(BubbleDirective, { static: false, read: BubbleDirective }) bubbleHost: BubbleDirective;
  @ViewChild('scrollPanel', { static: true, read: ScrollPanel }) scrollPanel: ScrollPanel

  p2pMessagesReceivedSubscription: Subscription;
  p2pOldMessagesGotSubscription: Subscription;
  clearAllMessagesSubscription: Subscription;

  canScrollToBottom = true;
  oldMessageGetting: boolean;
  allMessagesGetDone: boolean;
  topMessageId: string;

  faSpinner = faSpinner;

  isLoading:boolean = false
  isAtTop: boolean = false;
  lastScrollTop: number = 0;
  touchStartY: number = 0;

  isInitialScroll = true;
  nextKey: string = "";
  faArrowDown = faArrowDown
  showScrollDownIcon = false;
  p2pChatInitSubscription: Subscription;
  friendMessageHistoryDeletedSubscription: Subscription;


  constructor(
    private utilService: UtilService,
    private chatMessageService: ChatMessageService,
    private chatService: ChatService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private indexedDBService: IndexedDBService,
    private p2PService: P2PService,
    private panelManagerService: PanelManagerService,
    private friendshipService: FriendshipService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    public friendListService:FriendListService,
    public messagePanelService:MessagePanelService
  ) {
    this.messages = new Array<ChatMessageForBubble>();

    this.p2pMessagesReceivedSubscription = this.chatService.userChannelMessageReceivedSource
      .subscribe((chatMessage: ChatMessage) => {
        if (chatMessage.SenderId === this.chatPanelProps.UserId || chatMessage.ReceiverId === this.chatPanelProps.UserId) {
          let chatPanel = this.panelManagerService.p2pChatPanels.find(f => f.user.KullaniciId === chatMessage.SenderId);
          if (
            this.utilService.isNullOrEmtpyObject(chatPanel) &&
            chatMessage.SenderId !== this.indexedDBService.userFullInfo.KullaniciId) {
            this.panelManagerService.openP2PChatPanel(chatMessage.SenderId, chatPanel.user, null, false);
          }
          chatMessage.Time = new Date(chatMessage.Time);
          this.sendMessageToBubble(chatMessage);
          this.scrollToBottom();
        }
      });

      this.p2pChatInitSubscription = this.indexedDBService.p2pChatInit.subscribe(userId => {
        if (userId == this.chatPanelProps.UserId) {
          this.clearAllMessages()
          this.loadOldMessagesAndSetToBubbles(true)
        }
      })

      this.friendMessageHistoryDeletedSubscription = this.friendListService.deleteFriendMessageHistory.subscribe((chatPanelProps:ChatPanelProps) => {
        if (this.chatPanelProps == chatPanelProps) {
          this.deleteMessageHistory(chatPanelProps.UserId)
        }
      })  
  }

  ngOnInit() {
  }

  ngOnDestroy() {

    if (this.p2pMessagesReceivedSubscription)
      this.p2pMessagesReceivedSubscription.unsubscribe();

    if (this.p2pOldMessagesGotSubscription)
      this.p2pOldMessagesGotSubscription.unsubscribe();

    if (this.p2pChatInitSubscription) {
      this.p2pChatInitSubscription.unsubscribe();
    }
    if (this.friendMessageHistoryDeletedSubscription) {
      this.friendMessageHistoryDeletedSubscription.unsubscribe();
    }
  }

  deleteMessageHistory(friendId:number) {
    this.confirmationService.confirm({
      message: this.translateService.instant('want-delete-message-history'),
      header: this.translateService.instant('delete-message-history'),
      icon: null,
      acceptLabel: this.translateService.instant('Yes'),
      rejectLabel: this.translateService.instant('No'),
      accept: () => {
          this.chatService.getP2PChatOldMessages(friendId,"").then((response:MessageRecords)=>{
            this.friendshipService.channel = response.data[0].Channel
          }).then(() => {
            this.chatService.deleteP2PChatOldMessages(this.friendshipService.channel).then(() => {
              this.messagePanelService.ShowPopupMessageWithLocalization('successOperation', 2000);
            })
          }).finally(() => {
            this.clearAllMessages();
          })
      },
      reject: () => {
      }
    });
  }

  clearAllMessages() {
    if (this.bubbleHost && this.bubbleHost.viewContainerRef)
      this.bubbleHost.viewContainerRef.clear();
  }
  
  ngAfterViewInit() {
    this.addScrollEventListener();
  }

  addScrollEventListener() {
    const scrollContent = this.scrollPanel.contentViewChild.nativeElement;
    if (scrollContent) {
      scrollContent.addEventListener('scroll', this.onScroll.bind(this));
    }
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event) {
    const target = event.target as HTMLElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    this.isAtTop = scrollTop === 0;
    if (this.isAtTop && !this.isLoading) {
      if (this.nextKey !== "") {
        this.loadOldMessagesAndSetToBubbles();
      }
    }
    this.showScrollDownIcon = scrollTop + clientHeight < scrollHeight;
  }

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent) {
    if (event.deltaY < 0 && this.isAtTop && !this.isLoading) {
      if (this.nextKey !== '') {
        this.loadOldMessagesAndSetToBubbles();
      }
    }
  }


  loadOldMessagesAndSetToBubbles(isFirst?: boolean){
    this.isLoading = true
    const friendId = this.chatPanelProps.UserId
    this.chatService.getP2PChatOldMessages(friendId, this.nextKey).then((response:MessageRecords)=>{
      if (response.data != null) {
        let data = response
        data.data.forEach((chatMessage:ChatMessage) => {
            if (chatMessage.SenderId != this.indexedDBService.userFullInfo.KullaniciId) {
                chatMessage.MessageBubbleType =MessageBubbleType.PtoPOther
            }
            this.sendOldMessageToBubble(chatMessage);
        })
        this.friendshipService.channel = data.data[0].Channel
      }
      this.nextKey = response.next
  }).finally(() => {
    if (isFirst) {
      this.scrollToBottom()
    }
    this.isLoading = false
  })
}
  messagesScrollHandler(event) {

    var scrollTop = event.target.scrollTop;
    var scrollHeight = event.target.scrollHeight;
    var controlHeight = event.target.offsetHeight;

    if (scrollTop + controlHeight + 400 > scrollHeight)
      this.canScrollToBottom = true;
    else
      this.canScrollToBottom = false;

    if (this.oldMessageGetting === true || this.allMessagesGetDone === true)
      return;

    let oldTopMessageId = this.topMessageId;
    if (scrollTop < 50) {
      this.getOldMessages()
        .then(() => {
          let el = document.getElementById("message_" + oldTopMessageId);
          setTimeout(() => {
            if (el)
              this.scrollPanel.scrollTop(el.offsetTop);
            this.oldMessageGetting = false;
          }, 500);
        })
        .catch(error => {
          console.log(error)
        });
    }
  }

  sendMessageToBubble(chatMessage: ChatMessage) {
    this.chatMessageService.getChatMessageForRoomBubble(chatMessage)
      .then((chatMessageForBubble: ChatMessageForBubble) => {
        if (chatMessageForBubble) {
          if (chatMessageForBubble.messageType != MessageType.Text) {
            this.appendToBubbles(chatMessageForBubble);
            if (chatMessage.SenderId === this.indexedDBService.userFullInfo.KullaniciId)
              this.messageIsSended(chatMessage, chatMessageForBubble)
            if (chatMessageForBubble.senderId === this.indexedDBService.userFullInfo.KullaniciId || this.canScrollToBottom)
              this.scrollToBottom();
          } else {
            chatMessageForBubble.messageHtml = chatMessageForBubble.messageHtml.map(html => { return this.utilService.parseGetMessageValue(html) })
            var el = document.createElement('html');
            el.innerHTML = chatMessageForBubble.messageHtml[0];
            if (!this.utilService.isHTML(el.getElementsByTagName('div')[0].innerHTML)) {
              this.appendToBubbles(chatMessageForBubble);
              if (chatMessage.SenderId === this.indexedDBService.userFullInfo.KullaniciId)
                this.messageIsSended(chatMessage, chatMessageForBubble)
              if (chatMessageForBubble.senderId === this.indexedDBService.userFullInfo.KullaniciId || this.canScrollToBottom)
                this.scrollToBottom();
            }
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    //TODO scroll to botttom
  }

  sendOldMessageToBubble(chatMessage: ChatMessage) {
    this.chatMessageService.getChatMessageForRoomBubble(chatMessage)
      .then((chatMessageForBubble: ChatMessageForBubble) => {
        if (chatMessageForBubble) {
          if (chatMessageForBubble.messageType !== MessageType.Text) {
            this.appendToBubblesToLast(chatMessageForBubble);
            if (chatMessage.SenderId === this.indexedDBService.userFullInfo.KullaniciId) {
              this.messageIsSended(chatMessage, chatMessageForBubble);
            }
          } else {
            chatMessageForBubble.messageHtml = chatMessageForBubble.messageHtml.map(html => this.utilService.parseGetMessageValue(html));
            var el = document.createElement('html');
            el.innerHTML = chatMessageForBubble.messageHtml[0];
            if (!this.utilService.isHTML(el.getElementsByTagName('div')[0].innerHTML)) {
              this.appendToBubblesToLast(chatMessageForBubble);
              if (chatMessage.SenderId === this.indexedDBService.userFullInfo.KullaniciId) {
                this.messageIsSended(chatMessage, chatMessageForBubble);
              }
            }
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
  

  messageIsSended(chatMessage: ChatMessage, chatMessageForBubble: ChatMessageForBubble) {
    if (chatMessage.SenderId === this.chatPanelProps.UserId || chatMessage.ReceiverId === this.chatPanelProps.UserId) {
      if (this.utilService.isNullOrUndefined(this.indexedDBService.Friends.AllFriends.find(f => f.KullaniciId === chatMessage.ReceiverId)) === false) {
        if (this.indexedDBService.Friends.AllFriends.find(f => f.KullaniciId === chatMessage.ReceiverId).StateId !== StateImagesByName.State_Offline &&
          ChatType.SingleChat === chatMessage.ChatType) {
          // if (chatMessageForBubble.htmlEditorMessageId === chatMessage.LocalDBId)
          //   chatMessageForBubble.isSended = true;
        }
      }
      //else {
      //   if (chatMessageForBubble.htmlEditorMessageId === chatMessage.LocalDBId)
      //     chatMessageForBubble.isSended = true;
      // }
    }
  }

  scrollToBottom() {
    setTimeout(() => { 
      this.scrollPanel.scrollTop(99999999);
      this.showScrollDownIcon = false;
    });
  }

  prependOldChatMessages(chatMessages: Array<ChatMessage>) {
    chatMessages.forEach(chatMessage => {
      this.chatMessageService.getChatMessageForRoomBubble(chatMessage)
        .then((chatMessageForBubble: ChatMessageForBubble) => {
          if (chatMessageForBubble) {
            if (chatMessageForBubble.messageType != MessageType.Text) {
              this.appendToBubbles(chatMessageForBubble);
            } else {
              chatMessageForBubble.messageHtml = chatMessageForBubble.messageHtml.map(html => { return this.utilService.parseGetMessageValue(html) })
              var el = document.createElement('html');
              el.innerHTML = chatMessageForBubble.messageHtml[0];
              if (!this.utilService.isHTML(el.getElementsByTagName('div')[0].innerHTML)) {
                this.appendToBubbles(chatMessageForBubble);
              }
            }

          }
        })
    });
    this.scrollToBottom();

    if (chatMessages.length < this.p2PService.maxBubbleCount)
      this.allMessagesGetDone = true;

  }

  clearChats() {
    this.bubbleHost.viewContainerRef.clear();
  }

  appendToBubbles(chatMessageForBubble: ChatMessageForBubble, index?: number) {
    try {
      let componentFactory = this.componentFactoryResolver.resolveComponentFactory(BubbleComponent);
      let viewContainerRef = this.bubbleHost.viewContainerRef;
      let componentRef = viewContainerRef.createComponent(componentFactory, index !== 0 ? viewContainerRef.length : index);
      (<BubbleComponent>(componentRef.instance)).set(chatMessageForBubble);
    } catch (error) {
      console.log(error);
    }
  }
  appendToBubblesToLast(chatMessageForBubble: ChatMessageForBubble) {
    try {
      let componentFactory = this.componentFactoryResolver.resolveComponentFactory(BubbleComponent);
      let viewContainerRef = this.bubbleHost.viewContainerRef;
      let componentRef = viewContainerRef.createComponent(componentFactory,0);
      (<BubbleComponent>(componentRef.instance)).set(chatMessageForBubble);
    } catch (error) {
      console.log(error);
    }
  }

  getOldMessages() {
    this.oldMessageGetting = true;
    return this.indexedDBService.getOldChatMessage(this.chatPanelProps.UserId, this.p2PService.maxBubbleCount, this.topMessageId)
      .then((chatMessages: Array<ChatMessage>) => {

        if (chatMessages.length > 0)
          // this.topMessageId = chatMessages[chatMessages.length - 1].LocalDBId;

        return this.prependOldChatMessages(chatMessages);
      })
      .catch((error) => {
        console.log(error);
        this.oldMessageGetting = false;
        return false;
      })
  }
}