import { DeviceService } from './../../../../../services/device.service';
import { LivekitService } from './../../../../../livekit/services/livekit.service';
import { PanelManagerService } from "src/app/core/services/panel-manager.service";
import {
  Component,
  OnInit,
  HostListener,
  AfterViewInit,
  ViewChild,
  Input,
  ElementRef,
  OnDestroy,
} from "@angular/core";
import { RoomService } from "src/app/core/services/room.service";
import { ScrollPanel } from "primeng/scrollpanel";
import { IndexedDBService } from "src/app/core/services/indexed-db.service";
import { Subscription } from 'rxjs';

@Component({
  selector: "app-room-chat-microphone-settings",
  templateUrl: "./microphone-settings.component.html",
  styleUrls: ["./microphone-settings.component.scss"],
})
export class RoomChatButtonsMicrophoneSettingsComponent
  implements OnInit, AfterViewInit,OnDestroy
{
  @Input() isDisplay: boolean = false;

  userMicMuteMessageReceivedSubscription: Subscription;


  @ViewChild("micSettingsControl") micSettingsControl: ScrollPanel;
  @ViewChild("close", { static: false }) close: ElementRef;

  // isMicEnabled: boolean = true
  constructor(
    public roomService: RoomService,
    private indexedDBService: IndexedDBService,
    public panelManagerService: PanelManagerService,
    public livekitService:LivekitService,
    public deviceService:DeviceService
  ) {
    this.userMicMuteMessageReceivedSubscription = this.roomService.userMicMuteDynamicMethodReceived.subscribe((isMute:boolean) => {
      this.toggleMyAudioMuteFromDynamicMethod(isMute)
    })
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if(this.userMicMuteMessageReceivedSubscription){
      this.userMicMuteMessageReceivedSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.livekitService.handleDevicesChanged()
  }

  @HostListener("document:click", ["$event"])
  clickout(event) {
     if (this.close && this.close.nativeElement.contains(event.target)) {
      this.panelManagerService.showMicSettings = false;
    } else if (this.micSettingsControl && this.micSettingsControl.el.nativeElement.contains(event.target)) {
      this.panelManagerService.showMicSettings = true;
      event.preventDefault();
      event.stopPropagation();
    }
  }


  toggleMuteAudios(){
    if (!this.roomService.allParticipantAreMuted) {
      this.roomService.callUserMuteUnMuteAudio(true).then(() => {
        this.roomService.muteAllAudioElements();
      }).finally(() => {
        this.roomService.prepareAndSendRefreshRoomUserListDynamicMessage();
      })
    }
    else{
      this.roomService.callUserMuteUnMuteAudio(false).then(() => {
        this.roomService.unmuteAllAudioElements();
      }).finally(() => {
        this.roomService.prepareAndSendRefreshRoomUserListDynamicMessage();
      })
    }
  }

  toggleMyAudioMuteFromDynamicMethod(isMute:boolean){
    if (isMute) {
      this.roomService.callUserMuteUnMuteMic(true).then(() => {
        if (this.livekitService.isShareMusic) {
          this.livekitService.muteShareAudio();
        }
        this.livekitService.room.localParticipant.setMicrophoneEnabled(false);
        this.livekitService.allSenderAudioTrackAreMuted = true;
        this.livekitService.isMicEnabled = false
      }).finally(() => {
        this.roomService.prepareAndSendRefreshRoomUserMicListDynamicMessage();
      });
    }
    else{
      this.roomService.callUserMuteUnMuteMic(false).then(() => {
        if (this.livekitService.isShareMusic) {
          this.livekitService.unmuteShareAudio()
        }
        this.livekitService.room.localParticipant.setMicrophoneEnabled(true);
        this.livekitService.allSenderAudioTrackAreMuted = false;
        this.livekitService.isMicEnabled = true
      }).finally(() => {
        this.roomService.prepareAndSendRefreshRoomUserMicListDynamicMessage();
      })
    }
  }

  toggleMyAudioMute(){
    if (!this.livekitService.allSenderAudioTrackAreMuted) {
      this.roomService.callUserMuteUnMuteMic(true).then(() => {
        if (this.livekitService.isShareMusic) {
          this.livekitService.muteShareAudio();
        }
        this.livekitService.room.localParticipant.setMicrophoneEnabled(false);
        this.livekitService.allSenderAudioTrackAreMuted = true;
        this.livekitService.isMicEnabled = false
      }).finally(() => {
        this.roomService.prepareAndSendRefreshRoomUserMicListDynamicMessage();
      });
    }
    else{
      this.roomService.callUserMuteUnMuteMic(false).then(() => {
        if (this.livekitService.isShareMusic) {
          this.livekitService.unmuteShareAudio()
        }
        this.livekitService.room.localParticipant.setMicrophoneEnabled(true);
        this.livekitService.allSenderAudioTrackAreMuted = false;
        this.livekitService.isMicEnabled = true
      }).finally(() => {
        this.roomService.prepareAndSendRefreshRoomUserMicListDynamicMessage();
      })
    }
  }

  toggleMicForMuteUnMute(){
   let isMicEnabled = this.livekitService.room.localParticipant.isMicrophoneEnabled
    if (isMicEnabled) {
      this.roomService.callUserMuteUnMuteMic(true).then(() => {
        this.livekitService.room.localParticipant.setMicrophoneEnabled(false);
        this.livekitService.isMicEnabled = false
      }).finally(() => {
        this.roomService.prepareAndSendRefreshRoomUserMicListDynamicMessage();
      })
    }
    else{
      this.roomService.callUserMuteUnMuteMic(false).then(() => {
        this.livekitService.room.localParticipant.setMicrophoneEnabled(true);
        this.livekitService.isMicEnabled = true
      }).finally(() => {
        this.roomService.prepareAndSendRefreshRoomUserMicListDynamicMessage();
      })
    }
  }
}
