import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class PermissionsComponent implements OnInit {

  constructor(
    public panelManagerService: PanelManagerService,
    public indexedDBService: IndexedDBService
  ) {
  
  }

  ngOnInit(): void {
  }

}
