import { RankFullInfo } from 'src/app/core/models/rank';
import { SqlUser } from 'src/app/admin/models/users';
import { AltYetkililer } from '../../sub-authors/models/sub-authors';

export class AuthorizedUsers{
    AuthorizedUsers: Array<AuthorizedUser> = new Array<AuthorizedUser>();
    TotalCount: number = 0;
}
export class AuthorizedUser{
    BitisSuresiRengi: number;
    Derece: number;
    Id: number;
    ProfilResmiId: string;
    Rumuz: string;
    RutbeAdi: string;
    RutbeId: number;
    SonGiris: String;
    UstYetkili: string;
    BitisTarihi:string;
}

export class AuthorizedUserDetails {
    AltYetkililer: Array<AltYetkililer>;
    Bilgisayarlar: any[];
    User: SqlUser;
    Yetki: RankFullInfo;
}

export class UserAllLoginInfo{
    FINGERPRINT:string
    IP:string
    TABID:string
    LOGINDATE:string
}