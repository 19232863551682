<div *ngIf="this.indexedDBService.userFullInfo.RutbeDerece != -1" ngbDropdown class="d-inline-block">
    <button class="photo-upload" id="photoUpdateMenu" ngbDropdownToggle>
        <fa-icon [icon]="faUpload" class="faupload"></fa-icon>
    </button>
    <div ngbDropdownMenu aria-labelledby="photoUpdateMenu">
        <button ngbDropdownItem (click)="panelManagerService.imageCapturePopupDisplay = true">
            <fa-icon [icon]="faCamera"></fa-icon>
            <span>{{ 'selectimagefromcamera' | translate }}</span>
        </button>
        <button ngbDropdownItem>
            <fa-icon [icon]="faImage"></fa-icon>
            <input type="file" id="fileUpload" (change)="fileChangeEvent($event)"
                (click)="onInputClick($event)" accept="image/*" name="profilePhoto" style="display: none;">
            <span (click)="openFileUpload($event)">{{ 'selectimagefromfile' | translate }}</span>
        </button>
        <button ngbDropdownItem (click)="panelManagerService.selectAvatarPopupDisplay = true">
            <fa-icon [icon]="faFileImage"></fa-icon>
            <span>{{ 'select-avatar' | translate }}</span>
        </button>
        <button ngbDropdownItem (click)="deleteProfilePhoto()" class="deleteItem">
            <fa-icon [icon]="faTrash"></fa-icon>
            <span>{{ 'profile-picture-delete' | translate }}</span>
        </button>
    </div>
</div>