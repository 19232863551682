import { ChartModule } from 'primeng/chart';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//#region  ngx modules
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ImageCropperModule } from 'ngx-image-cropper';
//#endregion

//#region primeng modules
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ButtonModule } from 'primeng/button';
import { SliderModule } from 'primeng/slider';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FieldsetModule } from 'primeng/fieldset';
import { TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ListboxModule } from 'primeng/listbox';
import { TooltipModule } from 'primeng/tooltip';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToastModule } from 'primeng/toast';
import { SidebarModule } from 'primeng/sidebar';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';  
import { EditorModule } from 'primeng/editor';
import { InputMaskModule } from 'primeng/inputmask'; 
//#endregion

//#region pipes
import { CountdownPipe } from './pipes/countdown.pipe';
import { SafeHtmlPipe } from './pipes/safe-html-pipe';
import { KeysPipe } from './pipes/key-value-pipe';
import { SafePipe } from './pipes/safe-pipe';
import { FilterPipe } from './pipes/filter.pipe';
//#endregion

//#region fontawesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
//#endregion

//#region  other modules
import { AngularDraggableModule } from 'angular2-draggable';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ResizableModule } from 'angular-resizable-element';
//#endregion

//#region primeng services
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

//#endregion

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/app/", ".json");
};


export function createTranslateLoader2(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/admin/", ".json");
};
@NgModule({
  declarations: [
    SafeHtmlPipe,
    KeysPipe,
    SafePipe,
    CountdownPipe,
    FilterPipe
  ],
  imports: [
    FontAwesomeModule,
    CommonModule,
    ScrollPanelModule,
    ButtonModule,
    SliderModule,
    InputSwitchModule,
    FieldsetModule,
    TabViewModule,
    DropdownModule,
    RadioButtonModule,
    ListboxModule,
    TooltipModule,
    ColorPickerModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    CheckboxModule,
    DialogModule,
    MenuModule,
    ToggleButtonModule,
    ToastModule,
    SidebarModule,
    ContextMenuModule.forRoot({
      useBootstrap4: true,
    }),
    PickerModule,   
    AngularDraggableModule,
    DeviceDetectorModule,
    SwiperModule,
    ImageCropperModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ResizableModule,
    InputTextModule,
    InputNumberModule,
    InputTextareaModule,
    CalendarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader, 
        useFactory: (createTranslateLoader), 
        deps: [HttpClient]
      }
    }),
    TableModule,
    CardModule,
    EditorModule,
    InputMaskModule,
    DialogModule,
    ChartModule
  ],
  exports: [
    FontAwesomeModule,
    CommonModule,
    ScrollPanelModule,
    ButtonModule,
    SliderModule,
    InputSwitchModule,
    FieldsetModule,
    TabViewModule,
    DropdownModule,
    RadioButtonModule,
    ListboxModule,
    TooltipModule,
    ColorPickerModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    CheckboxModule,
    DialogModule,
    MenuModule,
    ToggleButtonModule,
    ToastModule,
    SidebarModule,
    ContextMenuModule,
    PickerModule,
    AngularDraggableModule,
    DeviceDetectorModule,
    SwiperModule,
    ImageCropperModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ResizableModule,
    SafeHtmlPipe,
    KeysPipe,
    SafePipe,
    CountdownPipe,
    FilterPipe,
    InputTextModule,
    InputNumberModule,
    InputTextareaModule,
    CalendarModule,
    TranslateModule,
    TableModule,
    CardModule,
    EditorModule,
    InputMaskModule,
    DialogModule,
    ChartModule
  ],
  providers: [
    ConfirmationService,
    MessageService
  ]
})
export class SharedModule {
  constructor(private translate: TranslateService) {
    // Add an icon to the library for convenient access in other components
  
    
    library.add(fas);
    library.add(far);
    library.add(fab);
  }
}
