import { Component, OnInit, Input, ViewEncapsulation, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { faStar } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-rank',
  templateUrl: './rank.component.html',
  styleUrls: ['./rank.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class RankComponent implements OnInit, OnChanges {
  @Input() degree: number;
  @Input() color: string = "white";
  @Input() size: number = 12;

  faStar = faStar;
  stars: Array<any>;
  load: Promise<boolean>;
  constructor() { }
  ngOnInit() {
    this.ngOnChanges();
  }

  ngOnChanges() {
    this.stars = [];
    for (var i = 0; i < this.degree; i++)
      this.stars.push(i);

    this.load = Promise.resolve(true);
  }
}