<p-dialog [(visible)]="panelManagerService.roomInfoCardPopupDisplay" [modal]="true" [baseZIndex]="999"
    [responsive]="true" [showHeader]="false" (onHide)="closeUserCard()" [dismissableMask]="true">
    <p-scrollPanel [style]="{width: '100%', height: '100%'}" #userCard>

        <div class="user-card-container">

            <div class="user-card">
                <app-user-photo [userPhotoId]="roomService.selectedRoomInfo.OwnerPicture" [width]="40" [height]="40"
                    [showStateIcon]="false" class="user-photo" (click)="showRoomOwnerPicture()"></app-user-photo>
                <div>
                    {{ roomService.selectedRoomInfo.RoomName }}
                    <app-room-id [roomId]="roomService.currentRoom.Info.ID"></app-room-id>
                </div>
            </div>
            
        </div>
    </p-scrollPanel>
</p-dialog>