import { Component, OnInit } from '@angular/core';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { AdminRoomService } from '../../../rooms/services/room.service';
import { DeviceService } from 'src/app/core/services/device.service';
import { UtilService } from 'src/app/core/services/util.service';

import { RoomAiService } from '../../services/room-ai.service';

@Component({
  selector: 'app-room-ai-image-cropper',
  templateUrl: './room-ai-image-cropper.component.html',
  styleUrls: ['./room-ai-image-cropper.component.scss']
})
export class RoomAiImageCropperComponent implements OnInit {
  isObject: boolean;
  croppedImage: string = '';
  canvasRotation = 0;
  rotation: number;
  scale = 1;
  showCropper = false;
  transform: ImageTransform = {};
  visibleSidebar: boolean = false;

  constructor(
    public panelManagerService: PanelManagerService,
    private messagePanelService: MessagePanelService,
    public adminRoomService: AdminRoomService,
    public deviceService: DeviceService,
    private utilService: UtilService,
    public roomAiService: RoomAiService
  ) {
  }

  ngOnInit() {
    this.setImageType();
  }

  openFileUpload($event) {
    $event.preventDefault();
    let element: HTMLElement = document.getElementById('imageFileUpload') as HTMLElement;
    element.click();
    this.closeSideBar();
  }

  setImageType() {
    (typeof this.roomAiService.aiImageChangedEvent) === 'object' ? this.isObject = true : this.isObject = false;
  }

  fileChangeEvent($event) {
    if ($event.target.files && $event.target.files.length) {
      for (const file of $event.target.files) {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => {
          const img = new Image();
          img.src = reader.result as string;
          img.onload = () => {
            if (img.width < 400 || img.height < 400) {
              this.messagePanelService.ShowPopupMessageWithLocalization('InvalidProfilePhotoSize', 2000);
              return;
            }
            else {
              this.roomAiService.aiImageChangedEvent = $event;
            }
          };
        };
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.utilService.resizeImage(event.base64, 300, 170, new Image())
      .then((value: string) => {
        this.croppedImage = value;
      })

  }

  takePicture() {
    this.roomAiService.aiImageChangedEvent = "";
    this.panelManagerService.imageCropperPopupDisplay = false;
    this.panelManagerService.imageCapturePopupDisplay = true;
  }

  saveImage() {
    let croppedImgForDisplay = this.croppedImage;
    this.croppedImage = this.croppedImage.split(',')[1];
      if (this.roomAiService.aiImageChangedEvent.target.files && this.roomAiService.aiImageChangedEvent.target.files.length) {
      for (const file of this.roomAiService.aiImageChangedEvent.target.files) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const img = new Image();
          img.src = reader.result as string;
          this.roomAiService.isAiImageEditMode= true
          this.roomAiService.aiImageEditBackImage = croppedImgForDisplay;
          //  this.roomAiService.roomDetails.AIIcon = this.utilService.guid();
          // this.roomAiService.isThereAiImage = true;
        };
      }
    }
    this.roomAiService.roomAiImageId = this.croppedImage;
    this.panelManagerService.roomAIImageCropperPopupDisplay = false;
  }


  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
  }

  loadImageFailed() {

  }

  rotateLeft() {
    this.closeSideBar();
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.closeSideBar();
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }


  flipHorizontal() {
    this.closeSideBar();
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.closeSideBar();
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.closeSideBar();
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }

  onHide($event) {

    this.panelManagerService.imageCropperPopupDisplay = false;
    this.roomAiService.aiImageChangedEvent = "";
  }

  closeSideBar() {
    this.visibleSidebar = false;
  }
}

