import { Injectable } from '@angular/core';
import { Subject, Observable, Subscription } from 'rxjs';
import { NatsConfig } from '../models/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OperationMessage } from '../../models/operation-message';
import { ChatMessage } from '../../models/chat-message';
import { IndexedDBService } from '../../services/indexed-db.service';
import { environment } from '../../../../environments/environment';
import { UtilService } from '../../services/util.service';

declare function require(name: string);

@Injectable({
    providedIn: 'root',
})
export class NatsService {
    dynamicMethodsReceivedSource: Subject<OperationMessage> = new Subject();
    dynamicMethodsReceived = this.dynamicMethodsReceivedSource.asObservable();

    roomMessagesReceivedSource: Subject<ChatMessage> = new Subject();
    roomMessagesReceived = this.roomMessagesReceivedSource.asObservable();

    constructor(
        private http: HttpClient,
        private indexedDBService: IndexedDBService,
        private utilService: UtilService,
    ) {

    }

    getRoomChatChannelName(roomId: string) {
        return NatsConfig.roomChannelTag + NatsConfig.roomChannelSeperator + roomId;
    }

    publishPublicSettingMessage(data: any) {
        return this.publishPublicSettingMessageEndpoint(NatsConfig.sessionChannelTag, data);
    }

    publishRoomChatMessage(roomId: number, data: any) {
        // return this.publish(this.subscriptions[this.getRoomChatChannelName(roomId.toString())], data);
        return this.publishRoomChatMessageEndpoint(this.getRoomChatChannelName(roomId.toString()), data);
    }
    publishRoomAIChatMessage(roomId: number, data: any) {
        return this.publishRoomAIChatMessageEndpoint(this.getRoomChatChannelName(roomId.toString()), data);
    }

    publishPublicSettingMessageEndpoint(channelName: string, data: any) {
        data.Channelname = channelName;
        if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
            data.MessageId = this.utilService.guid();;
        }
        let serviceurl = `${environment.apiUrl}api/dyn/PublishPublicSettingMessage`;
        return this.http.post<any>(serviceurl, data).toPromise();
    }

    publishRoomChatMessageEndpoint(channelName: string, data: any) {
        data.Channelname = channelName;
        if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
            data.MessageId = this.utilService.guid();;
        }
        let serviceurl = `${environment.apiUrl}api/dyn/PublishRoomChatMessage`;
        return this.http.post<any>(serviceurl, data).toPromise();
    }
    
    publishRoomAIChatMessageEndpoint(channelName: string, data: any) {
        data.Channelname = channelName;
        if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
            data.MessageId = this.utilService.guid();;
        }
        let serviceurl = `${environment.apiUrl}api/dyn/DynPublishRoomAIChatMessage`;
        return this.http.post<any>(serviceurl, data).toPromise();
    }

    publishP2PChatMessage(userId: number, receiverId: number, data: any) {
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let serviceurl = environment.apiUrl + "api/dyn/PublishP2PChatMessage/";
        return this.http.post<any>(serviceurl + receiverId, data, options)
            .toPromise();
    }

    publishP2PSettingMessage(userId: number, receiverId: number, data: any) {
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let serviceurl = environment.apiUrl + "api/publishP2PSettingMessage/";
        return this.http.post<any>(serviceurl + receiverId, data, options)
            .toPromise();
    }


    publishBulkMessageToFriends(userId: number, data: any) {
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let serviceurl = environment.apiUrl + "api/publish/friends";
        return this.http.post<any>(serviceurl, data, options)
            .toPromise();
    }


    publishBulkMessageToRankMembers(userId: number, data: any) {
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        let serviceurl = environment.apiUrl + "api/publish/rankmembers/";
        return this.http.post<any>(serviceurl + userId + '/' + this.indexedDBService.userFullInfo.Token, data, options)
            .toPromise();
    }

    publishBulkMessageToRankMembersFriends(userId: number, data: any) {
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        let serviceurl = environment.apiUrl + "api/publish/rankmembersfrineds/";
        return this.http.post<any>(serviceurl + userId + '/' + this.indexedDBService.userFullInfo.Token, data, options)
            .toPromise();
    }
}
