import { EventEmitter, Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class MessagePanelService {

  private subject = new Subject<any>();
  private keepAfterNavigationChange = false;

  displayMessagePanel: boolean = false;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private utilService: UtilService) {
    // clear alert message on route change
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next();
        }
      }
    });
  }


  ShowPopupMessageWithLocalization(key, autoCloseMilisecond, closable: boolean = false) {
    this.subject.next({ key: this.utilService.parseGetMessageValue(key), autoCloseMilisecond: autoCloseMilisecond, closable: closable });
  }

  getMessage(): Observable<any> {
    this.displayMessagePanel = true;
    return this.subject.asObservable();
  }
}