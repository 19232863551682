import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { ServiceInformation } from './serviceinformation.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UtilService } from 'src/app/core/services/util.service';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { Constants } from '../models/constants';

@Injectable({
    providedIn: 'root'
})
export class YoutubeService extends ServiceInformation {
    private httpClientForYoutube: HttpClient;
    apiKey: string = Constants.YoutubeApiKey;
    constructor(
        private handler: HttpBackend,
        private http: HttpClient,
        public messagePanelService: MessagePanelService,
        public utilService: UtilService,
        protected indexedDBService: IndexedDBService
    ) {
        super(messagePanelService, utilService, indexedDBService);
        this.httpClientForYoutube = new HttpClient(this.handler);

    }

    public getVideosBySearchText(q): Observable<Object> {
        let url = 'https://www.googleapis.com/youtube/v3/search?key=' + this.apiKey + '&part=snippet&type=video,id&q=' + q;
        return this.httpClientForYoutube.get(url)
          .pipe(map((res) => {
            return res;
          }))
      }
}
