import { Injectable } from '@angular/core';
import { DynamicMethod } from '../models/dynamic-method';
import { UserFullInfo } from 'src/app/core/models/user';
import { ChatType, ChatState } from 'src/app/core/models/enums';
import { OperationMessage } from '../models/operation-message';
import { UtilService } from './util.service';

@Injectable({
    providedIn: 'root'
})
export class OperationMessageService {

    constructor(
        private utilService: UtilService
    ) {

    }

    getSettingMessage(sender: UserFullInfo, channelName: string, chatType: ChatType, dynamicMethodArray: Array<DynamicMethod>, receiverId?: number, receiverName?: string) {
        var operationMessage = new OperationMessage();

        operationMessage.SenderId = sender.KullaniciId;
        operationMessage.SenderName = sender.KullaniciAdi;
        operationMessage.SenderRutbeDerece = sender.RutbeDerece;
        operationMessage.ReceiverId = this.utilService.isNullOrUndefined(receiverId) ? sender.KullaniciId : receiverId;
        operationMessage.ReceiverName =this.utilService.isNullOrUndefined(receiverName) ? sender.KullaniciAdi : receiverName;
        operationMessage.Channelname = channelName + "_forSettings";
        operationMessage.ChatType = chatType;
        operationMessage.Chatstate = ChatState.GonderilenMesaj;
        operationMessage.MessageId = this.utilService.guid();

        var methods = [];
        var parameters = [];

        for (let methodIndex = 0; methodIndex < dynamicMethodArray.length; methodIndex++) {

            methods.push(dynamicMethodArray[methodIndex].MethodName);

            if (parameters[methodIndex] === null || parameters[methodIndex] === undefined)
                parameters[methodIndex] = [];

            for (let paramIndex = 0; paramIndex < dynamicMethodArray[methodIndex].Parameters.length; paramIndex++)
                parameters[methodIndex].push(dynamicMethodArray[methodIndex].Parameters[paramIndex]);
        }

        operationMessage.MethodNames = methods;
        operationMessage.Parameters = parameters;

        return operationMessage;
    }
}   