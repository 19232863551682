<p-dialog class="updateStateMessage" [(visible)]="panelManagerService.updateStateMessagePopupDisplay" [modal]="true"
[baseZIndex]="10000" [responsive]="true" header="{{'stateMessage' | translate }}">

    <div style="text-align:center;">
        <input type="text" autofocus="autofocus" placeholder="{{'stateMessage' | translate }}" 
            pInputText class="inputText dialog-input" [(ngModel)]="stateMessage" />
    </div>

    <p-footer>
        <button pButton type="button" (click)="updateStateMessage()" label="{{'save' | translate}}"
            class="ui-button-raised ui-button-info popup" style="width: 100%;"></button>
    </p-footer>

</p-dialog>
