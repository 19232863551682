import { Component } from '@angular/core';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { RoomService } from 'src/app/core/services/room.service';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
  selector: 'app-room-info-card',
  templateUrl: './room-info-card.component.html',
  styleUrls: ['./room-info-card.component.scss']
})
export class RoomInfoCardComponent {
  constructor(
    public panelManagerService: PanelManagerService,
    public roomService: RoomService,
    public indexedDBService:IndexedDBService,
    public utilService:UtilService
  ) {
  }
  
  closeUserCard() {
    this.panelManagerService.roomInfoCardPopupDisplay = false;
  }

  showRoomOwnerPicture() {
    this.indexedDBService.friendProfilePicture = this.utilService.getProfilePhotoUrl(this.roomService.currentRoom?.OwnerInfo?.ProfilResmiId, true);
    this.panelManagerService.profilePhotoPopupDisplay = true;
  }
}
