<p-dialog [(visible)]="panelManagerService.roomSecondNickPanelIsOpened" [modal]="true" [responsive]="true"
    [closeOnEscape]="false" class="secondnick">
    <p-header>
        {{'SecondNick' | translate}}
    </p-header>
    <div>{{'SecondRoomPopupDescription' | translate}}</div>
    <div style="text-align:center;">
        <input type="text" pInputText id="roomSecondNickTextbox" class="textbox roomSecondNameTextBox dialog-input"
            placeholder="{{'EnterSecondNick' | translate}}" (keyup.enter)="enterSecondNick()" [(ngModel)]="secondnickname"  />
    </div>

    <p-footer class="footer">
        <button pButton type="button" (click)="enterSecondNick()" label="{{'enterTheRoom' | translate}}"
            class="ui-button-raised ui-button-info popup"></button>
        <!-- <button pButton type="button" (click)="enterSecondNick(_roomNickSelectionType.RealUserName)" label="{{'normalRoomEnter' | translate}}"
            class="ui-button-raised ui-button-danger popup"></button> -->
         </p-footer>

</p-dialog>