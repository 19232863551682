import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { nanoid } from 'nanoid'
@Component({
  selector: 'app-redirect-dynamic-room',
  templateUrl: './redirect-unregister-type.component.html',
  styleUrls: ['./redirect-unregister-type.component.scss']
})
export class RedirectUnRegisterTypeComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router
) {
  
}
  ngOnInit(): void {
    this.initGetTemplateIdAndRedirectToDynamicRoom()
  }

  initGetTemplateIdAndRedirectToDynamicRoom() {
    this.route.params.subscribe(params => {
      let templateId = parseInt(params['templateId']);
      this.controlTemplateIdAndRedirect(templateId)
    });
  }

  controlTemplateIdAndRedirect(templateId: number) {
    if (templateId) {
      const guid = nanoid();
      this.router.navigate(['/templateId', templateId, guid]);
    }
  }
}
