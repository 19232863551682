<div class="p2p-editor" >
        <!-- <div class="folder">
             <fa-icon [icon]="faFolder" class="folder-icon"></fa-icon>
        </div> -->
    <div class="emoji-panel" [hidden]="!emojiPanelIsOpen" *ngIf="!deviceService.isMobile" #emojiPanel>
        <emoji-mart set="apple" (emojiClick)="addEmoji($event)"
            [i18n]="{ search: 'Recherche', categories: { search: 'Résultats de recherche', recent: 'Récents' } }">
        </emoji-mart>
    </div>

    <div class="editor-content">
        <div class="editor-panels">
            <app-p2p-chat-animation [isDisplay]="animationToggle"></app-p2p-chat-animation>
        </div>
        <!-- <fa-icon [icon]="faSmile" class="editor-icon" *ngIf="!deviceService.isMobile" #emojiImg ></fa-icon> -->
        <div (click)="docsPopupDisplay()" class="folder">
             <fa-icon [icon]="faFolder" class="editor-icon"></fa-icon>
        </div>
        <div (click)="toggleAnimationsPanel()" class="editor-action-button" #animation
            pTooltip="{{ 'sendanimation' | translate }}" tooltipPosition="top">
            <fa-icon [icon]="faFilm" class="editor-icon"></fa-icon>
         </div>
        <div style="width: 100%">
            <p-scrollPanel [style]="{ 'width': '100%' }" class="editor-content-scroll">
                <app-editor (editorTextChanged)="editorTextChange($event)" (editorEnterPressed)="editorEnterPress()" #editor
                    [editorMessageForSet]="editorMessageForSet" [editorOn]="editorOn.P2PChat"></app-editor>
            </p-scrollPanel>
        </div>
        
        <fa-icon [icon]="faPaperPlane" class="editor-icon" (click)="sendMessage()"></fa-icon>
    </div>
</div>