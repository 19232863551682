<p-dialog [(visible)]="panelManagerService.p2pContextMenuPopupDisplay" [modal]="true" [baseZIndex]="99999999999"
  [responsive]="true" [header]="friend?.KullaniciAdi" (onHide)="closeContextMenu()" [dismissableMask]="false">

  <div class="context-menu">
    <div class="item" (click)="refreshFriendList()">
      <fa-icon [icon]="faRetweet"></fa-icon>
      <span> {{ 'refreshfriendlist' | translate }} </span>
    </div>

    <div class="item" *ngIf="!panelManagerService.showedFriendList" (click)="openChatPanel()">
      <fa-icon [icon]="faCommentAlt"></fa-icon>
      <span> {{ 'sendmessage' | translate }} </span>
    </div>

    <div class="item" (click)="blockUser()">
      <fa-icon [icon]="faBan"></fa-icon>
      <span> {{ 'blockuser' | translate }} </span>
    </div>

    <div class="item" (click)="deleteRequest()">
      <fa-icon [icon]="faMinusCircle"></fa-icon>
      <span> {{ 'removeFriend' | translate }} </span>
    </div>

    <div class="item" (click)="complainUser()">
      <fa-icon [icon]="faFlag"></fa-icon>
      <span> {{ 'report' | translate }} </span>
    </div> 
    <div class="item last" (click)="whichRoomUserIsIn()">
      <fa-icon [icon]="isChevronDown ? faChevronDown : faChevronRight"></fa-icon>
      <span > {{ 'which-room-friend-in' | translate }} </span>
    </div>
    <div class="item-roominfo" *ngIf="isChevronDown">
      <div class="roomInfo" *ngIf="!isLoading">
        <p *ngIf="friendCurrentRoomInfo; else noRoom" class="room-name-context">{{friendCurrentRoomInfo.room_name}}</p>
        <button *ngIf="friendCurrentRoomInfo" class="custom-btn" (click)="goToRoom()" type="button">{{'go-room' | translate}}</button>
      </div>
      <div class="roomInfo" *ngIf="isLoading">
        <p class="room-name">{{'loading' | translate}}.</p>
      </div>
    </div>
    <ng-template #noRoom>
      <p class="room-name">{{'no-room-friend-in' | translate}}</p>
    </ng-template>
  </div>
</p-dialog>