<p-dialog [(visible)]="panelManagerService.selectAvatarPopupDisplay" [modal]="true" [baseZIndex]="10000"
  [responsive]="true" header="{{ 'select-avatar' | translate}}">
  <p-scrollPanel [style]="{'width': deviceService.isMobile ? '100%' : '500px', 'height': '500px'}">
    <div *ngFor="let avatarIndex of utilService.avatarCounts" style="display: inline-block;">
      <div class="img_wrap" (click)="confirmImageSelected(avatarIndex)">
        <img [src]="photoPath + '/10000000-0000-0000-0000-0000000000' + (avatarIndex+1<10?'0':'')+ (avatarIndex +1) + '.png'" class="avatar" />
        <div class="hoveredImageLayer"> 
          <span class="hoveredImage">{{ 'select-image' | translate }}</span>
        </div>
      </div>
      <br *ngIf="avatarIndex % 5 === 0" />
    </div>
  </p-scrollPanel>
</p-dialog>