<div class="room-container" #roomMainDiv>

    <div class="user-list" [ngStyle]="roomService.userListBackground">
        <div class="user-count transparent-bg">
                    <span> {{ roomService.roomUserCount + " " + ('person' | translate) }} </span>
        </div>
        <div class="user-list-top">
            <div class="user-type middle" [ngClass]="roomService.selectedUserListType === 1 ? 'active' : '' " (click)="listOnlineUsers()">
                online
            </div>  
            <div class="user-type left" [ngClass]="roomService.selectedUserListType === 0 ? 'active' : '' " (click)="listVIPUsers()">
                vip
            </div>
            <div class="user-type right" [ngClass]="{'active': roomService.selectedUserListType === 2, 'pulse': roomService.isThereHandRequest}" (click)="listHandRequest()">
                {{'request' | translate}}
            </div>   
        </div>
        

        <div class="user-list-content">
            <app-room-user-list [roomUserList]="roomService.roomUserList"></app-room-user-list>
            <div class="top-pagination">
                <div class="pagination">
                    <div tooltipPosition="bottom" pTooltip="{{ 'İlk Liste' | translate }}" class="page-element w-action" 
                    [ngClass]="{'pagination-button-disabled': this.roomService.isClickedPaginationElement}"
                    (click)="firstPage()">
                        <span>First</span>
                    </div>
                    <div class="page-element w-action" tooltipPosition="bottom" pTooltip="{{ 'Bir Önceki Liste' | translate }}"
                    [ngClass]="{'pagination-button-disabled': this.roomService.isClickedPaginationElement}" 
                   >
                        <fa-icon class="prev" (click)="previousPageUserList()" [icon]="faChevronLeft"></fa-icon>
                    </div>
                    <div class="page-element index"  [ngClass]="{'pagination-button-disabled': this.roomService.isClickedPaginationElement}">
                        <span>{{roomService.pageIndex}}</span>
                    </div>
                    <div class="page-element w-action" tooltipPosition="bottom" pTooltip="{{ 'Bir Sonraki Liste' | translate }}"
                    [ngClass]="{'pagination-button-disabled': this.roomService.isClickedPaginationElement}"
                    >
                        <fa-icon class="prev" (click)="nextPageUserList()" [icon]="faChevronRight"></fa-icon>
                    </div>
                    <div class="page-element w-action" tooltipPosition="bottom" pTooltip="{{ 'Son Liste' | translate }}" (click)="lastPage()"
                    [ngClass]="{'pagination-button-disabled': this.roomService.isClickedPaginationElement}"
                    > 
                        <span>Last</span>
                    </div> 
                </div>
            </div>
        </div>

    </div>

    <div class="chat-content" [ngStyle]="{ 'background': roomBack }">
        <div class="room-chat-header transparent-bg">
            <div class="chat-header-left">
                <fa-icon *ngIf="indexedDBService.userFullInfo.RutbeDerece != -1" [icon]="faHeart" class="cursor" [ngStyle]="{'color': isFavoriteRoom ? '#ff4040' : 'white'}"
                    (click)="isFavoriteRoom ? removeFromFavoriteRoom() : addToFavoriteRoom()"
                    pTooltip="{{ isFavoriteRoom ? ('removeFromFavoriteRoom' | translate) : ('addToFavoriteRoom' | translate) }}"
                    tooltipPosition="top"></fa-icon>
                <app-user-photo [userPhotoId]="roomService.currentRoom?.OwnerInfo?.ProfilResmiId" [width]="40"
                    [height]="40" [showStateIcon]="false" class="cursor"
                    (click)="showRoomOwnerInfoCard()"></app-user-photo>
                <div class="room-owner-name">
                    {{ roomService.currentRoom?.OwnerInfo?.KullaniciAdi
                    ? roomService.currentRoom?.OwnerInfo?.KullaniciAdi
                    : (roomService.environmentVariable.UygulamaAdi ? roomService.environmentVariable.UygulamaAdi : constants.APPLICATION_NAME) }}

                    <div *ngIf="roomService.currentRoom?.OwnerInfo">
                        <app-rank [degree]="roomService.currentRoom?.OwnerInfo?.RutbeDerece" [color]="'orange'"
                            [size]="10">
                        </app-rank>
                    </div>
                </div>
            </div>

            <div class="chat-header-center">
                <div class="who-can-enter" [ngClass]="{'everyone': roomService.currentRoom.Info?.KIMLER_GIREBILIR === whoEnterRoomTypes.Everyone, 
                    'only-authorities': roomService.currentRoom.Info?.KIMLER_GIREBILIR === whoEnterRoomTypes.AuthoritiesOnly, 
                    'only-users': roomService.currentRoom.Info?.KIMLER_GIREBILIR === whoEnterRoomTypes.UsersOnly}"
                    pTooltip="{{
                        (roomService.currentRoom.Info?.KIMLER_GIREBILIR === whoEnterRoomTypes.Everyone ? 'roomOpenEverybody' :
                        (roomService.currentRoom.Info?.KIMLER_GIREBILIR === whoEnterRoomTypes.AuthoritiesOnly ? 'roomOpenOnlyAdmins'
                        :
                        'roomOpenOnlyUsers'))
                        | translate
                        }}" tooltipPosition="bottom">
                </div>
                <div class="room-name">{{ roomService.currentRoom.Info?.NAME }}</div>
                <div class="trophy-container" pTooltip="{{ 'room-point' | translate }}" tooltipPosition="bottom">
                    <fa-icon class="trophy ml-2" [icon]="faTrophy"></fa-icon>
                    <span class="room-trophy-count">{{  (roomService.currentRoom.Info?.Point + roomService.currentRoom.Info?.Stats) | kFormatter }}</span>
                </div>
            </div>

            <div class="chat-header-right">
                <div class="page-actions white cursor">
                    <fa-icon *ngIf="indexedDBService.userFullInfo.RutbeDerece == 10 && roomService.currentRoom.Info.SIP_IS_ON && roomService.environmentVariable.IsSipOn" [icon]="faPhone" class="faMinus" (click)="openPhoneCallingPanel()"></fa-icon>
                    <img *ngIf="roomService.environmentVariable.CanRecord && indexedDBService.userFullInfo.RutbeDerece != -1" src="../../../../assets/icons/records.png" class="records"
                        (click)="openRoomRecordPanel()" alt="">
                    <fa-icon *ngIf="indexedDBService.userFullInfo.RutbeDerece != -1" [icon]="faHeadset" class="faHeadset" pTooltip="Canlı Destek - Başvuru"
                        tooltipPosition="bottom" (click)="openLiveSupportPanel()"></fa-icon>
                    <fa-icon *ngIf="indexedDBService.userFullInfo.RutbeDerece != -1" [icon]="faBars" pTooltip="Menü" tooltipPosition="bottom"
                        (click)="openRoomContextMenu($event)" class="faBars"></fa-icon>
                    <fa-icon [icon]="faMinus" class="faMinus" (click)="hidePanel()"></fa-icon>
                    <fa-icon [icon]="faRedoAlt" class="faRedoAlt" (click)="refreshPage()"></fa-icon>
                    <fa-icon [icon]="faTimes" class="faTimes" (click)="closePanel()"></fa-icon>

                </div>
            </div>
        </div>
        <div class="chat-area-all">
            <div class="room-mic-users"
                 [ngStyle]="{'grid-template-columns': 'repeat(' + (micUsersCount > 5 ? 10 : micUsersCount) + ', minmax(0, 1fr))'}">
                <ng-template app-user-on-mic-host></ng-template>
             </div>
            <app-room-chat-bubble></app-room-chat-bubble>
        </div>
        
        <div class="editor-area">
            <app-room-chat-editor (editorTextChanged)="editorTextChange($event)"></app-room-chat-editor>
        </div>

    </div>

    <div class="mic-cam-access" [ngStyle]="roomService.userListBackground">
        <div class="page-actions">
            <div [ngClass]="{
                'page-action-button-small': roomService.environmentVariable.CanScreenShare && roomService.environmentVariable.CanRecord,
                'page-action-button-medium': !roomService.environmentVariable.CanScreenShare || !roomService.environmentVariable.CanRecord,
                'page-action-button-big': !roomService.environmentVariable.CanScreenShare && !roomService.environmentVariable.CanRecord
             }">
                <div (click)="roomService.clickMicrophoneButton(false)"
                    pTooltip="{{ microphoneState === _microphoneState.Stopped ? ('takeMic' | translate) : ('stopMic' | translate)}}"
                    tooltipPosition="top"
                    [ngClass]="{'button-disabled' : roomService.micAccessButtonDisabled, 'button-enabled': !roomService.micAccessButtonDisabled}">
                    <fa-icon [icon]="microphoneState === _microphoneState.Stopped ? faMicrophone : faMicrophoneSlash"
                        class="mic-cam-buttons"
                        [ngStyle]="{'color': microphoneState === _microphoneState.Stopped ? 'white' : 'red'}"></fa-icon>
                </div>
            </div>

            <div [ngClass]="{
                'page-action-button-small': roomService.environmentVariable.CanScreenShare && roomService.environmentVariable.CanRecord,
                'page-action-button-medium': !roomService.environmentVariable.CanScreenShare || !roomService.environmentVariable.CanRecord,
                'page-action-button-big': !roomService.environmentVariable.CanScreenShare && !roomService.environmentVariable.CanRecord
             }">
                <div pTooltip="{{ 'handupdown' | translate }}" tooltipPosition="top"
                    (click)="roomService.clickMicrophoneButton(true)"
                    [ngClass]="{'button-disabled' : roomService.micAccessButtonDisabled, 'button-enabled': !roomService.micAccessButtonDisabled}">
                    <fa-icon [icon]="faHandPaper" class="mic-cam-buttons"></fa-icon>
                </div>
            </div>

            <div [ngClass]="{
                'page-action-button-small': roomService.environmentVariable.CanScreenShare && roomService.environmentVariable.CanRecord,
                'page-action-button-medium': !roomService.environmentVariable.CanScreenShare || !roomService.environmentVariable.CanRecord,
                'page-action-button-big': !roomService.environmentVariable.CanScreenShare && !roomService.environmentVariable.CanRecord
             }">
                <div (click)="roomService.openCameraNew(false)" pTooltip="{{ 'openCam' | translate }}"
                    tooltipPosition="top">
                    <fa-icon [icon]="faCamera" class="mic-cam-buttons"></fa-icon>
                </div>
            </div>
            <div [ngClass]="{
                'page-action-button-small': roomService.environmentVariable.CanScreenShare && roomService.environmentVariable.CanRecord,
                'page-action-button-medium': !roomService.environmentVariable.CanScreenShare || !roomService.environmentVariable.CanRecord,
                'page-action-button-big': !roomService.environmentVariable.CanScreenShare && !roomService.environmentVariable.CanRecord
             }">
                <div (click)="toggleShareMusic()"
                    [style.pointer-events]="livekitService.isOnScreenSharing || livekitService.isOnSharingTabMusic ? 'none' : 'auto'"
                    pTooltip="{{ 'music-share' | translate }}" tooltipPosition="top">
                    <img src="./../../../../assets/icons/tab-music.png" style="color: white;" class="music-share"
                        alt="">
                </div>
            </div>

            <div *ngIf="roomService.environmentVariable.CanScreenShare">
                <div [ngClass]="{
                    'page-action-button-small': roomService.environmentVariable.CanScreenShare && roomService.environmentVariable.CanRecord,
                    'page-action-button-medium': !roomService.environmentVariable.CanScreenShare || !roomService.environmentVariable.CanRecord,
                    'page-action-button-big': !roomService.environmentVariable.CanScreenShare && !roomService.environmentVariable.CanRecord
                 }" (click)="roomService.openScreenShare()" *ngIf="!livekitService.isOnScreenSharing"
                    [style.pointer-events]="livekitService.isOnSharingTabMusic || livekitService.isOnScreenSharing ? 'none' : 'auto'"
                    pTooltip="{{ 'screen-share' | translate }}" tooltipPosition="top">
                    <img src="./../../../../assets/icons/screen-share.png" class="screen-share" alt="">
                </div>
                <div [ngClass]="{
                    'page-action-button-small': roomService.environmentVariable.CanScreenShare && roomService.environmentVariable.CanRecord,
                    'page-action-button-medium': !roomService.environmentVariable.CanScreenShare || !roomService.environmentVariable.CanRecord,
                    'page-action-button-big': !roomService.environmentVariable.CanScreenShare && !roomService.environmentVariable.CanRecord
                 }" *ngIf="livekitService.isOnScreenSharing" (click)="stopScreenShare()" pTooltip="Stop Screen Share"
                    tooltipPosition="top">
                    <img src="./../../../../assets/icons/stop-screen-share.png" class="stop-screen-share" alt="">
                </div>
            </div>

            <div *ngIf="roomService.environmentVariable.CanRecord">
                <div *ngIf="!roomService.isRoomRecordingStarted" [ngClass]="{
                    'page-action-button-small': roomService.environmentVariable.CanScreenShare && roomService.environmentVariable.CanRecord,
                    'page-action-button-medium': !roomService.environmentVariable.CanScreenShare || !roomService.environmentVariable.CanRecord,
                    'page-action-button-big': !roomService.environmentVariable.CanScreenShare && !roomService.environmentVariable.CanRecord
                 }" (click)="toggleStartRecording()" pTooltip="Recording" tooltipPosition="top">
                    <img src="./../../../../assets/icons/recording.png" class="recording" alt="">
                </div>

                <div *ngIf="roomService.isRoomRecordingStarted" [ngClass]="{
                    'page-action-button-small': roomService.environmentVariable.CanScreenShare && roomService.environmentVariable.CanRecord,
                    'page-action-button-medium': !roomService.environmentVariable.CanScreenShare || !roomService.environmentVariable.CanRecord,
                    'page-action-button-big': !roomService.environmentVariable.CanScreenShare && !roomService.environmentVariable.CanRecord
                 }" (click)="toggleStopRecording()" pTooltip="Stop Recording" tooltipPosition="top">
                    <img src="./../../../../assets/icons/stop-recording.png" class="stop-recording" alt="">
                </div>
            </div>

        </div>
    </div>
</div>

<app-room-editor-context-menu *ngIf="showRoomEditorContextMenu" [roomUser]="roomUser"></app-room-editor-context-menu>