<p-dialog [(visible)]="panelManagerService.viewRecordVideoPopupDisplay" [modal]="true" [baseZIndex]="10000" [responsive]="true" header="Kayıt" (onHide)="closeVideoPanel()">
    <p-scrollPanel *ngIf="roomService.videoInfo.videoType != 'mp4'" [style]="{width: '100%', height: '100%'}">
        <div class="video-elm" *ngIf="roomService.videoInfo.videoType != 'mp4'">
            <video width="300px" appHlsVideoPlayer [videoUrl]="roomService.videoInfo.videoUrl" controls></video>
        </div>
    </p-scrollPanel>
    <p-scrollPanel *ngIf="roomService.videoInfo.videoType == 'mp4'" [style]="{width: '100%', height: '100%'}">
        <div class="video-elm" [hidden]="roomService.videoInfo.videoType != 'mp4'">
            <video width="300px" controls [src]="roomService.videoInfo.videoUrl" type="video/mp4"></video>
        </div>
    </p-scrollPanel>
    <p-footer>
        <button pButton type="button" label="{{ 'close' | translate }}" (click)="closeVideoPanel()" class="ui-button-raised ui-button-danger"></button>
    </p-footer>
</p-dialog>
