import { Component } from '@angular/core';
import { PanelManagerService } from '../../services/panel-manager.service';
import { UserService } from '../../services/user.service';
import { RoomService } from '../../services/room.service';
import { MessagePanelService } from '../../services/messagePanel.service';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-guest-user-warning',
  templateUrl: './guest-user-warning.component.html',
  styleUrls: ['./guest-user-warning.component.scss']
})
export class GuestUserWarningComponent {
  newUserName:string

  constructor(
    public panelManagerService: PanelManagerService,
    private userService:UserService,
    public roomService:RoomService,
    public messagePanelService: MessagePanelService,
    public loginService:LoginService

  ) { }

  signOut(){
    this.roomService.signOut()
  }
}
