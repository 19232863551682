export enum CurrentRoomPage {
    roomList = 0,
    roomDetail = 1
}

export enum RoomExpireType {
    NotExpired = 0,
    HalfExpired = 1,
    Expired = 2
}

export enum RoomListType{
    all = 0,
    static = 1,
    dynamic = 2,
}