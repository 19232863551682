<div class="user-list">
  <p-scrollPanel styleClass="user-list" [style]="{width: '100%',  height: deviceService.isMobile ? 'calc(100% - 20px)' : '100%'}">
      <ng-template app-room-user-list-user-host></ng-template>
      
      <div *ngIf="roomService.currentRoom.Info.ID" class="main-no-data">
        <div *ngIf="roomService.selectedUserListType === 0 && this.roomService.isEmptyVipUserList" class="no-data-message">
            <p>{{'no-vip-user' | translate}}</p>
        </div>
        <div *ngIf="roomService.selectedUserListType === 1 && this.roomService.isEmptyOnlineUserList" class="no-data-message">
          <p>{{'no-online-user' | translate}}</p>
        </div>
        <div *ngIf="roomService.selectedUserListType === 2 && this.roomService.isEmptyHandRequestUserList" class="no-data-message">
          <p>{{'no-broadcast-request' | translate}}</p>
        </div>
    </div>    
  </p-scrollPanel>
</div>
