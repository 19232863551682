import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalizationService {
    tagStart = "<LANG>";
    tagEnd = "</LANG>";
   
    constructor(

    ) {

    }

    getKeyWithTag(str) {
        return this.tagStart + str + this.tagEnd;
    }
}
