import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InsertComplainRequest } from '../models/report';
import { IndexedDBService } from './indexed-db.service';
import { MessagePanelService } from './messagePanel.service';
import { ServiceInformation } from './serviceinformation.service';
import { UtilService } from './util.service';

@Injectable({
    providedIn: 'root'
})
export class ReportService extends ServiceInformation {

    reportPopupDisplay: boolean = false;
    
    header: string = "";

    reportedMessageContent: string = "";
    insertComplainRequest: InsertComplainRequest = new InsertComplainRequest();

    constructor(
        protected messagePanelService: MessagePanelService,
        protected utilService: UtilService,
        protected indexedDBService: IndexedDBService,
        private http: HttpClient
    ) {
        super(messagePanelService, utilService, indexedDBService);
    }

    getComplaintList() {}

    insertComplain() : Observable<any>{
        this.insertComplainRequest.ClientInfo = this.Get_ClientInfo();
        
        const endpoint = this.ServiceUrl + 'InsertComplain';
        return this.http.post<any>(endpoint, this.insertComplainRequest, this.options)
          .pipe(catchError(error => this.handleError(error)));
    }

    deleteComplaint() {}
}