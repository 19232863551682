import { Injectable, ViewRef, ViewContainerRef } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class DynamicComponentManagerService {
    constructor(
    ) {
    }

    detachFromViewRef(viewContainerRef: ViewContainerRef, index: number): ViewRef {
        if (viewContainerRef.length > 0)
            return viewContainerRef.detach(index) as ViewRef;

        return null;
    }

    getDataByEmbeddedView<T>(viewRef: ViewRef, propSelector: string): T {

        let embeddedView = viewRef["_lView"];

        if (embeddedView) {

            let aaa = embeddedView.find(f => {
                if (f && f[propSelector]) {
                    return f[propSelector];
                }
            });

            
            return aaa[propSelector] as T;
        } else {
            return null;
        }

    }

    getEmbeddedViews(viewContainerRef: ViewContainerRef): Array<ViewRef> {

        let _lContainer = viewContainerRef["_lContainer"];

        let viewRefList: Array<ViewRef> = [];

        _lContainer.find((c) => {

            if (c && c.length > 0 && c.find) { // [ViewRef , RootViewRef]   

                c.find((ce) => {
                    if (ce && ce["_lView"]) {
                        viewRefList.push(ce);
                    }
                });
            }
        });

        return viewRefList;
    }

}
