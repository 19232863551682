<p-dialog styleClass="updateWallpaper" [(visible)]="panelManagerService.updateWallpaperPopupDisplay"   [modal]="true"
[baseZIndex]="10000" [responsive]="true">
    <p-header>
        {{'menu_setwallpaper' | translate}}
    </p-header>
    <div class="wallpaper">
        <div class="wallpaper-row">
            <p-radioButton (onClick)="selectBackColor($event)" class="radioButton" name="wallpaperSelection" value="{{backColor}}" label="{{ 'selectColor' | translate }}" [(ngModel)]="selectedValue"></p-radioButton>
            <p-colorPicker [(ngModel)]="backColor" (onChange)="onChange($event)"></p-colorPicker>
        </div>
        <div class="wallpaper-row">
            <p-radioButton (onClick)="selectBackImage($event)" class="radioButton" name="wallpaperSelection" value="{{backImage}}" label="{{ 'selectImage' | translate }}" [(ngModel)]="selectedValue"></p-radioButton>
            <img *ngIf="isThereBackImage" [src]="backImagePreview" class="wallpaper-preview" (click)="selectImage()" pTooltip="{{ 'choose' | translate }}" tooltipPosition="top" />
            <div class="wallpaper-upload">
                <input type="file" style="display: none;" (change)="fileProgress($event)" accept="image/*" id="wallpaperUpload">
                <span (click)="openFileUpload($event)">{{ 'selectwallpaper' | translate }}</span>
            </div>
        </div>
    </div>
</p-dialog>