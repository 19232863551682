import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { ChatMessageForBubble } from 'src/app/core/models/chat-message';
import { Constants } from 'src/app/core/models/constants';
import { UtilService } from 'src/app/core/services/util.service';
import { BanOrMute, MessageBubbleType, MessageType, SikayetTipleri } from 'src/app/core/models/enums';
import { RoomUserContextMenuMode, RoomUser } from 'src/app/core/models/room-user';
import { RoomService } from 'src/app/core/services/room.service';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { TranslateService } from '@ngx-translate/core';
import { faCheck, faFlag, faCaretDown, faFolder, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { DeviceService } from '../../services/device.service';
import { MessagePanelService } from '../../services/messagePanel.service';
import { UserService } from '../../services/user.service';
import { ReportService } from '../../services/report.service';
import { PanelManagerService } from '../../services/panel-manager.service';
import { BanPanel } from '../../models/ban-panel';
import { ConfirmationService } from 'primeng/api';
import { saveAs } from 'file-saver';
import { GlobalService } from '../../services/global.service';
@Component({
  selector: 'app-bubble',
  templateUrl: './bubble.component.html',
  styleUrls: ['./bubble.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class BubbleComponent implements OnInit {
  faCheck = faCheck;
  faFlag = faFlag;
  faCaretDown = faCaretDown;
  roomUserContextMenuMode = RoomUserContextMenuMode;
  messageBubbleType = MessageBubbleType;
  roomCamIconUrl: string = Constants.roomCamIconUrl;
  faPaperclip = faPaperclip

  message: ChatMessageForBubble;
  load: Promise<boolean>;
  roomUser?: RoomUser; //TODO
  isCameraOpenedMessage: boolean = false;
  meInRoom: RoomUser;

  messageHtmlElement: ElementRef;

  reportedMessageContent: string = "";
  mathJaxObject
  constructor(
    private utilService: UtilService,
    private roomService: RoomService,
    public indexedDBService: IndexedDBService,
    private translateService: TranslateService,
    public deviceService: DeviceService,
    private messagePanelService: MessagePanelService,
    private userService: UserService,
    private reportService: ReportService,
    private panelManagerService: PanelManagerService,
    private confirmationService: ConfirmationService,
    public gs: GlobalService
  ) {

  }

  ngOnInit() {
    if (this.roomService.meInRoom) {
      this.meInRoom = this.roomService.meInRoom
    }
  }

  loadMathConfig() {
    this.mathJaxObject = this.gs.nativeGlobal()["MathJax"];
    this.mathJaxObject.Hub.Config({
      showMathMenu: false,
      tex2jax: {
        inlineMath: [
          ["$", "$"],
          ["\\(", "\\)"]
        ]
      },
      menuSettings: { zoom: "Double-Click", zscale: "150%" },
      CommonHTML: { linebreaks: { automatic: true } },
      "HTML-CSS": {
        linebreaks: { automatic: true }
      },
      SVG: { linebreaks: { automatic: true } },
      messageStyle: "none"
    });
  }

  set(chatMessageForBubble: ChatMessageForBubble, roomUser?: RoomUser) {
    try {
      this.roomUser = roomUser;
      chatMessageForBubble.messageHtml = chatMessageForBubble.messageHtml.map(html => this.utilService.parseGetMessageValue(html));
      const el = document.createElement('html');
      el.innerHTML = chatMessageForBubble.messageHtml[0];

      if (chatMessageForBubble.messageHtml[1] && this.isLatex(chatMessageForBubble.messageHtml[1])) {
        this.loadMathConfig();
        this.renderMath(chatMessageForBubble).then(renderedHtml  => {
          chatMessageForBubble.messageHtml[0] = renderedHtml;
          this.updateLatexMessageContent(chatMessageForBubble);
        }).catch(error => {
          console.log(error);
        });
      } else {
        this.updateMessageContent(chatMessageForBubble);
      }
    } catch (error) {
      console.log(error);
    }
  }

  updateMessageContent(chatMessageForBubble: ChatMessageForBubble) {
    const el = document.createElement('html');
    el.innerHTML = chatMessageForBubble.messageHtml[0];
    switch (chatMessageForBubble.messageType) {
        case MessageType.Text:
            if (this.utilService.isHTML(el.getElementsByTagName('div')[0].innerHTML)) {
                this.load = Promise.resolve(false);
                return;
            }
            break;
        case MessageType.Animation:
            el.getElementsByTagName('div')[0].innerHTML = `<img class="${el.getElementsByTagName('div')[0].innerHTML}" alt="" src="${Constants.S3AccountCentralUrl}${el.getElementsByTagName('div')[0].innerHTML}.gif" />`;
            break;
        case MessageType.EmojiGif:
            el.getElementsByTagName('div')[0].innerHTML = `<img class="${el.getElementsByTagName('div')[0].innerHTML}" alt="" src="${Constants.S3AccountCentralUrl}${el.getElementsByTagName('div')[0].innerHTML}.gif" />`;
            break;
        default:
            break;
    }

    el.getElementsByTagName('div')[0].style.lineHeight = el.getElementsByTagName('div')[0].style.fontSize;
    chatMessageForBubble.messageHtml[0] = el.innerHTML;
    if (chatMessageForBubble.MessageBubbleType === MessageBubbleType.PtoPInfo) {
        chatMessageForBubble.userName = chatMessageForBubble.userName.replace(':', '');
    }

    this.message = chatMessageForBubble;
    if (chatMessageForBubble.MessageBubbleType === MessageBubbleType.SystemMessages) {
        if (this.message.userName.includes("<LANG>") && this.message.userName.includes("</LANG>")) {
            this.message.userName = this.translateService.instant(this.message.userName.split("<LANG>")[1].split("</LANG>")[0]);
        }
    } else if (this.message.userName.includes("<LANG>")) {
        this.message.userName = this.message.userName.split(" [")[0] + ' [' + this.translateService.instant(this.message.userName.split("<LANG>")[1].split("</LANG>")[0]) + '] ' + this.message.userName.split("] ")[1];
    }

    if (this.message.messageHtml[1] === this.translateService.instant("OpenCam")) {
        this.isCameraOpenedMessage = true;
    }

    this.reportedMessageContent = this.message.messageHtml[0];

    this.load = Promise.resolve(true);
}

updateLatexMessageContent(chatMessageForBubble: ChatMessageForBubble) {
  const el = document.createElement('html');
  el.innerHTML = chatMessageForBubble.messageHtml[0];
  chatMessageForBubble.messageType = MessageType.Text

  el.getElementsByTagName('div')[0].style.lineHeight = el.getElementsByTagName('div')[0].style.fontSize;
  chatMessageForBubble.messageHtml[0] = el.innerHTML;
  if (chatMessageForBubble.MessageBubbleType === MessageBubbleType.PtoPInfo) {
      chatMessageForBubble.userName = chatMessageForBubble.userName.replace(':', '');
  }

  this.message = chatMessageForBubble;

  this.load = Promise.resolve(true);
  
  this.roomService.latexRenderedScrollTriggerSource.next()
}

renderMath(chatMessageForBubble: ChatMessageForBubble): Promise<string> {
  return new Promise((resolve, reject) => {
      this.mathJaxObject = this.gs.nativeGlobal()["MathJax"];

      let latexContent = chatMessageForBubble.messageHtml[1]
      let nickType= chatMessageForBubble.nickType

      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = latexContent;
      document.body.appendChild(tempDiv);

      this.mathJaxObject.Hub.Queue(
          ["Typeset", this.mathJaxObject.Hub, tempDiv],
          () => {
              const jax = this.mathJaxObject.Hub.getAllJax(tempDiv);
              if (jax.length > 0 && jax[0].SourceElement()) {
                  const error = jax[0].SourceElement().innerHTML.includes('error');
                  if (error) {
                      document.body.removeChild(tempDiv);
                      reject(new Error('Error rendering LaTeX'));
                  } else {
                      const renderedHtml = tempDiv.innerHTML;
                      document.body.removeChild(tempDiv);

                      const fontStyle = nickType.I ? 'italic' : 'normal';
                      const fontWeight = nickType.B ? 'bold' : 'normal';
                      const styledDiv = `
                          <div style="
                              font-family: '${nickType.F}'; 
                              font-style: ${fontStyle}; 
                              font-weight: ${fontWeight}; 
                              font-size: ${nickType.FS}px; 
                              color: ${nickType.C};">
                              ${renderedHtml}
                          </div>`;
                      resolve(styledDiv);
                  }
              } else {
                  document.body.removeChild(tempDiv);
                  reject(new Error('No LaTeX rendered'));
              }
          }
      );
  });
}


isLatex(text: string): boolean {
  const inlineLatexRegex = /\\\(.*?\\\)/;
  const blockLatexRegex = /\\\[.*?\\\]/;

  return inlineLatexRegex.test(text) || blockLatexRegex.test(text);
}



  displayFile() {
    this.roomService.doc = this.message.fileUrl
    saveAs(this.message.fileUrl);
  }

  goThisRoom(){
    if (this.indexedDBService.userFullInfo.KullaniciId === this.message.senderId ||
      this.message.roomIdWhereTheMessageSent === this.roomService.currentRoom.Info.ID) {
      this.messagePanelService.ShowPopupMessageWithLocalization('alreadyInRoom', 2000);
    }
    if (this.indexedDBService.userFullInfo.KullaniciId !== this.message.senderId) {
     this.confirmJoinRoom()
    }
  }

  confirmJoinRoom(){
    let message = this.message.roomNameWhereTheMessageSent + " odasına gitmek istediğinizden emin misiniz?"
    this.confirmationService.confirm({
      message: message,
      header: "Odaya Katıl",
      icon: null,
      accept: () => {
        this.roomService.enterRoomNew(this.message.roomIdWhereTheMessageSent);
      },
      reject: () => {
      }
    });
  }

  showUserBubbleCard() {
    if (this.message.MessageBubbleType !== this.messageBubbleType.GeneralMessage &&
      this.indexedDBService.userFullInfo.KullaniciId !== this.message.senderId) {
      this.roomService.selectedRoomUser = new RoomUser();
      this.reportService.reportedMessageContent = this.reportedMessageContent //this.message.messageHtml[0];
      this.roomService.roomUserContextMenuMode = RoomUserContextMenuMode.RoomChatBubble;
      // this.roomService.selectedRoomUser = this.roomService.findRoomUserById(this.message.senderId);
      //another way: get user with endpoint
      // this.roomService.selectedRoomUser = this.roomService.getRoomUserFromActiveListByUserId(this.message.senderId);
      if (this.message.userName.includes("Veribot")) {
        return
      }
      this.roomService.getRoomUserDataByUserId(this.message.senderId).then((roomUser:RoomUser[])=>{
        if (this.utilService.isNullOrEmtpyObject(roomUser) == false) {
          this.roomService.selectedRoomUser = roomUser[0];
          this.roomService.selectedRoomUser =this.roomService.getRoomUserInfos(this.roomService.selectedRoomUser)
        }
        else{
          return
        }
      }).then(() => {
        this.panelManagerService.roomUserCardPopupDisplay = true;
        })
        .catch(error => {
        return
      });
    }
  }
  displayImage(){
    this.indexedDBService.roomSharedImage = this.message.fileUrl
    this.panelManagerService.roomSharedImagePopupDisplay = true
  }

  watchCamera() {
    // let user = this.roomService.getRoomUserById(this.roomUser.KullaniciId);
    let user = this.roomService.micUserList.find(u => u.KullaniciId === this.roomUser.KullaniciId);
    if (user?.IsCamOpen)
      this.roomService.watchUserCam(this.roomUser.KullaniciId);
    else
      this.messagePanelService.ShowPopupMessageWithLocalization(this.roomUser.KullaniciAdi + ' ' + this.utilService.getKeyWithTag('CloseCam'), 2000);
  }
  onImgError(event) {
    event.target.src = this.userService.getUserPhoto(null);
  }

  complainMessage() {
    this.reportService.header = this.message.userName.substring(0, this.message.userName.indexOf(' '));
    this.reportService.insertComplainRequest.ComplainType = SikayetTipleri.P2pMesaj; //TODO bu tip mesaj tipine göre değişecek..
    this.reportService.insertComplainRequest.ComplainingId = this.indexedDBService.userFullInfo.KullaniciId;
    this.reportService.insertComplainRequest.ComplainedId = this.message.senderId;
    this.reportService.insertComplainRequest.Html = this.reportedMessageContent;
    this.reportService.reportPopupDisplay = true;
  }

  siteBan() {
    if (
      this.meInRoom.BanSite === false &&
      this.meInRoom.MuteSite === false &&
      this.meInRoom.BanOda === false &&
      this.meInRoom.MuteOda === false &&
      this.indexedDBService.userFullInfo.RutbeFullInfo.BAN_SITE === true &&
      this.message.MessageBubbleType === MessageBubbleType.RoomActionMessage &&
      this.message.senderId !== this.indexedDBService.userFullInfo.KullaniciId &&
      this.indexedDBService.userFullInfo.RutbeDerece > 7
    ) {
      this.indexedDBService.banPanel = new BanPanel();
      this.indexedDBService.banPanel.BanOrMute = BanOrMute.Ban;
      this.indexedDBService.banPanel.BanType = 6;
      this.indexedDBService.banPanel.UserId = this.message.senderId;
      this.indexedDBService.banPanel.BanMode = 'siteBan';
      this.indexedDBService.banPanel.UserName = this.message.userName;
      this.panelManagerService.banPanelPopupDisplay = true;
    }
  }
}
