import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { RoomUserListService } from 'src/app/core/services/room-user-list-service';
import { BanModel } from 'src/app/core/models/ban-panel';
import { UtilService } from 'src/app/core/services/util.service';
import { BanOrMute, ChatType, BulkMessageType } from 'src/app/core/models/enums';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { RoomService } from 'src/app/core/services/room.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { DynamicMethodsSendService } from 'src/app/core/services/dynamic-methods-send.service';
import { AdminService } from 'src/app/admin/services/admin.service';
import { ChatMessageService } from 'src/app/core/services/chatMessage.service';
import { Constants } from 'src/app/core/models/constants';
import { DynamicMethod } from 'src/app/core/models/dynamic-method';
import { DynamicMethodsService } from 'src/app/core/services/dynamic-methods.service';


@Component({
  selector: 'app-room-user-list-ban',
  templateUrl: './ban.component.html',
  styleUrls: ['./ban.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class RoomUserListBanComponent implements OnInit {

  selectedDesc: string;
  description: string;
  descriptionTemplates: any[];
  ban: BanModel;
  tarih;
  currentRoom: any;

  constructor(
    private translateService: TranslateService,
    private messagePanelService: MessagePanelService,
    private roomUserListService: RoomUserListService,
    private utilService: UtilService,
    public indexedDBService: IndexedDBService,
    private roomService: RoomService,
    public panelManagerService: PanelManagerService,
    private dynamicMethodsSendService: DynamicMethodsSendService,
    private adminService: AdminService,
    private chatMessageService: ChatMessageService,
    private dynamicMethodsService:DynamicMethodsService

  ) {
    this.ban = new BanModel();
  }

  ngOnInit() {
    this.descriptionTemplates = [
      { name: this.translateService.instant('breakingLayout') },
      { name: this.translateService.instant('swearing') },
      { name: this.translateService.instant('advertisement') },
      { name: this.translateService.instant('inappropriateCameraUse') },
      { name: this.translateService.instant('inappropriateMicrophoneUse') }
    ];

    this.selectedDesc = this.descriptionTemplates[0];
    this.description = this.selectedDesc["name"];
  }

  DescriptionSelectBoxChange() {
    this.description = this.selectedDesc["name"];
  }

  SaveBanMute() {
    this.panelManagerService.banPanelPopupDisplay = false;

    if (this.description.length <= 0) {
      this.messagePanelService.ShowPopupMessageWithLocalization('DescriptionEmpty', 2000);
    }
    else {
      this.tarih = new Date() //this.utilService.getDateFullForsMsSql(this.utilService.newDate(null))
      this.indexedDBService.banPanel.BanOrMute === BanOrMute.Ban ? this.SaveUserBan() : this.SaveUserMute();
    }
  }

  SaveUserBan() {
    // önce kullanıcı micte ise o sonlandırılır ve o şekilde odadan atılır...
    if (this.roomService.oldmicrophoneJsonStruct.M === this.indexedDBService.banPanel.UserId.toString() ||
      (
        !this.utilService.isNullOrUndefined(this.roomService.oldmicrophoneJsonStruct.M2) &&
        this.roomService.oldmicrophoneJsonStruct.M2.find(userid => userid === this.indexedDBService.banPanel.UserId.toString())
      )) {
      this.roomService.stopUserMicrophoneRequest(this.indexedDBService.banPanel.UserId, this.roomService.currentRoom.Info.ID)
        .then(() => {
          this.setBanDetails();
        })
    }
    else {
      this.setBanDetails();
    }
  }

  setBanDetails() {
    this.ban.ACILACAGI_TARIH = this.tarih;
    this.ban.BANLANAN_UYE_ID = this.indexedDBService.banPanel.UserId;
    this.ban.BANLAYAN_UYE_ID = +this.indexedDBService.userFullInfo.KullaniciId;
    this.ban.BAN_TIPI = this.indexedDBService.banPanel.BanType.toString();
    this.ban.KARALISTE = false;
    this.ban.ODA_ID = (this.indexedDBService.banPanel.BanMode === 'siteBan' ? null : this.roomService.currentRoom.Info.ID),
    this.ban.SEBEP = this.description;
    this.ban.TARIH = this.tarih;
    if (Number(this.ban.BAN_TIPI) === 8) {
      this.callSaveUserBanEmailOnly()
    }
    else{
      this.callSaveUserBan();
    }
  }

  callSaveUserBan() {
    let body = {
      'ClientInfo': null,
      'Ban': this.ban
    };

    this.roomUserListService.saveUserBan(body)
      .subscribe(response => {
        if (this.utilService.isNullOrUndefined(response)) {
          this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
          return;
        }

        if (response.Result === true && this.utilService.isNullOrEmtpyString(response.ErrMsg)) {
          this.saveUserBanMuteSuccess(response);
          return;
        }

        if (response.Result === false) {
          this.utilService.isNullOrEmtpyString(response.ErrMsg) === false ?
            this.messagePanelService.ShowPopupMessageWithLocalization(response.ErrMsg, 4000) :
            this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 4000)
        }
      },
        error => {
          console.log(error);
        });
  }
  
  callSaveUserBanEmailOnly(){
    let body = {
      'ClientInfo': null,
      'Ban': this.ban
    };
    this.roomUserListService.saveUserBanEmailOnly(body)
      .subscribe(response => {
        if (this.utilService.isNullOrUndefined(response)) {
          this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
          return;
        }

        if (response.Result === true && this.utilService.isNullOrEmtpyString(response.ErrMsg)) {
          this.saveUserBanMuteSuccess(response);
          return;
        }

        if (response.Result === false) {
          this.utilService.isNullOrEmtpyString(response.ErrMsg) === false ?
            this.messagePanelService.ShowPopupMessageWithLocalization(response.ErrMsg, 4000) :
            this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 4000)
        }
      },
        error => {
          console.log(error);
        });
  }
  SaveUserMute() {
    let Mute = {
      'MUTELENEN_UYE_ID': this.indexedDBService.banPanel.UserId,
      'MUTELEYEN_UYE_ID': this.indexedDBService.userFullInfo.KullaniciId,
      'ODA_ID': (this.indexedDBService.banPanel.BanMode === 'siteMute' ? null : this.roomService.currentRoom.Info.ID),
      'SEBEP': this.description,
      'TARIH': this.tarih
    };
    this.roomUserListService.saveUserMute(Mute)
      .subscribe(response => {
        if (this.utilService.isNullOrUndefined(response)) {
          this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
          return;
        }

        if (response.Result === false) {
          !this.utilService.isNullOrEmtpyString(response.ErrMsg) ?
            this.messagePanelService.ShowPopupMessageWithLocalization(response.ErrMsg, 4000) :
            this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
          return;
        } else {
          this.saveUserBanMuteSuccess(response);
          return;
        }
      },
        error => {
          console.log(error);
        });
  }

  saveUserBanMuteSuccess(saveUserBanResponse) {
    try {
      var banType: string = "";
      var adminActionMessage: string = "'" + this.indexedDBService.banPanel.UserName + "' ";
      var adminActionTarget: string = "";
      let userSettingMessage = new Array<DynamicMethod>();
      var userIds: Array<number> = saveUserBanResponse.UserIds.split(',').map(function (userid) {
        return parseInt(userid, 0);
      });

      if (this.indexedDBService.banPanel.BanOrMute === BanOrMute.Ban) {
        if (this.indexedDBService.banPanel.BanMode === 'siteBan') {
          if (this.roomService.selectedRoomUser)
            this.roomService.selectedRoomUser.BanSite = true;
          adminActionMessage += this.utilService.getKeyWithTag("UserBannedSite") + "(" +
            this.utilService.getKeyWithTag("bantype" + this.indexedDBService.banPanel.BanType) + ")";

          adminActionTarget = this.utilService.getKeyWithTag("UserGeneralBan");
          banType = this.utilService.getKeyWithTag("UserBannedSite");
        }
        else { //roomBan
          if (this.roomService.selectedRoomUser)
            this.roomService.selectedRoomUser.BanOda = true;
          adminActionMessage += this.utilService.getKeyWithTag("UserBannedRoom") + "(" + this.roomService.currentRoom.Info.NAME + " , " + this.utilService.getKeyWithTag("bantype" + this.indexedDBService.banPanel.BanType) + ")";

          adminActionTarget = this.utilService.getKeyWithTag("UserRoomBan");

          banType = this.utilService.getKeyWithTag("UserBannedRoom") + " -> " + this.roomService.currentRoom.Info.NAME;
        }
      }
      else {
        if (this.indexedDBService.banPanel.BanMode === 'siteMute') {

          if (this.roomService.selectedRoomUser)
            this.roomService.selectedRoomUser.MuteSite = true;
          adminActionMessage += this.utilService.getKeyWithTag("UserMutedSite");

          adminActionTarget = this.utilService.getKeyWithTag("UserGeneralMute");

          userSettingMessage = [this.dynamicMethodsSendService.KullaniciSiteMute(this.indexedDBService.banPanel.UserId)];
          banType = this.utilService.getKeyWithTag("UserMutedSite");
        }
        else { //roomMute
          if (this.roomService.selectedRoomUser)
            this.roomService.selectedRoomUser.MuteOda = true;
          adminActionMessage += this.utilService.getKeyWithTag("UserMutedRoom") + "(" + this.roomService.currentRoom.Info.NAME + ")";

          adminActionTarget = this.utilService.getKeyWithTag("UserRoomMute");

          userSettingMessage = [this.dynamicMethodsSendService.KullaniciOdaMute(this.indexedDBService.banPanel.UserId, this.roomService.currentRoom.Info.ID)];
          banType = this.utilService.getKeyWithTag("UserMutedRoom") + " -> " + this.roomService.currentRoom.Info.NAME;
        }
      }

      adminActionMessage += ". " + this.utilService.getKeyWithTag("BanReason") + ": " + this.description;


      //banlanana kullanıcıya ban işlemiyle ilgili mesaj atılır 
      if (userIds.length > 0 && this.indexedDBService.banPanel.BanOrMute === BanOrMute.Ban) {
        for (var i = 0; i < userIds.length; i++) {
          if (this.indexedDBService.banPanel.BanMode === 'siteBan') {
            userSettingMessage = [this.dynamicMethodsSendService.KullaniciSiteBan(userIds[i], this.indexedDBService.banPanel.BanType)];
            //this.chatMessageService.sendUserSettingMessage(userIds[i], userSettingMessage, this.indexedDBService.banPanel.UserName);
            let operationMessageForKullaniciSiteBan = this.dynamicMethodsService.prepareUserSettingMessage(
              userIds[i],
              userSettingMessage,
              this.indexedDBService.banPanel.UserName
              )
            this.dynamicMethodsService.publishDynKullaniciSiteBan(this.indexedDBService.userFullInfo.KullaniciId,userIds[i],operationMessageForKullaniciSiteBan);
          }
          else {
            userSettingMessage = [this.dynamicMethodsSendService.KullaniciOdaBan(userIds[i], this.roomService.currentRoom.Info.ID, this.indexedDBService.banPanel.BanType)];
            //Alttaki kaldırılacak
            // this.chatMessageService.sendUserSettingMessage(userIds[i], userSettingMessage, this.indexedDBService.banPanel.UserName);
          
            let operationMessageForKullaniciOdaBan = this.dynamicMethodsService.prepareUserSettingMessage(
              userIds[i],
              userSettingMessage,
              this.indexedDBService.banPanel.UserName
              )
            this.dynamicMethodsService.publishDynKullaniciOdaBan(this.indexedDBService.userFullInfo.KullaniciId,userIds[i],operationMessageForKullaniciOdaBan);
          }
        }

        //admin hareketi odalara mesaj olarak gönderilir
        var roomPublicMessage = "'" + this.indexedDBService.banPanel.UserName.charAt(0) + "..." + this.indexedDBService.banPanel.UserName.charAt(this.indexedDBService.banPanel.UserName.length - 1) + "' " + banType;
        this.chatMessageService.sendAdminBulkMessageToPublicChannel(roomPublicMessage, Constants.BulkMessageUnnecessaryId, BulkMessageType.AllRooms, ChatType.AdminChangesMessage);
      }
      else {
        //userSettingMessage KullaniciOdaMute veya KullaniciSiteMute ise burası çalışıyor diye düşünüldü.

        //KullaniciOdaMute durumu için kontrol
        if (userSettingMessage.some(method => method.MethodName === "KullaniciOdaMute")) {
          //this.chatMessageService.sendUserSettingMessage(this.indexedDBService.banPanel.UserId, userSettingMessage, this.indexedDBService.banPanel.UserName);
          let operationMessageForKullaniciOdaMute = this.dynamicMethodsService.prepareUserSettingMessage(
            this.indexedDBService.banPanel.UserId,
             userSettingMessage,
             this.indexedDBService.banPanel.UserName
            )
          this.dynamicMethodsService.publishDynKullaniciOdaMute(this.indexedDBService.userFullInfo.KullaniciId,this.indexedDBService.banPanel.UserId,operationMessageForKullaniciOdaMute);
        }
      
      // KullaniciSiteMute durumu için kontrol
        if (userSettingMessage.some(method => method.MethodName === "KullaniciSiteMute")) {
          // KullaniciSiteMute ile ilgili işlemler burada gerçekleştirilecek
          //this.chatMessageService.sendUserSettingMessage(this.indexedDBService.banPanel.UserId, userSettingMessage, this.indexedDBService.banPanel.UserName);
          let operationMessageForKullaniciSiteMute = this.dynamicMethodsService.prepareUserSettingMessage(
            this.indexedDBService.banPanel.UserId,
            userSettingMessage,
            this.indexedDBService.banPanel.UserName
            )
          this.dynamicMethodsService.publishDynKullaniciSiteMute(this.indexedDBService.userFullInfo.KullaniciId,this.indexedDBService.banPanel.UserId,operationMessageForKullaniciSiteMute);
        }
        //this.chatMessageService.sendUserSettingMessage(this.indexedDBService.banPanel.UserId, userSettingMessage, this.indexedDBService.banPanel.UserName);
      }

      //admin hareketleri kayıt edilir
      this.adminService.callSaveAdminActions(adminActionMessage, adminActionTarget);

      // this.roomService.roomUserListChangedSource.next(this.roomService.selectedRoomUser.KullaniciId);
      // this.roomService.refreshCurrentUserListPage();


      this.messagePanelService.ShowPopupMessageWithLocalization('OperationSuccess', 2000);
      if (this.roomService.currentRoom && this.roomService.currentRoom != null && this.roomService.currentRoom.Info && this.roomService.currentRoom.Info != null) {

        var operationMessageForRefreshRoomUserListForAllUsersInRoom = this.dynamicMethodsService.prepareRoomSettingMessage(this.roomService.currentRoom.Info?.ID,
          [this.dynamicMethodsSendService.RefreshRoomUserListForAllUsersInRoom()]
        );
        this.dynamicMethodsService.publishDynRefreshRoomUserListForAllUsersInRoom("room:" + this.roomService.currentRoom.Info?.ID, operationMessageForRefreshRoomUserListForAllUsersInRoom)
      }

    }
    catch (error) {
      console.log(error);
    }
  }

}