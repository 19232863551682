import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree
} from "@angular/router";
import { Observable } from "rxjs";
import { LoginService } from "../services/login.service";
import { IndexedDBService } from "src/app/core/services/indexed-db.service";

@Injectable({
    providedIn: "root"
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        private indexedDBService: IndexedDBService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        
          if (this.indexedDBService.isAuthenticated()) {
            return true
        }
        else{
          this.router.navigate(["login"])
          return false;
        }
      }

    controlAuth() {
        return new Promise((resolve, reject) => {
            if (this.indexedDBService.authenticated)
                resolve(null)
            else
                reject();
        });
    }

    canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(next, state);
    }
}
