import { NatsService } from './../nats/services/nats.service';
import { Injectable, OnDestroy } from '@angular/core';
import { IndexedDBService } from './indexed-db.service';
import { FriendshipService } from '../../core/services/friendship.service';
import { UserFullInfo, AutoLoginParams } from '../models/user';
import { MessagePanelService } from './messagePanel.service';
import { ConferenceRequestResponse, P2PConferenceAnswerType } from '../../core/models/chat-message';
import { PanelManagerService } from '../../core/services/panel-manager.service';
import { ContactType, BanPlaces, BanOrMute } from '../models/enums';
import { ChatService } from '../../core/services/chat.service';
import { OperationMessage } from '../../core/models/operation-message';
import { Subscription } from 'rxjs';
import { RoomUser } from '../models/room-user';
import { RoomUserListService } from '../../core/services/room-user-list-service';
import { RoomService } from '../../core/services/room.service';
import { UtilService } from './util.service';
import { UserService } from '../../core/services/user.service';
import { MessageService } from 'primeng/api';
import { DynamicMethodsSendService } from './dynamic-methods-send.service';
import { DeviceService } from './device.service';
import { AdminService } from 'src/app/admin/services/admin.service';
import { KeepAdminLog } from 'src/app/admin/models/keep-admin-log';
import { DynamicMethod } from '../models/dynamic-method';
import { RankFullInfo } from '../models/rank';
import { RoomSliderUser } from '../models/room-slider-user';
import { Room } from '../models/room';
import { AdminRoomService } from 'src/app/admin/tabs/rooms/services/room.service';
import { LivekitService } from '../livekit/services/livekit.service';
import { DynamicMethodsService } from './dynamic-methods.service';

@Injectable({
    providedIn: 'root'
})
export class DynamicMethodsReceiveService implements OnDestroy {
    methods = {};

    dynamicMethodsReceivedSubscription: Subscription;

    constructor(
        private indexedDBService: IndexedDBService,
        private friendshipService: FriendshipService,
        private messagePanelService: MessagePanelService,
        private panelManagerService: PanelManagerService,
        private chatService: ChatService,
        private roomUserListService: RoomUserListService,
        private roomService: RoomService,
        private utilService: UtilService,
        private userService: UserService,
        private messageService: MessageService,
        private dynamicMethodsSendService: DynamicMethodsSendService,
        private deviceService: DeviceService,
        private adminService: AdminService,
        private natsService: NatsService,
        private adminRoomService: AdminRoomService,
        private livekitService: LivekitService,
        private dynamicMethodsService:DynamicMethodsService
    ) {
        this.methods["RefreshMyFriendsFriendList"] = this.RefreshMyFriendsFriendList.bind(this);
        this.methods["AddFriendAfterEvents"] = this.AddFriendAfterEvents.bind(this);
        this.methods["RefreshFriendListForOneUser"] = this.RefreshFriendListForOneUser.bind(this);
        this.methods["RemoveUserFromMessengerList"] = this.RemoveUserFromMessengerList.bind(this);
        this.methods["P2PConferenceRequest"] = this.P2PConferenceRequest.bind(this);
        this.methods["P2PConferenceResponse"] = this.P2PConferenceResponse.bind(this);
        this.methods["StopP2PConference"] = this.StopP2PConference.bind(this);
        this.methods["AddUserToList"] = this.AddUserToList.bind(this);
        this.methods["RemoveUserFromList"] = this.RemoveUserFromList.bind(this);
        this.methods["CloseRoomVideoPanel"] = this.CloseRoomVideoPanel.bind(this);
        this.methods["CloseScreenShareVideoPanel"] = this.CloseScreenShareVideoPanel.bind(this);
        this.methods["ShowHideUserCamIconUserInList"] = this.ShowHideUserCamIconUserInList.bind(this);
        this.methods["AddRemoveCamWatcher"] = this.AddRemoveCamWatcher.bind(this);
        this.methods["FinishCam"] = this.FinishCam.bind(this);
        this.methods["KullaniciOdaBan"] = this.KullaniciOdaBan.bind(this);
        this.methods["KullaniciOdaBanKaldir"] = this.KullaniciOdaBanKaldir.bind(this);
        this.methods["KullaniciSiteBan"] = this.KullaniciSiteBan.bind(this);
        this.methods["KullaniciSiteBanKaldir"] = this.KullaniciSiteBanKaldir.bind(this);
        this.methods["KullaniciOdaMute"] = this.KullaniciOdaMute.bind(this);
        this.methods["KullaniciSiteMute"] = this.KullaniciSiteMute.bind(this);
        this.methods["KullaniciOdaMuteKaldir"] = this.KullaniciOdaMuteKaldir.bind(this);
        this.methods["KullaniciSiteMuteKaldir"] = this.KullaniciSiteMuteKaldir.bind(this);
        this.methods["ClearTextbox"] = this.ClearTextbox.bind(this);
        this.methods["AdminPaneliKapat"] = this.AdminPaneliKapat.bind(this);
        this.methods["ShowHideUserBanIconUserInList"] = this.ShowHideUserBanIconUserInList.bind(this);
        this.methods["ShowHideUserMuteIconUserInList"] = this.ShowHideUserMuteIconUserInList.bind(this);
        this.methods["KullaniciAdminHareketiMesajGoster"] = this.KullaniciAdminHareketiMesajGoster.bind(this);
        this.methods["UpdateUserImageInList"] = this.UpdateUserImageInList.bind(this);
        this.methods["KullaniciLogOut"] = this.KullaniciLogOut.bind(this);
        this.methods["RedirectToLoginPage"] = this.RedirectToLoginPage.bind(this);
        this.methods["ShowBanMessage"] = this.ShowBanMessage.bind(this);
        this.methods["UpdateUserAllInfo"] = this.UpdateUserAllInfo.bind(this);
        this.methods["UpdateUserStateInList"] = this.UpdateUserStateInList.bind(this);
        this.methods["SetOdaSetting"] = this.SetOdaSetting.bind(this);
        this.methods["OdadanAt"] = this.OdadanAt.bind(this);
        this.methods["UserBlockUnblock"] = this.UserBlockUnblock.bind(this);
        this.methods["RefreshUser"] = this.RefreshUser.bind(this);
        this.methods["ShowBalloonTipForFriendActions"] = this.ShowBalloonTipForFriendActions.bind(this);
        this.methods["RefreshRoomUserListForAllUsersInRoom"] = this.RefreshRoomUserListForAllUsersInRoom.bind(this);
        this.methods["RefreshRoomMicUserListForAllUsersInRoom"] = this.RefreshRoomMicUserListForAllUsersInRoom.bind(this);
        this.methods["RefreshMyRoomUserList"] = this.RefreshMyRoomUserList.bind(this);
        this.methods["Logout"] = this.Logout.bind(this);
        this.methods["RefreshFriendList"] = this.RefreshFriendList.bind(this);
        this.methods["RemoveBulkBanMute"] = this.RemoveBulkBanMute.bind(this);
        this.methods["OdaSiteBanGirisleriSet"] = this.OdaSiteBanGirisleriSet.bind(this);
        this.methods["OdaBanGirisleriDegisti"] = this.OdaBanGirisleriDegisti.bind(this);
        this.methods["EditUsersForChangedRank"] = this.EditUsersForChangedRank.bind(this);
        this.methods["SMSValidationRequestChanged"] = this.SMSValidationRequestChanged.bind(this);
        this.methods["InviteToRoom"] = this.InviteToRoom.bind(this);
        this.methods["InviteToMicrophone"] = this.InviteToMicrophone.bind(this);
        this.methods["MuteUnMuteUserMicrophone"] = this.MuteUnMuteUserMicrophone.bind(this);
        this.methods["OdaSahibi"] = this.OdaSahibi.bind(this);
        this.methods["CloseP2PChatPanel"] = this.CloseP2PChatPanel.bind(this);
        this.methods["UpdateAuthorizedUserSliderList"] = this.UpdateAuthorizedUserSliderList.bind(this);
        this.methods["DropMic"] = this.DropMic.bind(this);
        this.methods["ChangedRoomOrderList"] = this.ChangedRoomOrderList.bind(this);
        this.methods["GetComplainNotification"] = this.GetComplainNotification.bind(this);
        this.methods["GetLiveSupportNotification"] = this.GetLiveSupportNotification.bind(this);
        this.methods["RoomCreated"] = this.RoomCreated.bind(this);
        this.methods["ConnectionProblem"] = this.ConnectionProblem.bind(this);

        if (this.dynamicMethodsReceivedSubscription)
            this.dynamicMethodsReceivedSubscription.unsubscribe();


        this.dynamicMethodsReceivedSubscription = this.natsService.dynamicMethodsReceived.subscribe((operationMessage: OperationMessage) => {
            //TODO_PUBLISH migth be better place to check validation
            console.log(`dynamic-methods-receive.service dynamicMethodsReceived operationMessage: ${JSON.stringify(operationMessage)}`)
            if (operationMessage.MethodNames !== null)
                this.callMethods(operationMessage.MethodNames, operationMessage.Parameters);
        });

        //TODO_CENTRIFUGE : bu kalksın
        this.dynamicMethodsReceivedSubscription = this.chatService.getUserChannelSettingMessageReceivedSource().subscribe((dynamicMethods: Array<DynamicMethod>) => {
            //TODO_PUBLISH migth be better place to check validation
            console.log(`dynamic-methods-receive.service dynamicMethodsReceived dynamicMethods: ${JSON.stringify(dynamicMethods)}`)
            if (dynamicMethods !== null)
                this.callDynamicMethods(dynamicMethods);
        });
    }
    ngOnDestroy(): void {
        if (this.dynamicMethodsReceivedSubscription)
            this.dynamicMethodsReceivedSubscription.unsubscribe();{
        }
    }


    callDynamicMethods(dynamicMethods: Array<DynamicMethod>) {
        dynamicMethods.forEach(dynamicMethod => {
            this.callMethod(dynamicMethod.MethodName, dynamicMethod.Parameters);
        });
    }


    callMethods(methods: Array<string>, parameters: Array<Array<any>>) {
        for (let i = 0; i < methods.length; i++)
            this.callMethod(methods[i], parameters[i]);
    }

    callMethod(methodName: string, params: Array<any>) {
        if (this.methods[methodName])
            this.methods[methodName](params);
    }

    Logout(params?: Array<any>) {
        let userId: number = params[0];
        let tabId: string = params[1];
        let dropUser: boolean = params[2];

        let currentTabId = sessionStorage.getItem("tabID")

        console.log(`LOGOUT CALLED  userId: ${userId} - tabId: ${tabId} - currentTabId: ${currentTabId}`)

        if (currentTabId == tabId && userId === this.indexedDBService.userFullInfo.KullaniciId) {
            this.roomService.signOut();
        }
    }


    AddFriendAfterEvents(params?: Array<any>) {
        //TODO arakdaş listesinde bildirim varmış gibi göster.. webde arkadaş listesinin iconunu yanıp söndür. mobildede öyle
        //TaskbarFlash(true);
    }

    RefreshFriendListForOneUser(params?: Array<any>) {
        let friendId: number = params[0];
        let stateId: number = params[1];
        let isLogin: boolean = params[2];
        let useCache: boolean = params[3];

        if (friendId === this.indexedDBService.userFullInfo.KullaniciId)
            return;

        this.friendshipService.getFriends().then((response) => {
            this.indexedDBService.Friends = response;
            this.panelManagerService.loadedFriends = Promise.resolve(true);
        })
        /* let myFriend: UserFullInfo = this.indexedDBService.Friends.find(f => f.KullaniciId === friendId);
         if (this.indexedDBService.isMyFriend(myFriend?.KullaniciId)) {
             stateId = this.indexedDBService.Friends.find(f => f.KullaniciId === friendId).StateId;
         }*/

        //if (this.indexedDBService.isMyFriend(myFriend?.KullaniciId) === false || stateId === StateIdList.GonderilenIstek || stateId === StateIdList.GelenIstek) {
        //var oldFriend: UserFullInfo = this.indexedDBService.Friends.find(f => f.KullaniciId === friendId)
    }


    //TODO sil burayıııı
    // RefreshFriendListForOneUser_GetFriend_AfterServiceResponse(friend: UserFullInfo, isLogin: boolean = false) {
    //     try {

    //         if (friend !== null) {
    //             //var oldFriend = this.indexedDBService.getFriendById(friendId);

    //             this.indexedDBService.Friends.AllFriends = this.indexedDBService.Friends.AllFriends.filter(f => f.KullaniciId !== friend.KullaniciId);
    //             this.indexedDBService.Friends.AllFriends.push(friend);

    //             this.indexedDBService.refreshFriendListAfterChanges();

    //             if ((friend.StateId !== StateIdList.Cevrimdisi) && (isLogin === true)) {
    //                 //TODO mobilde yerini top yap
    //                 this.messageService.add({ severity: 'success', summary: friend.KullaniciAdi, key: 'online-message', life: this.deviceService.isMobile ? 1000 : 3000, detail: this.utilService.parseGetMessageValue("PersonOnlineMessage") });
    //             }
    //         }
    //     }
    //     catch (error) {
    //         console.log(error)
    //     }

    //     //TODO
    //     // setTimeout(function () {
    //     //     _messengerMain.ControlFriendsHasMessageImageState();
    //     // }, 2000);
    //     // StaticPublicMethods.AutoAcceptDenyFriendsRequest();
    // }


    RemoveUserFromMessengerList(params?: Array<any>) {
        try {
            var friendId: number = params[0];
            var friend = this.indexedDBService.Friends.AllFriends.find(f => f.KullaniciId === friendId);

            if (friend === undefined)
                return;

            if (this.indexedDBService.isMyFriend(friendId)) {
                this.panelManagerService.removeP2PChatPanel(friendId);
            }

            var friendName = friend.KullaniciAdi;

            this.indexedDBService.Friends.AllFriends = this.indexedDBService.Friends.AllFriends.filter(f => f.KullaniciId !== friendId);
            this.indexedDBService.friendsChangedSource.next(friendId);

            // this.messagePanelService.ShowPopupMessageWithLocalization("[ " + friendName + " ] " + this.localizationService.getKeyWithTag("youRemovedFriendList"), 5000);

            //TODO  kullanıcıya ait tüm panelleri kapat konferansları durdur
            // var button = $(".chatPanelMainDiv_u_" + friendId).find(".screen-close");

            // if (_chatPanelMain && button.length > 0) {
            //     var contactType = null;
            //     var chaPanelMainDiv = _chatPanelMain.GetChatPanel(button);
            //     if (chaPanelMainDiv.find(".stopVideoChatImg").is(":visible") === true)
            //         contactType = _enums.ContactType.VideoAndAudio;
            //     else if (chaPanelMainDiv.find(".stopAudioChatImg").is(":visible") === true)
            //         contactType = _enums.ContactType.Audio;

            //     if (_chatPanelTopBar) {
            //         _chatPanelTopBar.FinishConferenceByButton(button, contactType)
            //         _chatPanelTopBar.CloseChatPanelInternal(button);
            //     }
            // }
        }
        catch (error) {
            console.log(error);
        }
    }

    //#region room operations

    AddUserToList(params?: Array<any>) {
        let roomUser = JSON.parse(params[0]) as RoomUser;
        if (roomUser.KullaniciId === this.indexedDBService.userFullInfo.KullaniciId)   //kendini ekleme
            return;

        this.roomUserListService.roomUserAddedSource.next(roomUser);
    }

    RemoveUserFromList(params?: Array<any>) {

        let userId = params[0];

        if (userId === this.indexedDBService.userFullInfo.KullaniciId)   //kendini çıkartma
            return;

        this.roomUserListService.roomUserRemovedSource.next(userId);
    }


    ShowHideUserCamIconUserInList(params?: Array<any>) {
        let userId = params[0];
        let sessionId = params[1];
        let contactType = params[2];
        let show = params[3];

        this.roomService.showHideUserCamIconUserInList(userId, sessionId, contactType, show);
    }


    AddRemoveCamWatcher(params?: Array<any>) {
        let userId = params[0];
        let userName = params[1];
        let stateId = params[2];
        let rutbeDerece = params[3];
        let add = params[4];


        this.roomService.addRemoveCamWatcher(userId, add);
    }

    FinishCam(params?: Array<any>) {
        let userId = params[0];
        let roomId = params[1];
        let onlyMyCam = params[2];

        if (userId === this.indexedDBService.userFullInfo.KullaniciId)
            this.roomService.finishCamForReceivedCommand(roomId);
    }

    OdadanAt(params?: Array<any>) {
        let userId = params[0];
        let roomId = params[1];
        this.roomService.kickFromRoomReceivedCommand(userId, roomId);
    }

    ClearTextbox() {
        this.roomService.roomAllMessagesRemovedSource.next();
    };

    //#endregion

    //#region p2p conference operations

    P2PConferenceRequest(params?: Array<any>) {
        let conferenceRequestResponse: ConferenceRequestResponse = params[0];

        let friend = this.indexedDBService.getFriendById(conferenceRequestResponse.userId);

        if (!friend)
            return;

        this.panelManagerService.showP2pConferenceIncomingCallPanel(friend, conferenceRequestResponse);

    }

    P2PConferenceResponse(params?: Array<any>) {
        let conferenceRequestResponse: ConferenceRequestResponse = params[0];
        if (conferenceRequestResponse.answer === P2PConferenceAnswerType.Accept) {
            this.panelManagerService.closeP2pConferenceOutgoingCallingPanel(conferenceRequestResponse.userId, conferenceRequestResponse.conferenceId);

            let contactType = ContactType.Audio;
            if (conferenceRequestResponse.contactType === ContactType.VideoCalling)
                contactType = ContactType.VideoAndAudio;

            this.panelManagerService.openP2pConferencePanel(conferenceRequestResponse, conferenceRequestResponse.userId, conferenceRequestResponse.conferenceId, contactType);


        } else if (conferenceRequestResponse.answer === P2PConferenceAnswerType.CancelCalling) {
            this.panelManagerService.closeP2pConferenceIncomingCallingPanel(conferenceRequestResponse.userId, conferenceRequestResponse.conferenceId);
        } else if (conferenceRequestResponse.answer === P2PConferenceAnswerType.Reject) {
            this.panelManagerService.closeP2pConferenceIncomingCallingPanel(conferenceRequestResponse.userId, conferenceRequestResponse.conferenceId);
        } else if (conferenceRequestResponse.answer === P2PConferenceAnswerType.UnReachable) {
            this.panelManagerService.closeP2pConferenceIncomingCallingPanel(conferenceRequestResponse.userId, conferenceRequestResponse.conferenceId);
        }
    }



    StopP2PConference(params?: Array<any>) {

        // this.chatService.unsubscribeUserSettingMessageChannel();
    }

    //#endregion

    //#region room  conference operations

    CloseRoomVideoPanel(params?: Array<any>) {
        let userId: number = params[0];

        if (userId !== this.indexedDBService.userFullInfo.KullaniciId)
            this.roomService.stopCam(userId, this.roomService.currentRoom.Info.ID, true, false);
    }

    CloseScreenShareVideoPanel(params?: Array<any>){
        let userId: number = params[0];
        if (userId !== this.indexedDBService.userFullInfo.KullaniciId)
            this.roomService.stopScreenShare(userId, this.roomService.currentRoom.Info.ID, true, false);
    }
    //#endregion

    // #region Ban Operations

    KullaniciOdaBan(params?: Array<any>) {
        let userId: number = params[0];
        let roomId: number = params[1];
        let banType: number = params[2];
        let isUserInBannedRoom: boolean = true;

        if (userId !== this.indexedDBService.userFullInfo.KullaniciId)
            return;

        if (roomId !== this.roomService.currentRoom.Info?.ID) {
            isUserInBannedRoom = false;
        }

        if (!this.indexedDBService.userFullInfo.BanOdaList) {
            this.indexedDBService.userFullInfo.BanOdaList = new Array<any>();
        }

        this.indexedDBService.userFullInfo.BanOdaList.push(roomId);
        this.indexedDBService.updateUser(this.indexedDBService.userFullInfo);

        let roomUser = this.roomService.getRoomUserFromActiveListByUserId(userId);
        if (isUserInBannedRoom) {
            if (roomUser) {
                roomUser.BanOda = true;
                this.roomUserListService.roomUserUpdatedSource.next(roomUser);
            }
        }

        let message: any = [];
        message.push(this.utilService.getKeyWithTag("BannedThisRoom") +
            "( " + this.indexedDBService.getRoomNameById(roomId) + " , " +
            this.utilService.getKeyWithTag("bantype" + banType) + " )");

        this.ShowBanMessage(message);

        this.roomService.afterBanActionsForReceivedCommand(userId, roomId, BanPlaces.Room, BanOrMute.Ban);

    }

    KullaniciOdaBanKaldir(params?: Array<any>) {
        let userId: number = params[0];
        let roomId: number = params[1];
        if (userId != this.indexedDBService.userFullInfo.KullaniciId)
            return;

        //  this.roomService.removeRoomBan(new BanMuteInfo(), roomId);
        this.indexedDBService.userFullInfo.BanOdaList = this.indexedDBService.userFullInfo.BanOdaList?.filter(f => f !== roomId);
        this.indexedDBService.updateUser(this.indexedDBService.userFullInfo);

        let roomUser = this.roomService.getRoomUserFromActiveListByUserId(userId);
        if (roomUser) {
            roomUser.BanOda = false;
            this.roomUserListService.roomUserUpdatedSource.next(roomUser);
        }

        if (roomId) {
            let message: any = [];
            message.push(this.utilService.getKeyWithTag("RemovedRoomBan") +
                "( " + this.indexedDBService.getRoomNameById(roomId) + " )");

            this.ShowBanMessage(message);
        }

        this.roomService.afterRemoveBanMuteActionsForReceivedCommand(userId, roomId, BanPlaces.Room, BanOrMute.Ban);

    }

    KullaniciSiteBan(params?: Array<any>) {
        try {
            let userId: number = params[0];
            let banType: number = params[1];

            if (userId != this.indexedDBService.userFullInfo.KullaniciId)
                return;

            this.indexedDBService.userFullInfo.BanSite = true;
            this.indexedDBService.updateUser(this.indexedDBService.userFullInfo);

            let roomUser = this.roomService.getRoomUserFromActiveListByUserId(userId);
            if (roomUser) {
                roomUser.BanSite = true;
                this.roomUserListService.roomUserUpdatedSource.next(roomUser);
            }

            let message: any = [];
            message.push(this.utilService.getKeyWithTag("BannedAllRoom") +
                "( " + this.utilService.getKeyWithTag("bantype" + banType) + " )");

            this.ShowBanMessage(message);

            this.roomService.afterBanActionsForReceivedCommand(userId, null, BanPlaces.Site, BanOrMute.Ban);

        } catch (error) {
            console.log(error);
        }
    }

    KullaniciSiteBanKaldir(params?: Array<any>) {
        let userId: number = params[0];

        if (userId != this.indexedDBService.userFullInfo.KullaniciId)
            return;


        this.indexedDBService.userFullInfo.BanSite = false;
        this.indexedDBService.updateUser(this.indexedDBService.userFullInfo);

        let roomUser = this.roomService.getRoomUserFromActiveListByUserId(userId);
        if (roomUser) {
            roomUser.BanSite = false;
            this.roomUserListService.roomUserUpdatedSource.next(roomUser);
        }

        let message: any = [];
        message.push(this.utilService.getKeyWithTag("RemovedSiteBan"));

        this.ShowBanMessage(message);

        this.roomService.afterRemoveBanMuteActionsForReceivedCommand(userId, null, BanPlaces.Site, BanOrMute.Ban);

    }


    RefreshMyFriendsFriendList(isLogin: boolean = false, useCache: boolean = true) {

        this.dynamicMethodsService.RefreshFriendListForOneUserFriend(
            this.indexedDBService.userFullInfo.KullaniciId,
            {
                data: JSON.stringify([this.dynamicMethodsSendService.RefreshFriendListForOneUser(this.indexedDBService.userFullInfo.KullaniciId, this.indexedDBService.userFullInfo.StateId, isLogin, useCache)]),
                userId: this.indexedDBService.userFullInfo.KullaniciId
            }
        )
    }
    // #endregion

    // #region Mute Operations

    KullaniciOdaMute(params?: Array<any>) {
        try {
            let userId: number = params[0];
            let roomId: number = params[1];


            if (userId != this.indexedDBService.userFullInfo.KullaniciId){
                return;
            }
            this.messagePanelService.ShowPopupMessageWithLocalization('muted-in-this-room', 5000);
            if (userId == this.indexedDBService.userFullInfo.KullaniciId) {
                this.livekitService.toggleAudio(false);    
            }

            if (!this.indexedDBService.userFullInfo.MuteOdaList) {
                this.indexedDBService.userFullInfo.MuteOdaList = new Array<any>();
            }
            this.indexedDBService.userFullInfo.MuteOdaList.push(roomId);
            this.indexedDBService.updateUser(this.indexedDBService.userFullInfo);
            let roomUser = this.roomService.getRoomUserFromActiveListByUserId(userId);
            if (roomUser) {
                roomUser.MuteOda = true;
                this.roomUserListService.roomUserUpdatedSource.next(roomUser);
            }

            this.roomService.afterBanActionsForReceivedCommand(userId, roomId, BanPlaces.Room, BanOrMute.Mute);
        } catch (error) {
            console.log(error);
        }

    }

    KullaniciSiteMute(params?: Array<any>) {
        let userId: number = params[0];

        if (userId != this.indexedDBService.userFullInfo.KullaniciId)
            return;

        // this.roomService.addSiteMute(new BanMuteInfo());
        this.indexedDBService.userFullInfo.MuteSite = true;
        this.indexedDBService.updateUser(this.indexedDBService.userFullInfo);

        let roomUser = this.roomService.getRoomUserFromActiveListByUserId(userId);
        if (roomUser) {
            roomUser.MuteSite = false;
            this.roomUserListService.roomUserUpdatedSource.next(roomUser);
        }

        /* let message: any = [];
         message.push(this.utilService.getKeyWithTag("MutedAllRoom"));
 
         this.ShowBanMessage(message); */

        this.roomService.afterBanActionsForReceivedCommand(userId, null, BanPlaces.Site, BanOrMute.Mute);

    }

    KullaniciOdaMuteKaldir(params?: Array<any>) {

        let userId: number = params[0];
        let roomId: number = params[1];

        if (userId != this.indexedDBService.userFullInfo.KullaniciId){
            return;
        }
        this.messagePanelService.ShowPopupMessageWithLocalization('removed-muted-in-this-room', 5000);

        if (this.indexedDBService.userFullInfo.MuteOdaList) {
            this.indexedDBService.userFullInfo.MuteOdaList = this.indexedDBService.userFullInfo.MuteOdaList.filter(f => f !== roomId);
            this.indexedDBService.updateUser(this.indexedDBService.userFullInfo);
        }

        let roomUser = this.roomService.getRoomUserFromActiveListByUserId(userId);
        if (this.roomService) {
            roomUser.MuteOda = false;
            this.roomUserListService.roomUserUpdatedSource.next(roomUser);
        }

        /* let message: any = [];
 
         message.push(this.utilService.getKeyWithTag("RemovedRoomMute") +
             "( " + this.indexedDBService.getRoomNameById(roomId) + " )");
 
         this.ShowBanMessage(message);*/

        this.roomService.afterRemoveBanMuteActionsForReceivedCommand(userId, roomId, BanPlaces.Room, BanOrMute.Mute);

    }

    KullaniciSiteMuteKaldir(params?: Array<any>) {

        let userId: number = params[0];

        if (userId != this.indexedDBService.userFullInfo.KullaniciId)
            return;

        this.indexedDBService.userFullInfo.MuteSite = false;
        this.indexedDBService.updateUser(this.indexedDBService.userFullInfo);

        let roomUser = this.roomService.getRoomUserFromActiveListByUserId(userId);
        if (roomUser) {
            roomUser.MuteSite = false;
            this.roomUserListService.roomUserUpdatedSource.next(roomUser);
        }

        /*let message: any = [];
        message.push(this.utilService.getKeyWithTag("RemovedSiteMute"));

        this.ShowBanMessage(message);*/

        this.roomService.afterRemoveBanMuteActionsForReceivedCommand(userId, null, BanPlaces.Site, BanOrMute.Mute);
    }

    // #endregion


    ShowHideUserBanIconUserInList(params?: Array<any>) {
        let userId: number = params[0];
        let banPlace: string = params[1];
        let show: boolean = params[2];

        this.roomService.showHideUserBanMuteIconUserInListReceivedCommand(userId, banPlace, show, BanOrMute.Ban);
    }

    ShowHideUserMuteIconUserInList(params?: Array<any>) {
        let userId: number = params[0];
        let mutePlace: string = params[1];
        let show: boolean = params[2];

        this.roomService.showHideUserBanMuteIconUserInListReceivedCommand(userId, mutePlace, show, BanOrMute.Mute);
    }

    // #region Admin Operations
    AdminPaneliKapat() {
        try {
            this.panelManagerService.closeAdminPage();
            this.panelManagerService.adminModuleLoaded = false;
        } catch (error) {
            console.log(error);
        }
    }

    KullaniciLogOut(params?: Array<any>) {
        let isTrue: boolean = params[0];

        this.roomService.signOut();
    }

    RedirectToLoginPage(params?: Array<any>) {
        let userId: number = params[0];
        let message: string = params[1];

        this.messagePanelService.ShowPopupMessageWithLocalization('Bilgileriniz güncellenmiştir.. Yeniden login olmanız gerekmektedir..', 2000);
        setTimeout(() => {
            this.roomService.signOut()
        }, 2000);
    }

    // #endregion

    ShowBanMessage(params?: Array<any>) {
        let message: string = params[0];

        let htmlMessage = "<table>\
        <tr>\
            <td valign=\"middle\" align=\"center\">\
               <img src=\"https://sesliturkiye.azureedge.net/themes/SesliTurkiye/Banned_64x64.png\">\
            </td>\
        </tr>\
        <tr>\
            <td style=\"font-weight: bold;\">"+ message + "</td>\
        </tr>\
    </table>";
        this.messagePanelService.ShowPopupMessageWithLocalization(htmlMessage, 2000);
    }


    KullaniciAdminHareketiMesajGoster(params?: Array<any>) {
        let userId: number = params[0];
        let mesaj: string = params[1];

        if (typeof mesaj === 'object') {
            var keepAdminLog = new KeepAdminLog();
            mesaj = this.adminService.createAdminLog(keepAdminLog, mesaj)
        }

        if (userId === this.indexedDBService.userFullInfo.KullaniciId){
            this.messagePanelService.ShowPopupMessageWithLocalization(mesaj, 2000);
        }
        if (mesaj.includes("YourAccountDeleted")) {
            this.roomService.livekitService.disconnectRoom();
            this.roomService.redirectUserToLoginPage()
        }
    }

    UpdateUserImageInList(params?: Array<any>) {
        let userId: number = params[0];
        let imageId: string = params[1];

        if (this.panelManagerService.roomOpened)
            this.roomService.updateUserPhotoInList(userId, imageId);
    }

    UpdateUserAllInfo(params?: Array<any>) {
        let userFullInfo: UserFullInfo = params[0];
        let userId: number = params[1];
        let rutbeDerece: number = params[2];
        let rutbeName: string = params[3];
        this.roomService.updateUserAllInfo(userFullInfo, userId, rutbeDerece, rutbeName);
    }

    UpdateUserStateInList(params?: Array<any>) {
        let userId: number = params[0];
        let stateName: number = params[1];

        if (this.panelManagerService.roomOpened) {
            this.roomService.updateUserStateInRoomList(userId, stateName);
        }

        if (this.indexedDBService.isMyFriend(userId)) {
            var user = this.indexedDBService.Friends.AllFriends.find(f => f.KullaniciId === userId);

            if (this.utilService.isNullOrUndefined(user))
                return;

            user.StateId = stateName;
            this.indexedDBService.friendsChangedSource.next(user.KullaniciId);
        }
    }


    SetOdaSetting(params?: Array<any>) {
        let roomId: number = params[0];
        let odaAdi: string = params[1];
        let deleted: boolean = params[2];

        if (deleted) {
            // this.messagePanelService.ShowPopupMessageWithLocalization(odaAdi + ' Adlı oda silindi!', 2000);
            this.roomService.roomDeletedSource.next(roomId);
            if (roomId === this.roomService.currentRoom.Info?.ID) {
                this.roomService.kickFromRoomReceivedCommand(this.indexedDBService.userFullInfo.KullaniciId, roomId, true);
            }
        }

        this.roomService.setDefaultFavoriteButton(roomId, odaAdi);

        if (roomId === this.roomService.currentRoom.Info?.ID) {
            this.roomService.setRoomSetting(roomId);
        }


    }

    UserBlockUnblock(params?: Array<any>) {
        let userId: number = params[0];
        let userName: string = params[1];
        let block: boolean = params[2];


        if (block) {
            this.panelManagerService.removeP2PChatPanel(userId)

            let blockingMeFriend = this.indexedDBService.Friends.AllFriends.find(f => f.KullaniciId === userId);
            this.indexedDBService.Friends.AllFriends = this.indexedDBService.Friends.AllFriends.filter(f => f.KullaniciId !== userId);
            this.indexedDBService.Friends.BlockedMeFriends.push(blockingMeFriend);

            this.messagePanelService.ShowPopupMessageWithLocalization('[' + userName + '] ' + this.utilService.getKeyWithTag("UserBlockingMe"), 2000); this.indexedDBService.friendsChangedSource.next(userId);
        } else {
            let unBlockFriend = this.indexedDBService.Friends.BlockedMeFriends.find(f => f.KullaniciId === userId);
            this.indexedDBService.Friends.BlockedMeFriends = this.indexedDBService.Friends.BlockedMeFriends.filter(f => f.KullaniciId !== userId);
            this.indexedDBService.Friends.AllFriends.push(unBlockFriend);

            this.messagePanelService.ShowPopupMessageWithLocalization('[' + userName + '] ' + this.utilService.getKeyWithTag("UserBlockingMeRemoved"), 2000)
        }

    }

    RefreshUser(params?: Array<any>) {
        let userId = params[0];

        this.userService.getUser(userId)
            .subscribe((user: UserFullInfo) => {
                user.StateId = user.StateId === 0 ? 1 : user.StateId;
                user.Token = this.indexedDBService.userFullInfo.Token;

                this.indexedDBService.userFullInfo = user;
                var userFullInfo = Object.assign({}, this.indexedDBService.userFullInfo)
                userFullInfo.Email = ''
                // this.chatMessageService.sendAdminBulkSettingMessageToPublicChannel([this.dynamicMethodsSendService.RefreshMyRoomUserList(this.indexedDBService.userFullInfo.KullaniciId, false, userFullInfo)]);
                let operationMessageForRefreshMyRoomUserList= this.dynamicMethodsService.prepareAdminBulkSettingMessage(
                    [this.dynamicMethodsSendService.RefreshMyRoomUserList(this.indexedDBService.userFullInfo.KullaniciId, false, userFullInfo)]
                )
                this.dynamicMethodsService.publishDynRefreshMyRoomUserList(operationMessageForRefreshMyRoomUserList)
             
                this.indexedDBService.updateUser(this.indexedDBService.userFullInfo);
                this.RefreshMyFriendsFriendList();
                this.userService.userUpdatedSource.next(this.indexedDBService.userFullInfo);
                this.roomService.RefreshMyPhotoInRoom(this.indexedDBService.userFullInfo.ProfilResmiId);
            })
    }

    ShowBalloonTipForFriendActions(params?: Array<any>) {
        let userid = params[0];
        let title = params[1];
        let mesaj = params[2];
        this.messageService.add({ severity: 'success', summary: title, detail: this.utilService.parseGetMessageValue(mesaj) });

        //TODO
    }

    RefreshRoomUserListForAllUsersInRoom() {
    //   this.roomService.refreshCurrentUserListPage();
    }

    RefreshRoomMicUserListForAllUsersInRoom() {
        this.roomService.callGetMicUserList()
    }

    RefreshMyRoomUserList(params?: Array<any>) {
        let userId = params[0];
        let useCache = params[1];
        let userFullInfo: UserFullInfo = params[2];
        //another way for refresh
        // this.roomService.refreshUserList(userId, userFullInfo);
    }

    RefreshFriendList() {
        this.friendshipService.getFriends().then((response) => {
            this.indexedDBService.Friends = response;
            this.panelManagerService.loadedFriends = Promise.resolve(true);
        })
    }

    RemoveBulkBanMute(params?: Array<any>) {
        let banType = params[0];
        let userRutbe = params[1];
        this.roomService.removeBulkBanMute(banType, userRutbe);
    }

    OdaSiteBanGirisleriSet(params?: Array<any>) {
        let allowRoomBanEnter = params[0];
        let allowGenerelBanEnter = params[1];

        if (this.indexedDBService.redisRootSettings.OdaBanGirisleri !== allowRoomBanEnter) {
            this.indexedDBService.redisRootSettings.OdaBanGirisleri = allowRoomBanEnter;
            this.indexedDBService.UpdateOdaBanGirisleri(allowRoomBanEnter, this.indexedDBService.userFullInfo.KullaniciAdi);
        }

        if (this.indexedDBService.redisRootSettings.SiteBanGirisleri !== allowGenerelBanEnter) {
            this.indexedDBService.redisRootSettings.SiteBanGirisleri = allowGenerelBanEnter;
            this.indexedDBService.UpdateSiteBanGirisleri(allowGenerelBanEnter, this.indexedDBService.userFullInfo.KullaniciAdi);
        }

        if (allowGenerelBanEnter === false && this.indexedDBService.userFullInfo.BanSite === true && !this.utilService.isNullOrUndefined(this.roomService.currentRoom.Info)) {
            this.roomService.leaveFromRoomActions(null).then(() => {
                return this.livekitService.disconnectRoom()
              });
        }

        if (allowRoomBanEnter === false && this.indexedDBService.userFullInfo.BanOdaList.length > 0 && !this.utilService.isNullOrUndefined(this.roomService.currentRoom.Info)) {
            if (this.indexedDBService.userFullInfo.BanOdaList.find(o => o === this.roomService.currentRoom.Info.ID))
                this.roomService.leaveFromRoomActions(null).then(() => {
                    return this.livekitService.disconnectRoom()
            });
        }
    }

    OdaBanGirisleriDegisti(params?: Array<any>) {
        let message = params[0];
        this.messagePanelService.ShowPopupMessageWithLocalization(message, 2000);
    }

    EditUsersForChangedRank(params?: Array<any>) {
        let rankId: number = params[0];
        let userRankFullInfo: RankFullInfo = params[1];

        if (rankId === this.indexedDBService.userFullInfo.RutbeId) {
            this.indexedDBService.userFullInfo.RutbeFullInfo = userRankFullInfo;
            this.indexedDBService.userFullInfo.RutbeAdi = userRankFullInfo.ADI;
            this.indexedDBService.userFullInfo.RutbeDerece = userRankFullInfo.DERECE;
            this.indexedDBService.userFullInfo.RutbeId = userRankFullInfo.ID;
            this.indexedDBService.updateUser(this.indexedDBService.userFullInfo);
            this.userService.userUpdatedSource.next(this.indexedDBService.userFullInfo);
            this.roomService.updateUserAllInfo(this.indexedDBService.userFullInfo);
            this.RefreshMyFriendsFriendList();

            this.dynamicMethodsService.publishDynUpdateUserAllInfoFriend(
                this.indexedDBService.userFullInfo.KullaniciId,
                {
                    userId: this.indexedDBService.userFullInfo.KullaniciId,
                    data: JSON.stringify([this.dynamicMethodsSendService.UpdateUserAllInfo(this.indexedDBService.userFullInfo, this.indexedDBService.userFullInfo.KullaniciId, this.indexedDBService.userFullInfo.RutbeDerece, this.indexedDBService.userFullInfo.RutbeAdi)])
                }
            )
        }
    }

    SMSValidationRequestChanged(params?: Array<any>) {
        let SMSactive: boolean = params[0];
        this.indexedDBService.SMSActive = SMSactive;
        // this.messagePanelService.ShowPopupMessageWithLocalization(SMSactive ? 'ActiveSMSValidationRequest' : 'AbandonedSMSValidationRequest', 2000);
    }

    InviteToRoom(params?: Array<any>) {
        let roomID: number = params[0];
        let roomName: string = params[1];
        let invitedName: string = params[2];
        this.panelManagerService.inviteToRoomPopupDisplay = true;
        setTimeout(() => {
            this.roomService.takenInviteToRoomSource.next(params);
        }, 500);
    }

    InviteToMicrophone(params?: Array<any>) {
        try {
          let userId: number = params[0];
          let roomId: number = params[1];
          let inviteKey: string = params[2];
          this.roomService.micInviteKey = inviteKey;
          setTimeout(() => {
            this.panelManagerService.roomMicrophoneAcceptPopupDisplay = true;
          });
        } catch (error) {
          console.error('InviteToMicrophone(receiver) an error occurred:', error);
        }
      }

      MuteUnMuteUserMicrophone(params?: Array<any>) {
        let userId: number = params[0];
        let roomId: number = params[1];
        let isMute: boolean = params[2];
        if (userId === this.indexedDBService.userFullInfo.KullaniciId && this.roomService.currentRoom.Info.ID === roomId) {
            if (isMute) {
                this.roomService.userMicMuteDynamicMethodReceivedSource.next(isMute)
            }
            else{
                this.panelManagerService.openMicRequestPopupDisplay = true;
            }
        }
      }
      

    OdaSahibi(params?: Array<any>) {
        let isOwner: boolean = params[0];

        this.indexedDBService.UserIsAnyRoomOwner = isOwner;
        this.indexedDBService.UpdateUserIsAnyRoomOwner(this.indexedDBService.UserIsAnyRoomOwner);
    }

    CloseP2PChatPanel(params?: Array<any>) {
        let userId: number = params[0];
        this.roomService.closeP2PChatPanel(userId);
    }

    UpdateAuthorizedUserSliderList(params?: Array<any>) {
        let sliderUserList: RoomSliderUser[] = params[0];

        if (!this.deviceService.isMobile) {
            this.indexedDBService.AuthorizedUserSliderList = sliderUserList;
            this.indexedDBService.UpdateAuthorizedUserSliderList(this.indexedDBService.AuthorizedUserSliderList, this.indexedDBService.userFullInfo.KullaniciAdi)
            this.indexedDBService.authorizedUserSliderListSource.next();
        }
    }

    DropMic() {
        this.roomService.stopMicrophoneRequest().then(() => {
            this.livekitService.isOnMic = false
            this.livekitService.allSenderAudioTrackAreMuted = true
            this.livekitService.isMicEnabled = false
          }).then(() => {
            if (this.livekitService.isOnScreenSharing) {
              this.roomService.stopScreenShare(this.indexedDBService.userFullInfo.KullaniciId,this.roomService.currentRoom.Info?.ID,false,false);
            }
          })
          .then(() => {
            if (this.livekitService.isOnSharingTabMusic) {
              this.livekitService.toggleShareScreenAudio(false);
            }
          })
          .then(() => {
            if (this.livekitService.isMicEnabled == false) {
                return this.roomService.callUserMuteUnMuteMic(false)
             }
          })
    }

    ChangedRoomOrderList(params?: Array<any>) {
        let roomIds: Array<number> = params[0];
        this.indexedDBService.RoomOrderList = roomIds;

        this.roomService.setRoomOrderList(this.indexedDBService.Rooms);
    }

    GetComplainNotification() {
        if (this.indexedDBService.userFullInfo.RutbeFullInfo.SIKAYET_GORUNTULE ||
            this.indexedDBService.userFullInfo.RutbeFullInfo.SIKAYET_SIL) {
            this.panelManagerService.visibleComplainNotification = true;
            this.panelManagerService.notificationsOpenedSource.next();
        }
    }

    GetLiveSupportNotification() {
        if (this.indexedDBService.userFullInfo.RutbeFullInfo.SIKAYET_GORUNTULE ||
            this.indexedDBService.userFullInfo.RutbeFullInfo.SIKAYET_SIL) {
            this.panelManagerService.visibleLiveSupportNotification = true;
            this.panelManagerService.notificationsOpenedSource.next();
        }
    }

    RoomCreated(param: Room) {
        // this.indexedDBService.Rooms.push(param[0]);
        this.roomService.isNewRoomCreatedDynMessageReceived=true
        this.adminRoomService.newRoomAddedSource.next(param[0]);
    }

    ConnectionProblem(params?: Array<any>) {
        let isTrue: boolean = params[0];

        this.roomService.connectionProblemAndReconnect()
    }
}