import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Observable, Subscription } from 'rxjs';
import { Language } from '../models/language';
import { LocalStorageService } from './local-storage.service';

@Injectable({
    providedIn: 'root'
})

export class LanguageService {

    languageSource: Subject<Language> = new Subject();
    currentLanguage;
    selectedLanguage: string;
    public languages: Array<Language> = [
        new Language('en', 'english', 'English'),
        new Language('de', 'german', 'German'),
        new Language('es', 'spanish', 'Spanish'),
        new Language('it', 'italian', 'Italian'),
        new Language('fr', 'french', 'French'),
        new Language('cn', 'chinese', 'Chinese'),
        new Language('ar', 'arabic', 'Arabic'),
        new Language('ru', 'russian', 'Russian'),
        new Language('tr', 'turkish', 'Turkish'),
    ];
    

    constructor(
        private translateService: TranslateService,
        private storageService: LocalStorageService
    ) {
        // const currentLanguageStr = this.storageService.storageGetItem('currentLanguage');
        // if (currentLanguageStr) {
        //     this.currentLanguage = JSON.parse(currentLanguageStr);
        //     this.setChangedLanguage(this.currentLanguage);
        //     this.translateService.use(this.currentLanguage.key);
        // }
    }

    changeLanguage(data: Language) {
        this.currentLanguage = data;
        this.translateService.use(this.currentLanguage.key);
        this.setChangedLanguage(this.currentLanguage);
        this.storageService.storageSetItem('currentLanguage', JSON.stringify(data));
    }


    getDefaultLanguage() {
        return this.languages.find(f => f.key === "tr");
    }

    getChangedLanguage(): Observable<Language> {
        return this.languageSource.asObservable();
    }

    setChangedLanguage(language: Language) {
        this.languageSource.next(language);
    }


    initChangeLanguageEventsForChildModules(translate: TranslateService, languageService: LanguageService, changedLanguageSubscription: Subscription) {
        let currentLanguage = languageService.getCurrentLanguage();
        if (currentLanguage) {
            translate.use(currentLanguage.key);
        }
        if (!changedLanguageSubscription)
            changedLanguageSubscription = languageService.getChangedLanguage().subscribe((language: Language) => {
                translate.use(language.key);
            });
    }

    getCurrentLanguage() {
        const currentLanguageStr = this.storageService.storageGetItem('currentLanguage');
        if (currentLanguageStr) {
            this.currentLanguage = JSON.parse(currentLanguageStr);
        }
        return this.currentLanguage;
    }
}