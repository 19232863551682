export class ConfirmationDialogParams {
    title: string;
    message: string;
    display: boolean;
    acceptLabel: string;
    rejectLabel?: string;
    style: string;
    headerStyle: {};
    ok: Function;
    cancel: Function;
}