import { Injectable } from '@angular/core';
import { EmojiService } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { Observable } from 'rxjs';
import { ServiceInformation } from './serviceinformation.service';
import { UtilService } from 'src/app/core/services/util.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';


@Injectable({
  providedIn: 'root'
})
export class MessengerService extends ServiceInformation {

  constructor(
    private emojiService: EmojiService,
    private http: HttpClient,
    public utilService: UtilService,
    public messagePanelService: MessagePanelService,
    protected indexedDBService: IndexedDBService
  ) {

    super(messagePanelService, utilService, indexedDBService)

  }
  createEmojiHtml(emoji) {
    const styles = this.emojiService.emojiSpriteStyles(emoji.sheet, "apple");
    const el = document.createElement("img");

    Object.assign(el.style, styles);

    el.className = "emoji";
    el.setAttribute("data-key", emoji.id);
    el.setAttribute(
      "src",
      "https://d1n9fljh5ycvwn.cloudfront.net/assets/transparent.png"
    );
    el.id = emoji.id;
    return el.outerHTML;
  }

  public saveOfflineChat(body): Observable<any> {
    body["ClientInfo"] = this.Get_ClientInfo();
    const endpoint = this.ServiceUrl + 'api/chat/offline/save';
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }
}
