import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { DynamicMethodsReceiveService } from 'src/app/core/services/dynamic-methods-receive.service';
import { UserService } from 'src/app/core/services/user.service';
import { UtilService } from 'src/app/core/services/util.service';
import { faUpload, faCamera, faImage, faFileImage, faTrash } from '@fortawesome/free-solid-svg-icons';
import { RoomService } from 'src/app/core/services/room.service';
import { ChatMessageService } from 'src/app/core/services/chatMessage.service';
import { DynamicMethodsSendService } from 'src/app/core/services/dynamic-methods-send.service';
import { DynamicMethodsService } from 'src/app/core/services/dynamic-methods.service';
@Component({
  selector: 'app-update-profile-photo',
  templateUrl: './update-profile-photo.component.html',
  styleUrls: ['./update-profile-photo.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class UpdateProfilePhotoComponent implements OnInit {
  faUpload = faUpload;
  faCamera = faCamera;
  faImage = faImage;
  faFileImage = faFileImage;
  faTrash = faTrash;


  constructor(
    public panelManagerService: PanelManagerService,
    private messagePanelService: MessagePanelService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    public indexedDBService: IndexedDBService,
    private dynamicMethodsReceiveService: DynamicMethodsReceiveService,
    private userService: UserService,
    private utilService: UtilService,
    private roomService: RoomService,
    private chatMessageService: ChatMessageService,
    private dynamicMethodsSendService: DynamicMethodsSendService,
    private dynamicMethodsService:DynamicMethodsService
  ) {
  }

  ngOnInit() { }

  openFileUpload($event) {
    $event.preventDefault();
    let element: HTMLElement = document.getElementById('fileUpload') as HTMLElement;
    element.click();
  }

  onInputClick($event) {
    $event.target.value = '';
    $event.stopPropagation();
  }

  fileChangeEvent($event) {
   if ($event.target.files && $event.target.files.length) {
       for (const file of $event.target.files) {
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => {
           const img = new Image();
           img.src = reader.result as string;
           img.onload = () => {
             if (img.width < 400 || img.height < 400) {
               this.messagePanelService.ShowPopupMessageWithLocalization('InvalidProfilePhotoSize', 2000);
               return;
             }
             else {
               this.userService.imageChangedEvent = $event;
               this.panelManagerService.imageCropperPopupDisplay = true;
             }
           };
         };
       }
     }
  }

  deleteProfilePhoto() {
    this.confirmationService.confirm({
      message: this.translateService.instant('confirm-photo-del'),
      header: this.translateService.instant('profile-picture-delete'),
      icon: null,
      acceptLabel: this.translateService.instant('Yes'),
      rejectLabel: this.translateService.instant('No'),
      accept: () => {
        let avatarId: string = "";

        this.utilService.avatarCounts.find(avatarCount => {
          if (this.indexedDBService.userFullInfo.ProfilResmiId === '10000000-0000-0000-0000-0000000000' + (avatarCount + 1 < 10 ? '0' : '') + (avatarCount + 1))
            avatarId = this.indexedDBService.userFullInfo.ProfilResmiId;
        });

        //setTimeout(() => {
        this.callRemoveProfilePhoto(avatarId);
        //});
      },
      reject: () => {

      }
    });
  }


  callRemoveProfilePhoto(avatarId: string) {
    this.userService.callSaveUserImageServer({
      "ClientInfo": null,
      "Delete": true,
      "UserId": this.indexedDBService.userFullInfo.KullaniciId,
      "Base64String": "",
      "Base64StringSmall": "",
      "AvatarId": this.utilService.isNullOrEmtpyString(avatarId) ? "" : avatarId
    })
      .then(() => {
        this.dynamicMethodsReceiveService.RefreshMyFriendsFriendList(false, false);
        this.roomService.updateUserAllInfo(this.indexedDBService.userFullInfo);
        this.roomService.RefreshMyPhotoInRoom(this.indexedDBService.userFullInfo.ProfilResmiId);
      })
      .then(() => {
        // this.chatMessageService.sendUserSettingMessage(
        //   this.indexedDBService.userFullInfo.KullaniciId,
        //   [
        //     this.dynamicMethodsSendService.RefreshUser(this.indexedDBService.userFullInfo.KullaniciId)
        //   ],
        //   this.indexedDBService.userFullInfo.KullaniciAdi
        // )

        let operationMessageForRefreshUser = this.dynamicMethodsService.prepareUserSettingMessage(
          this.indexedDBService.userFullInfo.KullaniciId,
          [
            this.dynamicMethodsSendService.RefreshUser(this.indexedDBService.userFullInfo.KullaniciId)
          ],
          this.indexedDBService.userFullInfo.KullaniciAdi)
        this.dynamicMethodsService.publishDynRefreshUser(this.indexedDBService.userFullInfo.KullaniciId,this.indexedDBService.userFullInfo.KullaniciId,operationMessageForRefreshUser);
      });
  }

}