import { Component, ViewEncapsulation } from '@angular/core';
import { PanelManagerService } from '../../../services/panel-manager.service';
import { MessagePanelService } from '../../../services/messagePanel.service';
import { RoomService } from '../../../services/room.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-account-deletion-request',
  templateUrl: './account-deletion-request.component.html',
  styleUrls: ['./account-deletion-request.component.scss'],
  encapsulation: ViewEncapsulation.Emulated

})
export class AccountDeletionRequestComponent {
  reason:string = ""

  constructor(
    public panelManagerService: PanelManagerService,
    private userService:UserService,
    public messagePanelService: MessagePanelService,
  ) { }

  

  createDeleteRequest(){
    if (this.reason == null || this.reason == "") {
      this.messagePanelService.ShowPopupMessageWithLocalization('deletion-request-not-blank',2000);
      return
    }
    this.userService.callAccountDeletionRequest(this.reason).then(res=>{
        this.messagePanelService.ShowPopupMessageWithLocalization('deletion-request-created-success', 3000);
        this.panelManagerService.accountDeletionPopupDisplay = false
      }),err =>{
      this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
    }
  }
}
