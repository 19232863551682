import { Component, OnInit } from '@angular/core';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { RoomService } from 'src/app/core/services/room.service';

@Component({
  selector: 'app-open-mic-request',
  templateUrl: './open-mic-request.component.html',
  styleUrls: ['./open-mic-request.component.scss']
})
export class OpenMicRequestComponent implements OnInit {

  constructor(
    public panelManagerService: PanelManagerService,
    private roomService: RoomService
  ) {
   }

  ngOnInit(): void {
  }

  accept(){
    this.roomService.userMicMuteDynamicMethodReceivedSource.next(false)
    this.closeInviteToOpenMic();
  }

  closeInviteToOpenMic(){
    this.panelManagerService.openMicRequestPopupDisplay = false;
  }
}
