import { Component, Input, OnInit } from '@angular/core';
import { IndexedDBService } from '../../services/indexed-db.service';
import { MessagePanelService } from '../../services/messagePanel.service';

@Component({
  selector: 'app-user-id',
  templateUrl: './user-id.component.html',
  styleUrls: ['./user-id.component.scss']
})
export class UserIdComponent implements OnInit {

  @Input() userId: any = this.indexedDBService.userFullInfo.KullaniciId;
  @Input() color: string = 'rgba(255, 255, 255, 0.7)';

  

  constructor(
    public indexedDBService: IndexedDBService,
    private messagePanelService: MessagePanelService
  ) {

  }

  ngOnInit(): void {
  }

  copyID(event) {
    event.stopPropagation();
    navigator.clipboard.writeText(this.userId)
    .then(() => {
      this.messagePanelService.ShowPopupMessageWithLocalization('copiedSuccessfully', 2000);  
      event.preventDefault();
      event.stopPropagation();
    });
  }
}
