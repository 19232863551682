import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';

import { UtilService } from 'src/app/core/services/util.service';
import { Friend, UserFullInfo } from 'src/app/core/models/user';
import { FriendListService } from 'src/app/core/services/friendlist.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { Subscription } from 'rxjs';
import { FriendshipService } from 'src/app/core/services/friendship.service';
import { ChatService } from 'src/app/core/services/chat.service';
import { ChatMessage, MessageRecords } from 'src/app/core/models/chat-message';
import { ContactType, FriendListType } from 'src/app/core/models/enums';
import { DeviceService } from 'src/app/core/services/device.service';
import { faTimes, faArrowLeft, faAngleDown } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-friend-list',
  templateUrl: './friend-list.component.html',
  styleUrls: ['./friend-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class FriendtListComponent implements OnInit, OnDestroy {

  //#region properties 
  faTimes = faTimes;
  faArrowLeft = faArrowLeft;
  faAngleDown = faAngleDown;

  friendListType = FriendListType;
  selectedFriendListType = FriendListType.Friends;
  currentFriend: Friend;
  searchKey: any = '';
  userMessageTime: { [key: number]: number } = {};

  friendBlockedSubscription: Subscription;
  friendshipRequestDeletedSubscription: Subscription;
  newMessageNotificationTakenSubscription: Subscription;

  //#endregion

  //#region constructor init destroy 

  constructor(
    public friendListService: FriendListService,
    private utilService: UtilService,
    private messagePanelService: MessagePanelService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    public indexedDBService: IndexedDBService,
    public panelManagerService: PanelManagerService,
    private friendshipService: FriendshipService,
    private chatService: ChatService,
    public deviceService: DeviceService
  ) {

    this.friendBlockedSubscription = this.friendListService.friendBlocked.subscribe((friend) => {
      this.blockUser(friend);
    });

    this.friendshipRequestDeletedSubscription = this.friendListService.friendshipRequestDeleted.subscribe(friend => {
      this.deleteRequest(friend.KullaniciId, friend.KullaniciAdi, FriendListType.Friends);
    });

    this.newMessageNotificationTakenSubscription = this.chatService.newMessageNotificationTaken.subscribe((chatMessage: ChatMessage) => {
      this.userMessageTime[chatMessage.SenderId] = chatMessage.Time.getTime();
      this.sortFriendList();
    });

  }

  ngOnInit() {

  }

  ngOnDestroy() {
    if (this.friendshipRequestDeletedSubscription)
      this.friendshipRequestDeletedSubscription.unsubscribe();

    if (this.friendBlockedSubscription)
      this.friendBlockedSubscription.unsubscribe();

    if (this.newMessageNotificationTakenSubscription)
      this.newMessageNotificationTakenSubscription.unsubscribe();
  }
  //#endregion

  changeListType(friendListType: FriendListType) {
    this.selectedFriendListType = friendListType;
  }

  //#region friend list operations 
 
  sortFriendList() {
    this.indexedDBService.Friends.IncomingFriends
    this.indexedDBService.Friends.OutgoingFriends

    this.indexedDBService.Friends.AllFriends = this.indexedDBService.Friends.AllFriends.sort(this.sortByAll.bind(this));
  }

  sortByAll(a: UserFullInfo, b: UserFullInfo) {
    let aTime = this.userMessageTime[a.KullaniciId];
    if (!aTime)
      aTime = -1;

    let bTime = this.userMessageTime[b.KullaniciId];
    if (!bTime)
      bTime = -1;

    if (aTime > bTime)
      return -1;
    else if (aTime < bTime)
      return 1;
    else
      return 0;
  }

  //#region friendship operations 
  deleteRequest(friendId: number, friendName: string, friendListType: FriendListType) {
    let activeP2PChatPanel;
    if (this.utilService.isNullOrEmtpyObject(this.panelManagerService.p2pChatPanels) === false) {
      activeP2PChatPanel = this.panelManagerService.p2pChatPanels.find(f => f.user.KullaniciId === friendId);
    }

    if (this.utilService.isNullOrEmtpyObject(activeP2PChatPanel) === false && this.utilService.isNullOrEmtpyObject(activeP2PChatPanel.chatPanelProps) === false) {
      if (activeP2PChatPanel.chatPanelProps.ContactType === ContactType.Audio
        || activeP2PChatPanel.chatPanelProps.ContactType === ContactType.VideoAndAudio) {
        this.confirmationService.confirm({
          message: this.translateService.instant('RemoveFriendFormWarning'),
          header: this.translateService.instant('removeFriend'),
          icon: null,
          acceptLabel: this.translateService.instant('Yes'),
          rejectLabel: this.translateService.instant('No'),
          accept: () => {
            this.friendListService.callRemoveFriend(friendId, friendListType);
          },
          reject: () => {
          }
        });
      }
      else {
        this.confirmRemoveFriend(friendId, friendName, friendListType);
      }
    }
    else {
      this.confirmRemoveFriend(friendId, friendName, friendListType);
    }

  }

  confirmRemoveFriend(friendId: number, friendName: string, friendListType: FriendListType) {
    this.confirmationService.confirm({
      message: '[' + friendName + '] ' + this.translateService.instant('BeforeRemoveFriendQuestion'),
      header: this.translateService.instant('removeFriend'),
      icon: null,
      accept: () => {
        this.friendListService.callRemoveFriend(friendId, friendListType);
      },
      reject: () => {

      }
    });
  }

  acceptRequest(friend: UserFullInfo) {
    this.confirmationService.confirm({
      message: '[' + friend.KullaniciAdi + '] ' + this.translateService.instant('DoYouApproveThisFriend'),
      header: this.translateService.instant('approveFriendRequest'),
      icon: null,
      accept: () => {
        this.friendListService.callAcceptRequest(friend.KullaniciId);
      },
      reject: () => {

      }
    });
  }
  //#endregion

  //#region user menu operations 
  blockUser(friend: Friend) {
    this.currentFriend = friend;
    let activeP2PChatPanel;
    if (this.utilService.isNullOrEmtpyObject(this.panelManagerService.p2pChatPanels) === false) {
      activeP2PChatPanel = this.panelManagerService.p2pChatPanels.find(f => f.user.KullaniciId === this.currentFriend.KullaniciId);
    }

    if (this.utilService.isNullOrEmtpyObject(activeP2PChatPanel) === false && this.utilService.isNullOrEmtpyObject(activeP2PChatPanel.chatPanelProps) === false) {
      if (activeP2PChatPanel.chatPanelProps.ContactType === ContactType.Audio
        || activeP2PChatPanel.chatPanelProps.ContactType === ContactType.VideoAndAudio) {
        this.confirmationService.confirm({
          message: this.translateService.instant('BlockFriendFormWarning'),
          header: this.translateService.instant('blockuser'),
          icon: null,
          acceptLabel: this.translateService.instant('Yes'),
          rejectLabel: this.translateService.instant('No'),
          accept: () => {
            this.friendListService.callBlockUser(this.currentFriend.KullaniciId, this.currentFriend.KullaniciAdi);
          },
          reject: () => {
          }
        });
      }
      else {
        this.confirmBlockFriend();
      }
    }
    else {
      this.confirmBlockFriend();
    }

  }

  confirmBlockFriend() {
    this.confirmationService.confirm({
      message: '[' + this.currentFriend.KullaniciAdi + '] ' + this.translateService.instant('BeforeBlockUser'),
      header: this.translateService.instant('blockuser'),
      icon: null,
      accept: () => {
        this.friendListService.callBlockUser(this.currentFriend.KullaniciId, this.currentFriend.KullaniciAdi);
      },
      reject: () => {

      }
    });
  }
  //#endregion

  clickOnFriend(friend: Friend) {
    // event.stopPropagation();
    // && !this.panelManagerService.showedFriendList
    if (this.selectedFriendListType === FriendListType.Friends) {

      if (this.panelManagerService.showedFriendList) {
        this.panelManagerService.showedFriendList = false;
      }

      if (!this.panelManagerService.showedP2p) {
        this.panelManagerService.showedP2p = true;
      }

      this.panelManagerService.openP2PChatPanel(friend.KullaniciId, this.indexedDBService.getFriendById(friend.KullaniciId));
    }
    // delete this.userMessageTime[friend.KullaniciId];
  }

  openContextMenu(friend: Friend) {
    if (this.selectedFriendListType === FriendListType.Friends) {
      event.preventDefault();
      event.stopPropagation();
      this.currentFriend = friend;
      this.panelManagerService.p2pContextMenuPopupDisplay = true;
    }
  }

  copy() {
    event.stopPropagation();
  }
}
