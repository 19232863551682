import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { faMusic, faPlay, faTimes, faVideo } from '@fortawesome/free-solid-svg-icons';
import { PanelManagerService } from '../../services/panel-manager.service';
import { RoomService } from '../../services/room.service';
import { DeviceService } from '../../services/device.service';
import { RecordRoomVideoType, RoomRecord } from '../../models/room';
import { Constants } from '../../models/constants';
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-room-records',
  templateUrl: './room-records.component.html',
  styleUrls: ['./room-records.component.scss']
})
export class RoomRecordsComponent implements OnInit {
  faTimes = faTimes;
  faPlay = faPlay
  faMusic = faMusic
  
  @ViewChild('filterArea') filterArea: ElementRef<HTMLElement>;

  loaded: boolean = false;
  first: number = 0;
  height: string;
  isThereError: boolean = false;
  cols: any[];
  pageSize: number = 15;
  roomRecords: RoomRecord[] = [];
  totalCount: number = 0;

  recordListPanelDisplayedSubscription: Subscription;
  constructor(
    public panelManagerService: PanelManagerService,
    public roomService: RoomService,
    public deviceService: DeviceService
  ) {
    this.recordListPanelDisplayedSubscription = this.panelManagerService.recordListPanelDisplayed.subscribe((isOpen:boolean) => {
      if (isOpen) {
        this.ngOnInit()
      }
    })
  }

  ngOnInit(): void {
    this.initTableColumn()
    this.callListOfRecord(1)
  }

  initTableColumn() {
    this.cols = [
      { field: 'id', header: 'Id' },
      { field: 'kayıt_tarihi', header: 'record-date' },
      { field: 'oynat', header: 'play-record' },
    ];
  }

  openVideoPanel(url: string) {
    if (url) {
      if (!this.roomService.videoInfo) {
        this.roomService.videoInfo = {
          videoUrl: '',
          videoType: ''
        };
      }
      this.roomService.videoInfo = {
        videoUrl: Constants.AmazonS3RoomRecordPath + url,
        videoType: url.includes('mp4') ? 'mp4' : 'hls'
      };
      this.panelManagerService.viewRecordVideoPopupDisplay = true;
    }
  }
  
  loadRecordssLazy($event) {
    this.callListOfRecord(($event.first / this.pageSize) + 1);
  }

  callListOfRecord(pageIndex: number) {
    if (pageIndex === 1) {
      this.first = 0;
    }
    this.roomService.callListRecordings(
      false,
      this.pageSize,
      1
    )
      .then((response: RoomRecord[]) => {
        if (response.length === 0) {
          this.isThereError = true;
        }
        else{
          this.totalCount = response.length
          this.roomRecords = response
          this.roomRecords.forEach(record => {
            record.EndedAt = formatDate(record.EndedAt,'yyyy-MM-dd HH:mm:ss', 'en-US')
            if (record.PlaylistName.includes('mp4')) {
              record.OnlyAudio = true
            }
            else{
              record.OnlyAudio = false
            }
            record.OnlyAudio
          })
        }
      })
      .catch(error => {
        this.isThereError = true;
        console.log(error)
      })
      .finally(() => {
        this.loaded = true;
        this.deviceService.isMobile ? this.height = 'calc(100% - ' + (this.filterArea.nativeElement.offsetHeight + 8) + 'px)' :
          this.height = 'calc(100% - ' + (this.filterArea.nativeElement.offsetHeight + 21) + 'px)';
      })
  }

  refreshList() {
    this.callListOfRecord(1)
  }
}
