import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { UtilService } from 'src/app/core/services/util.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { ServiceInformation } from 'src/app/core/services/serviceinformation.service';
import { Observable, Subject } from 'rxjs';
import { UnpermittedWordData, UnpermittedWords } from '../models/unpermitted-words';
import { environment } from '../../../../../environments/environment';
import { BannedWordByService, BannedWordData } from '../../../../core/models/banned-word';

@Injectable({
    providedIn: 'root'
})

export class UnpermittedWordsService extends ServiceInformation {

    addUnpermittedWordPopupDisplay: boolean = false;
    unpermittedWords: Array<UnpermittedWords>;

    addedNewBannedWordSource: Subject<any> = new Subject();
    addedNewBannedWord = this.addedNewBannedWordSource.asObservable();

    totalCount: number = 0;

    constructor(
        private http: HttpClient,
        protected messagePanelService: MessagePanelService,
        protected utilService: UtilService,
        protected indexedDBService: IndexedDBService
    ) {
        super(messagePanelService, utilService, indexedDBService);
    }

    //#region get blocked word list
    callGetBlockedWordList(rutbeDerece: number, searchText: string,pageSize:number,pageIndex:number): Promise<any> {
        let body = {
            'ClientInfo': this.Get_ClientInfo(),
            'RutbeDerece': rutbeDerece,
            'SearchText': searchText,
            "PageIndex":pageIndex,
            "PageSize":pageSize
        };

        return new Promise((resolve, reject) => {
            this.getBlockedWordList(body)
                .subscribe((unpermittedWords:UnpermittedWordData) => {
                    if (this.utilService.isNullOrUndefined(unpermittedWords)) {
                        this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
                        reject();
                        return;
                    }

                    this.unpermittedWords = unpermittedWords.data;
                    this.totalCount = unpermittedWords.count;
                    resolve(null);
                }),
                error => {
                    console.log(error);
                    reject();
                };
        })
    }

    getBlockedWordList(body): Observable<any> {
        const endpoint = environment.apiUrl + "api/admin/blockedwords/list";
        return this.http.post<any>(endpoint, body)
            .pipe(catchError(error => this.handleError(error)));
    }

    getAllBlockedWords():Observable<BannedWordData>{
        let body = {
            'RutbeDerece': 999,
            'SearchText': '',
            "PageIndex":1,
            "PageSize":1000
        };
        const endpoint = environment.apiUrl + "api/admin/blockedwords/list";
        return this.http.post<any>(endpoint, body)
    }
    //#endregion

    //#region delete banned words
    callDeleteBannedWords(bannedWordId: string, rutbeDerece: number): Promise<any> {
        let body = {
            'ClientInfo': this.Get_ClientInfo(),
            'BannedWordId': bannedWordId,
            'RutbeDerece': rutbeDerece
        };

        return new Promise((resolve, reject) => {
            this.deleteBannedWords(body)
                .subscribe((response) => {
                    if (this.utilService.isNullOrUndefined(response) || response === "") {
                        this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
                        reject();
                        return;
                    }
                    resolve(null);
                }),
                error => {
                    console.log(error);
                    reject();
                };
        })
    }

    deleteBannedWords(body): Observable<any> {
        const endpoint = this.ServiceUrl + "api/admin/blockedwords/delete";
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }
    //#endregion

    //#region create banned words
    callCreateBannedWords(bannedWords: UnpermittedWords): Promise<any> {
        let body = {
            'ClientInfo': this.Get_ClientInfo(),
            'BannedWords': bannedWords,
        };

        return new Promise((resolve, reject) => {
            this.createBannedWords(body)
                .subscribe((response) => {
                    if (this.utilService.isNullOrUndefined(response) || response === "") {
                        this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
                        reject();
                        return;
                    }

                    if (response.Success === false && !this.utilService.isNullOrUndefined(response.ErrMsg)) {
                        this.messagePanelService.ShowPopupMessageWithLocalization(response.ErrMsg, 2000);
                        reject();
                        return;
                    }

                    if (response.Success) {
                        resolve(null);
                        return;
                    }

                }),
                error => {
                    console.log(error);
                    reject();
                };
        })
    }

    createBannedWords(body): Observable<any> {
        const endpoint = this.ServiceUrl + "api/admin/blockedwords/create";
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }
    //#endregion
}