import { Component, Input, OnInit } from '@angular/core';
import { RecordRoomVideoType } from 'src/app/core/models/room';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { RoomService } from '../../../services/room.service';

@Component({
  selector: 'app-playing-video',
  templateUrl: './playing-video.component.html',
  styleUrls: ['./playing-video.component.scss']
})
export class PlayingVideoComponent implements OnInit {
  @Input() videoInfo: RecordRoomVideoType 

  constructor(
    public panelManagerService: PanelManagerService,
    public roomService:RoomService
  ) {
  }
  ngOnInit(): void {

  }
  closeVideoPanel(){
    this.roomService.videoInfo = {
      videoUrl: '',
      videoType: ''
    }
    this.panelManagerService.viewRecordVideoPopupDisplay = false
  }
}
