import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessagePanelService } from '../../../services/messagePanel.service';
@Component({
  selector: 'app-message-panel',
  templateUrl: './message-panel.component.html',
  styleUrls: ['./message-panel.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class MessagePanelComponent implements OnInit {

  display: boolean = false;
  message: any;
  subscription: Subscription;
  closable: boolean = false;

  constructor(private messagePanelService: MessagePanelService) {
  }

  ngOnInit() {
    this.messagePanelService.getMessage()
      .subscribe(data => {

        setTimeout(() => {
          if (data) {
            if (data.closable) {
              this.closable = true;
            }

            this.message = data.key;
            this.display = true;

            if (!data.closable) {
              setTimeout(() => {
                this.message = null;
                this.display = false;
              }, data.autoCloseMilisecond);
            }
          }
        }, 200);

      });
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.display)
      this.display = false;
  }

  showDialog() {
    this.display = true;
  }
}
