import { Nicktype } from "./nick-type";
import { RankFullInfo } from "./rank";

export class UserFullInfo {
    AltYetkiliLimiti: number = 0;
    BanOdaList: Array<any> = new Array<any>(); //TODO 
    BanSite: boolean = false;
    DurumMesaj: string = "";
    Email: string = "";
    FlashnickId: string = "";
    GeciciOperatorListesi: Array<any> = new Array<any>(); //TODO 
    GenelMesajSayisi: number = 0;
    HasPhoneAccount: boolean = false;
    IkinciKullaniciAdi: string = "";
    Ip: string = "";
    Karaliste: boolean = false;
    KullaniciAdi: string = "";
    KullaniciAdiRenk: string = "";
    KullaniciId: number = 0;
    LastPcimei: string = "";
    Misafir: boolean = false;
    MuteOdaList: Array<any> = new Array<any>(); //TODO 
    MuteSite: boolean = false;
    Nicktype: Nicktype = new Nicktype();
    Password: string = ""; //sha512
    ProfilResmiId: string = "";
    RutbeAdi: string = "";
    RutbeDerece: number = 0;
    RutbeFullInfo: RankFullInfo = new RankFullInfo();
    RutbeId: number = 0;
    Secretkey: string = "";
    StateId: number = 0;
    Telefon: string = "";
    WebsyncClientID: string = "";
    WebsyncIcelinkInfo: WebsyncIcelinkInfo = new WebsyncIcelinkInfo();
    FromRoomChat: boolean = false;
    NicktypeForRoom?: Nicktype = new Nicktype();
    Token: string = "";
    RoomCamSession: string = "";
    AuthorizedUserId: number = 0;
    AuthorizedUserName: string = "";
    AuthorizableUserLimit: number = 0;
    FriendshipRequestActive: boolean = false;
}
export interface UserV2 {
    id: number;
    // KULLANICIADI: string;
    KullaniciAdi: string;
    // EMAIL:string;
    Email: string;
    // HESAPID: 1,
    HesapId: 1,
    // IMAGEID: string;
    ImageId: string;
    // BITISTARIHI: string;
    BitisTarihi: string;
    // KAYITTARIHI:string;
    KayitTarihi: string;
    // SONGIRIS: string;
    SonGiris: string;
    // FLASHNICKID: string;
    FlashnickId: string;
    RutbeAdi: string;
}

export enum UserEnum {
    KullaniciID = 0,
    KullaniciAdi = 1,
    IkinciKullaniciAdi = 2,
    ProfilResmiID = 3,
    FlashnickID = 4,
    GeciciOperatorListesiJSON = 5,
    RutbeAdi = 6,
    RutbeDerece = 7,
    RutbeID = 8,
    StateName = 9,
    Nicktype = 10,
    Secretkey = 11,
    BanOdaListJSON = 13,
    BanSite = 14,
    MuteOdaListJSON = 15,
    MuteSite = 16,
    Karaliste = 17,
    GenelMesajSayisi = 18,
    LastPcimei = 19,
    Password = 20,
    Ip = 21,
    Email = 22,
    DurumMesaj = 23,
    Misafir = 24,
    KullaniciAdiRenk = 25,
    AltYetkiliLimiti = 26,
    Telefon = 27,
    GroupIdList = 28,
    HasPhoneAccount = 29,
    // rediste olmayan clientta doldurulanlar
    WebsyncClientID = 12,
    RutbeInfoJson = 98,
    CamSession = 99
}
export class UserCurrentRoomInfo {
    room_id:string
    room_name:string
} 

export class WebsyncIcelinkInfo {
    IcelinkId: string;
    IcelinkServerName: string;
    IcelinkServerPass: string;
    IcelinkUrl: string;
    WebsyncId: string;
    WebsyncUrl: string;
}

export class ContextMenuProps {
    mouseEvent: MouseEvent;
    userId: number;
}

export class AutoLoginParams {
    eventId: string;
    success: boolean;
    method: Function;
    params: Array<any>;
    resetAutoLoginControl: boolean;
}
export interface UpdateUserResponse {
        UserNameExist:boolean
        Success:boolean
        ErrMsg:string
        Token:string
}
export class FriendList {
    AllFriends: Array<Friend>;
    BlockedByMeFriends: Array<Friend>;
    BlockedMeFriends: Array<Friend>;
    IncomingFriends: Array<Friend>;
    OutgoingFriends: Array<Friend>;
}

export class Friend {
    DurumMesaj: string;
    KullaniciAdi: string;
    KullaniciId: number;
    ProfilResmiId: string;
    RutbeAdi: string;
    RutbeDerece: number;
    RutbeId: number;
    StateId: number;
}

export class DecodeJWT {
    isNewLogin: boolean;
    rutbeAdi: string;
    rutbeID: number;
    userID: number;
    userName: string;
}

export class UpdateUserNameResponse {
    UserNameExist: boolean;
    Success: boolean;
    Token: string;
    ErrMsg: string;
}