<p-dialog [(visible)]="panelManagerService.roomMicrophoneAcceptPopupDisplay" (onShow)="onShow()" [closeOnEscape]="false" [focusOnShow]="false"
    header="{{ micInvitation ? ('InvitedToTheMic' | translate) : ('MicIsYours' | translate)}}" [modal]="true" [baseZIndex]="10000" [responsive]="true" [closable]="false">

    <div class="timer-text">{{ timeLeft }}</div>

    <p-footer>
        <button (click)="micInvitation ? acceptOrRejectInvitation(true) : acceptOrStopCalling(true) " 
        pButton type="button" label="{{ micInvitation ? ('AcceptButton' | translate) : ('takemicrophone' | translate) }}"
            class="ui-button-raised ui-button-success"></button>
        <button (click)="micInvitation ? acceptOrRejectInvitation(false) : acceptOrStopCalling(false)" pButton type="button" label="{{ 'reject' | translate }}"
            class="ui-button-raised ui-button-danger"></button>
    </p-footer>
</p-dialog>