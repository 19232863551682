<div *ngIf="load | async"
    [class]="'messageDiv message' + message.MessageBubbleType"
   >


    <div #userimage class="messageUserImgDiv" id="messageUserImage" *ngIf="
     message.MessageBubbleType !== messageBubbleType.RoomActionMessage &&
      message.MessageBubbleType !== messageBubbleType.AdminChangesMessage &&
       message.MessageBubbleType !== messageBubbleType.SystemMessages" (click)="showUserBubbleCard()">
        <img class="userImage" [attr.src]=" message.userImageUrl" (error)="onImgError($event)">
    </div>

    <div class="messageBubbleDiv">
        <div class="messageBubbleTriangle"></div>
        <div class="messageBubbleTextDiv">
            <div *ngIf="isCameraOpenedMessage" class="cam-div">
                <img [attr.src]="roomCamIconUrl" class="cam-icon" (click)="watchCamera()">
            </div>
            <div class="messageBubbleUserNameDiv" [ngStyle]="{'color': message.nickType.C}" *ngIf="
            message.MessageBubbleType !== messageBubbleType.RoomActionMessage &&
             message.MessageBubbleType !== messageBubbleType.AdminChangesMessage &&
              message.MessageBubbleType !== messageBubbleType.SystemMessages"
                [innerHTML]=" message.userName  | safeHtml">
            </div>
            <!-- <span> -->
            <fa-icon [icon]="faCaretDown" (click)="siteBan()"
                *ngIf="message.MessageBubbleType === messageBubbleType.RoomActionMessage &&
                        message.senderId !== indexedDBService.userFullInfo.KullaniciId && indexedDBService.userFullInfo.RutbeDerece > 7"
                class="bubble-action-button">
            </fa-icon>
            <!-- </span> -->
            <div class="messageBubbleUserNameDiv" *ngIf="
            message.MessageBubbleType === messageBubbleType.RoomActionMessage ||
             message.MessageBubbleType === messageBubbleType.AdminChangesMessage ||
              message.MessageBubbleType === messageBubbleType.SystemMessages">
                {{ message.userName.split(" ")[0] + " (" + message.senderId + ")" }}
            </div>
            <div class="messageBubbleTextMessageDiv" [innerHTML]="message.messageHtml[0] | safeHtml">
            </div>
            <div *ngIf="message.fileUrl" class="messageBubbleTextMessageDiv">
                <div *ngIf="message.isImage" style="width: 80px; height: 70px; overflow: hidden;">
                    <img *ngIf="message.isImage" [src]="message.fileUrl" (click)="displayImage()" alt="" style="max-width: 100%; max-height: 100%; object-fit: contain;">
                </div>
                <div (click)="displayFile()" class="message-file" *ngIf="message.isFile">
                    <fa-icon *ngIf="message.isFile" [icon]="faPaperclip" class="editor-icon"></fa-icon>
                    <p class="display-file">{{'display-file' | translate}}</p>
                </div>
            </div>
            <div *ngIf="message.roomNameWhereTheMessageSent && 
             message.MessageBubbleType === messageBubbleType.GeneralMessage"
             (click)="goThisRoom()" pTooltip="{{ 'go-this-room' | translate }}" 
             tooltipPosition="bottom" class="messageBubbleRoomNameDiv">({{message.roomNameWhereTheMessageSent}})</div>
        </div>
        <div class="complain"
            [class]="(message.MessageBubbleType ===  messageBubbleType.PtoPOther ? '' : 'hideSendedIcon')">
            <fa-icon [icon]="faFlag" pTooltip="{{ 'report-message' | translate }}" tooltipPosition="bottom"
                (click)="complainMessage()"></fa-icon>
        </div>
        <div class="messageBubbleTimeDiv">{{message.timeString}}</div>
        <div [class]="'messageBubbleStateDiv ' + (message.isSended?'': 'hideSendedIcon')">
            <fa-icon [icon]="faCheck" class="faCheck"></fa-icon>
        </div>
    </div>
</div>