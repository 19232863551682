import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { faMinus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { YoutubeVideo } from 'src/app/core/models/youtube';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { UtilService } from 'src/app/core/services/util.service';
import { YoutubeService } from 'src/app/core/services/youtube.service';

@Component({
  selector: 'app-room-chat-buttons-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class YoutubeComponent implements OnInit, OnDestroy {

  faTimes = faTimes;
  faMinus = faMinus;
  searchText: string = "";
  youtubeVideoResultList: Array<YoutubeVideo> = [];
  selectedYoutubeVideo: YoutubeVideo;
  safeUrl: any;
  isOpenedVideo: boolean = false;

  currentKeyIndex: number = 0;

  @ViewChild("youtube") youtube: ElementRef<HTMLElement>;
  public youtubeWidth: number = 176;

  constructor(
    public panelManagerService: PanelManagerService,
    private youtubeService: YoutubeService,
    private _sanitizer: DomSanitizer,
    private utilService: UtilService
  ) {
    this.panelManagerService.youtubePlayerPopupMinus = false;
  }

  ngOnInit(): void {
    this.panelManagerService.youtubePlayerPopupMinus = false;
  }

  ngOnDestroy(): void {
    this.isOpenedVideo = false;
    this.panelManagerService.youtubePlayerPopupMinus = false;
  }

  ngAfterViewInit() {
    this.openYoutubePanel();
  }

  openYoutubePanel(videoSearched: boolean = false){
    videoSearched ? this.youtubeWidth = this.youtube.nativeElement?.offsetWidth * 2 : this.youtubeWidth = this.youtube.nativeElement?.offsetWidth;
    this.panelManagerService.youtubePanelDisplayedSource.next(this.youtubeWidth);
  }

  searchVideo() {
    this.isOpenedVideo = false;
    if (!this.utilService.isNullOrEmtpyString(this.searchText)) {
      this.youtubeService.getVideosBySearchText(this.searchText)
        .toPromise()
        .then((res: any) => {
          this.youtubeVideoResultList = res.items;
          this.openYoutubePanel(true);          
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.panelManagerService.findFriendLoading = false;
        });
    }
    else {
      this.youtubeVideoResultList = new Array<YoutubeVideo>();
      this.panelManagerService.findFriendLoading = false;
    }
  }

  searchInYoutubeOnKeydown($event) {
    if ($event.code === "Enter" || $event.code === "NumpadEnter") {
      $event.preventDefault();
      this.panelManagerService.findFriendLoading = true;
      this.searchVideo()
    }
  }

  searchTextChange($event) {
    var curIndex = this.currentKeyIndex;
    this.panelManagerService.findFriendLoading = true;

    setTimeout(() => {
      if (curIndex + 1 === this.currentKeyIndex)
        this.searchVideo();
    }, 1000);
    this.currentKeyIndex++;
  }

  openVideo(videoId: string) {
    this.youtubeVideoResultList = new Array<YoutubeVideo>();
    this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${videoId}?autoplay=1`);
    this.isOpenedVideo = true;
  }
}

