import { EventEmitter, Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { UserFullInfo } from 'src/app/core/models/user';
import { ChatType, ConferenceType, MessageSendingType, ContactType } from 'src/app/core/models/enums';
import { ChatPanelProps } from '../models/chat-panel-props';

import { UtilService } from 'src/app/core/services/util.service';
@Injectable({
  providedIn: 'root'
})
export class ChatPanelService {

  private changeBackgroundColorSource = new Subject<any>();
  private changeBackgroundImageSource = new Subject<any>();
  private setBackgroundTypeSource = new Subject<boolean>();
  

  constructor(
    private router: Router,
    private utilService: UtilService

  ) {
  }

  changeBackgroundColor(colorCode) {
    this.changeBackgroundColorSource.next(colorCode);
  }

  changeBackgroundImage(image) {
    this.changeBackgroundImageSource.next(image);
  }

  setBackgroundType(isBackColor: boolean){
    this.setBackgroundTypeSource.next(isBackColor);
  }

  getBackgroundType() : Observable<any> {
    return this.setBackgroundTypeSource.asObservable();
  }

  getBackgroundColor(): Observable<any> {
    return this.changeBackgroundColorSource.asObservable();
  }

  getBackgroundImage(): Observable<any> {
    return this.changeBackgroundImageSource.asObservable();
  }


  getChatFormPropertiesForFriendChatPanel(userFullInfo: UserFullInfo, chatType: ChatType) {
    var chatPanelProps = new ChatPanelProps();

    chatPanelProps.UserId = userFullInfo.KullaniciId;
    chatPanelProps.UserName = userFullInfo.KullaniciAdi;
    chatPanelProps.UserRubeDerece = userFullInfo.RutbeFullInfo.DERECE;
    chatPanelProps.Channelname = this.utilService.getUserChannelName(userFullInfo.KullaniciId);
    chatPanelProps.ChatType = chatType;
    //chatPanelProps.GroupUsers = [];// new UserClass[] { },
    chatPanelProps.UserPhotoId = userFullInfo.ProfilResmiId;

    return chatPanelProps;
  }

}