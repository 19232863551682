
import { ChatType, ChatState, BulkMessageType } from 'src/app/core/models/enums';
import { Constants } from 'src/app/core/models/constants';

export class OperationMessage {
    Id: number = 0; //int
    MessageId: string = ""; //string
    Channelname: string = ""; //string
    SenderRutbeDerece: number = 0; //int
    SenderId: number = 0; //int
    SenderName: string = ""; // string
    ReceiverId: number = 0; //int
    ReceiverName: string = ""; // string
    MethodNames: Array<string> = []; // string[methodindex]
    Parameters: Array<Array<any>> = []; // object[methodindex][paramterindex]
    ChannelSessionId: string = Constants.guidEmpty; // Guid
    ChatType: ChatType = ChatType.AdminChangesMethod; // int  _enums.ChatType
    Chatstate: ChatState = ChatState.GonderilenMesaj; // int _enums.ChatState
    BulkMessageType: BulkMessageType = BulkMessageType.OneChannel; // int _enums.BulkMessageType
    BulkMessageIdListKey: string = ""; // string 
    BulkMessageSenderId: string = ""; // string 
    BulkMessageIdForGenerateChannell: number = 0; // int 
    // örn: BulkMessageType.AllMyFriends ise ve BulkMessageIdForGenerateChannell=45 ise websync server 45 idli kullanının tüm arkadaşlarına gelen mesajı iletecektir...
    // örn: BulkMessageType.AllGroupMembers ise ve BulkMessageIdForGenerateChannell=12 ise websync server 12 idli grubun tüm üyelerine gelen mesajı iletecektir.
}