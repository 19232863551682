<p-dialog [(visible)]="panelManagerService.banPanelPopupDisplay" [modal]="true"
[baseZIndex]="10000" [responsive]="true">
    <p-header>
        {{ indexedDBService.banPanel.BanMode | translate }}
        {{indexedDBService.banPanel.BanType !== null ?  '(' + ('bantype' + indexedDBService.banPanel.BanType | translate) + ')' : ''}}
    </p-header>
    <div class="banPanelContainer">
        <span class="title" for="onlytext">{{'descriptionTemplates'| translate}}</span>
        <br>
        <p-dropdown [options]="descriptionTemplates" (onChange)="DescriptionSelectBoxChange()"
            [(ngModel)]="selectedDesc" [style]="{'width': '100%'}" optionLabel="name">
        </p-dropdown>
        <br>
        <br>

        <span class="title" for="onlytext">{{'description'| translate}}</span>
        <br>
        <textarea class="description" pInputTextarea [(ngModel)]="description"></textarea>
        <span class="title textLengthText" style="float:left;">{{ description | countdown:500 }}</span>
    </div>
    <p-footer>
        <button pButton type="button" (click)="SaveBanMute()" label="{{'save' | translate}}"
            [ngStyle]="{'width': '100%'}" class="ui-button-raised ui-button-info popup"></button>
    </p-footer>

</p-dialog>