import { Component, OnInit, ViewEncapsulation, OnDestroy, ElementRef, ViewChild, HostListener } from '@angular/core';
import { ChatPanelService } from 'src/app/core/services/chatPanel.service';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { UserFullInfo } from 'src/app/core/models/user';
import { ActiveChatPanel } from 'src/app/core/models/chat-panel-props';
import { ContactType, MainPanels, MessageBubbleType, SikayetTipleri } from 'src/app/core/models/enums';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { DeviceService } from 'src/app/core/services/device.service';
import { Constants } from 'src/app/core/models/constants';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';
import { ChatService } from 'src/app/core/services/chat.service';
import { faBars, faMinus, faTimes, faArrowLeft, faFolder } from '@fortawesome/free-solid-svg-icons';
import { MenuItem } from 'primeng/api';
import { FriendListService } from '../../services/friendlist.service';
import { UtilService } from '../../services/util.service';
import { ReportService } from '../../services/report.service';
import { P2PService } from '../../services/p2p.service';

@Component({
  selector: 'app-p2p-chat',
  templateUrl: './p2p-chat.component.html',
  styleUrls: ['./p2p-chat.component.scss', './p2p-chat.web.component.scss', './p2p-chat.mobile.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})


export class P2PChatComponent implements OnInit, OnDestroy {

  faMinus = faMinus;
  faTimes = faTimes;
  faBars = faBars;

  faArrowLeft = faArrowLeft;
  
  chatPanelBounds: HTMLElement;
  constants = Constants;

  activeChatPanel: ActiveChatPanel;
  backgroundColor: string;
  backgroundImage: any;

  contactType: number;
  _contactType = ContactType;
  isMaksimize: boolean = true;
  chatPanelWidth: number = 460;
  mainPanels = MainPanels;
  isBackColor: boolean;

  headerOptions: MenuItem[];

  load: boolean;
  backgroundColorChangedSubscription: Subscription;
  backgroundImageChangedSubscription: Subscription;
  setBackgroundTypeSubscription: Subscription;
  @ViewChild("chatPanel") chatPanel: ElementRef<HTMLElement>;

  userUpdatedSubscription: Subscription;

  constructor(
    private chatPanelService: ChatPanelService,
    private indexedDBService: IndexedDBService,
    public panelManagerService: PanelManagerService,
    public deviceService: DeviceService,
    private userService: UserService,
    private chatService: ChatService,
    private friendListService: FriendListService,
    private utilService: UtilService,
    public reportService: ReportService,
    public p2PService:P2PService
  ) {

    this.setBackgroundTypeSubscription = this.chatPanelService.getBackgroundType()
      .subscribe((isBackColor: boolean) => {
        this.isBackColor = isBackColor;
        isBackColor === true ? this.getBackColor() : this.getBackImage();
      });

    this.userUpdatedSubscription = this.userService.userUpdated.subscribe((user: UserFullInfo) => {
      if (this.activeChatPanel.user.KullaniciId === user.KullaniciId) {
        this.activeChatPanel.user = this.indexedDBService.Friends.AllFriends.find(f => f.KullaniciId === user.KullaniciId);
      }
    });

  }

  ngOnInit() {
    setTimeout(() => {
      this.onFocus(null);
    });

    this.setOptions();
  }
  
  ngOnDestroy() {
    if (this.backgroundColorChangedSubscription)
      this.backgroundColorChangedSubscription.unsubscribe();

    if (this.backgroundImageChangedSubscription)
      this.backgroundImageChangedSubscription.unsubscribe();

    if (this.setBackgroundTypeSubscription)
      this.setBackgroundTypeSubscription.unsubscribe();

    if (this.userUpdatedSubscription)
      this.userUpdatedSubscription.unsubscribe();

  }

  //#region Option Menu Operations
  setOptions() {
    this.headerOptions = [
      {
        label: this.utilService.parseGetMessageValue('report-user'),
        command: () => {
          this.complainUser()
        }
      },
      {
        label: this.utilService.parseGetMessageValue('wallpaper'),
        command: () => {
          this.wallpaper()
        }
      },
      {
        label: this.utilService.parseGetMessageValue('deletecontactfromlist'),
        command: () => {
          this.deletecontactfromlist()
        }
      },
      {
        label: this.utilService.parseGetMessageValue('blockuser'),
        command: () => {
          this.blockuser()
        }
      },
      {
        label: this.utilService.parseGetMessageValue('menu_changefont'),
        command: () => {
          this.changeFont()
        }
      },
      {
        label: this.utilService.parseGetMessageValue('delete-message-history'),
        command: () => {
          this.deleteMessageHistory()
        }
      }
    ];
  }

  wallpaper() {
    this.panelManagerService.updateWallpaperPopupDisplay = true;
  }

  deletecontactfromlist() {
    this.friendListService.friendshipRequestDeletedSource.next(this.activeChatPanel.user);
  }

  blockuser() {
    this.friendListService.friendBlockedSource.next(this.activeChatPanel.user);
  }

  complainUser() {
    this.reportService.header = this.activeChatPanel.user.KullaniciAdi;
    this.reportService.insertComplainRequest.ComplainType = SikayetTipleri.Kullanici;
    this.reportService.insertComplainRequest.ComplainingId = this.indexedDBService.userFullInfo.KullaniciId;
    this.reportService.insertComplainRequest.ComplainedId = this.activeChatPanel.user.KullaniciId;
    this.reportService.insertComplainRequest.Html = "";//this.message.messageHtml[0];
    this.reportService.reportPopupDisplay = true;
  }

  changeFont() {
    this.panelManagerService.changeFontPopupDisplay = true;
  }
  
  deleteMessageHistory() {
    this.friendListService.deleteFriendMessageHistorySource.next(this.activeChatPanel.chatPanelProps);
  }
  //#endregion

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.chatService.p2pChatFocused = false;
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: Event): void {
    this.chatService.p2pChatFocused = true;
  }

  set(activeChatPanel: ActiveChatPanel, chatPanelBounds: HTMLElement) {
    this.chatPanelBounds = chatPanelBounds;
    this.activeChatPanel = activeChatPanel;

    this.initBackground()
      .then(() => {
        this.load = true;
      })
      .then(() => {
        setTimeout(() => {
          this.chatPanel.nativeElement.parentElement.setAttribute("id", Constants.mainSwiperTag + '' + MainPanels.P2PChat + '_' + activeChatPanel.chatPanelProps.UserId);
          this.panelManagerService.showP2PChatPanel(activeChatPanel.chatPanelProps.UserId, activeChatPanel.display,true);
        });
      });
  }

  initBackground(): Promise<any> {
    return this.indexedDBService.getPrivateSetting(this.indexedDBService.settingsMessagePanelSelectedBackgroundPrivateKey)
      .then((response) => {
        this.isBackColor = response;
        if (this.isBackColor)
          this.getBackColor();
        else
          this.getBackImage();
      })
      .catch(error => {
        console.log(error)
        this.isBackColor = false;
      });
  }

  getBackImage() {
    this.indexedDBService.getPrivateSetting(this.indexedDBService.settingsSelectedMessagePanelBackImagePrivateKey)
      .then(image => {
        if (image) {
          this.backgroundImage = image;
        }
        else
          throw "not found";
      })
      .catch(error => {
        this.backgroundImage = null;
      });


    this.backgroundImageChangedSubscription = this.chatPanelService.getBackgroundImage()
      .subscribe(data => {
        this.backgroundImage = data;
      });

  }

  getBackColor() {
    this.indexedDBService.getPrivateSetting(this.indexedDBService.settingsSelectedMessagePanelBackColorPrivateKey)
      .then(color => {
        if (color) {
          this.backgroundColor = color;
        }
        else
          throw "not found";
      })
      .catch(error => {
        this.backgroundColor = this.indexedDBService.settingsSelectedMessagePanelBackColorDefaultValue;
      });

    this.backgroundColorChangedSubscription = this.chatPanelService.getBackgroundColor()
      .subscribe(data => {
        this.backgroundColor = data;
      });
  }


  hideP2PChatPanel() {
    this.panelManagerService.showedP2p = false;
    this.panelManagerService.hideP2PChatPanel(this.activeChatPanel.user?.KullaniciId);
  }

  closeP2PChatPanel(userId: number) {
    this.panelManagerService.removeP2PChatPanel(userId);
    setTimeout(() => {
      this.panelManagerService.showedP2p = false;
    });
  }

  showProfilePicture() {
    this.indexedDBService.friendProfilePicture = this.utilService.getProfilePhotoUrl(this.activeChatPanel.user.ProfilResmiId, true);
    this.panelManagerService.profilePhotoPopupDisplay = true;
  }
}