<div class="chat-list" [ngClass]="{'room-view': panelManagerService.showedIncommingAnotherUserMessagePanel ? true : false}">
    <div class="header">
        <div class="chat">
            <span>{{ 'titlechats' | translate }}</span>
        </div>

        <div class="close-button">
            <fa-icon [icon]="faTimes" class="faTimes" (click)="panelManagerService.showedIncommingAnotherUserMessagePanel = false"></fa-icon>
        </div>
    </div>   
    <p-scrollPanel
        [style]="{width: '100%', height: deviceService.isMobile ? (panelManagerService.showedIncommingAnotherUserMessagePanel ? 'calc(100% - 130px)' : 'calc(100% - 190px)')  : 'calc(100% - 130px)'}">
        <div class="empty-list"
            *ngIf="panelManagerService.p2pIncomingMessageFromAntoherUserPanels.length <= 0"
            innerHTML="{{ 'Gelen mesaj yok' | translate }}"></div>

        <div class="friend" (click)="clickOnFriend(friend)"
            *ngFor="let friend of 
           panelManagerService.p2pIncomingMessageFromAntoherUserPanels; let i=index">

            <app-user-photo [userPhotoId]="friend.ProfilResmiId" [stateId]="friend.StateId" [width]="46" [height]="46"
                [showOnlineStateIcon]="true" class="friend-photo"></app-user-photo>

            <div class="friend-name">
                <span>{{friend.KullaniciAdi}}</span>
                <app-rank [degree]="friend.RutbeDerece" [color]="'#919191'" [size]="deviceService.isMobile ? 8 : 9">
                </app-rank>
            </div>

            <small class="state-message"
               >{{friend.DurumMesaj}}</small>
            <small class="rank-name"
               >{{friend.RutbeAdi}}</small>

            <div class="action"
                [ngStyle]="{'grid-column':  '4'}">
                <small class="rank-name"
                   >{{friend.RutbeAdi}}</small>
                <app-user-id 
                    [userId]="friend.KullaniciId"></app-user-id>

                <div class="notification" *ngIf="indexedDBService.messageCounts[friend.KullaniciId]>0">
                    <span>{{indexedDBService.messageCounts[friend.KullaniciId]}}</span>
                </div>
            </div>
        </div>
    </p-scrollPanel>
</div>