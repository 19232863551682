import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-image-capture',
  templateUrl: './image-capture.component.html',
  styleUrls: ['./image-capture.component.scss'],
  encapsulation: ViewEncapsulation.Emulated

})
export class ImageCaptureComponent implements OnInit {

  @ViewChild('video') video;
  @ViewChild('canvas') canvas;
  videoElement: HTMLVideoElement;
  mediaStream: MediaStream = null;

  constructor(
    public panelManagerService: PanelManagerService,
    private userService: UserService
  ) { }

  ngOnInit() {

    setTimeout(() => {
      this.videoElement = this.video.nativeElement;
    });

    navigator.mediaDevices
      .getUserMedia({
        video: { facingMode: 'environment' },
      })
      .then(stream => {
        this.mediaStream = stream;
        this.videoElement.srcObject = stream;
        this.videoElement.play();
      });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.stopMediaTracks();
  }

  stopMediaTracks() {
    return new Promise((resolve, reject) => {
      if (this.mediaStream && this.mediaStream.getTracks) {
        // getTracks() returns all media tracks (video+audio)
        this.mediaStream.getTracks()
          .forEach((track: MediaStreamTrack) => track.stop());

        resolve(null);
      }
      else
        reject();
    });
  }

  takePicture() {
    const canvasElement = this.canvas.nativeElement;
    const context = canvasElement.getContext('2d');
    context.canvas.width = context.canvas.height = canvasElement.width = canvasElement.height = 400;
    context.drawImage(this.videoElement, 0, 0, canvasElement.width, canvasElement.height);


    /*    navigator.mediaDevices
          .getUserMedia({
            video: { facingMode: 'environment' },
          })
          .then(stream => {
            this.videoElement.pause();
            this.videoElement.src = null;
            this.videoElement.srcObject = null;
            stream.getTracks().forEach(element => {
              element.stop();
            }) 
          })*/

    this.stopMediaTracks()
      .then(() => {
        this.userService.imageChangedEvent = this.canvas.nativeElement.toDataURL("image/png");
        this.panelManagerService.imageCapturePopupDisplay = false;
        this.panelManagerService.imageCropperPopupDisplay = true;
      });
  }

  getDataFromDataUrl(dataUrl: string, mimeType: string) {
    return dataUrl.replace(`data:${mimeType};base64,`, '');
  }


  onHide($event) { 
    this.panelManagerService.imageCapturePopupDisplay = false;
  }

}
