<p-dialog styleClass="blocked-friends" class="blockedFriends" [(visible)]="panelManagerService.showBlockedFriendsPopupDisplay"
[responsive]="true" [baseZIndex]="1000">
    <p-header>
        {{'menu_showblockedfrineds' | translate}}
    </p-header>

    <div style="text-align:center;">

        <div style="width: calc(100% - 20px); margin: auto; margin-top: 4px;">
            <header class="ui-dialog-titlebar" style="text-align: left;">
                {{ 'UserName' | translate }}
            </header>
            <div style="border: 1px solid #bbb; border-top: none; padding: 10px 10px 0px 10px;">
                <div *ngIf="indexedDBService.Friends.BlockedByMeFriends.length > 0"  style="display: grid;">
                    <div *ngFor="let friend of indexedDBService.Friends.BlockedByMeFriends" style="height: 30px; margin-bottom: 10px;">
                        <p style="float: left;">{{ friend.KullaniciAdi }}</p>
                        <button style="float: right;" pButton type="text" (click)="removeUserBlock(friend)"
                            label="{{ 'RemoveUserBlock' | translate }}"></button>
                    </div>
                </div>
                <div class="not-data-found" *ngIf="indexedDBService.Friends.BlockedByMeFriends.length === 0" style="margin-bottom: 10px;">
                    {{ 'nodatafound' | translate }}
                </div>

            </div>
        </div>
    </div>
</p-dialog>
