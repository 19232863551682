<div class="main">
    <div class="content">
        <div class="custom-dialog-container">
            <p-dialog header="Who are we?" styleClass="custom-dialog-style" [baseZIndex]="10000"
                [(visible)]="panelManagerService.whoWeArePageDisplay" [style]="{width: '100vw', height: '100vh'}">
                <div [innerHTML]="htmlContent | safeHtml"></div>
            </p-dialog>
        </div>
    </div>
</div>

