

(function (name, dependencies, definition) {
    if (typeof exports === 'object') { // Node/CommonJS

        for (var i = 0; i < dependencies.length; i++) {
            require('./' + dependencies[i]);
        }
        module.exports = definition();
    } else {
        this[name] = definition();
    }
}('IndexedDBHelper', [], function () {


    if (typeof global !== 'undefined' && !global.window) { global.window = global; global.document = { cookie: '' }; }
    if (!window.IndexedDBHelper) { window.IndexedDBHelper = {}; }

    var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
    var IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.msIDBTransaction;
    var IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange;



    IndexedDBHelper.LocalDB = {};
    IndexedDBHelper.Version = 2;

    IndexedDBHelper.openDBDynamic = function (localDbName, storeInfoList, onupgradeneededFunc) {
        return new Promise((resolve, reject) => {
            try {
                var localDb = IndexedDBHelper.LocalDB[localDbName];
                if (localDb === null || localDb === undefined) {
                    IndexedDBHelper.openDBInternal(localDbName, onupgradeneededFunc)
                        .then((result) => {
                            resolve(localDbName, storeInfoList);
                        }, (error) => {
                            console.log(error);
                            reject(error);
                        });
                } else {
                    resolve(localDbName, storeInfoList);
                }
            } catch (error) {
                resolve(null);
            }
        });
    };

    IndexedDBHelper.openDBInternal = function (dbName, onupgradeneededFunc) {
        return new Promise((resolve, reject) => {
            try {
                var request = indexedDB.open(dbName, IndexedDBHelper.Version);
                request.onerror = (error) => {
                    reject(error);
                };
                request.onsuccess = (event) => {
                    IndexedDBHelper.LocalDB[request.result.name] = request.result;
                    resolve();
                };
                request.onupgradeneeded = (event) => {
                    //versiyon değiştiğinde buraya girer
                    IndexedDBHelper.LocalDB[event.target.result.name] = event.target.result;
                    if (onupgradeneededFunc !== null)
                        onupgradeneededFunc(event);
                }
            } catch (error) {
                console.log(error);
            }
        });
    };

    IndexedDBHelper.createObjectStore = function (localDb, objectStoreInfoList) {
        try {
            if (localDb !== null && localDb !== undefined && objectStoreInfoList !== null && objectStoreInfoList !== undefined) {
                for (var i = 0; i < objectStoreInfoList.length; i++)
                    if ($.inArray(objectStoreInfoList[i], localDb.objectStoreNames) === -1)
                        localDb.createObjectStore(objectStoreInfoList[i].name, { keyPath: objectStoreInfoList[i].keyPath });
            }
        } catch (error) {
            console.log(error)
        }
    };

    IndexedDBHelper.getByKey = function (localDbName, objectStoreName, storeInfoList, privateKeyValue) {
        return new Promise((resolve, reject) => {
            IndexedDBHelper.openDBDynamic(
                localDbName,
                storeInfoList,
                () => { // onupgradeneededFunc                  
                    IndexedDBHelper.createObjectStore(IndexedDBHelper.LocalDB[localDbName], storeInfoList);

                }
            )
                .then((result) => {
                    setTimeout(() => {
                        IndexedDBHelper.getByKeyInternal(IndexedDBHelper.LocalDB[localDbName], objectStoreName, privateKeyValue)
                        .then((result) => {
                            resolve(result)
                        }, (error) => {
                            reject(error)
                        });
                    }, 200);
                }, (error) => {
                    console.log(error);
                    resolve(null);
                });

        });
    };

    IndexedDBHelper.getByKeyInternal = function (localDb, objectStoreName, privateKeyValue) {
        return new Promise((resolve, reject) => {
            try {
                var request = localDb.transaction([objectStoreName])
                    .objectStore(objectStoreName)
                    .get(privateKeyValue);
                request.onerror = (event) => {
                    reject(event);
                };
                request.onsuccess = (event) => {
                    resolve(request.result);
                };
            } catch (error) {
                resolve(null);
            }
        });
    };

    IndexedDBHelper.getByCursor = function (localDbName, objectStoreName, storeInfoList, options) {


        return new Promise((resolve, reject) => {
            IndexedDBHelper.openDBDynamic(
                localDbName,
                storeInfoList,
                () => { // onupgradeneededFunc                                 
                    IndexedDBHelper.createObjectStore(IndexedDBHelper.LocalDB[localDbName], storeInfoList);
                }
            )
                .then(() => {
                    return IndexedDBHelper.getByCursorInternal(
                        IndexedDBHelper.LocalDB[localDbName],
                        objectStoreName,
                        options
                    );
                })
                .then((result) => {
                    resolve(result);
                }, (error) => {
                    console.log(error);
                    reject(error);
                });
        });
    };

    IndexedDBHelper.getByCursorInternal = function (localDb, objectStoreName, options) {


        var list = [];
        var count = 0;
        var hasSkipped = false;

        return new Promise((resolve, reject) => {
            try {
                var request = localDb.transaction([objectStoreName])
                    .objectStore(objectStoreName)
                    .openCursor(options.keyRange, options.order);

                request.onsuccess = (event) => {
                    try {
                        if (!hasSkipped && options.skip > 0) {
                            hasSkipped = true;
                            event.target.result.advance(options.skip);
                            return;
                        }

                        if (event.target.result) {
                            count++;
                            if (count > options.limit) {
                                resolve(list);
                                return;
                            }
                            list.push(event.target.result.value);
                            event.target.result.continue();
                        } else {
                            resolve(list);
                        }
                    } catch (error) {
                        console.log(error)
                        resolve(null);
                    }
                };

                request.onerror = (error) => {
                    reject(error);
                };
            } catch (error) {
                console.log(error)
                resolve(null);
            }
        });
    };

    IndexedDBHelper.getAll = function (localDbName, objectStoreName, storeInfoList) {
        return new Promise((resolve, reject) => {
            IndexedDBHelper.openDBDynamic(
                localDbName,
                storeInfoList,
                () => { // onupgradeneededFunc                                 
                    IndexedDBHelper.createObjectStore(IndexedDBHelper.LocalDB[localDbName], storeInfoList);
                }
            )
                .then(() => {
                    return IndexedDBHelper.getAllInternal(
                        IndexedDBHelper.LocalDB[localDbName],
                        objectStoreName
                    );
                })
                .then((result) => {
                    resolve(result);
                }, (error) => {
                    console.log(error);
                    reject(error);
                });
        });
    };

    IndexedDBHelper.getAllInternal = function (localDb, objectStoreName) {

        return new Promise((resolve, reject) => {
            try {
                var request = localDb.transaction([objectStoreName])
                    .objectStore(objectStoreName)
                    .getAll();
                request.onsuccess = (event) => {
                    resolve(event.target.result);
                };
                request.onerror = (event) => {
                    reject(event);
                };
            } catch (error) {
                console.log(error);
                resolve(null);
            }
        });
    };

    IndexedDBHelper.init = function (localDbName, storeInfoList) {

        return new Promise((resolve, reject) => {
            IndexedDBHelper.openDBDynamic(
                localDbName,
                storeInfoList,
                () => { // onupgradeneededFunc


                    IndexedDBHelper.createObjectStore(IndexedDBHelper.LocalDB[localDbName], storeInfoList);
                    resolve();
                }
            )

                .then(() => {
                    resolve();
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    IndexedDBHelper.upsert = function (localDbName, objectStoreName, insertObject, storeInfoList) {
        return new Promise((resolve, reject) => {
            IndexedDBHelper.openDBDynamic(
                localDbName,
                storeInfoList,
                () => { // onupgradeneededFunc
                    IndexedDBHelper.createObjectStore(IndexedDBHelper.LocalDB[localDbName], storeInfoList);
                    IndexedDBHelper.upsertInternal(
                        IndexedDBHelper.LocalDB[localDbName],
                        objectStoreName,
                        insertObject
                    )
                        .then((result) => {
                            resolve(result);
                        }, (error) => {
                            console.log(error);
                            reject(error);
                        });
                }
            )
                .then(() => {
                    return IndexedDBHelper.upsertInternal(
                        IndexedDBHelper.LocalDB[localDbName],
                        objectStoreName,
                        insertObject
                    );
                })
                .then((result) => {
                    resolve(result);
                }, (error) => {
                    console.log(error);
                    reject(error);
                });

        });
    };

    IndexedDBHelper.upsertInternal = function (localDb, objectStoreName, insertObject) {
        return new Promise((resolve, reject) => {
            try {
                var request = localDb.transaction([objectStoreName], "readwrite")
                    .objectStore(objectStoreName)
                    .put(insertObject);
                request.onsuccess = (event) => {
                    resolve(event);
                };
                request.onerror = (event) => {
                    reject(event);
                }
            } catch (error) {

                console.log(error)
                resolve(null);
            }
        });
    };

    IndexedDBHelper.deleteByKey = function (localDbName, objectStoreName, storeInfoList, privateKeyValue) {
        return new Promise((resolve, reject) => {
            IndexedDBHelper.openDBDynamic(
                localDbName,
                storeInfoList,
                () => { // onupgradeneededFunc
                    IndexedDBHelper.createObjectStore(IndexedDBHelper.LocalDB[localDbName], storeInfoList);
                }
            )
                .then(() => {
                    return IndexedDBHelper.deleteByKeyInternal(
                        IndexedDBHelper.LocalDB[localDbName],
                        objectStoreName,
                        privateKeyValue
                    );
                })
                .then((result) => {
                    resolve(result);
                }, (error) => {
                    console.log(error);
                    reject(error);
                });
        });
    };

    IndexedDBHelper.deleteByKeyInternal = function (localDb, objectStoreName, privateKeyValue) {
        return new Promise((resolve, reject) => {
            try {
                if (localDb === null || localDb === undefined) {
                    resolve(null);
                    return;
                }
                var request = localDb.transaction([objectStoreName], "readwrite")
                    .objectStore(objectStoreName)
                    .delete(privateKeyValue);
                request.onsuccess = (event) => {
                    resolve(event);
                };
                request.onerror = (event) => {
                    reject(event);
                }
            } catch (error) {
                resolve(null);
            }
        });
    };

    IndexedDBHelper.clearObjectStore = function (localDbName, objectStoreName, storeInfoList) {
        return new Promise((resolve, reject) => {
            IndexedDBHelper.openDBDynamic(
                localDbName,
                storeInfoList,
                () => { // onupgradeneededFunc
                    IndexedDBHelper.createObjectStore(IndexedDBHelper.LocalDB[localDbName], storeInfoList);
                }
            )
                .then(() => {
                    return IndexedDBHelper.clearObjectStoreInternal(
                        IndexedDBHelper.LocalDB[localDbName],
                        objectStoreName
                    );
                })
                .then((result) => {
                    resolve(result);
                }, (error) => {
                    console.log(error);
                    reject(error);
                });
        });
    };

    IndexedDBHelper.clearObjectStoreInternal = function (localDb, objectStoreName) {
        return new Promise((resolve, reject) => {
            try {
                if (localDb === null || localDb === undefined) {
                    resolve(null);
                    return;
                }
                var request = localDb.transaction([objectStoreName], "readwrite")
                    .objectStore(objectStoreName)
                    .clear();
                request.onsuccess = (event) => {
                    resolve(event);
                };
                request.onerror = (event) => {
                    reject(event);
                }
            } catch (error) {
                resolve(null);
            }
        });
    };

    return IndexedDBHelper;
}));