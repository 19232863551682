import { Injectable } from '@angular/core';
import { DynamicMethod } from '../../core/models/dynamic-method';
import { ConferenceRequestResponse } from '../../core/models/chat-message';
import { RankFullInfo } from '../models/rank';
import { RoomSliderUser } from '../models/room-slider-user';
import { Room } from '../models/room';

@Injectable({
    providedIn: 'root'
})
export class DynamicMethodsSendService {

    constructor() {

    }

    Shake(userid, shakeMe): DynamicMethod {
        return { MethodName: "Shake", Parameters: [userid, shakeMe] };
    }

    RefreshFriendList(): DynamicMethod {
        return { MethodName: "RefreshFriendList", Parameters: [] };
    }

    RefreshFriendListForOneUser(userid: number, stateId: number, isLogin: boolean, useCache: boolean): DynamicMethod {
        return { MethodName: "RefreshFriendListForOneUser", Parameters: [userid, stateId, isLogin, useCache] };
    }

    RemoveUserFromMessengerList(userId): DynamicMethod {
        return { MethodName: "RemoveUserFromMessengerList", Parameters: [userId] };
    }

    AddFriendAfterEvents(): DynamicMethod {
        return { MethodName: "AddFriendAfterEvents", Parameters: [] };
    }

    UserBlockUnblock(userId, userName, block: boolean): DynamicMethod {
        return { MethodName: "UserBlockUnblock", Parameters: [userId, userName, block] };
    }

    GrupOnay(gorupId, jsonGroup): DynamicMethod {
        return { MethodName: "GrupOnay", Parameters: [gorupId, jsonGroup] };
    }

    Calling(senderId, senderName, contactType, chatType): DynamicMethod {
        return { MethodName: "Calling", Parameters: [senderId, senderName, contactType, chatType] };
    }

    AcceptCalling(senderId, senderName, contactType, chatType): DynamicMethod {
        return { MethodName: "AcceptCalling", Parameters: [senderId, senderName, contactType, chatType] };
    }

    CancelCalling(senderId, senderName, contactType, chatType): DynamicMethod {
        return { MethodName: "CancelCalling", Parameters: [senderId, senderName, contactType, chatType] };
    }

    CloseAcceptCallForm(senderUserId): DynamicMethod {
        return { MethodName: "CloseAcceptCallForm", Parameters: [senderUserId] };
    }

    CloseConferenceForm(senderId, senderName, contactType, chattype): DynamicMethod {
        return { MethodName: "CloseConferenceForm", Parameters: [senderId, senderName, contactType, chattype] };
    }

    RemoveGecmisKullaniciList(userId, roomId): DynamicMethod {
        return { MethodName: "RemoveGecmisKullaniciList", Parameters: [userId, roomId] };
    }

    RefreshUser(userId): DynamicMethod {
        return { MethodName: "RefreshUser", Parameters: [userId] };
    }

    KullaniciBilgiEkle(isTrue): DynamicMethod {
        return { MethodName: "KullaniciBilgiEkle", Parameters: [isTrue] };
    }

    RefreshRoomUserListForAllUsersInRoom(): DynamicMethod {
        return { MethodName: "RefreshRoomUserListForAllUsersInRoom", Parameters: [] };
    }

    RefreshRoomMicUserListForAllUsersInRoom(): DynamicMethod {
        return { MethodName: "RefreshRoomMicUserListForAllUsersInRoom", Parameters: [] };
    }

    AdminPaneliKapat(): DynamicMethod {
        return { MethodName: "AdminPaneliKapat", Parameters: [] };
    }

    KullaniciLogOut(isTrue): DynamicMethod {
        return { MethodName: "KullaniciLogOut", Parameters: [isTrue] };
    }

    RedirectToLoginPage(userId, message): DynamicMethod {
        return { MethodName: "RedirectToLoginPage", Parameters: [userId, message] };
    }

    KullaniciAdminHareketiMesajGoster(userId, mesaj): DynamicMethod {
        return { MethodName: "KullaniciAdminHareketiMesajGoster", Parameters: [userId, mesaj] };
    }

    ShowBalloonTipForFriendActions(userid, title, mesaj): DynamicMethod {
        return { MethodName: "ShowBalloonTipForFriendActions", Parameters: [userid, title, mesaj] };
    }


    SetOdaSetting(odaId, odaAdi: string = "", deleted: boolean = false): DynamicMethod {
        return { MethodName: "SetOdaSetting", Parameters: [odaId, odaAdi, deleted] };
    }

    SetPictureboxImage(): DynamicMethod {
        return { MethodName: "SetPictureboxImage", Parameters: [] };
    }

    DeleteUserImage_SendMessageToRoom(): DynamicMethod {
        return { MethodName: "DeleteUserImage_SendMessageToRoom", Parameters: [] };
    }

    InviteToMicrophone(userId, roomId, inviteKey): DynamicMethod {
        return { MethodName: "InviteToMicrophone", Parameters: [userId, roomId, inviteKey] };
    }

    MuteUnMuteUserMicrophone(userId, roomId, isMute): DynamicMethod {
        return { MethodName: "MuteUnMuteUserMicrophone", Parameters: [userId, roomId, isMute] };
    }

    // #region Room User List Operations

    // #region Ban Operations

    KullaniciOdaBan(userId, roomId, banTipiId): DynamicMethod {
        return { MethodName: "KullaniciOdaBan", Parameters: [userId, roomId, banTipiId] };
    }

    KullaniciOdaBanKaldir(userId, roomId): DynamicMethod {
        return { MethodName: "KullaniciOdaBanKaldir", Parameters: [userId, roomId] };
    }

    KullaniciSiteBan(userId, banTipiId): DynamicMethod {
        return { MethodName: "KullaniciSiteBan", Parameters: [userId, banTipiId] };
    }

    KullaniciSiteBanKaldir(userId): DynamicMethod {
        return { MethodName: "KullaniciSiteBanKaldir", Parameters: [userId] };
    }

    // #endregion

    // #region Mute Operations

    KullaniciOdaMute(userId, roomId): DynamicMethod {
        return { MethodName: "KullaniciOdaMute", Parameters: [userId, roomId] };
    }

    KullaniciSiteMute(userId): DynamicMethod {
        return { MethodName: "KullaniciSiteMute", Parameters: [userId] };
    }

    KullaniciOdaMuteKaldir(userId, roomId): DynamicMethod {
        return { MethodName: "KullaniciOdaMuteKaldir", Parameters: [userId, roomId] };
    }

    KullaniciSiteMuteKaldir(userId): DynamicMethod {
        return { MethodName: "KullaniciSiteMuteKaldir", Parameters: [userId] };
    }

    // #endregion

    FinishCam(userId, roomId, onlyMyCam): DynamicMethod {
        return { MethodName: "FinishCam", Parameters: [userId, roomId, onlyMyCam] };
    }

    OdadanAt(userId, roomId): DynamicMethod {
        return { MethodName: "OdadanAt", Parameters: [userId, roomId] };
    }

    AddUserToList(roomUser): DynamicMethod {
        return { MethodName: "AddUserToList", Parameters: [roomUser] };
    }

    RemoveUserFromList(userId): DynamicMethod {

        return { MethodName: "RemoveUserFromList", Parameters: [userId] };
    }

    ShowHideUserBanIconUserInList(userId, banType, show): DynamicMethod {
        return { MethodName: "ShowHideUserBanIconUserInList", Parameters: [userId, banType, show] };
    }

    ShowHideUserMuteIconUserInList(userId, muteType, show): DynamicMethod {
        return { MethodName: "ShowHideUserMuteIconUserInList", Parameters: [userId, muteType, show] };
    }

    ShowHideUserCamIconUserInList(userId, sessionId, contactType, show): DynamicMethod {
        return { MethodName: "ShowHideUserCamIconUserInList", Parameters: [userId, sessionId, contactType, show] };
    }

    ShowHideUserBlockedIconUserInList(userId): DynamicMethod {
        return { MethodName: "ShowHideUserBlockedIconUserInList", Parameters: [userId] };
    }

    ShowHideUserOperatorIconUserInList(userId, show): DynamicMethod {
        return { MethodName: "ShowHideUserOperatorIconUserInList", Parameters: [userId, show] };
    }

    UpdateUserImageInList(userId, imageId): DynamicMethod {
        return { MethodName: "UpdateUserImageInList", Parameters: [userId, imageId] };
    }

    UpdateUserStateInList(userId, stateName): DynamicMethod {
        return { MethodName: "UpdateUserStateInList", Parameters: [userId, stateName] };
    }

    UpdateUserAllInfo(userFullInfo, userId, rutbeDerece, rutbeName): DynamicMethod {
        return { MethodName: "UpdateUserAllInfo", Parameters: [userFullInfo, userId, rutbeDerece, rutbeName] };
    }

    AddRemoveCamWatcher(userId, userName, stateId, rutbeDerece, add): DynamicMethod {
        return { MethodName: "AddRemoveCamWatcher", Parameters: [userId, userName, stateId, rutbeDerece, add] };
    }

    CloseRoomVideoPanel(userId): DynamicMethod {
        return { MethodName: "CloseRoomVideoPanel", Parameters: [userId] };
    }

    CloseScreenShareVideoPanel(userId): DynamicMethod {
        return { MethodName: "CloseScreenShareVideoPanel", Parameters: [userId] };
    }



    // #endregion

    ClearTextbox(): DynamicMethod {
        return { MethodName: "ClearTextbox", Parameters: [] };
    }

    RefreshMyRoomUserList(userId, useCache, userFullInfo): DynamicMethod {
        if (userFullInfo && userFullInfo !== null) {
            userFullInfo.Email = ""
        }
        return { MethodName: "RefreshMyRoomUserList", Parameters: [userId, useCache, userFullInfo] };
    }

    // #region Admin Operations

    OdaSiteBanGirisleriSet(allowRoomBanEnter, allowGenerelBanEnter): DynamicMethod {
        return { MethodName: "OdaSiteBanGirisleriSet", Parameters: [allowRoomBanEnter, allowGenerelBanEnter] };
    }

    OdaBanGirisleriDegisti(message): DynamicMethod {
        return { MethodName: "OdaBanGirisleriDegisti", Parameters: [message] };
    }

    // #endregion


    //#region p2p conference operations 

    P2PConferenceRequest(conferenceRequestResponse: ConferenceRequestResponse) {
        return { MethodName: "P2PConferenceRequest", Parameters: [conferenceRequestResponse] };
    }

    P2PConferenceResponse(conferenceRequestResponse: ConferenceRequestResponse) {
        return { MethodName: "P2PConferenceResponse", Parameters: [conferenceRequestResponse] };
    }

    StopP2PConference(conferenceStop: any) {
        return { MethodName: "StopP2PConference", Parameters: [conferenceStop] };
    }

    //#endregion

    ShowBanMessage(message: string): DynamicMethod {
        return { MethodName: "ShowBanMessage", Parameters: [message] };
    }

    RemoveBulkBanMute(banType: number, userRutbe: number) {
        return { MethodName: "RemoveBulkBanMute", Parameters: [banType, userRutbe] };
    }

    EditUsersForChangedRank(rankId: number, userRankFullInfo: RankFullInfo) {
        return { MethodName: "EditUsersForChangedRank", Parameters: [rankId, userRankFullInfo] };
    }

    Logout(userId: number, tabId: string, dropUser: boolean) {
        return { MethodName: "Logout", Parameters: [userId, tabId, dropUser] };
    }

    SMSValidationRequestChanged(SMSactive: boolean) {
        return { MethodName: "SMSValidationRequestChanged", Parameters: [SMSactive] };
    }

    InviteToRoom(roomID: number, roomName: string, invitedName: string) {
        return { MethodName: "InviteToRoom", Parameters: [roomID, roomName, invitedName] };
    }

    OdaSahibi(isOwner: boolean) {
        return { MethodName: "OdaSahibi", Parameters: [isOwner] };
    }

    CloseP2PChatPanel(userId: number) {
        return { MethodName: "CloseP2PChatPanel", Parameters: [userId] };
    }

    UpdateAuthorizedUserSliderList(sliderUserList: RoomSliderUser[]) {
        return { MethodName: "UpdateAuthorizedUserSliderList", Parameters: [sliderUserList] };
    }

    DropMic() {
        return { MethodName: "DropMic", Parameters: [] };
    }

    ChangedRoomOrderList(roomIds) {
        return { MethodName: "ChangedRoomOrderList", Parameters: [roomIds] };
    }

    GetComplainNotification() {
        return { MethodName: "GetComplainNotification", Parameters: [] };
    }

    GetLiveSupportNotification() {
        return { MethodName: "GetLiveSupportNotification", Parameters: [] };
    }

    RoomCreated(roomId: Array<Room>) {
        return { MethodName: "RoomCreated", Parameters: [roomId] };
    }
}   