import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { nanoid } from 'nanoid';

@Component({
  selector: 'app-redirect-register-type',
  templateUrl: './redirect-register-type.component.html',
  styleUrls: ['./redirect-register-type.component.scss']
})
export class RedirectRegisterTypeComponent {
  constructor(
    private route: ActivatedRoute,
    private router: Router
) {
  
}
  ngOnInit(): void {
    this.initGetTemplateIdAndRedirectToDynamicRoom()
  }

  initGetTemplateIdAndRedirectToDynamicRoom() {
    this.route.params.subscribe(params => {
      let templateId = parseInt(params['templateId']);
      this.controlTemplateIdAndRedirect(templateId)
    });
  }

  controlTemplateIdAndRedirect(templateId: number) {
    if (templateId) {
      const guid = nanoid();
      this.router.navigate(['login/templateId', templateId, guid]);
    }
  }
}
