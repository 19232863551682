<p-dialog [(visible)]="panelManagerService.imageCapturePopupDisplay" [modal]="true" [baseZIndex]="10000"
  [responsive]="true" (onHide)="onHide($event)" class="imageCapture" [showHeader]="false" [dismissableMask]="true">

  <div class="container">
    <video #video autoplay playsinline class="videoArea"></video>

    <canvas #canvas id="canvas" [style.display]="'none'"></canvas>
    <button pButton type="button" class="take-photo" (click)="takePicture()"
    pTooltip="{{ 'takephoto' | translate }}" tooltipPosition="top" [tooltipZIndex]="1000000000000"></button>
  </div>


</p-dialog>