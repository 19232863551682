import { AdminRoomService } from 'src/app/admin/tabs/rooms/services/room.service';
import { Component, OnInit } from '@angular/core';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { ChatMessageService } from 'src/app/core/services/chatMessage.service';
import { DeviceService } from 'src/app/core/services/device.service';
import { DynamicMethodsReceiveService } from 'src/app/core/services/dynamic-methods-receive.service';
import { DynamicMethodsSendService } from 'src/app/core/services/dynamic-methods-send.service';
import { DynamicMethodsService } from 'src/app/core/services/dynamic-methods.service';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { RoomService } from 'src/app/core/services/room.service';
import { UserService } from 'src/app/core/services/user.service';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
  selector: 'app-room-list-back-image-cropper',
  templateUrl: './room-list-back-image-cropper.component.html',
  styleUrls: ['./room-list-back-image-cropper.component.scss']
})
export class RoomListBackImageCropperComponent implements OnInit {
  isObject: boolean;
  croppedImage: string = '';
  canvasRotation = 0;
  rotation: number;
  scale = 1;
  showCropper = false;
  transform: ImageTransform = {};
  visibleSidebar: boolean = false;

  constructor(
    public panelManagerService: PanelManagerService,
    private indexedDBService: IndexedDBService,
    private messagePanelService: MessagePanelService,
    private dynamicMethodsReceiveService: DynamicMethodsReceiveService,
    public adminRoomService: AdminRoomService,
    public deviceService: DeviceService,
    private utilService: UtilService,
    private roomService: RoomService,
    private chatMessageService: ChatMessageService,
    private dynamicMethodsSendService: DynamicMethodsSendService,
    private dynamicMethodsService:DynamicMethodsService

  ) {
  }

  ngOnInit() {
    this.setImageType();
  }

  openFileUpload($event) {
    $event.preventDefault();
    let element: HTMLElement = document.getElementById('imageFileUpload') as HTMLElement;
    element.click();
    this.closeSideBar();
  }

  setImageType() {
    (typeof this.adminRoomService.imageChangedEvent) === 'object' ? this.isObject = true : this.isObject = false;
  }

  fileChangeEvent($event) {
    if ($event.target.files && $event.target.files.length) {
      for (const file of $event.target.files) {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => {
          const img = new Image();
          img.src = reader.result as string;
          img.onload = () => {
            if (img.width < 400 || img.height < 400) {
              this.messagePanelService.ShowPopupMessageWithLocalization('InvalidProfilePhotoSize', 2000);
              return;
            }
            else {
              this.adminRoomService.imageChangedEvent = $event;
            }
          };
        };
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.utilService.resizeImage(event.base64, 300, 170, new Image())
      .then((value: string) => {
        this.croppedImage = value;
      })

  }

  takePicture() {
    this.adminRoomService.imageChangedEvent = "";
    this.panelManagerService.imageCropperPopupDisplay = false;
    this.panelManagerService.imageCapturePopupDisplay = true;
  }

  saveImage() {
    let croppedImgForDisplay = this.croppedImage;
    this.croppedImage = this.croppedImage.split(',')[1];
      if (this.adminRoomService.imageChangedEvent.target.files && this.adminRoomService.imageChangedEvent.target.files.length) {
      for (const file of this.adminRoomService.imageChangedEvent.target.files) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const img = new Image();
          img.src = reader.result as string;
          this.adminRoomService.favoriteButtonBackImageID = croppedImgForDisplay;
          this.adminRoomService.roomDetails.BUTTON_BACK_IMAGE_ID = this.utilService.guid();
          this.adminRoomService.isThereFavoriteButtonBackImage = true;
        };
      }
    }
    this.adminRoomService.roomSettings.RoomFavoriButtonBackBlob = this.croppedImage;
    this.panelManagerService.roomLobiImageCropperPopupDisplay = false;
  }


  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
  }

  loadImageFailed() {

  }

  rotateLeft() {
    this.closeSideBar();
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.closeSideBar();
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }


  flipHorizontal() {
    this.closeSideBar();
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.closeSideBar();
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.closeSideBar();
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }

  onHide($event) {

    this.panelManagerService.imageCropperPopupDisplay = false;
    this.adminRoomService.imageChangedEvent = "";
  }

  closeSideBar() {
    this.visibleSidebar = false;
  }
}
