<p-dialog class="updateStateMessage" [(visible)]="panelManagerService.sendFolderToFriendPopupDisplay" [modal]="true"
    [baseZIndex]="10000" [responsive]="true" header="{{'send-file-image' | translate }}">

    <div style="text-align:center;">
        <div class="upload-folder-section">
            <div class="upload-image">
                <div class="file-upload">
                    <fa-icon class="upload-icon" [icon]="faUpload"></fa-icon>
                    <input type="file" id="roomSendImageUpload" (change)="shareImageToRoomFileChangeEvent($event)"
                        (click)="shareImageToRoomOnInputClick($event)" accept="image/jpeg, image/jpg, image/png"
                        name="favoriteButton" style="display: none;">
                    <span (click)="shareImageToRoomOpenFileUpload($event)" class="custom-button-text">{{ 'Image' |
                        translate }}</span>
                </div>
            </div>
            <div class="upload-docs">
                <div class="file-upload">
                    <fa-icon class="upload-icon" [icon]="faUpload"></fa-icon>
                    <input type="file" id="roomSendDocsUpload" (change)="shareDocsToRoomFileChangeEvent($event)"
                        (click)="shareDocsToRoomOnInputClick($event)" accept=".pdf, .doc, .docx, .xls, .xlsx, .txt"
                        name="favoriteButton" style="display: none;">
                    <span (click)="shareDocsToRoomOpenFileUpload($event)" class="custom-button-text">{{ 'Dosya' |
                        translate }}</span>
                </div>
            </div>
        </div>
        <div *ngIf="selectedFile" class="selected">
            {{selectedFile.name}} - {{selectedFileSizeInKB | number:'1.0-2'}} KB
            <fa-icon class="delete-icon" [icon]="faTrashAlt" (click)="clearSelectedFile()"></fa-icon>
        </div>


    </div>
    <p-footer>
        <div *ngIf="selectedFile" class="delete-button">
            <button type="button" (click)="uploadFile()" [disabled]="isUploading" class="btn btn-primary btn-sm">{{'share-file' |
                translate}}</button>
        </div>
    </p-footer>
</p-dialog>