import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { UserCurrentRoomInfo, UserFullInfo } from 'src/app/core/models/user';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { FriendListService } from 'src/app/core/services/friendlist.service';
import { UtilService } from 'src/app/core/services/util.service';
import { TranslateService } from '@ngx-translate/core';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { ConfirmationService } from 'primeng/api';
import { ContactType, FriendListType, SikayetTipleri } from 'src/app/core/models/enums';
import { FriendshipService } from 'src/app/core/services/friendship.service';
import { faBan, faChevronDown, faChevronRight, faCommentAlt, faInfo, faMinusCircle, faRetweet } from '@fortawesome/free-solid-svg-icons';
import { faFlag } from '@fortawesome/free-regular-svg-icons';
import { ReportService } from 'src/app/core/services/report.service';
import { RoomService } from 'src/app/core/services/room.service';

@Component({
  selector: 'app-friendlist-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FriendlistContextMenuComponent implements OnInit {

  @Input() friend: UserFullInfo;

  faRetweet = faRetweet;
  faCommentAlt = faCommentAlt;
  faBan = faBan;
  faMinusCircle = faMinusCircle;
  faFlag = faFlag;
  faChevronRight = faChevronRight
  faChevronDown = faChevronDown
  isChevronDown = false;
  friendCurrentRoomInfo:UserCurrentRoomInfo;
  isLoading = false;
  constructor(
    private indexedDBService: IndexedDBService,
    private friendListService: FriendListService,
    private utilService: UtilService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    public panelManagerService: PanelManagerService,
    private friendshipService: FriendshipService,
    private reportService: ReportService,
    public roomService:RoomService
  ) { }

  ngOnInit() {
  }

  refreshFriendList() {
    this.panelManagerService.mainLoading = true;
    this.friendshipService.getFriends()
      .then((response) => {
        this.indexedDBService.Friends = response;
        this.panelManagerService.loadedFriends = Promise.resolve(true);
      })
      .then(() => {
        this.panelManagerService.mainLoading = false;
        this.closeContextMenu();
      });
  }

  openChatPanel() {
    this.panelManagerService.openP2PChatPanel(this.friend.KullaniciId, this.indexedDBService.getFriendById(this.friend.KullaniciId));
    this.closeContextMenu();
  }

  blockUser() {
    let activeP2PChatPanel;
    if (this.utilService.isNullOrEmtpyObject(this.panelManagerService.p2pChatPanels) === false) {
      activeP2PChatPanel = this.panelManagerService.p2pChatPanels.find(f => f.user.KullaniciId === this.friend.KullaniciId);
    }

    if (this.utilService.isNullOrEmtpyObject(activeP2PChatPanel) === false && this.utilService.isNullOrEmtpyObject(activeP2PChatPanel.chatPanelProps) === false) {
      if (activeP2PChatPanel.chatPanelProps.ContactType === ContactType.Audio
        || activeP2PChatPanel.chatPanelProps.ContactType === ContactType.VideoAndAudio) {
        this.confirmationService.confirm({
          message: this.translateService.instant('BlockFriendFormWarning'),
          header: this.translateService.instant('blockuser'),
          icon: null,
          acceptLabel: this.translateService.instant('Yes'),
          rejectLabel: this.translateService.instant('No'),
          accept: () => {
            this.friendListService.callBlockUser(this.friend.KullaniciId, this.friend.KullaniciAdi);
          },
          reject: () => {
          }
        });
        this.closeContextMenu();
      }
      else {
        this.confirmBlockFriend();
      }
    }
    else {
      this.confirmBlockFriend();
    }
  }

  confirmBlockFriend() {
    this.confirmationService.confirm({
      message: '[' + this.friend.KullaniciAdi + '] ' + this.translateService.instant('BeforeBlockUser'),
      header: this.translateService.instant('blockuser'),
      icon: null,
      accept: () => {
        this.friendListService.callBlockUser(this.friend.KullaniciId, this.friend.KullaniciAdi);
      },
      reject: () => {

      }
    });

    this.closeContextMenu();
  }

  deleteRequest() {
    let activeP2PChatPanel;
    if (this.utilService.isNullOrEmtpyObject(this.panelManagerService.p2pChatPanels) === false) {
      activeP2PChatPanel = this.panelManagerService.p2pChatPanels.find(f => f.user.KullaniciId === this.friend.KullaniciId);
    }

    if (this.utilService.isNullOrEmtpyObject(activeP2PChatPanel) === false && this.utilService.isNullOrEmtpyObject(activeP2PChatPanel.chatPanelProps) === false) {
      if (activeP2PChatPanel.chatPanelProps.ContactType === ContactType.Audio
        || activeP2PChatPanel.chatPanelProps.ContactType === ContactType.VideoAndAudio) {
        this.confirmationService.confirm({
          message: this.translateService.instant('RemoveFriendFormWarning'),
          header: this.translateService.instant('removeFriend'),
          icon: null,
          acceptLabel: this.translateService.instant('Yes'),
          rejectLabel: this.translateService.instant('No'),
          accept: () => {
            this.friendListService.callRemoveFriend(this.friend.KullaniciId, FriendListType.Friends);

          },
          reject: () => {
          }
        });
        this.closeContextMenu();
      }
      else {
        this.confirmRemoveFriend();
      }
    }
    else {
      this.confirmRemoveFriend();
    }

  }

  confirmRemoveFriend() {
    this.confirmationService.confirm({
      message: '[' + this.friend.KullaniciAdi + '] ' + this.translateService.instant('BeforeRemoveFriendQuestion'),
      header: this.translateService.instant('removeFriend'),
      icon: null,
      accept: () => {
        this.friendListService.callRemoveFriend(this.friend.KullaniciId, FriendListType.Friends);
      },
      reject: () => {

      }
    });

    this.closeContextMenu();
  }

  complainUser() {
    this.reportService.header = this.friend.KullaniciAdi;
    this.reportService.insertComplainRequest.ComplainType = SikayetTipleri.Kullanici;
    this.reportService.insertComplainRequest.ComplainingId = this.indexedDBService.userFullInfo.KullaniciId;
    this.reportService.insertComplainRequest.ComplainedId = this.friend.KullaniciId;
    this.reportService.insertComplainRequest.Html = ""; //TODO this.message.messageHtml[0];
    this.reportService.reportPopupDisplay = true;
    this.closeContextMenu();
  }

  whichRoomUserIsIn() {
    this.isChevronDown = !this.isChevronDown;
    this.isLoading = true; 
    this.friendshipService.getFriendCurrentRoomInfo(this.friend.KullaniciId).subscribe(res => {
      this.isLoading = false; 
      if (res.room_name != '' || res.room_id != '') {
        this.friendCurrentRoomInfo = res;
      } else {
        this.friendCurrentRoomInfo = null;
      }
    }, err => {
      this.isLoading = false; 
      this.friendCurrentRoomInfo = null;
    });
  }

  goToRoom(){
    if (this.friendCurrentRoomInfo) {
      this.panelManagerService.p2pContextMenuPopupDisplay = false
      this.isChevronDown = !this.isChevronDown;
      this.roomService.enterRoomNew(Number(this.friendCurrentRoomInfo.room_id));
    }
  }

  closeContextMenu() {
    this.panelManagerService.p2pContextMenuPopupDisplay = false;
  }
}
