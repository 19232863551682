import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class DeviceService {
    MobileDetect = require('mobile-detect');
    detection;

    isMobile = window.innerWidth <= 600;//  this.deviceDetectorService.isMobile();
    isTablet = false;//this.deviceDetectorService.isTablet();
    isDesktop = window.innerWidth > 600;//this.deviceDetectorService.isDesktop();

    constructor(
    ) {
        this.detection = new this.MobileDetect(window.navigator.userAgent);
        //bununaçınca iphone safariden girilmiyor

        // if (Notification.permission === "default") {
        //     Notification.requestPermission(function (permission) {

        //     });
        // }
    }

    defineAndGetTabID() {
        var iPageTabID = sessionStorage.getItem("tabID");

        if (iPageTabID === null || iPageTabID === undefined || iPageTabID === "") {
            iPageTabID = this.uuidv4();
            sessionStorage.setItem("tabID", iPageTabID);
        }

        return iPageTabID
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

}
