import { Component, OnInit, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';

import { UtilService } from 'src/app/core/services/util.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { UserFullInfo } from 'src/app/core/models/user';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-update-state-message',
  templateUrl: './update-state-message.component.html',
  styleUrls: ['./update-state-message.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class UpdateStateMessageComponent implements OnInit {

  stateMessage: string;

  constructor(
    private userService: UserService,
    public panelManagerService: PanelManagerService,
    private utilService: UtilService,
    private messagePanelService: MessagePanelService,
    private indexedDBService: IndexedDBService
  ) { }

  ngOnInit() {
    this.setStateMessage();
   }

   setStateMessage(){
    if (this.utilService.IsNullOrWhitespace(this.indexedDBService.userFullInfo.DurumMesaj)) {
      this.stateMessage = '';
    }
    else {
      this.stateMessage = this.indexedDBService.userFullInfo.DurumMesaj;
    }
   }

  updateStateMessage() {
    let body = {
      "ClientInfo": null,
      "UserId": this.indexedDBService.userFullInfo.KullaniciId,
      "StateMessage": this.stateMessage
    };

    this.userService.saveUserStateMessage(body)
      .subscribe(response => {
        this.panelManagerService.updateStateMessagePopupDisplay = false;
        if (this.utilService.isNullOrUndefined(response)) {
          this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
          return;
        }
        this.indexedDBService.userFullInfo.DurumMesaj = response;     
        this.indexedDBService.updateUser(this.indexedDBService.userFullInfo); 
        this.messagePanelService.ShowPopupMessageWithLocalization('OperationSuccess', 2000);
      },
        error=> {
          this.panelManagerService.updateStateMessagePopupDisplay = false;
          console.log(error);
        });
  }
}