export class BanMuteInfoByServer {
    HasSiteMute: boolean;
    IsOdaBanGirisleriAktif: boolean;
    IsSiteBanGirisleriAktif: boolean;

    OdaBanList: Array<RoomBanListInfo>;
    SiteBanList: Array<SiteBanListInfo>;
    OdaMuteList: Array<RoomMuteListInfo>;
}

export class BanMuteInfo {
    RoomBanList: { [key: number]: BanInfo } = {};
    SiteBan: BanInfo = null;
    RoomMuteList: { [key: number]: BanInfo } = {};
    HasSiteMute: boolean = false;
    IsOdaBanEnterAllow: boolean = false;
    IsSiteBanEnterAllow: boolean = false;
}

export class BanInfo {
    RoomId: number = null;
    StartDate: Date = null;
    EndDate: Date = null;
}


export class RoomBanListInfo {
    R: number = null;
    SD: string = null; // start Date "19.12.2019 14:50:24:913"
    ED: string = null; //end Date
}

export class SiteBanListInfo {
    SD: string = null; // start Date "19.12.2019 14:50:24:913"
    ED: string = null; //end Date
}

export class RoomMuteListInfo {
    R: number = null;
    SD: string = null; // start Date "19.12.2019 14:50:24:913" 
}

export enum BanMuteKaraListeType{
    "BanOda" = 1,
    "BanSite" = 2,
    "Karaliste" = 3,
    "MuteOda" = 11,
    "MuteSite" = 22
}

export class CheckBanResponse {
    Success: boolean;
    BanType: number;
    ErrMsg: string;
}