import { Component, OnInit } from '@angular/core';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { DeviceService } from 'src/app/core/services/device.service';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/core/models/constants';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { DynamicMethodsReceiveService } from 'src/app/core/services/dynamic-methods-receive.service';
import { UserService } from 'src/app/core/services/user.service';
import { RoomService } from 'src/app/core/services/room.service';
import { UtilService } from 'src/app/core/services/util.service';
import { ChatMessageService } from 'src/app/core/services/chatMessage.service';
import { DynamicMethodsSendService } from 'src/app/core/services/dynamic-methods-send.service';
import { DynamicMethodsService } from 'src/app/core/services/dynamic-methods.service';

@Component({
  selector: 'app-select-avatar',
  templateUrl: './select-avatar.component.html',
  styleUrls: ['./select-avatar.component.scss']
})
export class SelectAvatarComponent implements OnInit {

  photoPath = User.photoPath;
  constructor(
    public panelManagerService: PanelManagerService,
    public deviceService: DeviceService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private indexedDBService: IndexedDBService,
    private dynamicMethodsReceiveService: DynamicMethodsReceiveService,
    private userService: UserService,
    public utilService: UtilService,
    private roomService: RoomService,    
    private chatMessageService: ChatMessageService,
    private dynamicMethodsSendService: DynamicMethodsSendService,
    private dynamicMethodsService:DynamicMethodsService
  ) { }

  ngOnInit() {
  }


  confirmImageSelected(item) {

    this.panelManagerService.selectAvatarPopupDisplay = false;
    this.confirmationService.confirm({
      message: this.translateService.instant('confirm-photo-save'),
      header: null,
      icon: null,
      acceptLabel: this.translateService.instant('Yes'),
      rejectLabel: this.translateService.instant('No'),
      accept: () => {
        this.callSaveUserImageServer(item);
      },
      reject: () => {
        this.panelManagerService.selectAvatarPopupDisplay = true;
      }
    });
  }

  callSaveUserImageServer(avatarIndex) {
    this.userService.callSaveUserImageServer({
      "ClientInfo": null,
      "Delete": false,
      "UserId": this.indexedDBService.userFullInfo.KullaniciId,
      "Base64String":"",
      "Base64StringSmall":"",
      "AvatarId": '10000000-0000-0000-0000-0000000000' + (avatarIndex+1<10?'0':'')+ (avatarIndex +1)
    })
    .then(() => {
      this.indexedDBService.userFullInfo.ProfilResmiId = '10000000-0000-0000-0000-0000000000' + (avatarIndex+1<10?'0':'')+ (avatarIndex +1);
    })
    .then(() => {
      
      this.dynamicMethodsReceiveService.RefreshMyFriendsFriendList(false, false);
      this.roomService.updateUserAllInfo(this.indexedDBService.userFullInfo);
      this.roomService.RefreshMyPhotoInRoom(this.indexedDBService.userFullInfo.ProfilResmiId);
    })
    .then(() => {
      // this.chatMessageService.sendUserSettingMessage(
      //   this.indexedDBService.userFullInfo.KullaniciId,
      //   [
      //     this.dynamicMethodsSendService.RefreshUser(this.indexedDBService.userFullInfo.KullaniciId)
      //   ],
      //   this.indexedDBService.userFullInfo.KullaniciAdi
      // ) 

      let operationMessageForRefreshUser = this.dynamicMethodsService.prepareUserSettingMessage(
        this.indexedDBService.userFullInfo.KullaniciId,
        [
          this.dynamicMethodsSendService.RefreshUser(this.indexedDBService.userFullInfo.KullaniciId)
        ],
        this.indexedDBService.userFullInfo.KullaniciAdi)
      this.dynamicMethodsService.publishDynRefreshUser(this.indexedDBService.userFullInfo.KullaniciId,this.indexedDBService.userFullInfo.KullaniciId,operationMessageForRefreshUser);
    });
  }
}
