import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfirmationDialogParams } from '../models/dialog';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {

  private popupShowedSource: Subject<ConfirmationDialogParams> = new Subject();
  popupShowed = this.popupShowedSource.asObservable();
  loadConfirmationDialog: boolean = false;

  constructor(
  ) {

  }

  showPopup(params: ConfirmationDialogParams) {
    this.loadConfirmationDialog = true;
    this.popupShowedSource.next(params);
  }

}