//#region imports 
import { Component, OnInit, ViewChild, OnDestroy, ComponentFactoryResolver, Input, OnChanges, AfterContentInit } from '@angular/core';
import { RoomUserListService } from '../../../services/room-user-list-service';
import { UtilService } from '../../../services/util.service';
import { RoomUser, RoomUserEnum } from 'src/app/core/models/room-user';
import { RoomUserListUserDirective } from './user/user.directive';
import { RoomUserListUserComponent } from './user/user.component';
import { CurrentRoom } from 'src/app/core/models/room';
import { RoomService } from 'src/app/core/services/room.service';
import { UserFullInfo } from 'src/app/core/models/user';
import { Subscription } from 'rxjs';
import { DeviceService } from 'src/app/core/services/device.service';
import { DynamicComponentManagerService } from 'src/app/core/services/dynamic-component-manager.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { faChevronDown, faExpandAlt, faStar } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-room-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class RoomUserListComponent implements OnInit, OnChanges, OnDestroy, AfterContentInit {

  //#region properties 

  @Input() roomUserList: UserFullInfo;
  @ViewChild(RoomUserListUserDirective, { static: false, read: RoomUserListUserDirective }) roomUserHost: RoomUserListUserDirective;
  
  isThereOnMic: boolean;

  roomUserAddedSubscription: Subscription;

  roomOnlineUserListAddedSubscription: Subscription;
  roomVipUserListAddedSubscription: Subscription;

  roomUserRemovedSubscription: Subscription;
  roomOnlineUserListRemovedSubscription: Subscription;
  roomVipUserListRemovedSubscription: Subscription;

  roomUserListChangedSubscription: Subscription;
  roomInfoUpdatedSubscription: Subscription;
  roomUserListClearedSubscription: Subscription;

  vipUserListLoadedSubscription: Subscription;
  onlineUserListLoadedSubscription: Subscription;
  handUserListLoadedSubscription: Subscription;

  
  //#endregion


  //#region constructor & init 

  constructor(
    private roomUserListService: RoomUserListService,
    private utilService: UtilService,
    private componentFactoryResolver: ComponentFactoryResolver,
    public roomService: RoomService,
    public deviceService: DeviceService,
    private dynamicComponentManagerService: DynamicComponentManagerService,
    public panelManagerService: PanelManagerService
  ) {
    this.roomUserAddedSubscription = this.roomUserListService.roomUserAdded.subscribe((roomUser: RoomUser) => {
      this.addNewUser(roomUser);
    });

    this.roomOnlineUserListAddedSubscription = this.roomUserListService.roomOnlineUserListAdded.subscribe((roomUser: RoomUser) => {
      this.addNewOnlineUserToList(roomUser);
    });

    this.roomVipUserListAddedSubscription = this.roomUserListService.roomVipUserListAdded.subscribe((roomUser: RoomUser) => {
     this.sortVipUserList()
      this.addNewVipUserToList(roomUser);
    });

    this.roomUserRemovedSubscription = this.roomUserListService.roomUserRemoved.subscribe((userId: number) => {
      this.removeUser(userId);
    });

    this.roomOnlineUserListRemovedSubscription = this.roomUserListService.roomOnlineUserListRemoved.subscribe((userIds: number[]) => {
      userIds.forEach(userId => {
        this.removeOnlineUser(userId);
        })
    });

    this.roomVipUserListRemovedSubscription = this.roomUserListService.roomVipUserListRemoved.subscribe((userIds: number[]) => {
      userIds.forEach(userId => {
        this.removeVipUser(userId);
        })
    });

    this.roomUserListChangedSubscription = this.roomService.roomUserListChanged.subscribe((userId: number) => {
      this.sortRoomUserList();
      this.setRoomBackground();
      this.refreshRoomUserViewList();
    });

    this.roomInfoUpdatedSubscription = this.roomService.roomInfoUpdated.subscribe((currentRoom: CurrentRoom) => {
      this.roomService.currentRoom = currentRoom;
      this.setRoomBackground();
    });

    this.roomUserListClearedSubscription = this.roomService.roomUserListCleared.subscribe(() => {
      this.claerUserViewList();
    });

    this.vipUserListLoadedSubscription = this.roomService.vipUserListLoaded.subscribe(() => {
      this.sortVipUserList()
      this.loadRoomVipUserViewList();
    })
    
    this.onlineUserListLoadedSubscription = this.roomService.onlineUserListLoaded.subscribe(() => {
      this.loadRoomOnlineUserViewList();
    })

    this.handUserListLoadedSubscription = this.roomService.handUserListLoaded.subscribe(() => {
      this.loadRoomHandUserViewList();
    })
  }


  ngOnInit() {
    // this.init();
  }

  ngAfterContentInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

  }

  ngOnChanges() {
    this.setRoomBackground();
  }

  init() {
    //setTimeout(() => {
    this.setRoomBackground();
    //this.sortRoomUserList();
    this.changeAllRoomUserViewsIndex([]);
    //}, 200);
  }


  setRoomBackground() {
    this.roomService.userListBackground = {
      'background': this.utilService.hexToRgb(this.roomService.currentRoom.Info.RENK_USER_1) + 'linear-gradient(to right, ' + this.utilService.hexToRgb(this.roomService.currentRoom.Info.RENK_USER_1) + ',' + this.utilService.hexToRgb(this.roomService.currentRoom.Info.RENK_USER_2) + ')'
    };
  }

  ngOnDestroy() {
    if (this.roomUserAddedSubscription)
      this.roomUserAddedSubscription.unsubscribe();

    if (this.roomUserRemovedSubscription)
      this.roomUserRemovedSubscription.unsubscribe();

    if (this.roomOnlineUserListAddedSubscription)
      this.roomOnlineUserListAddedSubscription.unsubscribe();

    if (this.roomOnlineUserListRemovedSubscription)
      this.roomOnlineUserListRemovedSubscription.unsubscribe();

    if (this.roomUserListChangedSubscription)
      this.roomUserListChangedSubscription.unsubscribe();

    if (this.roomInfoUpdatedSubscription)
      this.roomInfoUpdatedSubscription.unsubscribe();

    if (this.roomUserListClearedSubscription)
      this.roomUserListClearedSubscription.unsubscribe();

    if (this.vipUserListLoadedSubscription) {
      this.vipUserListLoadedSubscription.unsubscribe();
    }
    if (this.onlineUserListLoadedSubscription) {
      this.onlineUserListLoadedSubscription.unsubscribe();
    }
    if (this.handUserListLoadedSubscription) {
      this.handUserListLoadedSubscription.unsubscribe();
    }
  }

  //#endregion

  //#region room user view operations 

  refreshRoomUserViewList() {
    this.claerUserViewList();
    this.roomService.roomUserList.forEach(roomUser => {
      this.appendToRoomUserList(roomUser);
    });
  }

  loadRoomVipUserViewList() {
    this.claerUserViewList();
    if (this.roomService.vipUserList.length > 0) {
      this.roomService.isEmptyVipUserList = false;
      this.roomService.vipUserList.forEach(roomUser => {
        this.appendToRoomUserList(roomUser);
      });
    }
    else{
      this.roomService.isEmptyVipUserList = true;
    }
  }

  loadRoomOnlineUserViewList() {
    this.claerUserViewList();
    this.roomService.isEmptyOnlineUserList = false;
    if (this.roomService.onlineUserList.length > 0) {
      this.roomService.onlineUserList.forEach(roomUser => {
        this.appendToRoomUserList(roomUser);
      });
    }
    else{
      this.roomService.isEmptyOnlineUserList = true;
    }
  }

  loadRoomHandUserViewList() {
    this.claerUserViewList();
    this.roomService.isEmptyHandRequestUserList = false;

    if (this.roomService.handUserList.length > 0) {
      this.roomService.handUserList.forEach(roomUser => {
        this.appendToRoomUserList(roomUser);
      });
    }
    else{
      this.roomService.isEmptyHandRequestUserList = true;

    }
  }
  
  appendToRoomUserList(roomUser: RoomUser, index?: number) {
    try {
      let componentFactory = this.componentFactoryResolver.resolveComponentFactory(RoomUserListUserComponent);
      let viewContainerRef = this.roomUserHost.viewContainerRef;
      let componentRef = viewContainerRef.createComponent(componentFactory, index);
      (<RoomUserListUserComponent>(componentRef.instance)).set(roomUser, this.roomService.userListBackground);
    } catch (error) {
      console.log(error);
      //window.location.reload();
    }
  }

  changeAllRoomUserViewsIndex(updatingUserIdList: Array<number>) {
    let viewRefDictionary = {};

    while (true) {
      let viewRef = this.dynamicComponentManagerService.detachFromViewRef(this.roomUserHost.viewContainerRef, 0);
      if (viewRef) {
        viewRefDictionary[this.dynamicComponentManagerService.getDataByEmbeddedView<RoomUser>(viewRef, "roomUser").KullaniciId] = viewRef;
      }
      else
        break;
    }

    for (var i = 0; i < this.roomService.roomUserList.length; i++) {
      let viewRef = viewRefDictionary[this.roomService.roomUserList[i].KullaniciId];
      if (viewRef && updatingUserIdList.find(f => f === this.roomService.roomUserList[i].KullaniciId) === undefined) {
        this.roomUserHost.viewContainerRef.insert(viewRef, i);
      }
      else {
        this.appendToRoomUserList(this.roomService.roomUserList[i], i);
      }

    }
  }

  //#region room user view helper methods 

  claerUserViewList() {
    if (this.roomUserHost && this.roomUserHost.viewContainerRef)
      this.roomUserHost.viewContainerRef.clear();

  }

  //#endregion

  //#endregion

  //#region room user list operations 

  addNewUser(roomUser: RoomUser) {
    this.sortRoomUserList();
    this.changeAllRoomUserViewsIndex([]);
  }

  addNewVipUserToList(roomUser: RoomUser) {
    let index = this.roomService.vipUserList.findIndex(f => f.KullaniciId === roomUser.KullaniciId);
    if (index != -1) {
      this.appendToRoomUserList(roomUser,index);
    }
  }

  addNewOnlineUserToList(roomUser: RoomUser) {
    this.appendToRoomUserList(roomUser);
  }

  removeUser(userId: number) {
    let roomuser: RoomUser = this.roomService.roomUserList.find(f => f.KullaniciId === userId);
    this.roomService.roomUserList = this.roomService.roomUserList.filter(f => f.KullaniciId !== userId);
    let userViewIndex = this.getRoomUserObjectIndexByUserId(userId);

    if (userViewIndex !== -1) {
      this.roomUserHost.viewContainerRef.remove(userViewIndex);

      delete this.roomUserListService.userViewInRoom[userId];

    }
  }

  removeOnlineUser(userId: number) {
    let roomuser: RoomUser = this.roomService.onlineUserList.find(f => f.KullaniciId === userId);
    let userViewIndex = this.getRoomUserObjectIndexByUserId(userId);

    if (userViewIndex !== -1) {
      this.roomUserHost.viewContainerRef.remove(userViewIndex);

      delete this.roomUserListService.userViewInRoom[userId];

    }
  }

  removeVipUser(userId: number) {
    let roomuser: RoomUser = this.roomService.vipUserList.find(f => f.KullaniciId === userId);
    let userViewIndex = this.getRoomUserObjectIndexByUserId(userId);

    if (userViewIndex !== -1) {
      this.roomUserHost.viewContainerRef.remove(userViewIndex);

      delete this.roomUserListService.userViewInRoom[userId];
    }
  }

  getRoomUserObjectIndexByUserId(userId: number): number {
    try {
      let embeddedViews = this.dynamicComponentManagerService.getEmbeddedViews(this.roomUserHost.viewContainerRef);

      for (var i = 0; i < embeddedViews.length; i++) {


        let embeddedView = this.dynamicComponentManagerService.getDataByEmbeddedView<RoomUser>(embeddedViews[i], "roomUser");
        if (embeddedView && embeddedView.KullaniciId === userId)
          return i;

      }


      //return -1;
      throw 'ERROR !!';
    } catch (error) {
      console.log(error)
      //window.location.reload();
      return -1;
    }
  }


  sortRoomUserList() {
    this.roomService.roomUserList.sort(this.sortByAll.bind(this));
  }

  sortVipUserList() {
    this.roomService.vipUserList.sort(this.sortByAll.bind(this));
  }

  sortOnlineUserList() {
    this.roomService.onlineUserList.sort(this.sortByAll.bind(this));
  }
  
  sortHandUserList() {
    this.roomService.handUserList.sort(this.sortByAll.bind(this));
  }

  sortByAll(a: RoomUser, b: RoomUser) {
        return this.utilService.sortByIntDescInternal(this.roomUserListService.userViewInRoom[a.KullaniciId]?.rutbeDerece, this.roomUserListService.userViewInRoom[b.KullaniciId]?.rutbeDerece, () => {
          return this.utilService.sortByInt(a, b, RoomUserEnum[RoomUserEnum.EnterRoomIndex], () => {
            return 0;
          });
        });
  }

  //#endregion
  // refreshUserList() {
  //   this.roomService.roomUserList = [];
  //   this.panelManagerService.roomUserListLoading = true;
  //   this.roomService.getRoomUserList(JsonFile.path, false, this.roomService.currentRoom.Info.ID)
  //     .then(() => {
  //       this.panelManagerService.roomUserListLoading = false;
  //     })
  //     .catch(error => {
  //       console.log(error);
  //       this.roomService.callErrorTracker(66, error);  //ERRORTRACKER
  //     });
  // }

}