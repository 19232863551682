import { Injectable } from '@angular/core';
import { SelectItem } from 'primeng/api/selectitem';
import { Constants } from '../models/constants';

@Injectable({
    providedIn: 'root'
})
export class FontService {

    fontFamilies: SelectItem[] = [];
    fontWeights: SelectItem[] = [];
    fontStlyes: SelectItem[] = [];

    constructor(
    ) {
        this.fillFontFamilies();
        this.fillFontWeigths();
        this.fillFontStlyes();
    }

    fillFontFamilies() {
        Constants.AVAILABLEFONTS.forEach(fontFamily => {
            this.fontFamilies.push({ label: fontFamily, value: fontFamily });
        });
    }

    fillFontWeigths(){
        Constants.AVAILABLEFONTWEIGTHS.forEach(fontWeight => {
            this.fontWeights.push({label: fontWeight, value: fontWeight});
        })
    }

    fillFontStlyes(){
        Constants.AVAILABLEFONTSTYLES.forEach(fontStyle => {
            this.fontStlyes.push({label: fontStyle, value: fontStyle});
        })
    }

}   