<p-scrollPanel [style]="{'width': '100%', 'height': '100%'}" [ngClass]="{ 'no-row': !roomService.isThereOnMic && deviceService.isDesktop,
 'one-row': roomService.isThereOnMic && roomService.rowNumberForUserOnMic == 1 && deviceService.isDesktop,
 'two-row': roomService.isThereOnMic && roomService.rowNumberForUserOnMic == 2 && deviceService.isDesktop}" class="bubble roomBubbleArea" #scrollPanel
    (contextmenu)="onContextMenu($event)">
    <!-- <div class="roomTopicDiv">
       <div class="roomTopicDivWhoEnterRoomInfo">
            {{
            (roomService.currentRoom.Info?.KIMLER_GIREBILIR === whoEnterRoomTypes.Everyone ? 'roomOpenEverybody' :
            (roomService.currentRoom.Info?.KIMLER_GIREBILIR === whoEnterRoomTypes.AuthoritiesOnly ? 'roomOpenOnlyAdmins'
            :
            'roomOpenOnlyUsers'))
            | translate
            }}
        </div> 

        <div class="roomTopicDivUserName mt-1"
            *ngIf="deviceService.isMobile && roomService.currentRoom.OwnerInfo?.ProfilResmiId">
            <div style="display: inline-block">
                <app-user-photo [userPhotoId]="roomService.currentRoom.OwnerInfo?.ProfilResmiId" [width]="28"
                    [height]="28" [showStateIcon]="false"></app-user-photo>
            </div>
            <span class="roomOwnerName"> {{ roomService.currentRoom.OwnerInfo?.KullaniciAdi}} </span>
            <span
                *ngIf="roomService.currentRoom.OwnerInfo?.RutbeDerece && roomService.currentRoom.OwnerInfo?.RutbeDerece!==0"
                style="font-size: 9px;">
                {{ ' (' }} <app-rank [size]="7" [degree]="roomService.currentRoom.OwnerInfo?.RutbeDerece"></app-rank> {{
                ' )' }}
            </span>
        </div>

        <div class="roomTopicDivTopicMessage mt-1" *ngIf="!isEmptyRoomMessage">
            <div class="roomTopicMessage" [innerHTML]="roomService.currentRoom.Info?.ODAMESAJ"></div>
        </div>
    </div>-->
    <div *ngIf="isLoading" class="loading" >
        <p-progressSpinner [style]="{width: '30px', height: '30px'}" strokeWidth="3" fill="#262626" animationDuration=".5s">
        </p-progressSpinner>
      </div>
    <div class="messageBubbleDiv room-topic" *ngIf="!isEmptyRoomMessage">
        <i class="pi pi-bell" pTooltip="{{ 'room-message' | translate }}" tooltipPosition="bottom"></i>
        <div [innerHTML]="roomService.currentRoom.Info?.ODAMESAJ"></div>
    </div>

    <app-room-editor-context-menu *ngIf="showRoomEditorContextMenu" [roomUser]="roomUser">
    </app-room-editor-context-menu>
   
    <ng-template app-bubble-host></ng-template>
    <fa-icon (click)="scrollToBottom()" *ngIf="showScrollDownIcon" [icon]="faArrowDown" class="scroll-icon"></fa-icon>
    
    <!-- <div id="user-audio" display="none">
        <div *ngFor="let room of roomService.roomUserList" style="display: none;">
            <audio id="user-mic-{{room.KullaniciId}}" ></audio>
        </div>
    </div> -->
</p-scrollPanel>

<!-- <div class="chat-panel-editor-area" #chatPanelEditorArea>
    <app-room-chat-buttons></app-room-chat-buttons>
    <app-room-chat-editor (editorTextChanged)="editorTextChange($event)"></app-room-chat-editor>
</div> -->