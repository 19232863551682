import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { StateService } from '../../../services/state.service';
import { UserStateType } from '../../../models/state';
import { Constants } from 'src/app/core/models/constants';
import { Subscription } from 'rxjs';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-user-photo',
  templateUrl: './user-photo.component.html',
  styleUrls: ['./user-photo.component.scss']
})
export class UserPhotoComponent implements OnInit, OnChanges {

  @Input() userPhotoId: string;
  @Input() stateId: number;
  @Input() showOnlineStateIcon: boolean = false;
  @Input() showStateIcon: boolean = true;
  @Input() width: number = 70;
  @Input() height: number = 70;
  @Input() borderRadius: number = 500;
  @Input() isBigPhoto: boolean = false;
  @Input() style = {};

  userUpdatedSubscription: Subscription;
  stateUrl: string = "";
  userPhotoUrl: string;
  load: Promise<boolean>;

  constructor(
    private stateService: StateService,
    public indexedDBService: IndexedDBService,
    private userService: UserService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.isBigPhoto) {
      this.userPhotoUrl = this.userService.getUserPhoto(this.userPhotoId, this.isBigPhoto);

    } else {
      if (this.userPhotoId === Constants.Veri24MessengerLogo70)
        this.userPhotoUrl = '../../../../../assets/images/zumti_48x48px.png';// Image.path + Constants.Veri24MessengerLogo70;
      else {
        this.userPhotoUrl = this.userService.getUserPhoto(this.userPhotoId);
      }

      if (!this.showStateIcon) {
        this.stateUrl = null;
      } else {
        this.stateId === 0 ? this.stateId = 1 : null;
        if (this.stateId) {
          if (this.showOnlineStateIcon) {
            this.stateUrl = this.stateService.getStateImageUrlByStateId(this.stateId);
          }
          else if (this.showOnlineStateIcon === false && this.stateService.getUserStateTypeByStateId(this.stateId) !== UserStateType.Cevrimici) {
            this.stateUrl = this.stateService.getStateImageUrlByStateId(this.stateId);
          }
          else {
            this.stateUrl = null;
          }
        }
      }

    }
    this.load = Promise.resolve(true);
  }

  ngOnDestroy(): void {
    if (this.userUpdatedSubscription)
      this.userUpdatedSubscription.unsubscribe();
  }

  onImgError(event) {
    event.target.src = this.userService.getUserPhoto(null);
  }
}
