import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import Hls from 'hls.js';
@Directive({
  selector: '[appHlsVideoPlayer]'
})
export class HlsVideoPlayerDirective implements OnInit {
  @Input()
  private videoUrl: string;

  private video: HTMLVideoElement;
  private hls: Hls;

  constructor(private el: ElementRef<HTMLVideoElement>) {
    this.video = el.nativeElement;
  }

  ngOnInit() {
    this.establishHlsStream();
  }

  private establishHlsStream() {
    if (this.hls) {
      this.hls.destroy();
    }
    if (Hls.isSupported()) {
      this.hls = new Hls();
      this.hls.attachMedia(this.video);
      this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        this.hls.loadSource(this.videoUrl);
        this.hls.on(Hls.Events.MANIFEST_PARSED, function(event, data) {
        });
      });

      this.hls.on(Hls.Events.ERROR, (event, data) => {
        console.log(data);
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              this.hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              this.hls.recoverMediaError();
              break;
            default:
              this.hls.destroy();
              break;
          }
        }
      });
    }
  }
}
