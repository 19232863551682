import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './component/home-page/home-page.component';
import { PublicComponent } from './component/public/public.component';
import { AuthGuard } from './guard/authenticate.guard';
import { LoginGuard } from './guard/login.guard';
import { RoomLinkComponent } from './component/public/room-link/room-link.component';
import { RedirectRegisterTypeComponent } from './component/public/dynamic-room/register-type/redirect-register-type/redirect-register-type.component';
import { RegisterTypeLoginComponent } from './component/public/dynamic-room/register-type/register-type-login/register-type-login.component';
import { RedirectUnRegisterTypeComponent } from './component/public/dynamic-room/unregister-type/redirect-unregister-type/redirect-unregister-type.component';
import { UnRegisterTypeLogin } from './component/public/dynamic-room/unregister-type/unregister-type-login/unregister-type-login.component';

const routes: Routes = [
  // Public Routes
  { path: '', component: PublicComponent, canActivate: [LoginGuard] },
  { path: 'login', redirectTo: '', pathMatch: 'full' },

  // Room Routes
  { path: 'join/roomId/:id/:roomPathParam', component: RoomLinkComponent },
  
  // Home Routes
  { path: 'home', component: HomePageComponent, canActivate: [AuthGuard] },
  { path: 'home/join/roomId/:id/:roomPathParam', component: HomePageComponent, canActivate: [AuthGuard] },
  
  // Template Routes
    //unregister type
    { path: 'template/:templateId/redirect', component: RedirectUnRegisterTypeComponent},
    { path: 'templateId/:templateId/:guid', component: UnRegisterTypeLogin },

    //register-type
    { path: 'template/:templateId/redirect/register', component: RedirectRegisterTypeComponent},
    { path: 'login/templateId/:templateId/:guid', component: RegisterTypeLoginComponent },
    
    { path: 'createRoom/templateId/:templateId/:guid', component: HomePageComponent, canActivate: [AuthGuard] }
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
