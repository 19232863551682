import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FriendshipService } from 'src/app/core/services/friendship.service';
import { UtilService } from 'src/app/core/services/util.service';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { ChatMessageService } from 'src/app/core/services/chatMessage.service';

@Component({
  selector: 'app-add-new-friend',
  templateUrl: './add-new-friend.component.html',
  styleUrls: ['./add-new-friend.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class AddNewFriendComponent implements OnInit {

  username: string;
  currentKeyIndex: number = 0;
  findedUser: string = "";
  friendId: number;
  responseLoaded: boolean = false;

  constructor(
    private friendshipService: FriendshipService,
    private utilService: UtilService,
    private indexedDBService: IndexedDBService,
    public panelManagerService: PanelManagerService,
    public chatMessageService: ChatMessageService
  ) { }

  ngOnInit() { }

  valuechange($event) {
    var curIndex = this.currentKeyIndex;
    this.responseLoaded = false;
    this.panelManagerService.findFriendLoading = true;

    setTimeout(() => {
      if (curIndex + 1 === this.currentKeyIndex) {
        this.username = this.username.replace(/\s/g, "");
        if (!this.utilService.isNullOrEmtpyString(this.username)) {
          this.friendshipService.getUserList(this.username, +this.indexedDBService.userFullInfo.KullaniciId)
            .then((data: any) => {
              this.responseLoaded = true;
              if (!this.utilService.isNullOrUndefined(data) &&
                (this.indexedDBService.Friends.AllFriends.find(f => f.KullaniciId === data.Id) === undefined) &&
                (this.indexedDBService.Friends.IncomingFriends.find(f => f.KullaniciId === data.Id) === undefined) &&
                (this.indexedDBService.Friends.OutgoingFriends.find(f => f.KullaniciId === data.Id) === undefined) &&
                (this.indexedDBService.Friends.BlockedByMeFriends.find(f => f.KullaniciId === data.Id) === undefined) &&
                (this.indexedDBService.Friends.BlockedMeFriends.find(f => f.KullaniciId === data.Id) === undefined)
              )
                this.successGetUserList(data);
              else
                this.errorGetUserList();
            })
            .catch(error => {
              console.log(error);
              this.responseLoaded = false;
              this.errorGetUserList();
            })
            .finally(() => {
              this.panelManagerService.findFriendLoading = false;
            })
        }
        else {
          this.responseLoaded = false;
        }
      }
    }, 1000);
    this.currentKeyIndex++;
  }

  successGetUserList(data) {
    this.findedUser = data.UserName;
    this.friendId = data.Id;
  }

  errorGetUserList() {
    this.findedUser = "";
    this.friendId = null;
  }

  addFriend() {
    this.friendshipService.callSendFriendshipRequest(this.friendId);
  }
}