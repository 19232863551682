<p-dialog [(visible)]="panelManagerService.userNameEntryPanelIsOpened" [closeOnEscape]="false" [closable]="false"
    [modal]="true" [baseZIndex]="10000" [responsive]="true" [focusOnShow]="false" [focusTrap]="true">
    <p-header>
        {{'UserName' | translate}}
    </p-header>

    <div style="text-align:center;">
        <input type="text" #roomPass autofocus class="textbox roomPassWordTextBox dialog-input" id="roomPassTextbox"
            [(ngModel)]="userName" autocomplete="new-password"
            placeholder="{{'EnterUserName' | translate}}" name="roomPassTextbox" />

        <input type="text" #roomPass autofocus class="textbox roomPassWordTextBox dialog-input" id="roomPassTextbox"
        [(ngModel)]="email" autocomplete="new-password"
        placeholder="{{'EnterEmail' | translate}}" name="roomPassTextbox" />

        <input type="text" #roomPass autofocus class="textbox roomPassWordTextBox dialog-input" id="roomPassTextbox"
        [(ngModel)]="telefon" autocomplete="new-password"
        placeholder="{{'EnterPhone' | translate}}" name="roomPassTextbox" />
    </div>

    <p-footer>
        <button pButton (click)="userNameControl()" label="{{'OkButtonText' | translate}}"
            class="ui-button-raised ui-button-info popup"></button>
        <button pButton (click)="close()" label="{{'CancelButtonText' | translate}}"
            class="ui-button-raised ui-button-danger popup"></button>
    </p-footer>
</p-dialog>