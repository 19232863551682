<p-dialog class="updateStateMessage" [(visible)]="panelManagerService.updateUserNamePopupDisplay" [modal]="true"
[baseZIndex]="10000" [responsive]="true" header="{{'update-user-name' | translate }}">

    <div style="text-align:center;">
        <input type="text" autofocus="autofocus" [(ngModel)]="newUserName" placeholder="{{'update-user-name' | translate }}" 
            pInputText class="inputText dialog-input" />
    </div>

    <p-footer>
        <button pButton type="button"  (click)="updateUserName()" label="{{'save' | translate}}"
            class="ui-button-raised ui-button-info popup" style="width: 100%;"></button>
    </p-footer>

</p-dialog>
