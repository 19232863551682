import { EnvironmentVariableService } from './../../services/environment-variable.service';
import { Component, Inject, ViewEncapsulation, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { IndexedDBService } from '../../services/indexed-db.service';
import { DeviceService } from '../../services/device.service';
import * as Bowser from "bowser";
import { LanguageService } from '../../services/language.service';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import jwt_decode from "jwt-decode";
import { DecodeJWT, UpdateUserNameResponse } from '../../models/user';
import { UtilService } from '../../services/util.service';
import { faArrowDown, faChevronDown, faInfoCircle, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { MessagePanelService } from '../../services/messagePanel.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from '../../services/local-storage.service';
import { Language } from '../../models/language';
import { Constants } from '../../models/constants';
@Component({
    selector: 'app-public',
    templateUrl: './public.component.html',
    styleUrls: ['./public.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})

export class PublicComponent implements OnInit {
    loaded: boolean = false;
    loadedTempPage: boolean = false;

    faGoogle = faGoogle
    faFacebook = faFacebook
    faChevronDown = faChevronDown;
    faInfoCircle = faInfoCircle;
    faGuest = faUserTie;
    apiUrl = `${environment.apiUrl}`
    selectedLanguage: Language = new Language('tr', 'turkish', 'Turkish');
    logoLoaded: boolean = false;
    token: string = "";
    logo_128: string = "";
    logo_800:string = "";
    load: Promise<boolean>;
    appName:string = "";
    CanGuestJoin: boolean = true;
    roomId:number 
    siteDescription:string = ""
    defaultLanguage:string = ""
    usernameForm = this.fb.group({
        userName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(13)]]
    });
    dropdownOpen = false;

    @ViewChild('topElement') topElement: ElementRef;
    constructor(
        @Inject(DOCUMENT) private document: Document,
        public panelManagerService: PanelManagerService,
        public indexedDBService: IndexedDBService,
        public deviceService: DeviceService,
        public languageService: LanguageService,
        private router: Router,
        private loginService: LoginService,
        public utilService: UtilService,
        private messagePanelService: MessagePanelService,
        private fb: UntypedFormBuilder,
        private route: ActivatedRoute,
        private storageService: LocalStorageService,
        private environmentVariableService:EnvironmentVariableService
    ) {
        this.callLogoAndAppName();   
        const browser = Bowser.getParser(window.navigator.userAgent);
        let browserInfo = browser.getBrowser();

        if (this.indexedDBService.SupportedBrowsers.includes(browserInfo.name)) {
            this.loaded = true;
            this.loadedTempPage = false;
            return;
        }
        else {
            this.loadedTempPage = true;
            this.loaded = false;
        }

    }

    ngOnInit(): void {
        this.setSelectedLanguage();
        this.checkUserIsExist();
    }

    @HostListener('document:click', ['$event'])
    onClickOutside(event: MouseEvent): void {
        const dropdownElement = event.target as HTMLElement;
        const topElement = this.topElement.nativeElement;
        const dropdownMenu = topElement.querySelector('.dropdown-menu');
        const dropdownButton = topElement.querySelector('.dropdown-button');

        if (dropdownMenu && dropdownButton && !dropdownMenu.contains(dropdownElement) && !dropdownButton.contains(dropdownElement)) {
        this.dropdownOpen = false;
        }
    }
    
    callLogoAndAppName(){
        this.environmentVariableService.callEnvironmentVariableWithoutSource().then(res=>{
            this.logo_128 = res.LogoL;
            this.logo_800 = res.LogoXL;
            this.appName=res.UygulamaAdi
            this.CanGuestJoin = res.CanGuestJoin
            this.siteDescription = res.Description
            this.defaultLanguage = res.DefaultLanguage
            this.setDefaultLanguageFromEnvVariable()
            this.load = Promise.resolve(true);
        },err=>{
            this.setDefaultLanguageFromEnvVariable()
            this.load = Promise.resolve(true);
        }); 
    }

    setDefaultLanguageFromEnvVariable(){
        let language = this.languageService.getCurrentLanguage();
        if (!language) {
            if (this.defaultLanguage != '') {
                this.languageService.changeLanguage(this.languageService.languages.find(element => element.value === this.defaultLanguage))
                this.selectedLanguage = this.languageService.languages.find(element => element.label === this.defaultLanguage)
            }
            else{
                this.selectedLanguage = this.languageService.languages.find(element => element.key ===  'tr')
                this.languageService.changeLanguage(this.languageService.languages.find(element => element.value === this.selectedLanguage.value))
            }
        } else {
            this.selectedLanguage = this.languageService.languages.find(element => element.key === language.key)
        }
    }

    ngOnDestroy(): void {
        this.setSelectedLanguage();
    }
    signInwithGoogle() {
        this.loginService.googleSignIn();
    }

    signInwithFacebook() {
        let url = this.apiUrl + "facebook/login";
        this.document.location.href = url;
        this.checkUserIsExist();
    }
    signAsGuest(){
        this.loginService.callGuestLogin();
    }

    checkUserIsExist() {
        if (this.token) {
            localStorage.setItem('token', this.token);
            let decodedJWT: DecodeJWT = this.decodeJWT();
            if (!decodedJWT.isNewLogin) { // user is exist
                this.login();
            } else { // user is not exist
                this.panelManagerService.newUserRegisterPopupDisplay = true;
            }
        }
    }
    login() {
        console.log(`public.component.ts login worked`)
        this.loginService.login()
    }

    openHelpLink() {
        window.open('https://www.seslidunya.com/yardim-merkezi/', '_blank');
    }
    checkUsername() {
        if (this.usernameForm.valid) {
            this.updateUsername();
        }
        else if (!this.usernameForm.get('userName').valid) {
            this.messagePanelService.ShowPopupMessageWithLocalization('UserNameEmptyWarning', 2000);
            return;
        }
        else {
            this.messagePanelService.ShowPopupMessageWithLocalization('invalidForm', 2000);
            return;
        }
    }

    updateUsername() {
        this.loginService.updateUsername({ 'UserName': this.usernameForm.controls['userName'].value })
            .subscribe((updateUserNameRes: UpdateUserNameResponse) => {
                if (updateUserNameRes.UserNameExist) {
                    this.messagePanelService.ShowPopupMessageWithLocalization('user-name-already-exist', 2000);
                    return
                }
                if (updateUserNameRes.Success) {
                    this.messagePanelService.ShowPopupMessageWithLocalization('SavedSuccess', 2000)
                    this.panelManagerService.newUserRegisterPopupDisplay = false;
                    this.token = updateUserNameRes.Token;
                    //NOTE: fix for tab close
                    // localStorage.setItem('token', this.token);
                    this.storageService.storageSetItem('token', this.token)
                    this.login();
                }
                else {
                    this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
                    return;
                }
            })
    }

    setSelectedLanguage() {
        let language = this.languageService.getCurrentLanguage();
          if (!language) {
          this.selectedLanguage = this.languageService.languages.find(element => element.key === 'tr');
        } else {
          this.selectedLanguage = this.languageService.languages.find(element => element.key === language.key);
        }
      }

    handleChange() {
        this.languageService.changeLanguage(this.languageService.languages.find(element => element.value === this.selectedLanguage.value))
    }

    decodeJWT(): DecodeJWT {
        return jwt_decode(this.token);
    }

    getLanguageFlagUrl(lang: Language): string {
        const awsUrl = Constants.S3AccountCentralUrl;
        return awsUrl + `themes/SesliTurkiye/Language_${lang.key}_24x24.png`; 
    }

    toggleDropdown() {
        this.dropdownOpen = !this.dropdownOpen;
    }

    selectLanguage(language: Language) {
        this.selectedLanguage = language;
        this.languageService.selectedLanguage = this.languageService.languages.find(element => element.label === this.selectedLanguage.label)?.value;
        this.dropdownOpen = false;
        this.handleChange();
    }


    
}