import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { RoomService } from 'src/app/core/services/room.service';
import { UtilService } from 'src/app/core/services/util.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
@Component({
  selector: 'app-room-password-entry',
  templateUrl: './password-entry.component.html',
  styleUrls: ['./password-entry.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class RoomPasswordEntryComponent implements OnInit {
  roomPassword: string = '';
  @ViewChild("roomPass") roomPass: ElementRef<HTMLElement>;


  constructor(
    private roomService: RoomService,
    private utilService: UtilService,
    private messagePanelService: MessagePanelService,
    public panelManagerService: PanelManagerService
  ) {

  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.roomPass.nativeElement.focus();
  }

  passwordControl() {
    if (this.utilService.isNullOrEmtpyString(this.roomPassword)) {
      this.messagePanelService.ShowPopupMessageWithLocalization('DontBlankWarning', 2000);
      return;
    }
    else {
      this.callGetRoomPassToken();
    }
  }

  callGetRoomPassToken() {
    this.roomService.getRoomPassToken(this.roomService.beforeEnterRoomData.ID, this.roomPassword)
      .then((response: boolean) => {
        // if (!this.utilService.isNullOrEmtpyString(response)) {
        if (response === true) {
          // this.roomService.beforeEnterRoomData.SIFRE = response;
          this.successGetRoomPassToken();
        } else
          this.errorGetRoomPassToken();
      })
      .catch(error => {
        console.log(error);
      })
  }

  successGetRoomPassToken() {
    this.panelManagerService.roomPasswordPanelIsOpened = false;
    this.panelManagerService.roomPasswordPanelResponseSource.next(true);
  }

  errorGetRoomPassToken() {
    this.messagePanelService.ShowPopupMessageWithLocalization("PasswordNotValid", 2000);
    this.close();
  }

  close() {
    this.panelManagerService.roomPasswordPanelIsOpened = false;
  }
}