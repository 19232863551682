import { Injectable } from '@angular/core';
import { UpdateUserResponse, UserFullInfo, UserV2 } from '../models/user';
import { UtilService } from './util.service';
import { HttpClient } from '@angular/common/http';
import { ServiceInformation } from './serviceinformation.service';
import { MessagePanelService } from './messagePanel.service';
import { catchError } from 'rxjs/operators';
import { IndexedDBService } from './indexed-db.service';
import { Observable, of, Subject } from 'rxjs';
import { Constants, Image } from '../models/constants';
import { PanelManagerService } from './panel-manager.service';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class UserService extends ServiceInformation {
    userUpdatedSource: Subject<UserFullInfo> = new Subject();
    userUpdated = this.userUpdatedSource.asObservable();
    apiUrl = `${environment.apiUrl}`;
    currentUser: UserV2
    imageChangedEvent: any;

    constructor(
        public utilService: UtilService,
        private http: HttpClient,
        public messagePanelService: MessagePanelService,
        public indexedDBService: IndexedDBService,
        private panelManagerService: PanelManagerService
    ) {
        super(messagePanelService, utilService, indexedDBService);
        this.indexedDBService.userFullInfo = new UserFullInfo();
        // this.getCurrentUser();
    }

    getUserPhoto(userPhotoId: string, isBigPhoto: boolean = false) {
        if (this.utilService.isNullOrEmtpyString(userPhotoId) || userPhotoId === Constants.guidEmpty)
            return isBigPhoto ? Constants.defaultBigUserPhotoUrl : Constants.defaultSmallUserPhotoUrl;
        else
            return Constants.userPhotoPath + userPhotoId.toString().toLowerCase() + (isBigPhoto ? "_b" : "") + Constants.userImageIconExtension;
    }


    getCurrentUser(): Observable<any> {
        let newPath = this.apiUrl + "api/user/currentuser"
        return this.http.get<UserV2>(newPath);
    }

    getUser(userId: number): Observable<UserFullInfo> {
        let newPath = this.apiUrl + `api/user/info/${userId}`;
        return this.http.get<UserFullInfo>(newPath);
    }

    callSaveUserImageServer(body): Promise<any> {
        return this.saveUserImageServer(body)
            .toPromise()
            .then((response) => {
                if (response) {
                    if (response.Result) {
                        return response.UserImageId;
                    }
                    else {
                        throw ''
                    }
                }
                throw '';
            })
            .then((userImageId) => {
                this.indexedDBService.userFullInfo.ProfilResmiId = userImageId;
                this.indexedDBService.updateUser(this.indexedDBService.userFullInfo);
                this.userUpdatedSource.next(this.indexedDBService.userFullInfo);
            })
            .then(() => {
                this.messagePanelService.ShowPopupMessageWithLocalization("successOperation", 2000);
                this.panelManagerService.imageCropperPopupDisplay = false;
                this.panelManagerService.imageCapturePopupDisplay = false;
                this.imageChangedEvent = "";
            })
            .catch((error) => {
                this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
                console.log(error);
            });
    }

    public saveUserImageServer(body): Observable<any> {
        body['ClientInfo'] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + 'api/user/image/save';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    callUpdateUserState(stateId): Promise<void> {

        let body = {
            'ClientInfo': null,
            'StateId': stateId,
            'UserId': +this.indexedDBService.userFullInfo.KullaniciId
        };
        return new Promise((resolve, reject) => {
            this.updateUserState(body)
                .subscribe(response => {
                    if (response) {
                        resolve();
                    }
                    else
                        reject();
                },
                    error => {
                        this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
                    });
        })

    }

    callUpdateUserName(userName:string): Promise<void> {
        let body = {'UserName': userName};
        return new Promise((resolve, reject) => {
            this.updateUserName(body)
                .subscribe((response:UpdateUserResponse) => {
                    if (response.UserNameExist) {
                        this.messagePanelService.ShowPopupMessageWithLocalization('user-name-already-exist', 2000);
                        return
                    }
                    if (response.Success) {
                        this.messagePanelService.ShowPopupMessageWithLocalization('update-user-name-success', 2000);
                        resolve();
                    }
                    else
                        reject();
                },
                    error => {
                        this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
                    });
        })

    }

    callAccountDeletionRequest(deleteReason:string): Promise<void> {
        let body = {'UserId': this.indexedDBService.userFullInfo.KullaniciId,
                    'DeleteReason':deleteReason};
        return new Promise((resolve, reject) => {
            this.createAccountDeletionRequest(body)
                .subscribe((response:any) => {
                    if (response.ErrMsg != "") {
                        this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000)
                        return
                    }
                    if (response.Result == true) {
                        resolve();
                    }
                    if (response.Result == false) {
                        reject();
                    }
                },
                    error => {
                        this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
                    });
        })
    }
    
    //#region rest service operations
    createAccountDeletionRequest(body): Observable<any>{
        const endpoint = this.ServiceUrl + "api/user/request/delete";
        return this.http.post<any>(endpoint, body, this.options)
        .pipe(catchError(error => this.handleError(error)));
    }

    updateUserState(body): Observable<any> {
        body["ClientInfo"] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + "api/user/state/update";
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    updateUserName(body):Observable<UpdateUserResponse>{
        const endpoint = this.ServiceUrl + "api/user/changeUsername";
        return this.http.post<any>(endpoint, body, this.options)
        .pipe(catchError(error => this.handleError(error)));
    }

    updateUser(body): Observable<any> {
        body["ClientInfo"] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + "UpdateUser";
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    getUserForMe(body): Observable<any> {
        body["ClientInfo"] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + "api/user/get";
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    saveUserStateMessage(body): Observable<any> {
        body["ClientInfo"] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + "SaveUserStateMessage";
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    sendSmsForPhoneConfirmation(body): Observable<any> {
        body["ClientInfo"] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + "SendSmsForPhoneConfirmation";
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));

        // return of({ 'Result': true, 'ErrMsg': '' });
    }

    deleteAccount(): Observable<any> {
        let newPath = this.apiUrl + `api/account/delete`;
        return this.http.delete<any>(newPath);
    }

    giveUpRank(): Observable<boolean> {
        const endpoint = `${this.apiUrl}api/user/rank/giveup`;
        return this.http.post<boolean>(endpoint, null);
    }

    //#endregion
}