import { Component } from '@angular/core';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { RoomService } from 'src/app/core/services/room.service';

@Component({
  selector: 'app-phone-calling',
  templateUrl: './phone-calling.component.html',
  styleUrls: ['./phone-calling.component.scss']
})
export class PhoneCallingComponent {
  phoneNumber: string = "";

  constructor(
    public panelManagerService: PanelManagerService,
    public roomService: RoomService,
    public messagePanelService: MessagePanelService,
  ) { }

  startCalling() {
    if (this.isValidPhoneNumber(this.phoneNumber)) {
      this.roomService.startCallPhoneNumber(this.phoneNumber);
    } else {
      this.messagePanelService.ShowPopupMessageWithLocalization('invalid-phone-number-format', 3000);
    }
  }
  
  isValidPhoneNumber(phoneNumber: string): boolean {
    const regex = /^009\d{11}$/;
    return regex.test(phoneNumber);
}

}
