import { Component, OnInit } from '@angular/core';
import { PanelManagerService } from '../../services/panel-manager.service';
import { faAngleDown, faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FriendListService } from '../../services/friendlist.service';
import { UtilService } from '../../services/util.service';
import { IndexedDBService } from '../../services/indexed-db.service';
import { FriendshipService } from '../../services/friendship.service';
import { ChatService } from '../../services/chat.service';
import { DeviceService } from '../../services/device.service';
import { Friend } from '../../models/user';

@Component({
  selector: 'app-incoming-messages-list',
  templateUrl: './incoming-messages-list.component.html',
  styleUrls: ['./incoming-messages-list.component.scss']
})
export class IncomingMessagesListComponent implements OnInit {
  faTimes = faTimes;
  faArrowLeft = faArrowLeft;
  faAngleDown = faAngleDown;
  constructor(public panelManagerService:PanelManagerService,
    public friendListService: FriendListService,
    private utilService: UtilService,
    public indexedDBService: IndexedDBService,
    private friendshipService: FriendshipService,
    private chatService: ChatService,
    public deviceService: DeviceService
  ) { }


  ngOnInit(): void {
  }

  clickOnFriend(friend: Friend) {
      if (this.panelManagerService.showedIncommingAnotherUserMessagePanel) {
        this.panelManagerService.showedIncommingAnotherUserMessagePanel = false;
      }

      if (!this.panelManagerService.showedP2p) {
        this.panelManagerService.showedP2p = true;
      }

      this.panelManagerService.openP2PChatPanel(friend.KullaniciId, this.indexedDBService.getFriendById(friend.KullaniciId));
  }

}
