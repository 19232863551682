import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';


import { MessagePanelService } from 'src/app/core/services/messagePanel.service';

declare function require(name: string);
enum DialogStatus {
  onShow,
  onHide,
  onCancel,
  onAllow
}

enum DeviceType {
  microphone,
  camera,
  microphoneAndCamera,
  screen
}

@Component({
  selector: 'app-media-device-access-modal',
  templateUrl: './media-device-access-modal.component.html',
  styleUrls: ['./media-device-access-modal.component.css']
})
export class MediaDeviceAccessModalComponent implements OnInit, OnChanges {

  @Input() isVisible: boolean
  @Output() isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  deviceType: DeviceType
  @Input() autoCheck: boolean;
  @Input() header: string

  @Input() message: string
  @Output() onAllow: EventEmitter<any> = new EventEmitter<any>()
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>()
  @Output() onHide: EventEmitter<any> = new EventEmitter<any>()

  constraints: Object;

  DeviceManager = require("src/app/core/extensions/devicemanager/deviceManager.js");

  media;
  status: DialogStatus

  constructor(
    private messagePanelService: MessagePanelService
  ) {

    this.initConstraints();
  }

  ngOnInit() {  
    this.deviceType = DeviceType.microphoneAndCamera;
    this.message = "Checking your Camera and Microphone access, please wait...";
  }

  ngOnChanges(changes: SimpleChanges) {
    let isVisible: SimpleChange = changes["isVisible"];

    if (isVisible && isVisible.previousValue === false && isVisible.currentValue === true) {
      if (this.autoCheck === true) {
        this.onDialogAllow();
        setTimeout(() => {
          if (this.isVisible === true)
            this.message = "You must allow camera and microphone access to broadcast";
        }, 2000);

      }

    }
  }

  initConstraints() {
    this.constraints = {};
    this.constraints[DeviceType.microphoneAndCamera] = {
      audio: true,
      video: true
    }

    this.constraints[DeviceType.microphone] = {
      audio: true,
      video: false
    }

    this.constraints[DeviceType.camera] = {
      audio: false,
      video: true
    }

    this.constraints[DeviceType.screen] = {
      video: true,
      audio: true
    }

  }
  setVisibility(isVisible: false) {
    this.isVisible = isVisible
    this.isVisibleChange.emit(isVisible)
  }

  onDialogShow() {
    this.status = DialogStatus.onShow
  }

  onDialogHide() {
    this.setVisibility(false)
    if (this.status === DialogStatus.onShow) {
      this.onHide.emit(null)
    }
    this.status = DialogStatus.onHide
  }

  onDialogAllow() {



    if (this.deviceType === DeviceType.microphoneAndCamera) {

      this.DeviceManager.accessCameraMicrophone(this.constraints[DeviceType.microphoneAndCamera])
        .then((stream) => {
          this.isVisible = false;
          this.status = DialogStatus.onAllow
          this.onAllow.emit(null)
        })
        .catch(err => {
          if (err === this.DeviceManager.notSupportedDevice) {
            this.messagePanelService.ShowPopupMessageWithLocalization("Microphone / Camera not supported", 2000);      
            this.onDialogCancel();
          } else {
            this.message = "Microphone / Camera blocked! please check device access on your browser settings";
          }
        });
    }
  }


  onDialogCancel() {
    this.status = DialogStatus.onCancel
    this.setVisibility(false)
    this.onCancel.emit(null)
  }

}
