<p-dialog [(visible)]="panelManagerService.permissionsDisplay" [modal]="true" [baseZIndex]="10000" [responsive]="true"
  [closable]="true" [closeOnEscape]="false" header="{{ 'camAndMicPermissionTitle' | translate }}">

  <div> {{ 'camAndMicPermission' | translate }} </div>

  <div *ngIf="false"> {{ 'batteryPermission' | translate }} </div>

  <p-footer *ngIf="false">
    <button type="button" pButton label="{{ 'goOn' | translate }}"
      class="ui-button-rounded ui-button-success ui-button-raised"></button>


    <button class="ui-button-rounded ui-button-success ui-button-raised" 
    type="button" pButton label="{{ 'panelback' | translate }}">
    </button>

    <button class="ui-button-rounded ui-button-success ui-button-raised" 
    label="{{ 'allow' | translate }}" type="button" pButton>
    </button>
  </p-footer>
</p-dialog>