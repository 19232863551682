<p-dialog styleClass="confirmation-dialog" [(visible)]="params.display" [modal]="true"
    [responsive]="true" [style]="params.style" [minY]="70" [maximizable]="false"
    [baseZIndex]="10000" class="confirmationDialog">
    <p-header [ngStyle]="params.headerStyle">
        {{params.title}}
    </p-header>
    <p>{{params.message}}</p>
    <p-footer>
        <button type="button" pButton (click)="okClick()" label="{{params.acceptLabel}}"></button>
        <button type="button" pButton (click)="cancelClick()" label="{{params.rejectLabel}}"
            class="ui-button-danger"></button>
    </p-footer>
</p-dialog>