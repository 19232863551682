

export const ConnectionOptions = {
    simulcast: true,
    dynacast : true,
    forceTurn : false,
    adaptiveStream : true,
    publishOption : false,
    autoSubscribe : true,
    e2eeEnabled : false,
    e2ee : false,
}
