import { Component, OnDestroy, OnInit } from '@angular/core';
import { PanelManagerService } from '../../services/panel-manager.service';
import { Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnvironmentVariableService } from '../../services/environment-variable.service';
@Component({
  selector: 'app-usage-agreement-page',
  templateUrl: './usage-agreement-page.component.html',
  styleUrls: ['./usage-agreement-page.component.scss']
})
export class UsageAgreementPageComponent implements OnInit, OnDestroy {
  htmlContent: string;
  envVariable: Subscription

  constructor(public panelManagerService: PanelManagerService,
    private http: HttpClient,
    private environmentVariableService: EnvironmentVariableService) {

    this.envVariable = this.environmentVariableService.sentEnvironmentVariable.subscribe(res => {
      this.setHtmlContentWithLink(res.KullanimSozlesmesi)
    })
  }

  ngOnInit(): void {
  }
  
  setHtmlContentWithLink(link: string) {
    this.environmentVariableService.getHtmlElementByLink(link).subscribe(
      (content: string) => {
        this.htmlContent = content;
      }
    )
  }
  ngOnDestroy(): void {
    if (this.envVariable) {
      this.envVariable.unsubscribe();
    }
  }
}
