import { Component, OnInit } from '@angular/core';
import { SikayetTipleri } from '../../models/enums';
import { ChatMessageService } from '../../services/chatMessage.service';
import { DynamicMethodsSendService } from '../../services/dynamic-methods-send.service';
import { IndexedDBService } from '../../services/indexed-db.service';
import { MessagePanelService } from '../../services/messagePanel.service';
import { PanelManagerService } from '../../services/panel-manager.service';
import { ReportService } from '../../services/report.service';
import { RoomService } from '../../services/room.service';
import { DynamicMethodsService } from '../../services/dynamic-methods.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  description: string = "";
  header: string = "";
  countDown: number = 150;

  constructor(
    public reportService: ReportService,
    public panelManagerService: PanelManagerService,
    private messagePanelService: MessagePanelService,
    private chatMessageService: ChatMessageService,
    private dynamicMethodsSendService: DynamicMethodsSendService,
      public indexedDBService: IndexedDBService,
      private roomService: RoomService,
      private dynamicMethodsService:DynamicMethodsService
  ) {

    this.reportService.insertComplainRequest.Description = "";
  }

  ngOnInit(): void {
    this.setHeader();
  }

  keyup() {
    this.countDown = 150 - this.description.length;
  }

  setHeader() {
    switch (this.reportService.insertComplainRequest.ComplainType) {
      case SikayetTipleri.Kullanici:
        this.header = "report-user";
        break;
      case SikayetTipleri.Oda:
        this.header = "report-room";
        this.reportService.insertComplainRequest.Description = this.reportService.header + ' \n';
        break;
      case SikayetTipleri.OdaMesaj:
        this.header = "report-message";
        break;
      case SikayetTipleri.P2pMesaj:
        this.header = "report-message";
        break;
      case SikayetTipleri.LiveSupport:
        this.reportService.insertComplainRequest.Description = this.roomService.currentRoom.Info.NAME + ' \n';
        break;
      default:
        this.header = "report";
        break;
    }
  }

  saveReport() {
    this.reportService.insertComplainRequest.Description += this.description;
    if(this.panelManagerService.isLiveSupport){
      this.reportService.insertComplainRequest.ComplainType = SikayetTipleri.LiveSupport;
      this.reportService.insertComplainRequest.ComplainedId = this.indexedDBService.userFullInfo.KullaniciId;
      this.reportService.insertComplainRequest.ComplainingId = this.indexedDBService.userFullInfo.KullaniciId;
    }

    this.panelManagerService.mainLoading = true;

    this.reportService.insertComplain()
      .subscribe((res) => {
        this.panelManagerService.mainLoading = false;
        if (res.Success) {
          if(!this.panelManagerService.isLiveSupport) {
            // this.chatMessageService.sendAdminBulkSettingMessageToPublicChannel([this.dynamicMethodsSendService.GetComplainNotification()]);
          
            let operationMessageForGetComplainNotification = this.dynamicMethodsService.prepareAdminBulkSettingMessage(
              [this.dynamicMethodsSendService.GetComplainNotification()]
            )
            this.dynamicMethodsService.publishDynGetComplainNotification(operationMessageForGetComplainNotification)

            this.messagePanelService.ShowPopupMessageWithLocalization('sent-report', 2000);
          }
          else{
            // this.chatMessageService.sendAdminBulkSettingMessageToPublicChannel([this.dynamicMethodsSendService.GetLiveSupportNotification()]);
          
            let operationMessageForGetLiveSupportNotification = this.dynamicMethodsService.prepareAdminBulkSettingMessage(
              [this.dynamicMethodsSendService.GetLiveSupportNotification()]
            )
            this.dynamicMethodsService.publishDynGetLiveSupportNotification(operationMessageForGetLiveSupportNotification)
          
            this.messagePanelService.ShowPopupMessageWithLocalization('SavedSuccess', 2000); //Canlı Destek Ekibimiz En Kısa Sürede Sizinle İletişime Geçecek !
          }
        }
        else {
          this.messagePanelService.ShowPopupMessageWithLocalization(res.ErrMsg, 2000);
        }
        this.reportService.reportPopupDisplay = false;
        this.panelManagerService.isLiveSupport = false;
      })
  }
}
