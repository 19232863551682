<div [attr.id]="videoPanelId" #videoPanel [rzMinWidth]="deviceService.isMobile?100:200"
    [rzMinHeight]="deviceService.isMobile?100:200" [rzHandles]="'s,e,se'" [ngDraggable]="!videoPanelIsMaximized"
    [ngResizable]="!videoPanelIsMaximized" [ngClass]="'videocontainer'" [inBounds]="true" [bounds]="roomPanelBounds"
    [class]="'videoContainer ' + (videoPanelIsMaximized?'videoContainerMaximized':'')"
    [style.display]="((deviceService.isMobile && panelManagerService.currentMobileActivePanelId === (constants.mainSwiperTag +''+ mainPanels.RoomPage)) || deviceService.isDesktop) && roomService.connectionProblem === false ?'block':'none'">
    <span class="videoContainerUserName" #userNameDiv>{{
        roomUserListService.userViewInRoom[roomUser?.KullaniciId]?.username }}</span>

    <fa-icon [icon]="faFlag" class="report-button" pTooltip="{{'report-user'|translate}}" tooltipPosition="top"
        (click)="complainUser()"></fa-icon>

    <fa-icon [icon]="faTimes" class="close-button" pTooltip="{{'close'|translate}}" tooltipPosition="top"
        (click)="stopCam()"></fa-icon>

    <fa-icon *ngIf="!videoPanelIsMaximized" [icon]="faExpandArrowsAlt" pTooltip="{{'maximize'|translate}}"
        tooltipPosition="top" class="maximize-button" (click)="videoPanelIsMaximized=true"></fa-icon>

    <fa-icon *ngIf="videoPanelIsMaximized" [icon]="faCompressArrowsAlt" pTooltip="{{'minimize'|translate}}"
        tooltipPosition="top" class="minimize-button" (click)="videoPanelIsMaximized=false"></fa-icon>


    <!-- <div class="roomVideoControl" #videoContainer></div> -->
    <video [attr.id]="videoContainerId" autoplay="true" #videoContainer class="roomVideoControl"></video>
    <div class="roomVideoWatcherListDiv">
        <p-scrollPanel [style]="{width: '100%', height: '100%'}">
            <div *ngFor="let roomCameraWatcher of roomCameraWatchers" style="color:white; padding:5px">
                {{roomCameraWatcher.userName}}
            </div>
        </p-scrollPanel>
    </div>

    <!-- <div [style.display]="panelManagerService.cameraLoading?'block':'none'" class="main-loading">
        <p-progressSpinner [style]="{width: '70px', height: '70px'}" strokeWidth="3" fill="#EEEEEE"
            animationDuration=".5s">
        </p-progressSpinner>
    </div> -->

    <div *ngIf="roomCamera.userId === indexedDBService.userFullInfo.KullaniciId">
        <select id="video-input" class="custom-select" 
                (change)="livekitService.handleDeviceSelected($event.target.value, 'videoinput')"
                [ngModel]="livekitService.selectedVideoDeviceId">
            <option *ngFor="let device of livekitService.videoDevices" [value]="device.deviceId">
                {{ device.label }}
            </option>
        </select>
    </div>
    

</div>