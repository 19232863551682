import { environment } from './../../../environments/environment';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { error } from 'console';
import { promise } from 'protractor';
import { Subject } from 'rxjs';
import { EnvironmentVariable } from '../models/environmentVariables';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentVariableService {

  apiUrl = `${environment.apiUrl}`

  private httpClientEnv: HttpClient;

  sentEnvironmentVariableSource: Subject<EnvironmentVariable> = new Subject();
  sentEnvironmentVariable = this.sentEnvironmentVariableSource.asObservable();

  constructor(private http: HttpClient,
    private handler: HttpBackend) { 
      this.httpClientEnv = new HttpClient(this.handler);
    }


  getEnvironmentVariableByDomainName(domainName: string) {
    let body = { "AlanAdi": domainName }
    return this.http.post<EnvironmentVariable>(this.apiUrl + 'GetOrtamDegiskenleriPublic', body)
  }

  getHtmlElementByLink(link: string) {
    return this.httpClientEnv.get(link,{responseType:'text'})
  }

  callEnvironmentVariable(): Promise<EnvironmentVariable> {
    return new Promise((resolve, reject) => {
      let domainName = window.location.hostname;
      if (domainName.includes('www.')) {
        domainName = domainName.replace('www.', '');
      }
      this.getEnvironmentVariableByDomainName(domainName).subscribe(
        (res: EnvironmentVariable) => {
          if (res) {
            const baseUrl = `https://${res.BucketName}.s3.eu-central-1.amazonaws.com`;
  
            if (res.LogoS) res.LogoS = `${baseUrl}${res.LogoS}`;
            if (res.LogoM) res.LogoM = `${baseUrl}${res.LogoM}`;
            if (res.LogoL) res.LogoL = `${baseUrl}${res.LogoL}`;
            if (res.LogoXL) res.LogoXL = `${baseUrl}${res.LogoXL}`;
            if (res.BizKimiz) res.BizKimiz = `${baseUrl}${res.BizKimiz}`;
            if (res.YasalUyari) res.YasalUyari = `${baseUrl}${res.YasalUyari}`;
            if (res.KullanimSozlesmesi) res.KullanimSozlesmesi = `${baseUrl}${res.KullanimSozlesmesi}`;
            if (res.ButtonBackImageID) res.ButtonBackImageID = `${baseUrl}${res.ButtonBackImageID}`;
            //capacitor url
            this.sentEnvironmentVariableSource.next(res); 
            resolve(res);
          }
        },
        (error) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  callEnvironmentVariableWithoutSource(): Promise<EnvironmentVariable>{
    return new Promise((resolve, reject) => {
      let domainName = window.location.hostname;
      if (domainName.includes('www.')) {
        domainName = domainName.replace('www.', '');
      }
      this.getEnvironmentVariableByDomainName(domainName).subscribe(
        (res: EnvironmentVariable) => {
          if (res) {
            const baseUrl = `https://${res.BucketName}.s3.eu-central-1.amazonaws.com`;
  
            if (res.LogoS) res.LogoS = `${baseUrl}${res.LogoS}`;
            if (res.LogoM) res.LogoM = `${baseUrl}${res.LogoM}`;
            if (res.LogoL) res.LogoL = `${baseUrl}${res.LogoL}`;
            if (res.LogoXL) res.LogoXL = `${baseUrl}${res.LogoXL}`;
            if (res.BizKimiz) res.BizKimiz = `${baseUrl}${res.BizKimiz}`;
            if (res.YasalUyari) res.YasalUyari = `${baseUrl}${res.YasalUyari}`;
            if (res.KullanimSozlesmesi) res.KullanimSozlesmesi = `${baseUrl}${res.KullanimSozlesmesi}`;
            if (res.ButtonBackImageID) res.ButtonBackImageID = `${baseUrl}${res.ButtonBackImageID}`;
            //capacitor url
            resolve(res);
          }
        },
        (error) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }
  
  
  
}
