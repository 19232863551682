import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnvironmentVariableService } from '../../services/environment-variable.service';

@Component({
  selector: 'app-who-are-we-page',
  templateUrl: './who-are-we-page.component.html',
  styleUrls: ['./who-are-we-page.component.scss']
})
export class WhoAreWePageComponent implements OnDestroy {
  htmlContent: string;
  envVariable: Subscription

  constructor(public panelManagerService: PanelManagerService,
    public environmentVariableService: EnvironmentVariableService,
    private http: HttpClient) {

    this.envVariable = this.environmentVariableService.sentEnvironmentVariable.subscribe(res => {
      this.setHtmlContentWithLink(res.BizKimiz)
    })
  }

  ngOnInit(): void {
  }
  setHtmlContentWithLink(link: string) {
    this.environmentVariableService.getHtmlElementByLink(link).subscribe(
      (content: string) => {
        this.htmlContent = content;
      }
    )
  }

  ngOnDestroy(): void {
    if (this.envVariable) {
      this.envVariable.unsubscribe();
    }
  }
}
