<context-menu #roomEditorContextMenuClick [menuClass]="'room-contextmenu'" [useBootstrap4]="true">
  <ng-template contextMenuItem let-item [visible]="isThereRankForOpenAdmin === true" (execute)="openAdminPanel()">
    <div class="contextMenuItem">
      <fa-icon [icon]="faUserTie" class="menuImgModal admin-option"></fa-icon>
      <span class="contextMenuItemText admin-option"> {{ 'admin' | translate | uppercase }} </span>
    </div>
  </ng-template>

  <ng-template contextMenuItem let-item (execute)="changeFont()">
    <div class="contextMenuItem">
      <fa-icon [icon]="faPalette" class="menuImgModal"></fa-icon>
      <span class="contextMenuItemText"> {{ 'menu_changefont' | translate }} </span>
    </div>
  </ng-template>

  <ng-template contextMenuItem let-item (execute)="clearRoomChatPanel()">
    <div class="contextMenuItem">
      <fa-icon [icon]="faBroom" class="menuImgModal"></fa-icon>
      <span class="contextMenuItemText"> {{ 'Prop_ClearRoomChatPanel' | translate }} </span>
    </div>
  </ng-template>

  <ng-template contextMenuItem let-item
    [visible]="indexedDBService.userFullInfo && indexedDBService.userFullInfo?.RutbeFullInfo &&  roomService.currentRoom && roomService.currentRoom.Info && indexedDBService.userFullInfo?.RutbeFullInfo.YAZILARI_SIL === true && roomService.currentRoom.Info?.YAZILARI_SIL === false"
    (execute)="clearChatPanelMessageAreaForAllUsers()">
    <div class="contextMenuItem">
      <fa-icon [icon]="faBroom" class="menuImgModal"></fa-icon>
      <span class="contextMenuItemText"> {{ 'Prop_ClearRoomChatPanel1' | translate }} </span>
    </div>
  </ng-template>

  <ng-template contextMenuItem let-item [visible]="indexedDBService.userFullInfo?.RutbeFullInfo?.GENEL_MESAJ"
    (execute)="sendMessageToAllUsers()">
    <div class="contextMenuItem">
      <fa-icon [icon]="faPencilAlt" class="menuImgModal"></fa-icon>
      <span class="contextMenuItemText"> {{ 'GenelHerkes' | translate }} </span>
    </div>
  </ng-template>

  <ng-template contextMenuItem let-item [visible]="indexedDBService.userFullInfo?.RutbeFullInfo?.GENEL_MESAJ"
    (execute)="sendMessageToAllAuthorizedUsers()">
    <div class="contextMenuItem">
      <fa-icon [icon]="faPencilAlt" class="menuImgModal"></fa-icon>
      <span class="contextMenuItemText"> {{ 'GenelAdmin' | translate }} </span>
    </div>
  </ng-template>

  <ng-template contextMenuItem let-item [visible]="true" (execute)="complainRoom()">
    <div class="contextMenuItem">
      <fa-icon [icon]="faFlag" class="menuImgModal"></fa-icon>
      <span class="contextMenuItemText"> {{ 'report-room' | translate }} </span>
    </div>
  </ng-template>

  <ng-template contextMenuItem let-item (execute)="logout()">
    <div class="contextMenuItem">
      <fa-icon [icon]="faSignOutAlt" class="menuImgModal"></fa-icon>
      <span class="contextMenuItemText"> {{ 'logoutsecurely' | translate }} </span>
    </div>
  </ng-template>

</context-menu>