import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DeviceService } from 'src/app/core/services/device.service';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { LoginService } from 'src/app/core/services/login.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { RoomService } from 'src/app/core/services/room.service';
import { UtilService } from 'src/app/core/services/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dynamic-room',
  templateUrl: './unregister-type-login.component.html',
  styleUrls: ['./unregister-type-login.component.scss']
})
export class UnRegisterTypeLogin implements OnInit,OnDestroy {

  apiUrl = `${environment.apiUrl}`
  templateId:number
  guid:string
  newRoomId:number

  userNameResponseSubscription: Subscription;


  constructor(
      @Inject(DOCUMENT) private document: Document,
      public panelManagerService: PanelManagerService,
      public indexedDBService: IndexedDBService,
      public deviceService: DeviceService,
      private loginService: LoginService,
      public utilService: UtilService,
      private route: ActivatedRoute,
      public storageService:LocalStorageService,
      public roomService:RoomService

  ) {
    
  }

  ngOnInit(): void {
   this.initCreateUserAndDynamicRoom()
  }

  initCreateUserAndDynamicRoom() {
    this.panelManagerService.aiUserNameEntryLoading=true;

    this.route.params.subscribe(params => {
      this.templateId = parseInt(params['templateId']);
      this.guid = params['guid'];
    });

    this.userNameEntryControl().then(()=>{
      return this.loginService.loginForDynamicRoom(this.templateId,this.guid);
    })
    .catch((error)=>{
      console.log(error)
    })
    .finally(()=>{
    })
  }

  checkTemplateIdAndGuidParameter(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.route.params.subscribe(params => {
        this.templateId = parseInt(params['templateId']);
        this.guid = params['guid'];
        if (this.templateId && this.guid) {
          resolve();
        } else {
          reject('templateId or guid is missing');
        }
      });
    });
  }

  userNameEntryControl(): Promise<string> {
    return new Promise((resolve, reject) => {
        this.panelManagerService.userNameEntryPanelIsOpened = true;
        if (this.userNameResponseSubscription)
            this.userNameResponseSubscription.unsubscribe();
        this.userNameResponseSubscription = this.panelManagerService.userNameEntryPanelResponse
            .subscribe(
                (response: any) => {
                    if (response != ""){
                      this.storageService.storageSetItem('token', response.token);
                      resolve(response);
                    }
                    else
                        reject("");
                }
            );
    });
  }

  
  ngOnDestroy(): void {
    if (this.userNameResponseSubscription) {
      this.userNameResponseSubscription.unsubscribe();
  }
  }
}
