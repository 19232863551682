import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FriendListService } from 'src/app/core/services/friendlist.service';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Friend } from 'src/app/core/models/user';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { FriendshipService } from 'src/app/core/services/friendship.service';

@Component({
  selector: 'app-show-blocked-friends',
  templateUrl: './show-blocked-friends.component.html',
  styleUrls: ['./show-blocked-friends.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class ShowBlockedFiendsComponent implements OnInit {

  constructor(
    private friendListService: FriendListService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    public indexedDBService: IndexedDBService,
    public panelManagerService: PanelManagerService,
    public friendshipService: FriendshipService
  ) { }

  ngOnInit() {
    this.indexedDBService.Friends.BlockedByMeFriends
  }

  removeUserBlock(friend: Friend) {
    this.confirmationService.confirm({
      message: '[' + friend.KullaniciAdi + '] ' + this.translateService.instant('BeforeUnblockUser'),
      header: this.translateService.instant('RemoveUserBlock'),
      icon: null,
      accept: () => {
        this.friendListService.callUnBlockUser(friend.KullaniciId, friend.KullaniciAdi);
      },
      reject: () => {

      }
    });
  }
}