<p-dialog [(visible)]="load" [modal]="true" [responsive]="true" header="{{ 'InvitedThisRoom' | translate}}"
    (onHide)="closeInviteToRoomPanel()" [focusOnShow]="false"
    [style.display]="panelManagerService.inviteToRoomPopupDisplay ? 'block' : 'none'">


    <div class="invitation-details">{{ 'InvitedBy' | translate }}
        <span> {{invitedName}}</span>
    </div>

    <div class="invitation-details">{{ 'InvitedRoom' | translate }}
        <span> {{roomName}}</span>
    </div>

    <!-- <div><small>{{ 'InvitedBy' | translate }}</small><span class="invitation-info"> {{invitedName}}</span></div>
    <div><small>{{ 'InvitedRoom' | translate }}</small><span class="invitation-info"> {{roomName}}</span></div> -->

    <p-footer [ngStyle]="{'float': 'right'}">
        <button (click)="accept()" pButton type="button" label="{{ 'acceptrequest' | translate }}"
            class="ui-button-raised ui-button-success"></button>
        <button (click)="closeInviteToRoomPanel()" pButton type="button" label="{{ 'rejectrequest' | translate }}"
            class="ui-button-raised ui-button-danger"></button>
    </p-footer>
</p-dialog>