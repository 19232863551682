<!-- (deviceService.isMobile?        
                (panelManagerService.currentMobileActivePanelId === (constants.mainSwiperTag +''+ mainPanels.P2PChat +'_' + activeChatPanel.chatPanelProps.UserId))
                :
                true)
             &&  -->
<div class="chat-panel" #chatPanel [ngDraggable]="deviceService.isMobile?false:true"
    [ngResizable]="deviceService.isMobile?false:true" [handle]="headerHandle" [rzHandles]="'n,e,s,w,se,sw,ne,nw'"
    [inBounds]="true" [bounds]="chatPanelBounds" [ngClass]="activeChatPanel.display ? 'showed-p2p' : 'hidden-p2p'"
    [style.z-index]="panelManagerService.focusedPanel===(constants.chatPanelTag + activeChatPanel.chatPanelProps.UserId)? panelManagerService.focusedZIndex:panelManagerService.unFocusedZIndex">
    <div class="header" #headerHandle>

        <!-- <fa-icon [icon]="faArrowLeft" class="header-12-1" 
            *ngIf="deviceService.isMobile && panelManagerService.roomPageDisplay">
        </fa-icon> -->

        <app-user-photo class="cursor header-12-1" [userPhotoId]="activeChatPanel.user?.ProfilResmiId"
            [stateId]="activeChatPanel.user?.StateId" [width]="46" [height]="46" [showOnlineStateIcon]="false"
            (click)="showProfilePicture()">
        </app-user-photo>


        <div class="header-1-2" [ngStyle]="{'grid-row': activeChatPanel.user?.DurumMesaj === '' ? '1/3' : '1/2' }">{{ activeChatPanel.user?.KullaniciAdi }}
            <app-rank [degree]="activeChatPanel.user?.RutbeDerece" [color]="'white'" [size]="10"></app-rank>
        </div>

        <div class="header-2-2" *ngIf="activeChatPanel.user?.DurumMesaj">{{ activeChatPanel.user?.DurumMesaj }}</div>

        <fa-icon [icon]="faMinus" class="header-1-3 cursor" *ngIf="!deviceService.isMobile" (click)="hideP2PChatPanel()">
        </fa-icon>

        <fa-icon [icon]="faTimes" class="header-1-4 cursor" (click)="closeP2PChatPanel(activeChatPanel.user?.KullaniciId)">
        </fa-icon>

        <fa-icon [icon]="faBars" class="header-2-4 cursor" (click)="menu.toggle($event)"></fa-icon>
        <p-menu #menu [popup]="true" [showTransitionOptions]="'250ms'" [hideTransitionOptions]="'250ms'"
            [model]="headerOptions"></p-menu>

    </div>

    <div class="content" [ngStyle]="{'background-color': isBackColor ? backgroundColor : 'unset'}">
        <img *ngIf="!isBackColor && backgroundImage" class="content-background-image" [src]="backgroundImage">
        <div class="chat">
            <app-p2p-chat-bubble [chatPanelProps]="activeChatPanel.chatPanelProps"></app-p2p-chat-bubble>
        </div>
        <div class="editor">
            <app-p2p-chat-editor [chatPanelProps]="activeChatPanel.chatPanelProps"></app-p2p-chat-editor>
        </div>
    </div>
</div>