import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilService } from 'src/app/core/services/util.service';
import { ServiceInformation } from './serviceinformation.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { StateIdList } from '../models/state';
import { PanelManagerService } from './panel-manager.service';
import { ChatMessageService } from './chatMessage.service';
import { DynamicMethodsSendService } from './dynamic-methods-send.service';
import { FriendList, UserCurrentRoomInfo } from '../models/user';
import { DynamicMethodsService } from './dynamic-methods.service';

@Injectable({
  providedIn: 'root'
})
export class FriendshipService extends ServiceInformation {
  channel:string = '';
  constructor(
    private http: HttpClient,
    public utilService: UtilService,
    public messagePanelService: MessagePanelService,
    protected indexedDBService: IndexedDBService,
    public panelManagerService: PanelManagerService,
    public chatMessageService: ChatMessageService,
    private dynamicMethodsSendService: DynamicMethodsSendService,
    private dynamicMethodsService:DynamicMethodsService
  ) { super(messagePanelService, utilService, indexedDBService); }


  //#region send friendship request operations
  public callSendFriendshipRequest(friendId: number): Promise<void> {
    let body = {
      'UserId': this.indexedDBService.userFullInfo.KullaniciId,
      'FriendId': friendId
    };
    return new Promise((resolve, reject) => {
      this.sendFriendshipRequest(body)
        .toPromise()
        .then(data => {
          if (data.Result === true)
            return this.successAddFriend(friendId);
          else
            return this.errorAddFriend(data);
        })
        .catch(error => {
          console.log(error);
          this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
        });
    });
  }

  sendFriendshipRequest(body): Observable<any> {
    const endpoint = this.ServiceUrl + 'api/friend/request';
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  getFriendCurrentRoomInfo(friendId: number): Observable<UserCurrentRoomInfo> {
    const body = {}
    const endpoint = this.ServiceUrl + 'api/friend/room/' + friendId;
    return this.http.post<UserCurrentRoomInfo>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  errorAddFriend(data) {
    if (!this.utilService.isNullOrUndefined(data.ErrMsg)) {
      this.messagePanelService.ShowPopupMessageWithLocalization(data.ErrMsg, 2000);
    }
    else {
      this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
    }
  }

  successAddFriend(friendId: number) {
    // this.chatMessageService.sendUserSettingMessage(friendId,
    //   [
    //     this.dynamicMethodsSendService.AddFriendAfterEvents(),
    //     this.dynamicMethodsSendService.RefreshFriendListForOneUser(this.indexedDBService.userFullInfo.KullaniciId, StateIdList.GelenIstek, false, false)
    //   ]
    // );
    
    let operationMessageForRefreshFriendListForOneUser = this.dynamicMethodsService.prepareUserSettingMessage(friendId, [ this.dynamicMethodsSendService.RefreshFriendListForOneUser(this.indexedDBService.userFullInfo.KullaniciId, StateIdList.GelenIstek, false, false)])
    let operationMessageForAddFriend = this.dynamicMethodsService.prepareUserSettingMessage(friendId, [this.dynamicMethodsSendService.AddFriendAfterEvents()])
    this.dynamicMethodsService.publishDynRefreshFriendListForOneUserP2PSetting(this.indexedDBService.userFullInfo.KullaniciId,friendId,operationMessageForRefreshFriendListForOneUser);
    this.dynamicMethodsService.publishDynAddFriendAfterEvents(this.indexedDBService.userFullInfo.KullaniciId,friendId,operationMessageForAddFriend);
    
    this.callGetFriendList(friendId);
  }

  callGetFriendList(friendId: number) {
    //TODO ARKADASI FRIENDLISTTEN BUL
    //return this.getFriend(friendId, StateIdList.GonderilenIstek)
    //  .then((friend: UserFullInfo) => {
    this.messagePanelService.ShowPopupMessageWithLocalization('FriendRequestSent', 2000);
    this.panelManagerService.addFriendPopupDisplay = false;
    this.indexedDBService.friendsChangedSource.next(friendId);
    // });
  }
  //#endregion

  getUserList(SearchKey: string, UserId: number) {
    let data = {
      'ClientInfo': this.Get_ClientInfo(),
      'SearchKey': SearchKey,
      'UserId': UserId
    };

    let serviceurl = this.ServiceUrl + 'GetUserList';
    return this.http.post<any>(serviceurl, data, this.options)
      .toPromise()
      .then(json => {

        return json[0];
      })
      .catch(error => {
        console.log(error);
      });
  }


  isAddFriendActive(userId): Observable<any> {
    const endpoint = this.ServiceUrl + 'api/user/isfriendshipactive';
    var data = { UserId: userId }
    return this.http.post<any>(endpoint, data, this.options)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => this.handleError(error))
      );
  }

  public callIsAddFriendActive(userId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.isAddFriendActive(userId)
        .toPromise()
        .then((isActiveAddFriendRes) => {
          if (this.utilService.isNullOrUndefined(isActiveAddFriendRes.ErrMsg)) {
            return reject(isActiveAddFriendRes.ErrMsg)
          }
          return resolve(isActiveAddFriendRes.Result);
        })
        .catch((err) => {
          return reject(err)
        });
    })
  }


  public callSaveFriendshipRequestActivation(): Observable<any> {
    let body = {
      'ClientInfo': this.Get_ClientInfo(),
      'IsActive': this.indexedDBService.userFullInfo.FriendshipRequestActive
    };
    const endpoint = this.ServiceUrl + 'api/user/friendshiprequest';
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  public getFriends(): Promise<FriendList> {
    const endpoint = this.ServiceUrl + `api/user/friend/${this.indexedDBService.userFullInfo.KullaniciId}`
    return this.http.get<FriendList>(endpoint).toPromise();
  }
}
