
export const NatsConfig = {
    userChatChannelTag: 'chat',
    // created at login => chat.service => subscribeUserChatChannel (887)
    // publish => from backend
    // message.controller.go => PublishP2PChatMessage (41) - to receciverId
    userSettingChannelTag: 'setting',
    // created at login => chat.service => subscribeUserSettingChannel (914)
    // publish => from backend
    // message.controller.go => PublishP2PSettingMessage - to receciverId
    // message.service.go => PublishForFriends - toFriends
    // session.service.go => LogoutOtherSessionsService - to user own channel
    sessionChannelTag: 'allusers',
    // created at login => centrifugo.service => connect (90)
    // publish => centrifugo.service => publishPublicSettingMessage (257) - call backend service - to all logged in users
    roomChannelTag: 'room',
    // created at enter room - room.service.ts => enterRoomAfterControlComplete (859)
    // publish => centrifugo.service => publishRoomChatMessage (262) - call backend service - to all users in the room
    roomSettingChannelTag: 'roomsetting',
    // created at enter room - room.service.ts => enterRoomAfterControlComplete (859)
    // publish => centrifugo.service => publishRoomSettingMessage (267) - call backend service - to all users in the room
    roomMicrophoneChannelTag: 'roommicrophone',
    // created at enter room - room.service.ts => enterRoomAfterControlComplete (859)
    // publish => from backend
    // microphone.service.go => publishMicrophoneToCentrifugo - to all users in the room
    // initialize.go => 
    userChannelSeperator: '#',
    roomChannelSeperator: ':',
  };
  
