<p-dialog [(visible)]="isVisible" modal="true" (onShow)="onDialogShow()" (onHide)="onDialogHide()"
[closeOnEscape]="false" [closable]="false" [showHeader]="false" >
    <p-header>
        <h5 class="text-gray-700 d-inline">{{ header }}</h5>
    </p-header>
    <article>
        <header>
            <h6 class="text-gray-600">
                <i class="pi pi-exclamation-triangle mr-1 text-danger translatey-10"></i>
                <span>{{ message }}</span>  
            </h6>
        </header>       
    </article>   
</p-dialog>