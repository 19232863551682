
export enum User {
    KullaniciID = 0,
    KullaniciAdi = 1,
    IkinciKullaniciAdi = 2,
    ProfilResmiID = 3,
    FlashnickID = 4,
    GeciciOperatorListesiJSON = 5,
    RutbeAdi = 6,
    RutbeDerece = 7,
    RutbeID = 8,
    StateName = 9,
    Nicktype = 10,
    Secretkey = 11,

    BanOdaListJSON = 13,
    BanSite = 14,
    MuteOdaListJSON = 15,
    MuteSite = 16,
    Karaliste = 17,
    GenelMesajSayisi = 18,
    LastPcimei = 19,
    Password = 20,
    Ip = 21,
    Email = 22,
    DurumMesaj = 23,
    Misafir = 24,
    KullaniciAdiRenk = 25,
    AltYetkiliLimiti = 26,
    Telefon = 27,
    GroupIdList = 28,
    HasPhoneAccount = 29,

    // rediste olmayan clientta doldurulanlar
    WebsyncClientID = 12,
    RutbeInfoJson = 98,
    CamSession = 99
}

export enum BanPlaces {
    Room = 0,
    Site = 1
}

export enum BanDetail {
    HasBanCantEnterRoom = 0,
    HasBanButCanEnterRoom = 1,
    HasNoBan = 2

}



export enum BubbleTypes {
    Text = 1,
    File = 2,
    Location = 3,
    Sound = 4,
    Video = 5,
    Animation = 6
}




export enum MessageBubbleType {
    PtoPMe = "PtoPMe",
    PtoPOther = "PtoPOther",
    RoomMe = "RoomMe",
    RoomOther = "RoomOther",
    SystemMessages = "RoomSystemMessages",
    AdminChangesMessage = "RoomAdminChangesMessage",
    RoomActionMessage = "RoomActionMessage",
    GeneralMessage = "GeneralMessage",
    PtoPInfo = "PtoPInfo",
    ConnectionMessage = "ConnectionMessage",
}

export enum BanOrMute {
    Ban = 0,
    Mute = 1
}

export enum ContactType {
    Chat = 0,
    VideoAndAudio = 1,
    Video = 2,
    Audio = 3,
    Calling = 4,
    ShareMyScreen = 5,
    ShareYourScreen = 6,
    VideoCalling=7,
    AudioCalling=8
    
}

export enum ConferenceType {
    Normal = 0,
    Sender = 1,
    Receiver = 2
}

export enum MessageSendingType {
    Normal = 0,
    GenelHerkes = 1,
    GenelAdmin = 2,
    OdaOzel = 3
}


export enum ContactState {
    GelenArama = 0,
    GidenArama = 1,
    SonlananArama = 2,
    YanitYok = 3,
    Mesgul = 4,
    DosyaTransfer = 5,
    GrupdanCikma = 6,
    Hicbiri = 7
}

export enum ChatState {
    GelenMesaj = 0,
    GonderilenMesaj = 1
}
export enum ChatType {
    SingleChat = 0,
    GroupChat = 1,
    CommunicationType = 2,
    AdminChangesMessage = 3,
    AdminChangesMethod = 4,
    SystemMessages = 5,
    Animation = 6,
    File = 7,
    RootAdminTopluMesaj = 8,
    RoomActionMessage = 9
}

export enum MessageType {
    Text = 0,
    Animation = 1,
    Emoji = 2,
    EmojiGif = 3
}

export enum BulkMessageType {
    OneChannel = 0,
    AllMyFriends = 1,
    AllRooms = 2,
    AllMyRooms = 3,
    AllGroupMembers = 4,
    AllGroupMembersDistinctMe = 5,
    AllTaskMembers = 6,
    AllEmergencyMembers = 7,
    OneUserAllFriends = 8,
    OneRutbeAllMembers = 9,
    OneRutbeAllMembersAllFriends = 10,
    UserCurrentRoom = 11
}

export enum BanTypes {
    OneHour = 1,
    OneDay = 2,
    ThreeDay = 3,
    SevenDay = 4,
    OneMonth = 5,
    UnLimited = 6,
    BlackList = 7
}


export enum MainPanels {
    None = "0",
    RoomList = "1",
    FriendList = "2",
    MainMenu = "3",
    RoomPage = "4",
    P2PChat = "5",
    RoomVideo = "6",
    Admin = "7",
    ScreenShare="8"
}

export enum RoomPagePanels {
    UserList = 0,
    Chat = 1,
    Info = 2,
    FavoriteRooms = 3
}


export enum PopupPanels {
    RoomPasswordPanel = 1,
    RoomSecondNickPanel = 2
}

export enum CodecSettingsType {
    Room = 1,
    PrivateLow = 2,
    PrivateNormal = 3,
    PrivateHigh = 4,
    ScreenSharingLow = 5,
    ScreenSharingNormal = 6,
    ScreenSharingHigh = 7,
    PrivateSd480 = 8,
    PrivateSd576 = 9,
    PrivateHd720 = 10
}


export enum  WhoEnterRoomTypes {
    Everyone = "h",
    AuthoritiesOnly = "y",
    UsersOnly = "u"
}

export enum MicrophoneState {
    Started = 1,
    Stopped = 2
}

export enum EditorOn {
    Room = "Room",
    P2PChat = "P2PChat"
}

export enum FriendShipJsonType {
    friendsjsons = 0,
    incomingfriendsjsons = 1,
    outgoingfriendsjsons = 2,
    blockedfriendsjsons = 3,
    friendswhoblockedmejsons = 4
}

export enum RedisUser {
    KullaniciAdi = 0,
    RutbeID = 1,
    RutbeAdi = 2,
    RutbeDerece = 3,
    ProfilResmiID = 4,
    IkinciKullaniciAdi = 5,
    FlashnickID = 6,
    Nicktype = 7,
    StateName = 8,
    DurumMesaj = 9,
    BanOdaList = 10,
    BanSite= 11,
    MuteOdaList= 12,
    MuteSite = 13,
    Karaliste = 14,
    RoomCamSession = 15,
    KullaniciId = 16
}

export enum RoomAzureContainerType {
    room = "room/",
    roomusers = "roomusers/"
}

export enum RoomNickSelectionType {
    SecondUserName = 0,
    RealUserName = 1
}

export enum SikayetTipleri {
    Oda = 1,
    Kullanici = 2,
    OdaMesaj = 3,
    P2pMesaj = 4,
    LiveSupport = 5
}

export enum FriendListType {
    Friends = "Friends",
    IncomingFriends = "IncomingFriends",
    OutgoingFriends = "OutgoingFriends"
}

export enum MainMenuType {
    None = "None",
    State = "State",
    Personal = "Personal",
    Chat = "Chat",
    MyPeople = "MyPeople",
    Help = "Help",
    AuthorizationTrading = "AuthorizationTrading",
    UnderConstruction = "UnderConstruction",
    Signout = "Signout"    
}
// #endregion