import { Injectable } from '@angular/core';
import { StateImagesById, UserStateType, StateIdNumberList } from '../models/state';
import { Constants, Image } from 'src/app/core/models/constants';
import { Subject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class StateService {

    //#region properties 
    currentStateId: number=1;
    currentStateText: string = 'stateonline';
    //#endregion


    //#region contructor 

    constructor() {
    }

    //#endregion




    getStateImageUrlByStateId(stateId: number) {
        let stateIconName = StateImagesById[stateId];
        if (!stateIconName)
            stateIconName = Constants.defaultStateIconName;

        return Image.path + StateImagesById[stateId] + Constants.stateIconExtension;
    }


    getUserStateTypeByStateId(stateId:number): UserStateType {
        let stateNumber= StateIdNumberList[stateId];
        return stateNumber as UserStateType; 
    }

}
