import { Component, Input } from '@angular/core';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';

@Component({
  selector: 'app-room-id',
  templateUrl: './room-id.component.html',
  styleUrls: ['./room-id.component.scss']
})
export class RoomIdComponent {
  @Input() roomId: number ;
  @Input() color: string = 'rgba(255, 255, 255, 0.7)';

  

  constructor(
    public indexedDBService: IndexedDBService,
    private messagePanelService: MessagePanelService
  ) {

  }

  ngOnInit(): void {
  }

  copyID(event) {
    event.stopPropagation();
    navigator.clipboard.writeText(this.roomId.toString())
    .then(() => {
      this.messagePanelService.ShowPopupMessageWithLocalization('copiedSuccessfully', 2000);  
      event.preventDefault();
      event.stopPropagation();
    });
  }
}
