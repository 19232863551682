import { Injectable, OnDestroy } from '@angular/core';
import { UtilService } from './util.service';
import { MessagePanelService } from './messagePanel.service';
import { UserFullInfo, WebsyncIcelinkInfo, UserEnum, FriendList, Friend } from '../models/user';
import { RoomFavoriteButton } from '../../core/models/room-favorite-button';
import { Room, RedisRootSettings } from '../models/room';
import { Subject } from 'rxjs';
import { RoomSliderUser } from '../../core/models/room-slider-user';
import { BannedWordByService, BannedWordData } from '../../core/models/banned-word';
import { StateIdList, StateIdsForSort } from '../../core/models/state';
import { RankFullInfo } from '../models/rank';
import { Nicktype } from '../models/nick-type';
import { Font } from '../models/font';
import { Constants } from '../models/constants';
import { ChatMessage } from '../../core/models/chat-message';
import { BanPanel } from '../../core/models/ban-panel';
import { RedisUser } from '../models/enums';
import { DeviceService } from './device.service';
import { LocalStorageService } from '../services/local-storage.service';

declare function require(name: string);

@Injectable({
    providedIn: 'root'
})
export class IndexedDBService implements OnDestroy {

 
    
    p2pChatInitSource: Subject<number> = new Subject();
    p2pChatInit = this.p2pChatInitSource.asObservable();

    indexedDBHelper = require("../../core/extensions/indexed-db/IndexedDBHelper.js");

    private publicLocalDBName = "puldbn";
    private privateLocalDBName = "prldbn";

    newMessageCountsStoreName = "newmessagecounts";
    newMessageCountsPrivateKey = "ID";

    settingsStoreName = "settings";
    settingsPrivateKey = "ID";

    usersStoreName = "users";
    usersPrivateKey = "KullaniciAdi";

    userNameIdStoreName = "usersnameid";
    userNameIdPrivateKey = "UserName";

    animattionsStoreName = "animations";
    animattionsPrivateKey = "Id";

    roomFavoriteButtonsStoreName = "roomfavoritebuttons"; //{R,F,C,I}  int RoomId, string Font, string Color, string ImageId
    roomFavoriteButtonsPrivateKey = "R";

    roomsStoreName = "rooms"; //{"I","N","O","C","M",W,F,FB}  int RoomId, string RoomName, int Online, int Cam, string Message,string OwnerName,bool Favorite, roomfavoritebuttons{R,F,C,I} RedisRoomFavoriteButton
    roomsPrivateKey = "I";

    bannedWordsStoreName = "bannedwords"; // {Y,I} string Yasakli, string Izinli
    bannedWordsPrivateKey = "ID";

    versionValueStoreName = "versionvalue";
    versionValuePrivateKey = "ID";

    notificationStoreName = "notifications";
    notificationPrivateKey = "Id";

    userProfileImagesStoreName = "userprofileimages";
    userProfileImagesPrivateKey = "Id";

    emojisStoreName = "emojis";
    emojisPrivateKey = "Id";

    favoriteRoomsStoreName = "frsn";
    favoriteRoomsPrivateKey = "Id";

    chatMessageStoreName = "chatmessage";
    chatMessageStoreNameOffline = "chatmessageoffline";
    chatMessagePrivateKey = "LocalDBId";


    //#region settings properties 

    settingsSignUpLoginNamePrivateKey = "settingsSignUpLoginName";
    settingsSignUpLoginNameDefaultValue = null;

    settingsRememberMeUserNamePrivateKey = "settingsRememberMeUserName";
    settingsRememberMeUserNameDefaultValue = null;

    settingsRememberMePasswordPrivateKey = "settingsRememberMePassword";
    settingsRememberMePasswordDefaultValue = null;

    settingsCurrentStatePrivateKey = "settingsCurrentState";
    settingsCurrentStateDefaultValue = this.utilService.states[0]; // StateIdList.Cevrimici;

    settingsUserIsAnyRoomOwnerPrivateKey = "settingsUserIsAnyRoomOwner";
    settingsUserIsAnyRoomOwnerDefaultValue = false;

    settingsThemePrivateKey = "settingsTheme";
    settingsThemeDefaultValue = "";

    settingsSelectedMessagePanelBackColorPrivateKey = "settingsSelectedMessagePanelBackColor";
    settingsSelectedMessagePanelBackColorDefaultValue = "#3e3d43"; // "#8B8B8B"; "#fdfdfd"; // "#636366"; // "#a9add9"; // #bde0e7

    settingsSelectedMessagePanelBackImagePrivateKey = "settingsSelectedMessagePanelBackImage";
    settingsSelectedMessagePanelBackImageDefaultValue = "";

    settingsMessagePanelSelectedBackgroundPrivateKey = "settingsMessagePanelSelectedBackground";
    settingsMessagePanelSelectedBackgroundDefaultValue = true; // true = back type is color, false = back type is image

    // settingsIsSelectedMessagePanelBackImagePrivateKey = "settingsIsSelectedMessagePanelBackImage";
    // settingsIsSelectedMessagePanelBackImageDefaultValue = false;

    settingsNickTypePrivateKey = "settingsNickType";
    settingsNickTypeDefaultValue = new Nicktype();

    settingsRoomFavoriteRoomListSplitterDivHeightPrivateKey = "settingsRoomFavoriteRoomListSplitterDivHeight";
    settingsRoomFavoriteRoomListSplitterDivHeightDefaultValue = 72;

    settingsRoomUserListPanelSplitterDivWidthPrivateKey = "settingsRoomUserListPanelSplitterDivWidth";
    settingsRoomUserListPanelSplitterDivWidthDefaultValue = 210;

    settingsRoomInfoSplitterDivWidthPrivateKey = "settingsRoomInfoSplitterDivWidth";
    settingsRoomInfoSplitterDivWidthDefaultValue = 200;

    settingsFavoriteRoomsPrivateKey = "settingsFavoriteRooms";
    settingsFavoriteRoomsDefaultValue = [];

    settingsShowFlashNickPrivateKey = "settingsShowFlashNick";
    settingsShowFlashNickDefaultValue = true;

    // key: userid, value: index  (indexi küçük olan en son mesajlaşılan)
    settingsLastChatListPrivateKey = "settingsLastChatList";
    settingsLastChatListDefaultValue = {};

    settingsLastChatListMinIndexPrivateKey = "settingsLastChatListMinIndex";
    settingsLastChatListMinIndexDefaultValue = 99999999;

    settingsVersionValuePrivateKey = "settingsVersionValue";
    settingsVersionValueDefaultValue = ["-", "-", "-", "-", "-", "-", "-", "-", "-", "-"];

    settingsMachineIDPrivateKey = "settingsMachineID";
    settingsMachineIDDefaultValue = "";

    AuthorizedUserSliderListPrivateKey = "AuthorizedUserSliderList";
    AuthorizedUserSliderListDefaultValue = [];

    settingsSiteBanGirisleriPrivateKey = "settingsSiteBanGirisleri";
    settingsSiteBanGirisleriDefaultValue = false;

    settingsOdaBanGirisleriPrivateKey = "settingsOdaBanGirisleri";
    settingsOdaBanGirisleriDefaultValue = false;

    settingsAudioDeviceIdPrivateKey = "settingsAudioDeviceId";
    settingsAudioDeviceIdDefaultValue = "default";

    settingsVideoDeviceIdPrivateKey = "settingsVideoDeviceId";
    settingsVideoDeviceIdDefaultValue = undefined;

    settingsAudioIsMutedPrivateKey = "settingsAudioIsMuted";
    settingsAudioIsMutedDefaultValue = false;

    userLastRoomPrivateKey = "userLastRoom";
    userLastRoomDefaultValue = null;

    //#endregion
    Animations: Array<number> = [];
    RoomFavoriteButtonList: Array<RoomFavoriteButton> = [];
    RoomFavoriteButtonListDictionary: { [roomID: number]: { 'color': string, 'font-family': string, 'background-image': string, 'background-color': string } } = {};
    Rooms: Array<Room> = [];
    Friends: FriendList = new FriendList();
    BannedWords: { [key: string]: string } = {};
    tempNicktype: Nicktype = new Nicktype();
    //Groups = [];
    //Phones = [];
    AuthorizedUserSliderList: Array<RoomSliderUser> = [];
    SMSActive = false;
    UserIsAnyRoomOwner = false;
    VersionValue = this.settingsVersionValueDefaultValue;
    // MachineID = this.settingsMachineIDDefaultValue;
    RoomOrderList: Array<number> = new Array<number>();
    FingerprintID = "";
    FingerPrintChanged: boolean = false;
    ClientIP = "";
    IPChanged: boolean = false;
    IsAddFriendActive = null;// kullanılmıyor diye kapattım
    FriendshipRequestActive: boolean;
    RoomListJsonCurrentFileIndex: number;
    Settings = {};
    RoomUserListFullInfos: Array<UserFullInfo> = [];

    underConstructionModeActive: boolean = false;

    ConnectionToken: string;
    SubscriptionToken: string;
    Notifications = {};

    newMessageCounts = { userMessages: {} };
    newMessageCountsUserKey = "userMessages";

    messageCounts: { [senderId: number]: number } = {};
    stateIndex = 0;
    lang = "tr";

    serverTimeDistance: number;
    websyncIcelinkInfo: WebsyncIcelinkInfo = <WebsyncIcelinkInfo>{
        IcelinkId: "1",
        IcelinkServerName: "SesliDunyaServer",
        IcelinkServerPass: "xyz12345",
        //IcelinkUrl: "stun.l.google.com:19302",
        IcelinkUrl: "global.stun.twilio.com"//,


    };

    SupportedBrowsers = ['Chrome', 'Firefox', 'Microsoft Edge', 'Safari', 'Samsung Internet for Android', 'Opera'] // TODO add samsung internet browser  
    // [ 
    //     { name: 'Chrome', icon: faChrome }, 
    //     { name: 'Firefox', icon: faFirefoxBrowser },
    //     { name: 'Edge', icon: faEdge }, 
    //     { name: 'Safari', icon: faSafari }       
    // ]; 


    userFullInfo: UserFullInfo;

    banPanel: BanPanel;

    privateDBinitOK = false;
    //#region events 

    friendsChangedSource: Subject<any> = new Subject();
    friendsChanged = this.friendsChangedSource.asObservable();

    friendProfilePicture: string;
    isThereNewMessage: boolean;
    roomSharedImage :string;

    redisRootSettings: RedisRootSettings = new RedisRootSettings();
    totalMessageCount: number = 0;

    authenticated = false;

    authorizedUserSliderListSource: Subject<any> = new Subject();
    authorizedUserSliderListDone = this.authorizedUserSliderListSource.asObservable();

    //#endregion



    //#endregion

    constructor(
        public utilService: UtilService,
        public messagePanelService: MessagePanelService,
        private deviceService: DeviceService,
        private storageService: LocalStorageService
    ) {
        console.log(`indexed-db.service constructor called`)
    }

    ngOnDestroy() {
        console.log(`indexed-db.service ngOnDestroy called`)
        this.Animations = [];
        this.RoomFavoriteButtonListDictionary = {};
        this.Rooms = [];
        this.Friends = new FriendList();
        this.BannedWords = {};
        this.AuthorizedUserSliderList = [];
        this.SMSActive = false;
        this.UserIsAnyRoomOwner = false;
        //this.IsAddFriendActive = null;
        this.RoomListJsonCurrentFileIndex = 0;
        this.Settings = {};
        this.Notifications = {};
        this.newMessageCounts = { userMessages: {} };
        this.messageCounts = {};
        this.newMessageCountsUserKey = "userMessages";
        this.stateIndex = 0;
        this.serverTimeDistance = 0;
        this.websyncIcelinkInfo = new WebsyncIcelinkInfo();
        this.userFullInfo = new UserFullInfo();
        this.banPanel = new BanPanel();
        this.friendProfilePicture = null;
        this.VersionValue = this.settingsVersionValueDefaultValue;
        this.redisRootSettings = new RedisRootSettings();
    }

    //#region get operations 

    getUser(username: string) {
        return this.indexedDBHelper.getByKey(
            this.getPrivateDBName(username),
            this.usersStoreName,
            this.createLocalDataForPrivateDB(),
            username
        ).then(res => {

            return res;
        }).catch(err => { console.log(err); });
    }


    isAuthenticated(): boolean {
        //NOTE: fix for tab close
        let token = this.storageService.storageGetItem('token')
        // let token = localStorage.getItem("token")
        if (token) {
            return true
        }
        else {
            return false
        }
    }

    getOldChatMessage(friendUserId, limit, topMessageId): Promise<Array<ChatMessage>> {
        return this.indexedDBHelper.getByCursor(
            this.getUserDbName(friendUserId),
            this.chatMessageStoreName,
            this.createLocalDataForPrivateDB(),
            {
                limit: limit,
                skip: 0,
                order: "prev",
                keyRange: this.gettIndexedDbKeyRangeByOptions({
                    lower: null,
                    lowerOpen: false,
                    upper: topMessageId,
                    upperOpen: true
                })
            },
        ); //as PromiseLike<Array<LocalMessage>>;     
    }

    getChatMessage(friendId, localDbId) {
        return this.indexedDBHelper.getByKey(
            this.getUserDbName(friendId),
            this.chatMessageStoreName,
            this.createLocalDataForPrivateDB(),
            localDbId
        );
    }

    getPrivateSetting(key, username: string = ""): Promise<any> {

        var value = this.Settings[key];

        if (value === null || value === undefined) {
            return this.indexedDBHelper.getByKey(
                this.getPrivateDBName(username),
                this.settingsStoreName,
                this.createLocalDataForPrivateDB(),
                key
            ).then(responseValue => {

                if (responseValue === null || responseValue === undefined) {

                    var defaultVal = this[key + "DefaultValue"];
                    if (defaultVal === null || defaultVal === undefined)
                        throw "error";


                    this.Settings[key] = defaultVal;
                    return defaultVal;
                } else {


                    try {
                        this.Settings[key] = JSON.parse(responseValue.Value);
                        return this.Settings[key];
                    } catch (error) {
                        this.Settings[key] = responseValue.Value;
                        return responseValue.Value;
                    }

                }
            }).catch(err => { console.log(err); });
        } else {
            return Promise.resolve(value);
        }
    }

    getNewMessageCount(senderTag, senderId): Promise<any> {
        var messageCount = this.newMessageCounts[senderTag][senderId];
        if (messageCount === null || messageCount === undefined) {

            return this.indexedDBHelper.getByKey(
                this.getPrivateDBName(),
                this.newMessageCountsStoreName,
                this.createLocalDataForPrivateDB(),
                senderTag + senderId
            ).then(responseValue => {
                if (responseValue === null || responseValue === undefined) {
                    delete this.newMessageCounts[senderTag][senderId];
                    return 0;
                } else {
                    this.newMessageCounts[senderTag][senderId] = responseValue.Value;
                    return responseValue.Value;
                }
            }).catch(err => { console.log(err); });
        } else {
            return Promise.resolve(messageCount);
        }
    }

    getRoomById(roomId) {
        return this.Rooms.find(room => room.I === roomId);
    }

    getRoomNameById(roomId) {
        return this.Rooms.find(room => room.I === roomId).N;
    }

    //#endregion

    // #region Update

    updateUser(userFullInfo: UserFullInfo) {
        return this.indexedDBHelper.upsert(
            this.getPrivateDBName(),
            this.usersStoreName,
            userFullInfo,
            this.createLocalDataForPrivateDB()
        )
            .then((res) => {
                return this.updateUserIdName(userFullInfo.KullaniciAdi, userFullInfo.KullaniciId);
            }).catch(err => { console.log(err); });
    }

    updateUserIdName(userName: string, userId: number) {
        return this.indexedDBHelper.upsert(
            this.publicLocalDBName,
            this.userNameIdStoreName,
            { "UserName": userName, "ID": userId },
            this.createLocalDataForPublicDB()
        );
    }



    updateState(stateIndex) {
        return this.indexedDBHelper.upsert(
            this.publicLocalDBName,
            this.settingsStoreName,
            { "ID": this.settingsCurrentStatePrivateKey, "Value": stateIndex },
            this.createLocalDataForPublicDB()
        );
    }

    updatePrivateSetting(key, value, userName: string = ""): Promise<any> {
        return this.indexedDBHelper.upsert(
            this.getPrivateDBName(userName),
            this.settingsStoreName,
            { "ID": key, "Value": value },
            this.createLocalDataForPrivateDB()
        ).then(() => {
            this.Settings[key] = value;
        }).catch(err => { console.log(err); });
    }


    updateSetting(key, value): Promise<any> {
        return this.indexedDBHelper.upsert(
            this.getPrivateDBName(),
            this.settingsStoreName,
            { "ID": key, "Value": value },
            this.createLocalDataForPrivateDB()
        )
            .then(() => {
                this.Settings[key] = value;
            }).catch(err => { console.log(err); });
    }

    updateSettingJsonObject(key, value): Promise<any> {

        return this.indexedDBHelper.upsert(
            this.getPrivateDBName(),
            this.settingsStoreName,
            { "ID": key, "Value": JSON.stringify(value) },
            this.createLocalDataForPrivateDB()
        ).then(() => {
            this.Settings[key] = value;
        }).catch(err => { console.log(err); });
    }

    updateNewMessageCount(senderTag, sernderId, messageCount) {
        if (messageCount > 0) {
            return this.indexedDBHelper.upsert(
                this.getPrivateDBName(),
                this.newMessageCountsStoreName,
                { "ID": senderTag + sernderId, "Value": messageCount },
                this.createLocalDataForPrivateDB()
            ).then(() => {
                this.newMessageCounts[senderTag][sernderId] = messageCount;
            })
                .then(() => {
                    if (Object.values<number>(this.newMessageCounts[senderTag]).length > 0) {
                        Object.values<number>(this.newMessageCounts[senderTag]).forEach(element => {
                            this.totalMessageCount = this.totalMessageCount + element;
                        });
                    } else {
                        this.totalMessageCount = 0;
                    }
                }).catch(err => { console.log(err); });
        } else {
            return this.indexedDBHelper.deleteByKey(
                this.getPrivateDBName(),
                this.newMessageCountsStoreName,
                this.createLocalDataForPrivateDB(),
                senderTag + sernderId
            ).then(() => {
                delete this.newMessageCounts[senderTag][sernderId];
            })
                .then(() => {
                    if (Object.values<number>(this.newMessageCounts[senderTag]).length > 0) {
                        Object.values<number>(this.newMessageCounts[senderTag]).forEach(element => {
                            this.totalMessageCount = this.totalMessageCount + element;
                        });
                    } else {
                        this.totalMessageCount = 0;
                    }
                });
            //TODO
            //_xamarin.ClearNewMessageCounts(userOrGroupId);
        }
    }

    UpdateUserIsAnyRoomOwner(val: boolean = false, userName: string = "") {
        return this.indexedDBHelper.upsert(
            this.getPrivateDBName(userName),
            this.settingsStoreName,
            { "ID": this.settingsUserIsAnyRoomOwnerPrivateKey, "Value": val },
            this.createLocalDataForPrivateDB()
        );
    }


    UpdateAnimations(animations: Array<number>) {
        animations.forEach(animationId => {
            this.indexedDBHelper.upsert(
                this.publicLocalDBName,
                this.animattionsStoreName,
                { "Id": animationId },
                this.createLocalDataForPublicDB()
            );
        });
    }

    UpdateVersionValue(versionValue: {}, userName: string = "") {
        this.updatePrivateSetting(this.settingsVersionValuePrivateKey, versionValue, userName)
            .then(() => {
            })
            .catch(error => { console.log(error); });
    }

    UpdateAuthorizedUserSliderList(authorizedUserSliderList, userName: string = "") {
        this.updatePrivateSetting(this.AuthorizedUserSliderListPrivateKey, authorizedUserSliderList, userName)
            .then(() => {
            })
            .catch(error => { console.log(error); });
    }

    UpdateSiteBanGirisleri(siteBanGirisi: boolean, userName: string = "") {
        this.updatePrivateSetting(this.settingsSiteBanGirisleriPrivateKey, siteBanGirisi, userName)
            .then(() => {
            })
            .catch(error => { console.log(error); });
    }

    UpdateOdaBanGirisleri(odaBanGirisi: boolean, userName: string = "") {
        this.updatePrivateSetting(this.settingsOdaBanGirisleriPrivateKey, odaBanGirisi, userName)
            .then(() => {
            })
            .catch(error => { console.log(error); });
    }

    updateNicktype(nicktype: Nicktype, username: string = ""): Promise<void> {
        return this.updatePrivateSetting(this.settingsNickTypePrivateKey, nicktype, username)
            .then(() => {
                this.userFullInfo.NicktypeForRoom = nicktype;
            })
            .catch(error => { console.log(error); });
    }

    UpdateRoomFavoriteButtonList(favoriteButtons: Array<any>) {
        favoriteButtons.forEach(favoriteButton => {
            this.indexedDBHelper.upsert(
                this.publicLocalDBName,
                this.roomFavoriteButtonsStoreName,
                favoriteButton,
                this.createLocalDataForPublicDB()
            );
        });
    }

    UpdateRooms(rooms: Array<any>) {
        rooms.forEach(room => {
            this.indexedDBHelper.upsert(
                this.publicLocalDBName,
                this.roomsStoreName,
                room,
                this.createLocalDataForPublicDB()
            );
        });
    }

    UpdateBannedWords(bannedWords: { [key: string]: string }) {


        this.indexedDBHelper.upsert(
            this.publicLocalDBName,
            this.bannedWordsStoreName,
            { "ID": this.bannedWordsStoreName, "Data": bannedWords },
            this.createLocalDataForPublicDB()
        );
    }

    setChatMessageLocalDbId(chatMessage) {
        var localDbSenderId;
        var localDbReceiverId;
        if (chatMessage.SenderId === this.userFullInfo.KullaniciId) {
            localDbSenderId = chatMessage.ReceiverId;
            localDbReceiverId = chatMessage.SenderId;
        } else {
            localDbSenderId = chatMessage.SenderId;
            localDbReceiverId = chatMessage.ReceiverId;
        }
        chatMessage["LocalDBId"] = localDbSenderId + "_" + localDbReceiverId + "_" + chatMessage.MessageId;
    }

    updateChatMessage(chatMessage: ChatMessage, isOfflineMessage: boolean): Promise<any> {
        //  this.setChatMessageLocalDbId(chatMessage);

        var friendId = chatMessage.SenderId;
        if (friendId === this.userFullInfo.KullaniciId)
            friendId = chatMessage.ReceiverId;

        //this.addToLastChatList(friendId);

        //TODO
        // burada event fırlat gereken yerler yakalayıp ona göre işlem yapsın
        // this.addToLastChatList(friendId);

        // //ilk başa eklenmediyse hata alınmıştır refreshle listeyi
        // if (this.Friends[0].KullaniciId !== friendId)
        //     StaticPublicMethods.RefreshFriendList(true)

        return this.indexedDBHelper.upsert(
            isOfflineMessage === true ? this.getOfflineUserDbName(friendId.toString()) : this.getUserDbName(friendId.toString()),
            this.chatMessageStoreName,
            chatMessage,
            this.createLocalDataForPrivateDB()
        );
    }

    addToLastChatList(friendId: number) {
        var lastChatListMinIndex: number;
        this.getPrivateSetting(this.settingsLastChatListMinIndexPrivateKey)
            .then((value => {
                lastChatListMinIndex = value - 1;
            }));


        var lastChatList = {};
        this.getPrivateSetting(this.settingsLastChatListPrivateKey)
            .then((value => {
                lastChatList = value;
                lastChatList[friendId] = lastChatListMinIndex;
            }))

        this.updateSetting(this.settingsLastChatListPrivateKey, lastChatList);
        this.updateSetting(this.settingsLastChatListMinIndexPrivateKey, lastChatListMinIndex);
    }
    // #endregion

    // #region GetLocalDbSetLocalData

    // #endregion

    //#region delete operations 

    deleteChat(friendId, privateKeyValue) {
        return this.indexedDBHelper.deleteByKey(
            this.getUserDbName(friendId),
            this.chatMessageStoreName,
            this.createLocalDataForPrivateDB(),
            privateKeyValue
        );
    }

    deleteOfflineChat(friendId, privateKeyValue) {
        return this.indexedDBHelper.deleteByKey(
            this.getOfflineUserDbName(friendId),
            this.chatMessageStoreNameOffline,
            this.createLocalDataForPrivateDB(),
            privateKeyValue
        );
    }


    //#endregion

    //#region clearUserLastLoginInfo
    clearUserLastLoginInfo() {
        return this.clearUserLastLoginInfo_SettingsRememberMeUserNamePrivateKey()
            .then(() => {
                return this.clearUserLastLoginInfo_SettingsRememberMePasswordPrivateKey()
            })
        // .then(() => {
        //     return this.clearUserLocalDb_AllChatMessages();
        // })
    }

    clearUserLastLoginInfo_SettingsRememberMeUserNamePrivateKey() {
        return this.indexedDBHelper.deleteByKey(
            this.publicLocalDBName,
            this.settingsStoreName,
            this.settingsPrivateKey,
            this.settingsRememberMeUserNamePrivateKey,
            null
        );
    }

    clearUserLastLoginInfo_SettingsRememberMePasswordPrivateKey() {
        return this.indexedDBHelper.deleteByKey(
            this.publicLocalDBName,
            this.settingsStoreName,
            this.settingsPrivateKey,
            this.settingsRememberMePasswordPrivateKey,
            null
        );
    }
    //#endregion

    // #region clearUserLocalDb

    clearUserLocalDb() {
        return this.clearUserLocalDb_SettingsStoreName()
            .then(() => {
                return this.clearUserLocalDb_UsersStoreName()
            })
            .then(() => {
                return this.clearUserLocalDb_UserProfileImagesStoreName()
            })
            .then(() => {
                return this.clearUserLocalDb_AllChatMessages()
            })
            .then(() => {
                return this.clearUserLocalDb_VersionValueStoreName()
            });
    }

    clearUserLocalDb_SettingsStoreName() {
        return this.indexedDBHelper.clearObjectStore(
            this.getPrivateDBName(),
            this.settingsStoreName,
            this.createLocalDataForPrivateDB()
        );
    }

    clearUserLocalDb_UsersStoreName() {
        return this.indexedDBHelper.clearObjectStore(
            this.getPrivateDBName(),
            this.usersStoreName,
            this.createLocalDataForPrivateDB()
        );
    }

    clearUserLocalDb_VersionValueStoreName() {
        return this.indexedDBHelper.clearObjectStore(
            this.getPrivateDBName(),
            this.versionValueStoreName,
            this.createLocalDataForPrivateDB()
        );
    }

    clearUserLocalDb_UserProfileImagesStoreName() {
        return this.indexedDBHelper.clearObjectStore(
            this.getPrivateDBName(),
            this.userProfileImagesStoreName,
            this.createLocalDataForPrivateDB()
        );
    }

    clearUserLocalDb_AllChatMessages(): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                this.Friends.AllFriends.forEach(friend => {
                    this.clearUserLocalDb_AllChatMessagesForOneFriend(friend.KullaniciId);
                });

                setTimeout(() => {
                    this.friendsChangedSource.next();
                    resolve();
                }, 3000);
            } catch (error) {
                reject(error);
            }
        });
    }

    clearUserLocalDb_AllChatMessagesForOneFriend(friendId) {
        return this.indexedDBHelper.clearObjectStore(
            this.getUserDbName(friendId),
            this.chatMessageStoreName, // + "_u_" + friendId
            this.createLocalDataForPrivateDB()
        )
            .then(() => {
                return this.indexedDBHelper.clearObjectStore(
                    this.getOfflineUserDbName(friendId),
                    this.chatMessageStoreNameOffline, // + "_u_" + friendId
                    this.createLocalDataForPrivateDB()
                );
            });
    }

    // #endregion

    //#region helpers 

    private getUserDbName(friendUserId: string) {
        return (this.chatMessageStoreName + "_" + this.userFullInfo.KullaniciId + "_" + friendUserId);
    }

    private getOfflineUserDbName(friendUserId: string) {
        return (this.chatMessageStoreName + "_offline_" + this.userFullInfo.KullaniciId + "_" + friendUserId);
    }

    private gettIndexedDbKeyRangeByOptions(keyRange) {
        if (keyRange.upper && keyRange.lower)
            return IDBKeyRange.bound(keyRange.lower, keyRange.lowerOpen, keyRange.upper, keyRange.upperOpen);
        else if (keyRange.upper)
            return IDBKeyRange.upperBound(keyRange.upper, keyRange.upperOpen);
        else if (keyRange.lower)
            return IDBKeyRange.lowerBound(keyRange.lower, keyRange.upperOpen);
        else
            return null;
    }

    getPrivateDBName(userName: string = "") {

        if (userName == "")
            return this.privateLocalDBName + "_" + this.userFullInfo.KullaniciAdi.toLowerCase();
        else
            return this.privateLocalDBName + "_" + userName.toLowerCase();
    }


    //#endregion

    //#region Create Object Operations

    private createLocalDataForPrivateDB() {
        return [
            { name: this.newMessageCountsStoreName, keyPath: this.newMessageCountsPrivateKey },
            { name: this.settingsStoreName, keyPath: this.settingsPrivateKey },
            { name: this.usersStoreName, keyPath: this.usersPrivateKey },
            { name: this.userProfileImagesStoreName, keyPath: this.userProfileImagesPrivateKey },
            { name: this.notificationStoreName, keyPath: this.notificationPrivateKey },
            { name: this.favoriteRoomsStoreName, keyPath: this.favoriteRoomsPrivateKey },
            { name: this.chatMessageStoreName, keyPath: this.chatMessagePrivateKey },
        ];
    }


    private createLocalDataForPublicDB() {
        return [
            { name: this.settingsStoreName, keyPath: this.settingsPrivateKey },
            { name: this.animattionsStoreName, keyPath: this.animattionsPrivateKey },
            { name: this.roomsStoreName, keyPath: this.roomsPrivateKey },
            { name: this.bannedWordsStoreName, keyPath: this.bannedWordsPrivateKey },
            { name: this.emojisStoreName, keyPath: this.emojisPrivateKey },
            { name: this.roomFavoriteButtonsStoreName, keyPath: this.roomFavoriteButtonsPrivateKey },
            { name: this.userNameIdStoreName, keyPath: this.userNameIdPrivateKey }
        ];
    }

    //#endregion

    //#region set by service response operations 


    setBannedWordsByServiceResponse(serviceResponse: BannedWordData) {
        this.BannedWords = {};
        serviceResponse.data.forEach(bannedWord => {
            this.BannedWords[bannedWord.KELIME.toLowerCase()] = bannedWord.YENI_KELIME;
        });
    }


    //#endregion

    //#region user operations 


    getUserFullInfo(userJsonString) {
        try {
            if (userJsonString === null)
                return null;
            var userJsonObject = userJsonString[0];
            let userFulInfo = new UserFullInfo()
            userFulInfo.KullaniciId = this.utilService.getIntFromJsonData(userJsonObject[UserEnum.KullaniciID]);
            userFulInfo.KullaniciAdi = this.utilService.getStringFromJsonData(userJsonObject[UserEnum.KullaniciAdi]);
            userFulInfo.IkinciKullaniciAdi = this.utilService.getStringFromJsonData(userJsonObject[UserEnum.IkinciKullaniciAdi]);
            userFulInfo.ProfilResmiId = this.utilService.getGuidFromJsonData(userJsonObject[UserEnum.ProfilResmiID]);
            userFulInfo.FlashnickId = this.utilService.getGuidFromJsonData(userJsonObject[UserEnum.FlashnickID]);
            userFulInfo.GeciciOperatorListesi = this.utilService.getIdListFromJson(this.utilService.getStringFromJsonData(userJsonObject[UserEnum.GeciciOperatorListesiJSON]));
            userFulInfo.RutbeAdi = this.utilService.getStringFromJsonData(userJsonObject[UserEnum.RutbeAdi]);
            userFulInfo.RutbeDerece = this.utilService.getIntFromJsonData(userJsonObject[UserEnum.RutbeDerece]);
            userFulInfo.RutbeId = this.utilService.getIntFromJsonData(userJsonObject[UserEnum.RutbeID]);
            userFulInfo.StateId = this.utilService.getIntFromJsonData(userJsonObject[UserEnum.StateName]);
            userFulInfo.Nicktype = this.utilService.GetNickType(userJsonObject[UserEnum.Nicktype]);
            /*this.getPrivateSetting(this.settingsNickTypePrivateKey).then((nicktype) => {
                userFulInfo.Nicktype = nicktype;
            });*/
            userFulInfo.Secretkey = this.utilService.getStringFromJsonData(userJsonObject[UserEnum.Secretkey]);
            userFulInfo.BanOdaList = userJsonObject[UserEnum.BanOdaListJSON]

            userFulInfo.BanSite = this.utilService.getBoolFromJsonData(userJsonObject[UserEnum.BanSite]);
            userFulInfo.MuteOdaList = userJsonObject[UserEnum.MuteOdaListJSON]

            userFulInfo.MuteSite = this.utilService.getBoolFromJsonData(userJsonObject[UserEnum.MuteSite]);
            userFulInfo.Karaliste = this.utilService.getBoolFromJsonData(userJsonObject[UserEnum.Karaliste]);
            userFulInfo.GenelMesajSayisi = this.utilService.getIntFromJsonData(userJsonObject[UserEnum.GenelMesajSayisi]);
            userFulInfo.LastPcimei = this.utilService.getStringFromJsonData(userJsonObject[UserEnum.LastPcimei]);
            userFulInfo.Password = this.utilService.getStringFromJsonData(userJsonObject[UserEnum.Password]);
            userFulInfo.Ip = this.utilService.getStringFromJsonData(userJsonObject[UserEnum.Ip]);
            userFulInfo.Email = this.utilService.getStringFromJsonData(userJsonObject[UserEnum.Email]);
            userFulInfo.DurumMesaj = this.utilService.getStringFromJsonData(userJsonObject[UserEnum.DurumMesaj]);
            userFulInfo.Misafir = this.utilService.getBoolFromJsonData(userJsonObject[UserEnum.Misafir]);
            userFulInfo.KullaniciAdiRenk = this.utilService.getStringFromJsonData(userJsonObject[UserEnum.KullaniciAdiRenk]);
            userFulInfo.AltYetkiliLimiti = this.utilService.getIntFromJsonData(userJsonObject[UserEnum.AltYetkiliLimiti]);
            userFulInfo.Telefon = this.utilService.getStringFromJsonData(userJsonObject[UserEnum.Telefon]);
            userFulInfo.HasPhoneAccount = this.utilService.getBoolFromJsonData(userJsonObject[UserEnum.HasPhoneAccount]);
            userFulInfo.NicktypeForRoom = this.getNickFromJsonType(this.userFullInfo?.NicktypeForRoom);

            userFulInfo.RutbeFullInfo = new RankFullInfo();

            userFulInfo.RutbeFullInfo.ID = this.utilService.getIntFromJsonData(userJsonObject[UserEnum.RutbeID]);
            userFulInfo.RutbeFullInfo.DERECE = this.utilService.getIntFromJsonData(userJsonObject[UserEnum.RutbeDerece]);
            userFulInfo.RutbeFullInfo.ADI = this.utilService.getStringFromJsonData(userJsonObject[UserEnum.RutbeAdi]);
            console.log(`userFulInfo : ${JSON.stringify(userFulInfo)}`)
            return userFulInfo;

        } catch (error) {
            console.log(error);
            return null;
        }
    }


    getNickFromJsonType(value): Nicktype {
        if (this.utilService.isNullOrEmtpyString(value)) {
            return this.getDefaultNickType();
        } else {
            try {
                return JSON.parse(value) as Nicktype
            }
            catch (error) {
                return this.getDefaultNickType();
            }
        }
    }

    getDefaultNickType(): Nicktype {
        let nickType = new Nicktype();
        nickType.B = true;
        nickType.C = "white";
        nickType.F = "Segoe UI";
        nickType.I = false;
        nickType.S = "";
        nickType.T = 0 //default style

        return nickType;
    }

    //#endregion

    //#region Friends Operations 

    refreshFriendListAfterChanges(friendID: number = -1) {
        this.Friends.AllFriends = this.Friends.AllFriends.sort(this.sortFriends.bind(this));
        this.friendsChangedSource.next(friendID);
    }

    sortFriends(a: UserFullInfo, b: UserFullInfo) {

        var sortBayLastChatResult = this.sortByLastChat(a, b);

        if (sortBayLastChatResult === 0)
            return this.sortByStates(a, b);

        return sortBayLastChatResult;
    }

    sortByLastChat(a: UserFullInfo, b: UserFullInfo) {
        var aResult = this.getLastChatIndex(a.KullaniciId);
        var bResult = this.getLastChatIndex(b.KullaniciId);

        if (aResult < bResult)
            return -1;
        else if (aResult === bResult)
            return 0;
        return 1;
    }

    sortByStates(a: UserFullInfo, b: UserFullInfo) {
        var aSortIndex = this.getStateSortIndex(a.StateId);
        var bSortIndex = this.getStateSortIndex(b.StateId);

        if (aSortIndex < bSortIndex)
            return -1;
        else if (aSortIndex === bSortIndex)
            return 0;
        else
            return 1;
    }

    getStateSortIndex(stateId) {
        var obj = StateIdsForSort[stateId];
        if (obj !== null && obj !== undefined)
            return obj;

        return 3;
    }

    getLastChatIndex(userId) {
        var chatList = this.Settings[this.settingsLastChatListPrivateKey];
        if (!chatList)
            chatList = this.settingsLastChatListDefaultValue;

        var obj = chatList[userId];
        if (obj !== null && obj !== undefined)
            return obj;

        return this.settingsLastChatListMinIndexDefaultValue;
    }

    getFriendById(userId): Friend {
        try {

            if (this.Friends === null || this.Friends === undefined)
                return null;

            return this.Friends.AllFriends.filter(x => x.KullaniciId === userId)[0];
        } catch (error) {
            return null;
        }
    }

    //#endregion

    // #region Controls
    containsBlockedByMeUsers(kullaniciId: number) {
        let flag: boolean = false;
        if (this.Friends.BlockedByMeFriends && this.Friends.BlockedByMeFriends.length > 0) {
            this.Friends.BlockedByMeFriends.forEach(f => {
                if (f.KullaniciId == kullaniciId) {
                    flag = true;
                }
            })
        }
        return flag;
    }

    containsBlockingMeUsers(kullaniciId: number) {
        let flag: boolean = false;
        if (this.Friends.BlockedMeFriends && this.Friends.BlockedMeFriends.length > 0) {
            this.Friends.BlockedMeFriends.forEach(f => {
                if (f.KullaniciId == kullaniciId) {
                    flag = true;
                }
            })
        }
        return flag;
    }
    // #endregion

    isMyFriend(userId: number): boolean {
        if (this.utilService.isNullOrEmtpyObject(this.Friends) || this.utilService.isNullOrEmtpyObject(this.Friends.AllFriends)) {
            return false;
        }
        let user: Friend = this.Friends.AllFriends.find(f => f.KullaniciId === userId);
        if (!this.utilService.isNullOrEmtpyObject(user) &&
            user.StateId !== StateIdList.GelenIstek &&
            user.StateId !== StateIdList.GonderilenIstek) {
            return true;
        }
        return false;
    }

    setRoomUsersFullInfo(list: Array<UserFullInfo>) {
        this.RoomUserListFullInfos = list;
    }
}
