import { LoginService } from 'src/app/core/services/login.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { RoomService } from 'src/app/core/services/room.service';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
  selector: 'app-username-entry',
  templateUrl: './username-entry.component.html',
  styleUrls: ['./username-entry.component.scss']
})
export class UsernameEntryComponent implements OnInit {
  userName: string = '';
  email: string = '';
  telefon: string = '';
  @ViewChild("roomPass") roomPass: ElementRef<HTMLElement>;


  constructor(
    private roomService: RoomService,
    private utilService: UtilService,
    private messagePanelService: MessagePanelService,
    public panelManagerService: PanelManagerService,
    public loginService:LoginService
  ) {

  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.roomPass.nativeElement.focus();
  }

  async userNameControl() {
    if (this.utilService.isNullOrEmtpyString(this.userName)) {
      this.messagePanelService.ShowPopupMessageWithLocalization('DontBlankWarning', 2000);
      return;
    }
  
    try {
      const permissionsGranted = await this.checkPermissions();
      if (permissionsGranted) {
        this.callGetRoomPassToken();
      } else {
        this.messagePanelService.ShowPopupMessageWithLocalization('PermissionDeniedWarning', 2000);
      }
    } catch (error) {
      this.messagePanelService.ShowPopupMessageWithLocalization('PermissionCheckError', 2000);
    }
  }
  
  async checkPermissions(): Promise<boolean> {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const cameraGranted = devices.some(device => device.kind === 'videoinput' && device.label !== '');
      const microphoneGranted = devices.some(device => device.kind === 'audioinput' && device.label !== '');
  
      if (!cameraGranted) {
        const cameraPermission = await this.requestCameraPermission();
        if (!cameraPermission) return false;
      }
  
      if (!microphoneGranted) {
        const microphonePermission = await this.requestMicrophonePermission();
        if (!microphonePermission) return false;
      }
  
      return true;
    } catch (error) {
      console.error('Error while checking permissions:', error);
      return false;
    }
  }
  
  async requestCameraPermission(): Promise<boolean> {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      stream.getTracks().forEach(track => track.stop());
      return true;
    } catch (error) {
      console.error('Camera permission denied:', error);
      return false;
    }
  }
  
  async requestMicrophonePermission(): Promise<boolean> {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach(track => track.stop());
      return true;
    } catch (error) {
      console.error('Microphone permission denied:', error);
      return false;
    }
  }
  
  

  callGetRoomPassToken() {
    let body = {
      userName: this.userName,
      email: this.email,
      telefon: this.telefon
    }
    this.loginService.callAIUserLogin(body)
      .then((response: any) => {
        if (response != "") {
          this.successGetRoomPassToken(response);
        } else
          this.errorGetRoomPassToken();
      })
      .catch(error => {
        console.log(error);
      })
  }

  successGetRoomPassToken(response:any) {
    this.panelManagerService.userNameEntryPanelIsOpened = false;
    this.panelManagerService.userNameEntryPanelResponseSource.next(response);
  }

  errorGetRoomPassToken() {
    this.messagePanelService.ShowPopupMessageWithLocalization("PasswordNotValid", 2000);
    this.close();
  }

  close() {
    this.panelManagerService.userNameEntryPanelIsOpened = false;
  }
}