<div [attr.class]="'room-user ' + (hasMicOrCam?'micuser':'')"
  [ngStyle]="{'left': isView ? '-50px': '0px'}"
  *ngIf="(isVisible === true) && (load | async)">
  
  <div class="mic-div" *ngIf="!isView">
    <img [attr.src]="userMuteAudioIconUrl" class="mic-img">
  </div>

  <div class="cam-div" *ngIf="!isView">
    <img [attr.src]="iconUrl" class="cam-img"  (click)="activateMicState()">
  </div>

  <div class="userListUserDiv" (click)="showUserCard()">
    <div class="hoverinfo" [ngStyle]="{'width': isView ? '100%' : 'calc(100% - 70px)'}">
      <div class="username">
        <span>{{ roomUserListService.userViewInRoom[roomUser?.KullaniciId]?.username }}</span>
      </div>
      <div class="userrankname">
        <span>{{ roomUserListService.userViewInRoom[roomUser?.KullaniciId]?.rutbeAdi }}</span>
      </div>
    </div>
    <img *ngIf="flashNick!==''" [attr.src]="flashNick" [ngStyle]="{'width': '150px', 'height': '50px', 
    'left': isView ? '0px' : '50px', 'position': 'absolute'}" />
    <div class="user-photo-div" *ngIf="flashNick===''">
      <app-user-photo [userPhotoId]="roomUser?.ProfilResmiId" [stateId]="roomUser?.StateId" [width]="40" [height]="40"
        [showOnlineStateIcon]="false"></app-user-photo>
    </div>
    <div class="user-info-div" *ngIf="flashNick===''">
      <div class="user-name-div">
        <span [class]="'user-name ' + userNameClass" [ngStyle]="userNameStyle">
          <p>{{ roomUserListService.userViewInRoom[roomUser?.KullaniciId]?.username }}</p>
        </span>
      </div>
      <div class="user-rank-div" *ngIf="roomUser?.RutbeDerece && !roomUserListService.userViewInRoom[roomUser?.KullaniciId]?.showJustSecondNick">
        <fa-icon [icon]="faStar" class="user-rank" *ngFor="let rank of rankDegreeArray"></fa-icon>
      </div>
    </div>
  </div>
</div>