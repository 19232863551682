<p-dialog styleClass="change-font" [(visible)]="panelManagerService.changeFontPopupDisplay" [modal]="true"
    [baseZIndex]="10000" [responsive]="true">
    <p-header>
        {{'menu_changefont' | translate}}
    </p-header>

    <p-scrollPanel class="font-area font-families" [style]="{'width': '100%', 'height': '150px'}">
        <p-listbox styleClass="fonts" [options]="fontService.fontFamilies" [(ngModel)]="nicktype.F">
            <ng-template let-family pTemplate="item">
                <div style="display: inline-flex;">
                    <span [ngStyle]="{'font-family': family.value}">{{family.value}}</span>
                </div>
            </ng-template>
        </p-listbox>
    </p-scrollPanel>
    <br>
    <div class="fonts-area">
        <p-scrollPanel class="font-area colors" [style]="{'width': '100%', 'height': '100px',  'overflow-x': 'hidden'}">
            <p-listbox [options]="colors" [listStyle]="{'background-color':'#797878'}" [(ngModel)]="nicktype.C">
                <ng-template let-color pTemplate="item">
                    <div class="color-tops"
                        [ngStyle]="{'background-color': color.value, 'width': '100%', 'height': '15px',  'margin': '3px auto'}">
                    </div>
                </ng-template>
            </p-listbox>
        </p-scrollPanel>
        <p-scrollPanel class="font-area font-sizes" [style]="{'width': '100%', 'height': '100px'}">
            <p-listbox styleClass="fonts" [options]="fontSizes" [(ngModel)]="nicktype.FS">
                <ng-template let-size pTemplate="item">
                    <div style="display: inline-flex;">
                        <span [ngStyle]="{'font-size': size.value + 'px'}">{{size.label}}</span>
                    </div>
                </ng-template>
            </p-listbox>
        </p-scrollPanel>
        <div class="styles">
            <div class="font-area font-weigths">
                <p-checkbox [style]="{'margin': '10px auto'}" name="bold" binary="true" value="bold"
                    label="{{ 'bold' | translate }}" [(ngModel)]="nicktype.B"></p-checkbox>
            </div>
            <div class="font-area font-styles">
                <p-checkbox [style]="{'margin': '10px auto'}" name="style" binary="true" value="style"
                    label="{{ 'italic' | translate }}" [(ngModel)]="nicktype.I"></p-checkbox>
            </div>
        </div>

    </div>

    <div class="selected-font" [ngStyle]="{
         'font-family': nicktype.F, 
         'font-size': nicktype.FS + 'px', 
         'color': nicktype.C, 
         'font-weight': nicktype.B === true ? 'bold' : 'normal', 
         'font-style': nicktype.I === true ? 'italic' : 'normal'
        }">
        <span> {{ nicktype.F }} ({{nicktype.C}}, {{nicktype.FS}})</span>

    </div>

    <p-footer>
        <button type="button" pButton label="{{ 'save' | translate }}" (click)="saveFontSettings()"></button>
    </p-footer>
</p-dialog>