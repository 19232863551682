import { Component, ViewEncapsulation } from '@angular/core';
import { PanelManagerService } from '../../../services/panel-manager.service';
import { UserService } from '../../../services/user.service';
import { RoomService } from '../../../services/room.service';
import { MessagePanelService } from '../../../services/messagePanel.service';

@Component({
  selector: 'app-update-user-name',
  templateUrl: './update-user-name.component.html',
  styleUrls: ['./update-user-name.component.scss'],
  encapsulation: ViewEncapsulation.Emulated

})
export class UpdateUserNameComponent {

  newUserName:string

  constructor(
    public panelManagerService: PanelManagerService,
    private userService:UserService,
    public roomService:RoomService,
    public messagePanelService: MessagePanelService,

  ) { }

  updateUserName(){
    if (this.newUserName == null || this.newUserName == "") {
      this.messagePanelService.ShowPopupMessageWithLocalization('user-name-blank-characters-cannot-be-entered',2000);
      return
    }
    if (this.newUserName.length < 3 || this.newUserName.length > 13) {
      this.messagePanelService.ShowPopupMessageWithLocalization('user-name-length-invalid', 2000); // Kullanıcı adı geçersiz uzunlukta
      return;
    }
    this.userService.callUpdateUserName(this.newUserName).then(res=>{
      this.roomService.signOut();
    },err =>{
      this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
    })
  }
}
