<div class="chat-list" [ngClass]="{'room-view': panelManagerService.showedFriendList ? true : false}">
    <div class="header">
        <div class="chat">
            <fa-icon [icon]="faArrowLeft" *ngIf="selectedFriendListType !== friendListType.Friends"
                (click)="changeListType(friendListType.Friends)" class="faArrowLeft"></fa-icon>
            <span (click)="changeListType(friendListType.Friends)">{{ 'titlechats' | translate }}</span>
        </div>

        <div class="close-button">
            <fa-icon [icon]="faTimes" class="faTimes" (click)="panelManagerService.closeMainPanel()"></fa-icon>
        </div>

        <span class="sub-header"
            [ngStyle]="{'border-bottom-color': selectedFriendListType === friendListType.IncomingFriends ? '#9e99ff': 'transparent' }"
            (click)="changeListType(friendListType.IncomingFriends)">{{ 'incomingfriendrequests' | translate }}
            <span class="notification" *ngIf="indexedDBService.Friends?.IncomingFriends?.length > 0">{{
                indexedDBService.Friends?.IncomingFriends?.length }}</span>
        </span>

        <span class="sub-header"
            [ngStyle]="{'border-bottom-color': selectedFriendListType === friendListType.OutgoingFriends ? '#9e99ff': 'transparent' }"
            (click)="changeListType(friendListType.OutgoingFriends)">{{ 'sentfriendrequests' | translate }}</span>


        <div class="sub-header nav-header">
            <input type="text" [(ngModel)]="searchKey" placeholder="{{ 'search' | translate }}" class="search-input">
        </div>
    </div>

    <p-scrollPanel
        [style]="{width: '100%', height: deviceService.isMobile ? (panelManagerService.showedFriendList ? 'calc(100% - 130px)' : 'calc(100% - 190px)')  : 'calc(100% - 130px)'}">
        <div class="empty-list" (click)="changeListType(friendListType.Friends)"
            *ngIf="selectedFriendListType === friendListType.IncomingFriends && indexedDBService.Friends.IncomingFriends.length <= 0"
            innerHTML="{{ 'userIncomingRequestListDivEmptyMessage' | translate }}"></div>

        <div class="empty-list" (click)="changeListType(friendListType.Friends)"
            *ngIf="selectedFriendListType === friendListType.OutgoingFriends && indexedDBService.Friends.OutgoingFriends.length <= 0"
            innerHTML="{{ 'userSentRequestListDivEmptyMessage' | translate }}"></div>

        <div class="friend" [ngClass]="{'my-friend': selectedFriendListType === friendListType.Friends ? true : false}"
            (click)="clickOnFriend(friend)"
            *ngFor="let friend of 
            (selectedFriendListType === friendListType.Friends ? 
            indexedDBService.Friends.AllFriends : 
                (selectedFriendListType === friendListType.IncomingFriends ? indexedDBService.Friends.IncomingFriends : indexedDBService.Friends.OutgoingFriends )) | filterFriends:searchKey; let i=index">

            <div class="friend-menu" *ngIf="selectedFriendListType === friendListType.Friends"
                (click)="openContextMenu(friend)">
                <fa-icon [icon]="faAngleDown" class="faAngleDown"></fa-icon>
            </div>

            <app-user-photo [userPhotoId]="friend.ProfilResmiId" [stateId]="friend.StateId" [width]="46" [height]="46"
                [showOnlineStateIcon]="true" class="friend-photo"></app-user-photo>

            <div class="friend-name"
                [ngClass]="{'friend-name-alignment': selectedFriendListType === friendListType.Friends && friend.DurumMesaj === '' ? true : false}">
                <span>{{friend.KullaniciAdi}}</span>
                <app-rank [degree]="friend.RutbeDerece" [color]="'#919191'" [size]="deviceService.isMobile ? 8 : 9">
                </app-rank>
            </div>

            <small class="state-message"
                *ngIf="selectedFriendListType === friendListType.Friends">{{friend.DurumMesaj}}</small>
            <small class="rank-name"
                *ngIf="selectedFriendListType !== friendListType.Friends">{{friend.RutbeAdi}}</small>

            <div class="action"
                [ngStyle]="{'grid-column': selectedFriendListType === friendListType.Friends ? '4' : '3'}">
                <small class="rank-name"
                    *ngIf="selectedFriendListType === friendListType.Friends">{{friend.RutbeAdi}}</small>
                <app-user-id (click)="copy()" *ngIf="selectedFriendListType === friendListType.Friends"
                    [userId]="friend.KullaniciId"></app-user-id>

                <!-- *ngIf="userMessageTime[friend.KullaniciId]" -->
                <div class="notification" *ngIf="indexedDBService.messageCounts[friend.KullaniciId]>0">
                    <span>{{indexedDBService.messageCounts[friend.KullaniciId]}}</span>
                </div>

                <p-button label="{{ 'acceptrequest' | translate }}" (onClick)="acceptRequest(friend)"
                    *ngIf="selectedFriendListType === friendListType.IncomingFriends"></p-button>
                <p-button label="{{ 'rejectrequest' | translate }}"
                    *ngIf="selectedFriendListType === friendListType.IncomingFriends"
                    (onClick)="deleteRequest(friend.KullaniciId, friend.KullaniciAdi, friendListType.IncomingFriends)"></p-button>

                <p-button label="{{ 'deleterequest' | translate }}"
                    *ngIf="selectedFriendListType === friendListType.OutgoingFriends"
                    (onClick)="deleteRequest(friend.KullaniciId, friend.KullaniciAdi, friendListType.OutgoingFriends)"></p-button>
            </div>
        </div>
    </p-scrollPanel>
</div>

<app-friendlist-context-menu [friend]="currentFriend"></app-friendlist-context-menu>