import { Component } from '@angular/core';
import { faTrashAlt, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FileMessageData, FileMessageUploadResponse } from 'src/app/core/models/chat-message';
import { MessageSendingType } from 'src/app/core/models/enums';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { RoomService } from 'src/app/core/services/room.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-send-folder-to-room',
  templateUrl: './send-folder-to-room.component.html',
  styleUrls: ['./send-folder-to-room.component.scss'],
})
export class SendFolderToRoomComponent {
  faUpload = faUpload;
  faTrashAlt = faTrashAlt;
  selectedFile: File | null = null;
  selectedFileSizeInKB: number | null = null;

  docsFormData= new FormData();
  isImage: boolean = false;
  isFile: boolean = false;
  Url: string = ""
  path:string = ""
  duration: number = 2;

  isUploading = false;
  constructor(
    public panelManagerService: PanelManagerService,
    public roomService:RoomService,
    public messagePanelService: MessagePanelService,
    public indexedDBService: IndexedDBService

  ) { }


  shareImageToRoomOnInputClick($event) {
    $event.target.value = '';
    $event.stopPropagation();
  }

  shareImageToRoomOpenFileUpload($event) {
    $event.preventDefault();
    let element: HTMLElement = document.getElementById('roomSendImageUpload') as HTMLElement;
    element.click();
  }

  shareImageToRoomFileChangeEvent($event) {
    if ($event.target.files && $event.target.files.length) {
      for (const file of $event.target.files) {
        if (file.type.startsWith('image/')) {
          this.selectedFile = file;
          this.selectedFileSizeInKB = file.size / 1024;
          this.docsFormData = new FormData();
          this.docsFormData.append('file', file);
          this.docsFormData.append('receiver', this.roomService.currentRoom.Info.ID.toString());
          this.docsFormData.append('duration', this.duration.toString());
  
          this.isImage = true;
          this.isFile = false;
        } else {
          this.messagePanelService.ShowPopupMessageWithLocalization('only-image', 2000);
        }
      }
    }
  }

  shareDocsToRoomOnInputClick($event) {
    $event.target.value = '';
    $event.stopPropagation();
  }

  shareDocsToRoomOpenFileUpload($event) {
    $event.preventDefault();
    let element: HTMLElement = document.getElementById('roomSendDocsUpload') as HTMLElement;
    element.click();
  }

  shareDocsToRoomFileChangeEvent($event: any) {
    if ($event.target.files && $event.target.files.length) {
      for (const file of $event.target.files) {
        const allowedExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'txt'];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        
        if (allowedExtensions.includes(fileExtension)) {
          this.selectedFile = file;
          this.selectedFileSizeInKB = file.size / 1024;
  
          this.docsFormData = new FormData();
          this.docsFormData.append('file', file);
          this.docsFormData.append('receiver', this.roomService.currentRoom.Info.ID.toString());
          this.docsFormData.append('duration', this.duration.toString());
          
          this.isImage = false;
          this.isFile = true;
        } else {
         this.messagePanelService.ShowPopupMessageWithLocalization('docs-file-type', 2000);
        }
      }
    }
  }

clearSelectedFile() {
  this.selectedFile = null;
  this.selectedFileSizeInKB = null;
}

uploadFile(){
  if (this.indexedDBService.userFullInfo.StateId == 2) {
      this.messagePanelService.ShowPopupMessageWithLocalization('offline-user-cannot-send-message', 3000);
      return
  }
  if (this.roomService.currentMessageSendingType !== MessageSendingType.Normal ) {
    this.messagePanelService.ShowPopupMessageWithLocalization('file-message-cannot-sent-all-room', 3000);
    return
  }
  if (this.docsFormData) {
    this.isUploading = true;
    this.roomService.roomDocsUpload(this.docsFormData).then((res:FileMessageUploadResponse) => {
      if (res.Result !== true) {
        this.messagePanelService.ShowPopupMessageWithLocalization('file-message-failed', 2000);
        return
      }
      this.path = res.Path
      this.Url = res.URL
    })
    .then(() => {
      let prepareMessageData:FileMessageData = {
        isFile: this.isFile,
        isImage: this.isImage,
        url:  this.Url,
        path: this.path
      }
      this.roomService.roomFileMessageReceivedSource.next(prepareMessageData) 
    })
    .finally(() => {
      this.messagePanelService.ShowPopupMessageWithLocalization('file-message-sent', 2000);
      this.panelManagerService.sendFolderToRoomPopupDisplay = false
      this.isUploading = false;
    })
  }
}
}
