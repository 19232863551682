import { LivekitService } from './../../../../livekit/services/livekit.service';

import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { RoomUserListService } from '../../../../services/room-user-list-service';
import { UtilService } from '../../../../services/util.service';
import { Constants } from 'src/app/core/models/constants';
import { RoomUser, RoomUserContextMenuMode } from 'src/app/core/models/room-user';
import { Nicktype } from 'src/app/core/models/nick-type';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { RoomService } from 'src/app/core/services/room.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { Subscription } from 'rxjs';
import { DeviceService } from 'src/app/core/services/device.service';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { MicLevel } from 'src/app/core/models/micLevel';

@Component({
  selector: 'app-room-user-list-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class RoomUserListUserComponent implements OnInit {
  faStar = faStar;

  @Input() roomUser: RoomUser;
  roomUserContextMenuMode: RoomUserContextMenuMode;

  userNameStyle: object;
  flashNick: string = '';
  isVisible: boolean = false;
  roomCamIconUrl: string = Constants.roomCamIconUrl;
  roomScreenShareUrl: string = ""
  load: Promise<boolean>;
  iconUrl: string;
  hasMicOrCam: boolean = false;
  rankDegreeArray: Array<number> = [];
  //userListBackground: object;
  isView: boolean = false;
  userNameClass: string;
  micLevelHeight: string;
  isOnMic: boolean;

  roomUserUpdatedSubscription: Subscription;
  micLevelChangedSubscription: Subscription;

  userMuteAudioIconUrl: string = "";

  constructor(
    public roomUserListService: RoomUserListService,
    public utilService: UtilService,
    public indexedDBService: IndexedDBService,
    public roomService: RoomService,
    public panelManagerService: PanelManagerService,
    public deviceService: DeviceService,
    public livekitService:LivekitService
  ) {
    this.roomUserUpdatedSubscription = this.roomUserListService.roomUserUpdated.subscribe((roomUser: RoomUser) => {
      if (this.roomUser.KullaniciId === roomUser.KullaniciId) {
        this.roomUser = roomUser;
        this.updateUserInfo(this.roomUser);
        this.setUserVisibility(roomUser, true);
      }
    });

  }

  ngOnInit() {
    //TODO önce burada user ben miyim kontrolünü yap, bensem direkt updateuserinfoya gir vs..
    // ben değilsem setuservisibility e gir ve orada duruma göre updateuserinfoyu çağır...
    if (this.roomUser && this.roomUser.KullaniciId === this.indexedDBService.userFullInfo.KullaniciId) {
      this.updateUserInfo(this.roomUser);
      this.isVisible = true;
    }
    else {
      this.setUserVisibility(this.roomUser, this.isView ? false : true);
    }
  }

  ngOnDestroy(): void {
    if (this.roomUserUpdatedSubscription)
      this.roomUserUpdatedSubscription.unsubscribe();

    if (this.micLevelChangedSubscription)
      this.micLevelChangedSubscription.unsubscribe();
  }

  setRankDegreeArray() {
    this.rankDegreeArray = this.utilService.getArray(this.roomUserListService.userViewInRoom[this.roomUser.KullaniciId].rutbeDerece);
  }

  setUserVisibility(roomUser: RoomUser, isEvent: boolean) {
    if (roomUser.KullaniciId === this.roomUser?.KullaniciId) {
      if (this.roomService.isUserVisible(roomUser.StateId, roomUser.RutbeDerece, roomUser.KullaniciId) === true) {
        if (!this.isVisible) {
          this.isVisible = true;
        }
        this.updateUserInfo(roomUser);
      } else {
        if (this.isVisible) {
          this.isVisible = false;
        }
      }
    }
  }

  updateUserInfo(roomUser) {
    this.roomUser = roomUser;
    this.setIconUrl();
    this.setEditor();
    this.setCamIconUrl();
    this.setShareScreenIconUrl();
    this.setRankDegreeArray();
    if (this.deviceService.isMobile === false)
      this.setFlashNick();
    this.setUserNameStyle();
    this.setUserNameClass();
    this.load = Promise.resolve(true);
  }

  /*setRoomBackground() {
    this.userListBackground = {
      'background': this.utilService.hexToRgb(this.roomService.currentRoom.Info.RENK_USER_1) + 'linear-gradient(to right, ' + this.utilService.hexToRgb(this.roomService.currentRoom.Info.RENK_USER_1) + ',' + this.utilService.hexToRgb(this.roomService.currentRoom.Info.RENK_USER_2) + ')'
    };
    this.userListBackground = Object.values(this.userListBackground)[0];
  }*/

  set(roomUser: RoomUser, userListBackground: object, isView: boolean = false) {
    this.roomUser = roomUser;
    /* this.userListBackground = userListBackground;
     this.userListBackground = Object.values(this.userListBackground)[0];*/
    this.isView = isView;
    // this.roomUser?.StateId offline sa style class ı değiştir
    this.load = Promise.resolve(true);
  }

  setFlashNick() {
    var roomNickType = this.getUserRoomNickTypeInternal();
    this.indexedDBService.getPrivateSetting(this.indexedDBService.settingsShowFlashNickPrivateKey)
      .then((showFlashNick) => {
        if (showFlashNick === true && roomNickType.T === 1 &&
          this.utilService.IsNullOrWhitespace(this.roomUser?.IkinciKullaniciAdi) === true &&
          !this.utilService.isNullOrEmtpyString(this.roomUser?.FlashnickId) &&
          this.roomUser?.FlashnickId !== Constants.guidEmpty) {

          this.flashNick = Constants.veri24MessengerUserPhotosUrl + "flash_" + this.roomUser?.FlashnickId.toLowerCase() + ".gif";
        }
        else {
          this.flashNick = '';
        }
      });
  }

  setEditor() {
    if (this.roomUser?.KullaniciId === this.indexedDBService.userFullInfo.KullaniciId) {
      if (
        (this.roomUser?.BanSite === false) &&
        (this.roomUser?.BanOda === false)
      ) {
        this.panelManagerService.contentEditable = true;
      }
      else {
        this.panelManagerService.contentEditable = false;
      }
    }
  }

  setIconUrl() {
    this.isOnMic = false;
    this.iconUrl = "";

    if (this.roomUser?.BanOda === true || this.roomUser?.BanSite === true) {
      this.iconUrl = Constants.roomBannedIconUrl;
    }
    if (this.roomUser?.HandUp === true){
      this.iconUrl = Constants.roomHandIconUrl;
    }
    if (this.roomUser?.MuteOda === true || this.roomUser?.MuteSite === true) {
      this.iconUrl = Constants.roomUserMutedIconUrl
    }
    if (this.roomUser?.IsAudioMuted === true) {
      this.userMuteAudioIconUrl = "../../../../../../assets/icons/audio-mute.png"
    }

  }

  setCamIconUrl() {
    if (this.roomUser?.IsCamOpen) {
      this.roomCamIconUrl = Constants.roomCamIconUrl;
    }
    else
      this.roomCamIconUrl = "";
  }
  setShareScreenIconUrl() {
    if (this.roomUser?.IsScreenShareOpen) {
      this.roomScreenShareUrl = "./../../../../../../assets/icons/screen-share.png"
    }
    else{
      this.roomScreenShareUrl = "";
    }
  }

  setUserNameStyle() {
    let nickType = this.getUserRoomNickType();
    // let textShadow: string = '';
    // if (this.utilService.isNullOrEmtpyString(nickType.S) || nickType.S === 'none') {
    //   textShadow = '1px 1px 1px white'; //'0px 0px 4px white'; 
    // } else {
    //   if ((nickType.S).includes('px'))
    //     textShadow = nickType.S;
    //   else if ((nickType.S).includes('#'))
    //     textShadow = "2px 2px 5px " + nickType.S;
    //   else {
    //     try {
    //       textShadow = "2px 2px 5px #" + (JSON.parse(nickType.S) >>> 0).toString(16).slice(-6);
    //     } catch (err) {
    //       console.log(err)
    //     }
    //   }
    // }

    this.userNameStyle = {
      'font-weight': (nickType.B ? "bold" : "normal"),
      'font-style': (nickType.I ? "italic" : "normal"),
      'color': typeof nickType.C === "number" ? "#fff" : nickType.C,
      'font-family': nickType.F
    }


  }

  setUserNameClass() {
    let nickType = this.getUserRoomNickType();
    if (nickType.T !== null && nickType.T !== 0 && nickType.T !== 9) {
      this.userNameClass = Constants.roomUserNameStylePrefix + (nickType.T - 1);
    }
    else{
      this.userNameClass = Constants.roomUserNameStylePrefix + nickType.T;
    }
  }

  getUserRoomNickType(): Nicktype {
    if (this.utilService.isNullOrEmtpyString(this.roomUser?.IkinciKullaniciAdi))
      return this.getUserRoomNickTypeInternal();

    if (this.roomUser?.KullaniciId === this.indexedDBService.userFullInfo.KullaniciId)
      return this.getUserRoomNickTypeInternal();

    if (this.roomUser?.RutbeDerece < this.indexedDBService.userFullInfo.RutbeFullInfo.DERECE)
      return this.getUserRoomNickTypeInternal();

    return new Nicktype(); // ikinici nicki görünüyorsa rütbesi görünmeyecek 
  }

  getUserRoomNickTypeInternal() {
    if (this.utilService.isNullOrEmtpyString(this.roomUser?.Nicktype) || this.roomUser?.Nicktype.T === 0) {
      this.flashNick = '';
      return new Nicktype();
    }

    if (typeof this.roomUser?.Nicktype === 'string')
      return JSON.parse(this.roomUser?.Nicktype);

    return this.roomUser?.Nicktype;
  }

  watchCamera() {
    this.roomService.watchUserCam(this.roomUser?.KullaniciId);
    this.panelManagerService.showedRoomUserList = false;
  }
  watchScreenShare() {
    this.roomService.watchUserScreenShare(this.roomUser?.KullaniciId);
  }

  activateMicState() {
    this.roomService.activateMicState();
  }

  showUserCard() {
    if (this.isView) {
      this.roomService.roomUserContextMenuMode = RoomUserContextMenuMode.ViewUsersInRoom;
    }
    else {
      this.roomService.roomUserContextMenuMode = RoomUserContextMenuMode.RoomUserList;
    }

    this.roomService.selectedRoomUser = this.roomUser;
    this.panelManagerService.roomUserCardPopupDisplay = true;
  }
}
