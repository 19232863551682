import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ConfirmationDialogService } from '../../../services/confirmation-dialog.service';
import { ConfirmationDialogParams } from 'src/app/core/models/dialog';
import { faTintSlash } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ConfirmationDialogComponent implements OnInit, OnDestroy {

  params: ConfirmationDialogParams = new ConfirmationDialogParams();
  popupShowedSubscription;



  constructor(private confirmationDialogService: ConfirmationDialogService) { }

  ngOnInit() {
    this.popupShowedSubscription = this.confirmationDialogService.popupShowed
      .subscribe(params => {
        this.params = params;
      });
  }

  ngOnDestroy() {
    if (this.popupShowedSubscription)
      this.popupShowedSubscription.unsubscribe();
  }

  okClick() {
    this.params.title = "";
    this.params.message = "";
    this.params.acceptLabel = "";
    this.params.rejectLabel = "";
    this.params.style = "";
    this.params.headerStyle = {};
    this.params.display = false;
    if (this.params.ok)
      this.params.ok();
  }

  cancelClick() {
    this.params.title = "";
    this.params.message = "";
    this.params.acceptLabel = "";
    this.params.rejectLabel = "";
    this.params.style = "";
    this.params.headerStyle = {};
    this.params.display = false;
    if (this.params.cancel)
      this.params.cancel();
  }
}
