<div class="top">
    <div class="admin-header" #filterArea>
        <div class="tab-buttons-view" (click)="panelManagerService.closeRoomRecordingPage()">
            <fa-icon [icon]="faTimes"></fa-icon>
        </div>
    </div>
    <div class="table-section">
        <p-scrollPanel [style]="{width: '100%', height: height}">
            <p-table [value]="roomRecords" [rowHover]="true" *ngIf="loaded" [paginator]="true" [(first)]="first" [lazy]="true"
                [rows]="pageSize" [totalRecords]="totalCount" (onLazyLoad)="loadRecordssLazy($event)">
                <ng-template pTemplate="caption">
                    <button pButton type="button" class="ui-button-raised refresh-button m-auto" icon="pi pi-refresh"
                        pTooltip="{{ 'refreshList' | translate }}" (click)="refreshList()" tooltipPosition="bottom" >
                    </button>
                    <h1 class="list-header">{{ 'room-records' | translate }}</h1>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th scope="col" *ngFor="let col of cols">
                            {{ col.header | translate }}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-request >
                    <tr>
                        <td>{{request.id}}</td>
                        <td>{{request.EndedAt}}</td>
                        <td class="ban-detail-cell">
                            <fa-icon [icon]="request.OnlyAudio ? faMusic : faPlay" class="ban-detail"
                                [pTooltip]="request.OnlyAudio ? 'Sesi Oynat' : 'Videoyu oynat'" tooltipPosition="bottom"
                                (click)="openVideoPanel(request.PlaylistName)"></fa-icon>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-scrollPanel>
        <div *ngIf="isThereError">{{ 'CannotFindData' | translate }}</div>
    </div>
</div>

<app-playing-video *ngIf="panelManagerService.viewRecordVideoPopupDisplay"></app-playing-video>