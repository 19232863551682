export class Language{
    key: string;
    value: string;
    label: string;

    constructor(key: string, value: string, label: string) {
        this.key = key;
        this.label = label;
        this.value = value;
    }
}