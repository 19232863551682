<p-scrollPanel class="mic-settings-panel" [style]="{ height: 'auto', width: 'auto', 'min-height': '150px', 'min-width': '200px' }" #micSettingsControl
[style.display]="panelManagerService.showMicSettings ? 'block' : 'none'">
    <div class="audio-settings">
        <p class="close" #close>x</p>
        <div *ngIf="livekitService.isOnMic" >
            <div class="title">{{'mics'| translate}}</div>
            <select id="audio-input"  [ngModel]="livekitService.selectedAudioInputDeviceId"  (change)="livekitService.handleDeviceSelected($event.target.value, 'audioinput')">
                <option *ngFor="let device of livekitService.audioInputDevices" [value]="device.deviceId">{{ device.label }}</option>
              </select>
        </div>
        <div *ngIf="livekitService.isOnMic">
            <div class="title">{{'outgoing-sound'| translate}}</div>
            <p-inputSwitch [(ngModel)]="!livekitService.allSenderAudioTrackAreMuted" (onChange)="toggleMyAudioMute()">
            </p-inputSwitch>
        </div>
        <div *ngIf="livekitService.isOnMic">
            <div class="title">{{'mic-sound'| translate}}</div>
            <p-inputSwitch [(ngModel)]="livekitService.isMicEnabled" (onChange)="toggleMicForMuteUnMute()">
            </p-inputSwitch>
        </div>
        <div>
            <div class="title">{{'incoming-sound'| translate}}</div>
            <p-inputSwitch [(ngModel)]="!roomService.allParticipantAreMuted" (onChange)="toggleMuteAudios()">
            </p-inputSwitch>
        </div>
    </div>
</p-scrollPanel>