<div class="public-container"  *ngIf="load | async" #topElement>
    <div class="sidebar-container">
        <div class="sidebar">
            <div class="sidebar-header">
                <div class="header-left">
                    <label (click)="openHelpLink()">{{'help' | translate}}</label>
                </div>
                <div class="header-center">
                    <img [src]="logo_128" class="site-logo">
                </div>
            
                <div class="header-right">
                    <div class="custom-dropdown">
                        <button class="dropdown-button" (click)="toggleDropdown()">
                            <img [src]="getLanguageFlagUrl(selectedLanguage)" alt="Flag" class="language-flag"/>
                            {{ selectedLanguage.value | translate }}
                            <fa-icon [icon]="faChevronDown" class="dropdown-arrow"></fa-icon>
                        </button>
                        <div class="dropdown-menu" [ngClass]="{ 'show': dropdownOpen }">
                            <div *ngFor="let language of languageService.languages" (click)="selectLanguage(language)">
                                <img [src]="getLanguageFlagUrl(language)" alt="Flag" class="language-flag"/>
                                {{ language.value | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sidebar-content">
                <div class="buttons">
                    <button (click)="signInwithGoogle()" type="button" class="btn btn-primary w-100">
                        <fa-icon [icon]="faGoogle"></fa-icon>
                        {{ 'signInwithGoogle' | translate }}</button>
                </div>
                <div  *ngIf="CanGuestJoin && load | async" class="buttons guest">
                    <button data-testid="fast-login" (click)="signAsGuest()" type="button" class="btn btn-primary w-100">
                        <fa-icon [icon]="faGuest"></fa-icon>
                       {{'fast-login' | translate}}</button>
                </div>
            </div>

        </div>
    </div>
    <div class="app-image" *ngIf="load | async">
        <img [src]="logo_800">
    </div>


    <div class="site-description">
        {{ siteDescription}}
    </div>

</div>

<div class="not-supported" *ngIf="loadedTempPage"></div>



<p-dialog [(visible)]="panelManagerService.newUserRegisterPopupDisplay" [baseZIndex]="10000" [modal]="true"
    [closable]="false" header="{{ 'createUsername' | translate }}">

    <form class="username-form" [formGroup]="usernameForm">
        <input type="text" class="new-username" formControlName="userName" placeholder="{{ 'UserName' | translate }}"
            pInputText minlength="3" maxlength="13" />
        <fa-icon [icon]="faInfoCircle" class="faInfoCircle" tooltipZIndex="99999999"
            pTooltip="{{'UserNameCharLimitWarning'|translate}}" tooltipPosition="bottom"></fa-icon>

        <div class="footer">
            <button type="submit" pButton class="ui-button-raised" label="{{ 'save' | translate }}"
                (click)="checkUsername()"></button>
            <button type="button" pButton class="ui-button-raised ui-button-danger" label="{{ 'cancel' | translate }}"
                (click)="panelManagerService.newUserRegisterPopupDisplay = false"></button>
        </div>

    </form>

</p-dialog>