<div
  id="editor"
  [ngStyle]="editorStyle"
  class="editor"
  [attr.contenteditable]="panelManagerService.contentEditable"  
   (keydown)="onKeydown($event)"
  [innerHTML]="(editorMessageForSet | async)" 
  #editormsg
  [attr.placeholder]="(editorOn === editoron.P2PChat ? 'TypeHere' : (panelManagerService.contentEditable===false?'BanMuteWarning': (roomService.currentMessageSendingType === messageSendingType.Normal ? 'WriteToRoom' : 
  (roomService.currentMessageSendingType === messageSendingType.GenelHerkes ? 'WriteToAllInRoom' : 'WriteToAdminsInRoom')))) | translate"
  (mousedown)="mousedown($event)"  
  (paste)="pasteToEditor($event)"
></div>