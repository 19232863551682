import { RagData } from './../models/rag';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { ServiceInformation } from 'src/app/core/services/serviceinformation.service';
import { UtilService } from 'src/app/core/services/util.service';
import { CurrentRoomAIPage } from '../models/enums';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RoomAITemplate, RoomAITemplateData, RoomAITemplateDetail, TemplateScoreData, TemplateScoreModel, UpdateRoomAITemplateBody } from '../models/roomAITemplate';
import { PromptData, PromptModel } from '../../room-ai-prompts/models/prompt';
import { AdminRoomService } from '../../rooms/services/room.service';
import { SqlUser } from 'src/app/admin/models/users';
import { FileMessageUploadResponse } from 'src/app/core/models/chat-message';
import { RagListResponse } from '../models/rag';
import { error } from 'console';
import { RoomService } from 'src/app/core/services/room.service';




@Injectable({
  providedIn: 'root'
})
export class RoomAiService extends ServiceInformation {
  currentPage = CurrentRoomAIPage.list;


  selectedTTSModel: any
  selectedTTSLanguage: any
  selectedTTSVoice: any
  selectedSTTModel: any
  selectedSTTLanguage: any
  selectedAIModel: any

  aiImageChangedEvent:any;
  imageChangedEvent:any
  aiOwnerImageChangedEvent:any;

  favoriteButtonBackImageID: string;
  roomAiImageId:string
  roomAiOwnerImageId:string

  favouriteButtonEditBackImage: string;
  aiImageEditBackImage: string;
  aiOwnerImageEditBackImage:string

  roomAITemplateList:RoomAITemplate[]
  templateDetails:RoomAITemplateDetail

  deleteButtonBack:boolean = false
  deleteAIImage:boolean = false
  deleteAIOwnerImage:boolean = false

  isAiImageEditMode:boolean = false
  isButtonBackEditMode:boolean = false
  isAiOwnerImageEditMode:boolean = false

  listOfPrompts: PromptData[] = [];
  totalCount:number = 0;
  pageSize: number = 50;

  totalCountOfTemplates: number = 0;
  selectedPromptDetail: PromptData;

  isSelectedSttModelWhisper: boolean = false
  sqlUsers: Array<SqlUser> = [];

  totalCountOfTemplateScores: number = 0;
  templateScoresList:TemplateScoreData[]
  selectedTemplateId: number;

  ragList:RagData[]

  selectedCategoryOrClass : any

  private httpClientForRagApi: HttpClient;
  constructor(
    private http: HttpClient,
    public messagePanelService: MessagePanelService,
    public utilService: UtilService,
    protected indexedDBService: IndexedDBService,
    public roomService:AdminRoomService,
    private handler: HttpBackend,
    public coreRoomService:RoomService
  ) {
    super(messagePanelService, utilService, indexedDBService);
    this.httpClientForRagApi = new HttpClient(this.handler);
  }

  setSelectedUserCategoryOrClass() {
    const type = this.coreRoomService.categoryOrClassList.find(option => option.value === this.templateDetails.CategoryOrClass);
    if (type) {
        this.selectedCategoryOrClass = type;
    } else {
        this.selectedCategoryOrClass = null
    }
  }

  setSelectedTTSModels() {
    const selected = this.coreRoomService.ttsModelList.find(option => option.value === this.templateDetails.TTSModel);
    if (selected) {
      this.selectedTTSModel = selected;
    } else {
      this.selectedTTSModel = null;
    }
  }
  
  setSelectedTTSLanguages() {
    const selected = this.coreRoomService.ttsLanguageList.find(option => option.value === this.templateDetails.TTSLanguage);
    if (selected) {
      this.selectedTTSLanguage = selected;
    } else {
      this.selectedTTSLanguage = null;
    }
  }
  setSelectedTTSVoices() {
    const selected = this.coreRoomService.ttsVoiceList.find(option => option.value === this.templateDetails.TTSVoice);
    if (selected) {
      this.selectedTTSVoice = selected;
    } else {
      this.selectedTTSVoice = null;
    }
  }

  setSelectedSttModels() {
    const selected = this.coreRoomService.sttModelList.find(option => option.value === this.templateDetails.STTModel);
    if (selected) {
      this.selectedSTTModel = selected;
      if (this.selectedSTTModel.value == "whisper") {
        this.isSelectedSttModelWhisper = true 
     }
    } else {
      this.selectedSTTModel = null;
    }
  }

  setSelectedSttLanguages() {
    const selected = this.coreRoomService.sttLanguageList.find(option => option.value === this.templateDetails.STTLanguage);
    if (selected) {
      this.selectedSTTLanguage = selected;
    } else {
      this.selectedSTTModel = null;
    }
  }

  setSelectedAIModels() {
    const selected = this.coreRoomService.aiModelList.find(option => option.value === this.templateDetails.AIModel);
    if (selected) {
      this.selectedAIModel = selected;
    } else {
      this.selectedAIModel = null;
    }
  }

  callCreateRoomTemplate(roomName: string): Promise<any> {
    let body = {
      'room_name': roomName
    };

    return new Promise((resolve, reject) => {
      this.createRoomTemplate(body)
        .subscribe((response) => {
          if (response == false) {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject()
            return;
          }
          resolve(response);
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  callRagFileIndex(): Promise<any>  {
    const fileNamesArray = this.ragList.map(item => item.fileName);
    const body = {
      room_id: this.templateDetails.ID.toString(),
      file_names: fileNamesArray
    };

    return new Promise((resolve, reject) => {
      this.ragFileIndex(body)
        .subscribe((response) => {
          if (response.error) {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject()
            return;
          }
          resolve(response);
        },error => {
          reject();
          this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  ragFileIndex(body): Observable<any> {
   const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const endpoint = 'https://ragindexer.websiteanaliz.com/index';
    return this.httpClientForRagApi.post<any>(endpoint, body, options)
      .pipe(catchError(error => this.handleError(error)));
  }

  createRoomTemplate(body): Observable<any> {
    const endpoint = this.ServiceUrl + "api/admin/room/template/create";
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  callListPrompts(pageIndex:number,searchText:string,isCategory:boolean): Promise<void> {
    let body = {
      'pageSize': this.pageSize, 
      "pageIndex" : pageIndex,
      "searchName" : searchText,
       "isCategory":isCategory
    };

    return new Promise((resolve, reject) => {
      this.getListOfPrompts(body)
        .subscribe((response) => {
          if (this.utilService.isNullOrEmtpyObject(response.data)) {
            this.listOfPrompts = []
            reject()
            return;
          }
          resolve();
          this.listOfPrompts = response.data
          this.totalCount = response.count
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }
  callListRoomTemplates(pageSize:number,pageIndex:number,searchText:string): Promise<void> {
    //TODO:Pagination
    let body = {
      'limit': pageSize, 
      "offset" : pageIndex,
      "searchText":searchText
    };

    return new Promise((resolve, reject) => {
      this.getListOfRoomTemplates(body)
        .subscribe((response) => {
          if (this.utilService.isNullOrEmtpyObject(response.data)) {
            this.roomAITemplateList = []
            this.totalCountOfTemplates = 0
            reject()
            return;
          }
          resolve();
          this.roomAITemplateList = response.data
          this.totalCountOfTemplates = response.count
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  callGetListOfTemplateScore(pageSize:number,pageIndex:number,template_id?:number):Promise<void>{
    let body = {
      'page_size': pageSize, 
      "page_index" : pageIndex,
      "template_id":template_id?template_id:this.selectedTemplateId
    };

    return new Promise((resolve, reject) => {
      this.getListOfTemplateScore(body)
        .subscribe((response) => {
          if (this.utilService.isNullOrEmtpyObject(response.data)) {
            this.templateScoresList = []
            this.totalCountOfTemplateScores = 0
            reject()
            return;
          }
          resolve();
          this.templateScoresList = response.data
          this.totalCountOfTemplateScores = response.count
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  callGetRoomOwnerList(): Promise<void> {
    let body = {
      'ClientInfo': this.Get_ClientInfo(),
      'RutbeId': 0
    };

    return new Promise((resolve, reject) => {
      this.roomService.getRoomOwnerList(body)
        .subscribe((sqlUsers: SqlUser[]) => {
          if (this.utilService.isNullOrEmtpyObject(sqlUsers)) {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject()
            return;
          }
          this.sqlUsers = sqlUsers;
          resolve();
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }
  
  callDeleteRoomTemplate(roomId:number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.deleteRoomTemplateById(roomId)
        .subscribe((response) => {
          if (response != true) {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject()
            return;
          }
          resolve();
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  callRoomTemplateById(roomId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getRoomTemplateById(roomId)
        .subscribe((response) => {
          if (this.utilService.isNullOrEmtpyObject(response)) {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject()
            return;
          }
          this.templateDetails = response
          resolve();
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  calUpdateRoomTemplateById(updateBody:UpdateRoomAITemplateBody,roomId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.updateRoomTemplateById(updateBody,roomId)
        .subscribe((response) => {
          if (response == false) {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject(false)
            return;
          }
          resolve(true);
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }


  callGetRagList(templateId:number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getRagListByTemplateId(templateId)
        .subscribe((response: RagListResponse) => {
          if (!this.utilService.isNullOrEmtpyString(response.err_msg) || this.utilService.isNullOrEmtpyObject(response.data)) {
            this.ragList = []
            reject()
            return;
          }
          this.ragList = response.data.map((item: RagData) => {
            item.type = this.determineFileType(item.path);
            item.extension = this.setFileExtension(item.path);
            item.fileName = this.setFileName(item.path);
            return item;
          });  
          resolve();
        },error => {
          reject()
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  callDeleteRagFileByRagId(ragId:number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.deleteRagFileById(ragId)
        .subscribe((response) => {
          if (response != true) {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject()
            return;
          }
          resolve();
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }
  setFileName(path: string): string {
    const fileName = path.substring(path.lastIndexOf('/') + 1);
    return fileName;
  }
  
  determineFileType(path: string): string {
    const extension = path.split('.').pop()?.toLowerCase();
    
    const imageExtensions = ['jpg', 'jpeg', 'png'];
    const documentExtensions = ['pdf', 'doc', 'docx', 'txt'];
  
    if (imageExtensions.includes(extension)) {
      return 'image';
    } else if (documentExtensions.includes(extension)) {
      return 'document';
    } else {
      return 'unknown';
    }
  }
  setFileExtension(path: string): string {
    const extension = path.split('.').pop()?.toLowerCase();
    return extension || ''; 
  }
  
  getListOfPrompts(body): Observable<PromptModel> {
    const endpoint = this.ServiceUrl + "api/admin/prompt";
    return this.http.post<any>(endpoint,body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }
  getListOfRoomTemplates(body): Observable<RoomAITemplateData> {
    const endpoint = this.ServiceUrl + "api/admin/room/template/list";
    return this.http.post<any>(endpoint,body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  getRoomTemplateById(roomId: number): Observable<RoomAITemplateDetail> {
    const endpoint = this.ServiceUrl + "api/admin/room/template/"+roomId;;
    return this.http.get<any>(endpoint, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  updateRoomTemplateById(updateBody:UpdateRoomAITemplateBody, roomId: number): Observable<any> {
    const endpoint = this.ServiceUrl + "api/admin/room/template/"+roomId;;
    return this.http.post<any>(endpoint, updateBody,this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  deleteRoomTemplateById(roomId: number): Observable<any> {
    const endpoint = this.ServiceUrl + "api/admin/room/template/"+roomId;
    return this.http.delete<any>(endpoint, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  getListOfTemplateScore(body): Observable<TemplateScoreModel> {
    const endpoint = this.ServiceUrl + "api/admin/score/template";
    return this.http.post<any>(endpoint,body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  uploadRAGFile(body:FormData): Promise<FileMessageUploadResponse> {
    const endpoint = this.ServiceUrl + "api/admin/template/rag";
    const options = {};
    return this.http.post<FileMessageUploadResponse>(endpoint, body, options)
      .pipe(catchError(error => this.handleError(error))).toPromise();
  }

  getRagListByTemplateId(templateId: number): Observable<RagListResponse> {
    const endpoint = this.ServiceUrl + "api/admin/template/rag/"+templateId;;
    return this.http.get<any>(endpoint, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  deleteRagFileById(ragId: number): Observable<any> {
    const endpoint = this.ServiceUrl + "api/admin/template/rag/"+ragId;
    return this.http.delete<any>(endpoint, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }
}
