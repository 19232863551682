import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ServiceInformation } from 'src/app/core/services/serviceinformation.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { UtilService } from 'src/app/core/services/util.service';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { SystemSettings } from '../models/system-settings';
import { SelectItem } from 'primeng/api';


@Injectable({
    providedIn: 'root'
})
export class AdminSystemSettingsService extends ServiceInformation {

    systemSettings: SystemSettings;
    allCountryPhoneCodes: SelectItem[];
    bannedCountryPhoneCodesArray: any[];

    constructor(
        private http: HttpClient,
        protected messagePanelService: MessagePanelService,
        protected utilService: UtilService,
        protected indexedDBService: IndexedDBService
    ) {
        super(messagePanelService, utilService, indexedDBService);
        this.allCountryPhoneCodes = [
            { label: 'Turkey', value: '_90' },
            { label: 'Germany', value: '_49' },
            { label: 'France', value: '_33' },
            { label: 'Netherlands', value: '_31' },
            { label: 'USA', value: '_1' },
            { label: 'Belgium', value: '_32' },
            { label: 'Austria', value: '_43' },
            { label: 'Canada', value: '_1' },
            { label: 'Australia', value: '_61' },
            { label: 'Albania', value: '_355' },
            { label: 'Andorra', value: '_376' },
            { label: 'Armenia', value: '_374' },
            { label: 'Azerbaijan', value: '_994' },
            { label: 'Belarus', value: '_375' },
            { label: 'Bosnia and Herzegovina', value: '_387' },
            { label: 'Bulgaria', value: '_359' },
            { label: 'Croatia', value: '_385' },
            { label: 'Cyprus', value: '_357' },
            { label: 'Czech Rep.', value: '_420' },
            { label: 'Denmark', value: '_45' },
            { label: 'England', value: '_44' },
            { label: 'Estonia', value: '_372' },
            { label: 'Finland', value: '_358' },
            { label: 'Georgia', value: '_995' },
            { label: 'Greece', value: '_30' },
            { label: 'Hungary', value: '_36' },
            { label: 'Iceland', value: '_354' },
            { label: 'Ireland', value: '_353' },
            { label: 'Italy', value: '_39' },
            { label: 'Kazakhistan', value: '_7' },
            { label: 'Latvia', value: '_371' },
            { label: 'Liechtenstein', value: '_41' },
            { label: 'Lithuania', value: '_370' },
            { label: 'Luxembourg', value: '_352' },
            { label: 'Macedonia', value: '_389' },
            { label: 'Malta', value: '_356' },
            { label: 'Moldova', value: '_373' },
            { label: 'Monaco', value: '_377' },
            { label: 'Montenegro', value: '_382' },
            { label: 'Norway', value: '_47' },
            { label: 'Poland', value: '_48' },
            { label: 'Portugal', value: '_351' },
            { label: 'Romania', value: '_40' },
            { label: 'Russian Federation', value: '_7' },
            { label: 'San Marino', value: '_378' },
            { label: 'Serbia', value: '_381' },
            { label: 'Slovakia', value: '_421' },
            { label: 'Slovenia', value: '_386' },
            { label: 'Spain', value: '_34' },
            { label: 'Sweden', value: '_46' },
            { label: 'Switzerland', value: '_41' },
            { label: 'Ukraine', value: '_380' },
            { label: 'Afghanistan', value: '_93' },
            { label: 'American Samoa', value: '_684' },
            { label: 'Angola', value: '_244' },
            { label: 'Anguilla', value: '_1264' },
            { label: 'Antigua', value: '_1268' },
            { label: 'Argentina', value: '_54' },
            { label: 'Aruba', value: '_297' },
            { label: 'Asensio', value: '_247' },
            { label: 'Azores-Mader Islands', value: '_351' },
            { label: 'UAE', value: '_971' },
            { label: 'Bahama', value: '_1242' },
            { label: 'Bahrain', value: '_973' },
            { label: 'Bangladesh', value: '_880' },
            { label: 'barbados', value: '_1246' },
            { label: 'Western samoa', value: '_685' },
            { label: 'Belize', value: '_501' },
            { label: 'Benin', value: '_229' },
            { label: 'Bermuda', value: '_1441' },
            { label: 'Bolivia', value: '_591' },
            { label: 'Botswana', value: '_267' },
            { label: 'Brazil', value: '_55' },
            { label: 'Brıtısh Vırgın Man.', value: '_1284' },
            { label: 'Brunei', value: '_673' },
            { label: 'Burkina Faso', value: '_226' },
            { label: 'Burma', value: '_95' },
            { label: 'Burundi', value: '_257' },
            { label: 'Butane', value: '_975' },
            { label: 'Chad', value: '_235' },
            { label: 'Gibraltar', value: '_350' },
            { label: 'Algeria', value: '_213' },
            { label: 'Djibouti', value: '_253' },
            { label: 'China', value: '_86' },
            { label: 'Cook island', value: '_682' },
            { label: 'Digigo Gorsia', value: '_246' },
            { label: 'Dominican', value: '_1767' },
            { label: 'Dominican Rep.', value: '_1809' },
            { label: 'Equator', value: '_593' },
            { label: 'Equatorial Guinea', value: '_240' },
            { label: 'El salvador', value: '_503' },
            { label: 'Indonesia', value: '_62' },
            { label: 'Eritrea', value: '_291' },
            { label: 'Ethiopia', value: '_251' },
            { label: 'Falkland Many.', value: '_500' },
            { label: 'Faroe Islands', value: '_298' },
            { label: 'Morocco', value: '_212' },
            { label: 'Fiji', value: '_679' },
            { label: 'Ivory Coast', value: '_225' },
            { label: 'Philippines', value: '_63' },
            { label: 'French Guiana', value: '_594' },
            { label: 'French Polynesia', value: '_689' },
            { label: 'Gabon', value: '_241' },
            { label: 'Gambia', value: '_220' },
            { label: 'Ghana', value: '_233' },
            { label: 'Guinea', value: '_224' },
            { label: 'Guinea Bissau', value: '_245' },
            { label: 'Grenada', value: '_1473' },
            { label: 'Greenland', value: '_299' },
            { label: 'Guadalupe', value: '_590' },
            { label: 'Guam', value: '_1671' },
            { label: 'Guatemala', value: '_502' },
            { label: 'South African Rep.', value: '_27' },
            { label: 'South Korea', value: '_82' },
            { label: 'Supposedly the', value: '_592' },
            { label: 'Haiti', value: '_509' },
            { label: 'India', value: '_91' },
            { label: 'Netherlands Antilles', value: '_599' },
            { label: 'Honduras', value: '_504' },
            { label: 'Hongkong', value: '_852' },
            { label: 'Iraq', value: '_964' },
            { label: 'Iranian', value: '_98' },
            { label: 'Israel', value: '_972' },
            { label: 'Jamaica', value: '_1876' },
            { label: 'Japan', value: '_81' },
            { label: 'Cambodia', value: '_855' },
            { label: 'Cameroon', value: '_237' },
            { label: 'Cap verd', value: '_238' },
            { label: 'Train', value: '_974' },
            { label: 'Cayman Islands', value: '_1345' },
            { label: 'Kenya', value: '_254' },
            { label: 'Kirghizistan', value: '_996' },
            { label: 'Kiribati', value: '_686' },
            { label: 'Colombia', value: '_57' },
            { label: 'Comoros Islands', value: '_269' },
            { label: 'Congo', value: '_242' },
            { label: 'Costa Rica', value: '_506' },
            { label: 'Cuba', value: '_53' },
            { label: 'Kuwait', value: '_965' },
            { label: 'North Korea', value: '_850' },
            { label: "Lao Dem. People's Rep.", value: '_856' },
            { label: 'lesotho', value: '_266' },
            { label: 'Liberia', value: '_231' },
            { label: 'Libya', value: '_218' },
            { label: 'Lebanon', value: '_961' },
            { label: 'Madagascar', value: '_261' },
            { label: 'Macau', value: '_853' },
            { label: 'Malawi', value: '_265' },
            { label: 'Maldive Islands', value: '_960' },
            { label: 'Malaysia', value: '_60' },
            { label: 'Financial', value: '_223' },
            { label: 'Mariyan Islands', value: '_1670' },
            { label: 'Malsar Islands', value: '_692' },
            { label: 'Martinique', value: '_596' },
            { label: 'Mexican', value: '_52' },
            { label: 'Central African Rep.', value: '_236' },
            { label: 'Micronesia', value: '_691' },
            { label: 'Egypt', value: '_20' },
            { label: 'Mongolia', value: '_976' },
            { label: 'montserrat', value: '_1664' },
            { label: 'Moris Islands', value: '_230' },
            { label: 'Mauritania', value: '_222' },
            { label: 'Mozambique', value: '_258' },
            { label: 'Nakhodka', value: '_7504915' },
            { label: 'Nakhodka', value: '_750492' },
            { label: 'Namibia', value: '_264' },
            { label: 'Nauru Island', value: '_674' },
            { label: 'Nepal', value: '_977' },
            { label: 'Niger', value: '_227' },
            { label: 'Nigeria', value: '_234' },
            { label: 'Nicaragua', value: '_505' },
            { label: 'Niue Islands', value: '_683' },
            { label: 'Norfolk Islands', value: '_672' },
            { label: 'Uzbekistan', value: '_998' },
            { label: 'Pakistan', value: '_92' },
            { label: 'palau', value: '_680' },
            { label: 'Panama', value: '_507' },
            { label: 'Papua New Guinea', value: '_675' },
            { label: 'Paraguay', value: '_595' },
            { label: 'Peru', value: '_51' },
            { label: 'Porto Rico', value: '_1787' },
            { label: 'rADIUS', value: '_750996' },
            { label: 'the Reunyo', value: '_262' },
            { label: 'Rwanda', value: '_250' },
            { label: 'Sakhalin', value: '_750440' },
            { label: 'Sakhalin', value: '_7504416' },
            { label: 'Sakhalin', value: '_750442' },
            { label: 'Sakhalin', value: '_750443' },
            { label: 'Sao Tome & Prıncıpe', value: '_239' },
            { label: 'senegal', value: '_221' },
            { label: 'Seychelles', value: '_248' },
            { label: 'Sierra leone', value: '_232' },
            { label: 'Chile', value: '_56' },
            { label: 'Singapore', value: '_65' },
            { label: 'Slovak Rep.', value: '_421' },
            { label: 'Solomon Islands', value: '_677' },
            { label: 'Somalia', value: '_252' },
            { label: 'Sri lanka', value: '_94' },
            { label: 'ST.HELENA', value: '_290' },
            { label: 'st.kıttıs & Nevis', value: '_1869' },
            { label: 'St. Lucia', value: '_1758' },
            { label: 'St. Marten', value: '_5995' },
            { label: 'st.piy & Miquelon is the', value: '_508' },
            { label: 'St.Vincent & Grenada', value: '_1784' },
            { label: 'Sudan', value: '_249' },
            { label: 'Suriname', value: '_597' },
            { label: 'Syria', value: '_963' },
            { label: 'Saudi Arabia', value: '_966' },
            { label: 'Swaziland', value: '_268' },
            { label: 'Tajikistan', value: '_7' },
            { label: 'Tanzania', value: '_255' },
            { label: 'Tatarstan', value: '_7843' },
            { label: 'I Tatınco', value: '_7513' },
            { label: 'Thailand', value: '_66' },
            { label: 'Taiwan', value: '_886' },
            { label: 'togo', value: '_228' },
            { label: 'the Tokel', value: '_690' },
            { label: 'Tonga', value: '_676' },
            { label: 'Trinidad & Tobago', value: '_1868' },
            { label: 'Tunisia', value: '_216' },
            { label: 'Turkmenistan', value: '_993' },
            { label: 'Turks & Caicos Adl.', value: '_1649' },
            { label: 'Tuvalu', value: '_688' },
            { label: 'U.S.Virgin Island', value: '_1340' },
            { label: 'Uganda', value: '_256' },
            { label: 'Oman', value: '_968' },
            { label: 'Uraguay', value: '_598' },
            { label: 'Jordan', value: '_962' },
            { label: 'Vallis Fununa Adl.', value: '_681' },
            { label: 'Venezuelan', value: '_58' },
            { label: 'Vietnamese', value: '_84' },
            { label: 'Yemen Arab Rep.', value: '_967' },
            { label: "Yemen People's Rep.", value: '_969' },
            { label: 'New Hebrites', value: '_678' },
            { label: 'New Caledonia', value: '_687' },
            { label: 'New zelenda', value: '_64' },
            { label: 'Yugoslavia', value: '_381' },
            { label: 'Zaire', value: '_243' },
            { label: 'Zambia', value: '_260' },
            { label: 'Zanzibar', value: '_259' },
            { label: 'Zimbabwe', value: '_263' }
        ]
    }



    //#region  sms validation
    callSmsValidation(requestSmsValidation: boolean): Promise<any> {
        let body = {
            'ClientInfo': this.Get_ClientInfo(),
            'GetSmsValidation': requestSmsValidation
        };

        return new Promise((resolve, reject) => {
            this.smsValidation(body)
                .subscribe(response => {
                    if (!this.utilService.isNullOrEmtpyObject(response)) {
                        if (response.Result === false || !this.utilService.isNullOrEmtpyString(response.ErrMsg))
                            reject();
                        else
                            resolve(null);
                    }
                    else
                        reject();
                }),
                error => {
                    console.log(error);
                    reject();
                };
        })
    }

    smsValidation(body): Observable<any> {
        const endpoint = this.ServiceUrl + "SmsValidation";
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }
    //#endregion

    //#region get system settings
    callGetSettingsBulkInfo(dayForTotalLoginCount: number, monthForTotalNeverLoginCount: number): Promise<any> {
        let body = {
            'ClientInfo': this.Get_ClientInfo(),
            'DayForTotalLoginCount': dayForTotalLoginCount,
            'MonthForTotalNeverLoginCount': monthForTotalNeverLoginCount
        };

        return new Promise((resolve, reject) => {
            this.getSettingsBulkInfo(body)
                .subscribe((systemSettings: SystemSettings) => {
                    if (!this.utilService.isNullOrEmtpyObject(systemSettings)) {
                        this.systemSettings = systemSettings;
                        resolve(null);
                    }
                    else
                        reject();
                }),
                error => {
                    console.log(error);
                    reject();
                };
        })
    }

    getSettingsBulkInfo(body): Observable<any> {
        const endpoint = this.ServiceUrl + "api/admin/settings";
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }
    //#endregion

    kickAllUserFromSite(): Observable<any> {
        const endpoint = this.ServiceUrl + "api/logout/all";
        return this.http.get<any>(endpoint)
            .pipe(catchError(error => this.handleError(error)));
    }
    //#region  get banned counrty phone codes
    callgetBannedCountryPhoneCodes(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getBannedCountryPhoneCodes()
                .subscribe(response => {
                    if (!this.utilService.isNullOrEmtpyString(response)) {
                        this.bannedCountryPhoneCodesArray = JSON.parse(response);
                        resolve(null);
                    }
                    else
                        reject();
                }),
                error => {
                    console.log(error);
                    reject();
                };
        })
    }

    getBannedCountryPhoneCodes(): Observable<any> {
        const endpoint = this.ServiceUrl + "api/admin/bannedcountry";
        return this.http.post<any>(endpoint, this.Get_ClientInfo(), this.options)
            .pipe(catchError(error => this.handleError(error)));
    }
    //#endregion

    //#region save banned country phone codes
    callbannedCountryPhoneCodes(codes: string): Promise<any> {
        let body = {
            'ClientInfo': this.Get_ClientInfo(),
            'Codes': codes
        };

        return new Promise((resolve, reject) => {
            this.bannedCountryPhoneCodes(body)
                .subscribe(response => {
                    if (!this.utilService.isNullOrEmtpyString(response)) {
                        if (response)
                            resolve(null);
                        else
                            reject();
                    }
                    else
                        reject();
                }),
                error => {
                    console.log(error);
                    reject();
                };
        })
    }

    bannedCountryPhoneCodes(body): Observable<any> {
        const endpoint = this.ServiceUrl + "bannedCountryPhoneCodes";
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }
    //#endregion

    //#region save system settings
    callSaveAdminSettings(settings: {}): Promise<any> {
        let body = {
            'ClientInfo': this.Get_ClientInfo(),
            'Settings': settings,
            'SMSActiveDeactive': this.indexedDBService.SMSActive
        };

        return new Promise((resolve, reject) => {
            this.saveAdminSettings(body)
                .subscribe(response => {
                    if (!this.utilService.isNullOrEmtpyString(response)) {
                        if (response)
                            resolve(null);
                        else
                            reject();
                    }
                    else
                        reject();
                }),
                error => {
                    console.log(error);
                    reject();
                };
        })
    }

    saveAdminSettings(body): Observable<any> {
        const endpoint = this.ServiceUrl + "api/admin/settings/save";
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }
    //#endregion


    //#region drop all users
    callLogoutOtherAllSessions(tabId: string, userId: number): Promise<any> {
        let body = {
            'ClientInfo': this.Get_ClientInfo(),
            'TabId': tabId,
            'UserId': userId
        };

        return new Promise((resolve, reject) => {
            this.logoutOtherAllSessions(body)
                .subscribe((userIdList: Array<number>) => {
                    if (!this.utilService.isNullOrEmtpyString(userIdList)) {
                        resolve(userIdList);
                    }
                    else
                        reject();
                }),
                error => {
                    console.log(error);
                    reject();
                };
        })
    }

    logoutOtherAllSessions(body): Observable<any> {
        const endpoint = this.ServiceUrl + "LogoutOtherAllSessions";
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }
    //#endregion

    //#region close login
    callUnderConstructionModeActivate(isOpen: boolean): Promise<void> {
        let body = {
            'ClientInfo': this.Get_ClientInfo(),
            'IsOpen': isOpen
        };

        return new Promise((resolve, reject) => {
            this.underConstructionModeActivate(body)
                .subscribe((response) => {
                    if (response) {
                        if (response.Result && response.ErrMsg === "") {
                            resolve();
                        }
                        else {
                            reject();
                        }
                    }
                    else {
                        reject();
                    }
                }),
                error => {
                    console.log(error);
                    reject();
                };
        })
    }

    underConstructionModeActivate(body): Observable<any> {
        const endpoint = this.ServiceUrl + "UnderConstructionModeActivate";
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }
    //#endregion
}