import { Pipe, PipeTransform } from '@angular/core';
import { Friend } from 'src/app/core/models/user';

@Pipe({
  name: 'filterFriends'
})

export class FilterPipe implements PipeTransform {

  transform(items: Friend[], searchText: string) {
    if(!items)
      return [];

    if(!searchText)
      return items;

    searchText = searchText.toLocaleLowerCase();

    return items.filter(item => {
        return item.KullaniciAdi.toLocaleLowerCase().includes(searchText);
    });
  }

}
