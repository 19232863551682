import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { RoomService } from 'src/app/core/services/room.service';
@Component({
  selector: 'app-room-microphone-queue-accepting',
  templateUrl: './microphone-queue-accepting.component.html',
  styleUrls: ['./microphone-queue-accepting.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class RoomMicrophoneQueueAcceptingPanelComponent implements OnInit {

  timeLeftDefault: number = 10;
  timeLeft: number = this.timeLeftDefault;
  interval;

  accepted: boolean; 
  roomMicrophoneAcceptOrRejectSubscription: Subscription;
  micInvitation: boolean = false;
  
  constructor(
    public panelManagerService: PanelManagerService,
    private roomService: RoomService
  ) {

    this.roomService.micInviteKey;

    if(this.roomService.micInviteKey !== '')
      this.micInvitation = true;
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.roomService.micInviteKey = '';
  }

  onShow() {   
    this.accepted = null;
    this.timeLeft = this.timeLeftDefault;
    this.startTimer();
  }

  startTimer() {
    this.interval = setInterval(() => {    
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {   
        if (this.accepted != true)
          this.acceptOrStopCalling(false);
      }
    }, 1000)
  }

  hide(){
    clearInterval(this.interval);
    this.timeLeft = this.timeLeftDefault;
    this.micInvitation = false;
    this.panelManagerService.roomMicrophoneAcceptPopupDisplay = false;
  }

  acceptOrStopCalling(accept: boolean) {
    if (accept) {
      this.accepted=true;      
      this.roomService.startMicrophoneRequest();
    }
    else {   
      this.roomService.stopMicrophoneRequest();
    }

    this.hide();
  }

  acceptOrRejectInvitation(acceptInvitation: boolean){
    if(acceptInvitation){
      this.accepted = true;
      this.roomService.joinMicrophoneRequest(this.roomService.micInviteKey);
    }
    this.hide();
  }
}