import { Injectable } from '@angular/core';
import { UtilService } from 'src/app/core/services/util.service';
import { DeviceService } from 'src/app/core/services/device.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  browserSupportPushNotification = null;

  constructor(
    private utilService: UtilService,
    private deviceService: DeviceService,
    private translateService: TranslateService
  ) {
    setTimeout(() => {
      this.initBrowserPushNotification()
    });
  }

  showNewMessageNotification(userId: number, userName: string, userPhotoId: string, onclickFunc) {

    this.showUserNotification(
      userId,
      userName,
      userPhotoId,
      "newMessageTitle",
      "newMessageBody",
      "newMessageText",
      onclickFunc
    );
  }


  showUserNotification(userId: number, userName: string, userPhotoId: string, userIsOnlineTitle: string, userIsOnlineBody: string, userIsOnlineText: string, onclickFunc) {
    var imgUrl = this.utilService.getProfilePhotoUrl(userPhotoId, false);
    if (this.initBrowserPushNotification() === 1) { // if push browser notificaion  supported
      //TODO SoundHelper.Play(_constants.Veri24MessengerSoundsUrl + _constants.THEME + "/" + _constants.Veri24MessengerIncomingMessageSoundFileName);

      this.browserPushNotification(
        this.translateService.instant(userIsOnlineTitle),
        "'" + userName + "' " + this.translateService.instant(userIsOnlineText),
        imgUrl,
        onclickFunc,
        5000
      );
    }
    // else if (NotificationHelper.InitBrowserPushNotification() === -1) { // if push browser notificaion not supported
    //   SoundHelper.Play(_constants.Veri24MessengerSoundsUrl + _constants.THEME + "/" + _constants.Veri24MessengerIncomingMessageSoundFileName);
    //   NotificationHelper.ShowToast(
    //     userIsOnlineTitle,
    //     "'" + userName + "' " + userIsOnlineBody,
    //     NotificationHelper.ToastGetImageUrlOption(imgUrl),
    //     onclickFunc
    //   );
    // }
    else { // if require permission acception
      Notification.requestPermission().then(function (result) {
        if (result === "granted")
          this.showUserNotification(userId, userName, userPhotoId, userIsOnlineTitle, userIsOnlineBody, userIsOnlineText, onclickFunc);
      });
    }

  }



  initBrowserPushNotification() { // 1: readytosend , 0: wait allow , -1:not supported

    if (this.deviceService.isMobile)
      return 1;

    if (this.isBrowserSupportPushNotification() === true) {
      if (Notification.permission === "granted")
        return 1;

      Notification.requestPermission();
      return 0;
    }

    return -1;
  }

  browserPushNotification(title: string, body: string, icon: string, onclickFunc, autoCloseMilisecond: number) {

    if (this.initBrowserPushNotification() !== 1)
      return false;

    try {

      setTimeout(() => {

        var notification = new Notification(title, { icon: icon, body: body });
        if (onclickFunc !== null && onclickFunc !== undefined)
          notification.onclick = onclickFunc;

        if (autoCloseMilisecond !== undefined)
          setTimeout(() => {
            try {
              notification.close();
            } catch (error) {
              console.log(error)
            }
          }, autoCloseMilisecond);
      }, 200);

    } catch (error) {
      console.log(error);
    }
  }

  isBrowserSupportPushNotification() {
    if (this.browserSupportPushNotification !== null)
      return this.browserSupportPushNotification;

    if (!Notification || !Notification.requestPermission) {
      return false;
    }

    try {
      var notify = new Notification("", { silent: true });
      setTimeout(notify.close.bind(notify), 100);
      this.browserSupportPushNotification = true;
    } catch (e) {
      if (e.name === "TypeError")
        return false;
    }
    return true;
  }


}
