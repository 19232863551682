import { Nicktype } from './nick-type';

export class RoomUser {
    KullaniciId: number;
    KullaniciAdi: string;
    IkinciKullaniciAdi: string;
    Nicktype: Nicktype;
    BanOda: boolean;
    BanSite: boolean;
    MuteOda: boolean;
    MuteSite: boolean;
    Karaliste: boolean;
    RutbeAdi: string;
    RutbeDerece: number;
    RutbeId: number;
    ProfilResmiId: string;
    FlashnickId: string;
    BanOdaList: Array<number> = new Array<number>();
    MuteOdaList: Array<number> = new Array<number>();
    StateId: number;
    RoomCamSession: string;
    EnterRoomIndex: number;
    OnMainMic: boolean;
    OnSecondMic: boolean;
    HandUp: boolean;
    IsCamOpen:boolean;
    IsScreenShareOpen: boolean;
    IsMicMuted?: boolean;
    IsAudioMuted?: boolean;
}

export class VipUserData {
    data:RoomUser[]
    totalCount:number 
}
export class UserListData {
    data:RoomUser[]
    count:number 
}

export enum RoomUserEnum {
    KullaniciId = 0,
    KullaniciAdi = 1,
    IkinciKullaniciAdi = 2,
    Nicktype = 10, //?
    BanOda = 13,
    BanSite = 14,
    MuteOda = 15,
    MuteSite = 16,
    Karaliste = 17,
    RutbeAdi = 6,
    RutbeDerece = 7,
    RutbeId = 8,
    ProfilResmiId = 3,
    FlashnickId = 4,
    GeciciOperatorListesi = 5,
    StateId = 9,
    Secretkey = 11,
    CamSession = 99,
    EnterRoomIndex,
    OnMainMic,
    OnSecondMic,
    HandUp
}

export enum RoomUserContextMenuMode{
    RoomUserList = 'RoomUserList',
    RoomChatBubble = 'RoomChatBubble',
    ViewUsersInRoom = 'ViewUsersInRoom',
    RoomActionBubble = 'RoomActionBubble'
}

export class AuthorizedUsers {
    I: number // ID
    N: string // Name
    O: number // ? 1
    P: string // ? "1FB18C62-029D-421D-9DFE-97388E086864"
    RD: number // Rank Degree
    RN: string // Rank Name
}

export class RoomUserContextMenuOperation{
    banOda: boolean;
    banSite: boolean;
    muteOda: boolean;
    muteSite: boolean;
    banSaat: boolean;
    ban_1: boolean;
    ban_3: boolean;
    ban_7: boolean;
    ban_30: boolean;
    ban_suresiz: boolean;
    removeBanOda: boolean;
    removeBanSite: boolean;
    removeMuteOda: boolean;
    removeMuteSite: boolean;
    removeOperator: boolean;
    kameraSonlandir: boolean;   
    mikrofonAlma: boolean;
    mikrofonDusurme: boolean;
    sesiniKapatma: boolean;
    sesAcmaTalebi: boolean;
    atmaOda: boolean;
    elIndir: boolean;
    geciciOpOda: boolean;
    geciciOpSite: boolean;
    kisiBireyselBilgiGorme: boolean;
    odadaKullaniciListele: boolean;
    kendiFlashi: boolean;
    sendFriendShipInvitation: boolean;
    inviteToMic: boolean;
    writePrivateMessage: boolean;
    blockUser: boolean;
    removeBlockUser: boolean;
    refreshUserList: boolean;
    odayaDavet: boolean;
}