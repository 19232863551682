export enum UserStateType {
    Cevrimici = 3,
    Cevrimdisi = 14,
    Mesgul = 4,
    Disarda = 5,
    Gorunmez = 12,
    GelenIstek = 1,
    GonderilenIstek = 2,
    Telefonda = 6,
    Hicbiri = 15,
    YakinZamanda = 0,
    RahatsizEtme = 11,
    Mutlu = 7,
    Asik = 8,
    Sinirli = 9,
    Uyuyor = 10
}

export const StateIdNumberList = {
    9: 2, // "GonderilenIstek",
    14: 11, // "RahatsizEtme",
    3: 4, // "Mesgul",
    7: 5, // "Disarda",
    15: 7, // "Mutlu",
    16: 8, // "Asik",
    17: 9, // "Sinirli",
    18: 10, // "Uyuyor",
    1: 3, // "Cevrimici",
    2: 14, // "Cevrimdisi"
    8: 1, // "GelenIstek"
    10: 6, // "Telefonda"
    11: 15, // "Hicbiri"
    13: 12 // "Gorunmez"
}


export const StateImagesByName = {
    "State_AddFriend": 9,
    "State_DontDisturb": 14,
    "State_Busy": 3,
    "State_Outside": 7,
    "State_Happy": 15,
    "State_Love": 16,
    "State_Angry": 17,
    "State_Sleep": 18,
    "State_Online": 1,
    "State_Offline": 2,
    "State_AddFriendMe": 8,
    "State_Phone": 10,
}

export const StateImagesById = {
    9: "State_AddFriend",
    14: "State_DontDisturb",
    3: "State_Busy",
    7: "State_Outside",
    15: "State_Happy",
    16: "State_Love",
    17: "State_Angry",
    18: "State_Sleep",
    1: "State_Online",
    2: "State_Offline",
    8: "State_AddFriendMe",
    10: "State_Phone",
    11: "State_Offline",
    13: "State_Offline"
}

export enum StateImagesByIdDb {
    _9 = "State_AddFriend",
    _14 = "State_DontDisturb",
    _3 = "State_Busy",
    _7 = "State_Outside",
    _15 = "State_Happy",
    _16 = "State_Love",
    _17 = "State_Angry",
    _18 = "State_Sleep",
    _1 = "State_Online",
    _2 = "State_Offline",
    _8 = "State_AddFriendMe",
    _10 = "State_Phone",
    _11 = "State_Offline",
    _13 = "State_Offline"
}

export enum StateImagesEnum {
    State_Online = 0,
    State_Busy = 1,
    State_Outside = 2,
    State_Phone = 3,
    State_Happy = 4,
    State_Love = 5,
    State_Angry = 6,
    State_Sleep = 7,
    State_DontDisturb = 8,
    State_Offline = 9
}


export const StateImages = {
    0: "State_Online",
    1: "State_Busy",
    2: "State_Outside",
    3: "State_Phone",
    4: "State_Happy",
    5: "State_Love",
    6: "State_Angry",
    7: "State_Sleep",
    8: "State_DontDisturb",
    9: "State_Offline"
}

export const StateLocalizationKeys = {
    0: "stateonline",
    1: "statebusy",
    2: "stateoutside",
    3: "statephone",
    4: "statehappy",
    5: "stateolove",
    6: "stateangry",
    7: "statesleep",
    8: "statedontdisturb",
    9: "stateoffline"
}

export enum StateLocalizationKeysEnum {
    stateonline = 0,
    statebusy = 1,
    stateoutside = 2,
    statephone = 3,
    statehappy = 4,
    stateolove = 5,
    stateangry = 6,
    statesleep = 7,
    statedontdisturb = 8,
    stateoffline = 9
}

export const StateIndexIdList = {
    0: 1, // "stateonline",
    1: 3, // "statebusy",
    2: 7, // "stateoutside",
    3: 10, // "statephone",
    4: 15, // "statehappy",
    5: 16, // "stateolove",
    6: 17, // "stateangry",
    7: 18, // "statesleep",
    8: 14, // "statedontdisturb",
    9: 2 // "stateoffline"
}

export const StateIdIndexList = {
    1: 0, // "stateonline",
    3: 1, // "statebusy",
    7: 2, // "stateoutside",
    10: 3, // "statephone",
    15: 4, // "statehappy",
    16: 5, // "stateolove",
    17: 6, // "stateangry",
    18: 7, // "statesleep",
    14: 8, // "statedontdisturb",
    2: 9 // "stateoffline"
}


export const StateIdsForSort = {

    1: 1, // "Cevrimici",
    8: 2, // "GelenIstek"
    // others :3
    14: 4, // "RahatsizEtme",
    2: 5, // "Cevrimdisi"
    9: 6 // "GonderilenIstek",
}





export enum StateIdList {
    GonderilenIstek = 9, // "GonderilenIstek",
    RahatsizEtme = 14, // "RahatsizEtme",
    Mesgul = 3, // "Mesgul",
    Disarda = 7, // "Disarda",
    Mutlu = 15, // "Mutlu",
    Asik = 16, // "Asik",
    Sinirli = 17, // "Sinirli",
    Uyuyor = 18, // "Uyuyor",
    Cevrimici = 1, // "Cevrimici",
    Cevrimdisi = 2, // "Cevrimdisi"
    GelenIstek = 8, // "GelenIstek"
    Telefonda = 10, // "Telefonda"
    Hicbiri = 11, // "Hicbiri"
    Gorunmez = 13 // "Gorunmez"
}

export class Settings {
    Lang = "tr";
    StateIndex = 0;
}
