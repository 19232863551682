import { NgModule, Optional, SkipSelf, } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { HttpClientModule } from '@angular/common/http';

// //#region primeng services
// import { ConfirmationService } from 'primeng/api';
// import { MessageService } from 'primeng/api';
// //#endregion

//#region services
import { UserService } from './services/user.service';
import { LocalStorageService } from './services/local-storage.service';
import { RoomUserListService } from './services/room-user-list-service';
import { StateService } from './services/state.service';
import { DeviceService } from './services/device.service';
import { FriendshipService } from './services/friendship.service';
import { LoginService } from './services/login.service';
import { FriendListService } from './services/friendlist.service';
import { MessagePanelService } from './services/messagePanel.service';
import { P2PService } from './services/p2p.service';
import { ChatService } from './services/chat.service';
import { RoomService } from './services/room.service';
import { NotificationService } from './services/notification.service';
import { DynamicComponentManagerService } from './services/dynamic-component-manager.service';
import { PanelManagerService } from './services/panel-manager.service';
import { UtilService } from './services/util.service';
import { ConfirmationDialogService } from './services/confirmation-dialog.service';
import { DynamicMethodsReceiveService } from './services/dynamic-methods-receive.service';
import { DynamicMethodsSendService } from './services/dynamic-methods-send.service';
import { IndexedDBService } from './services/indexed-db.service';
import { LocalizationService } from './services/localization.service';
import { LanguageService } from './services/language.service';
import { AdminService } from '../admin/services/admin.service';
import { FontService } from './services/font.service';

//#endregion

@NgModule({
  declarations:[],
  imports: [
    SharedModule,
    HttpClientModule
  ],
  exports: [
    HttpClientModule,
    
  ],
  providers: [
    UserService,
    LocalStorageService,
    RoomUserListService,
    StateService,
    DeviceService,
    FriendshipService,
    LoginService,
    FriendListService,
    MessagePanelService,
    P2PService,
    ChatService,
    RoomService,
    NotificationService,
    DynamicComponentManagerService,
    PanelManagerService,
    UtilService,
    AdminService,
    ConfirmationDialogService,
    DynamicMethodsReceiveService,
    DynamicMethodsSendService,
    IndexedDBService,
    LocalizationService,
    LanguageService,
    FontService,
    // ConfirmationService,
    // MessageService
  ]
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. You should only import Core modules in the AppModule only.');
    }

  }
}
