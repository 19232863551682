import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { RoomService } from 'src/app/core/services/room.service';

@Component({
  selector: 'app-invite-to-room',
  templateUrl: './invite-to-room.component.html',
  styleUrls: ['./invite-to-room.component.scss']
})
export class InviteToRoomComponent implements OnInit {

  takenInviteToRoomSubscription: Subscription;
  roomID: number;
  roomName: string;
  invitedName: string;
  load: boolean = false;

  constructor(
    public panelManagerService: PanelManagerService,
    private roomService: RoomService
  ) {
    this.takenInviteToRoomSubscription = this.roomService.takenInviteToRoom.subscribe((roomInfo: Array<any>) => {
      this.roomID = roomInfo[0];
      this.roomName = roomInfo[1];
      this.invitedName = roomInfo[2];
      this.load =true;
    });
   }

  ngOnInit(): void {
   
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
 
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this.takenInviteToRoomSubscription)
      this.takenInviteToRoomSubscription.unsubscribe();
  }

  accept(){
    this.roomService.enterRoomNew(this.roomID);
    this.closeInviteToRoomPanel();
  }

  closeInviteToRoomPanel(){
    this.panelManagerService.inviteToRoomPopupDisplay = false;
  }
}
