<p-dialog [(visible)]="panelManagerService.viewTheRoomPopupDisplay" [modal]="false"
    [responsive]="true" (onHide)="closeViewRoomPanel()">
    <!-- [draggable]="true" [handle]="viewRoom" [ngDraggable]="true" [inBounds]="true" -->
    <p-header #viewRoom>{{ roomService.viewingRoomInfo.NAME }}</p-header>
    <p-scrollPanel [style]="{width: '170px', height: panelManagerService.viewRoomLoading ? '50px' : '100%'}">
        <ng-template *ngIf="!roomService.connectionProblem && isCurrentRoom === false" #dynamicComponent></ng-template>    
        <div *ngIf="isCurrentRoom === false &&
            roomService.roomUserListForViewTheRoom.length === 0 &&
            panelManagerService.viewRoomLoading === false">
            {{ 'NoOneInRoom' | translate }}</div>

        <div *ngIf="isCurrentRoom">{{ 'InSameRoom' | translate }}</div>
      
        <div *ngIf="panelManagerService.viewRoomLoading" class="main-loading">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="3" fill="#EEEEEE"
                animationDuration=".5s">
            </p-progressSpinner>
        </div>
    </p-scrollPanel>
</p-dialog>
