import { Component } from '@angular/core';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { RoomService } from 'src/app/core/services/room.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-control-of-recording',
  templateUrl: './control-of-recording.component.html',
  styleUrls: ['./control-of-recording.component.scss']
})
export class ControlOfRecordingComponent {
  isAudioOnly:boolean

  constructor(
    public panelManagerService: PanelManagerService,
    private userService:UserService,
    public roomService:RoomService,
    public messagePanelService: MessagePanelService,

  ) { }

  recordRoomTrack(){
   if(this.isAudioOnly == undefined){
     this.messagePanelService.ShowPopupMessageWithLocalization('please-select-record-option', 2000);
     return
   } 
   this.roomService.recordRoomTrack(this.isAudioOnly);
  }

}
