import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { throwError } from 'rxjs';
import { UtilService } from 'src/app/core/services/util.service';
import { ClientInfo } from 'src/app/core/models/clientInfo';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
@Injectable()
export abstract class ServiceInformation {

    protected options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
 
 //  protected ServiceUrl: string = "http://localhost:51613/WCFVeri24Messenger.svc/mex/"; 
    protected ServiceUrl: string =  environment.apiUrl;
  
    constructor(
        protected messagePanelService: MessagePanelService,
        protected utilService: UtilService,
        protected indexedDBService: IndexedDBService
    ) { }




    ngOnInit() { }

    protected Get_ClientInfo(): ClientInfo {

        let ClientInfo: ClientInfo = <ClientInfo>{};

    
        if (!this.utilService.isNullOrUndefined(this.indexedDBService.userFullInfo)) {
            if (!this.utilService.isNullOrUndefined(this.indexedDBService.userFullInfo.KullaniciId))
                ClientInfo["UserId"] = this.indexedDBService.userFullInfo.KullaniciId;

            if (!this.utilService.isNullOrUndefined(this.indexedDBService.userFullInfo.KullaniciAdi))
                ClientInfo["Username"] = this.indexedDBService.userFullInfo.KullaniciAdi;

            if (!this.utilService.isNullOrUndefined(this.indexedDBService.userFullInfo.Token))
                ClientInfo["Token"] = this.indexedDBService.userFullInfo.Token.toLocaleUpperCase();
        }

        return ClientInfo;
    }



    protected handleError(error: HttpErrorResponse) {
        console.log(error);
        // console.trace();
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            //console.error('An error occurred:', error.error.message);
            // this.messagePanelService.ShowPopupMessageWithLocalization(error.error.message, 2000);
        } else {
            if (error.status === 401) {
                return throwError('sessionNotFound');
                //this.sessionNotFoundSource.next();
            }
            if (error.status === 400) {
                // if (error.error.validationErrors) {
                //     error.error.validationErrors.forEach((val, index) => {
                //         this.messagePanelService.ShowPopupMessageWithLocalization(val.message, 2000);
                //     });
                // } else if (error.error.failure) {
                //     this.messagePanelService.ShowPopupMessageWithLocalization(JSON.parse(error.error.failure.failure).message, 2000);

                // } else {
                //     this.messagePanelService.ShowPopupMessageWithLocalization(error.error.message, false);
                // }
            } else if (error.status === 500) {
                // this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
            } else {
                // The backend returned an unsuccessful response code.
                // The response body may contain clues as to what went wrong,
                // console.log(error);
                // if (!error.error) {
                //     this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
                // } else {
                //     this.messagePanelService.ShowPopupMessageWithLocalization(error.error.error_description, 2000);
                // }
            }
        }
        return throwError(error.message); //throwError('ErrorPleaseReTry');
    }


}