(function (name, definition) {
	if (typeof exports === 'object') {
		window.adapter = require('./adapter.js');
		module.exports = definition();
	} else {
		this[name] = definition();
	}
}('DeviceManager', function () {

	if (!window.DeviceManager) { window.DeviceManager = {}; }
  
	DeviceManager.notSupportedDevice="notSuported";

    DeviceManager.accessCameraMicrophone = function (constraints) {               
    
		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
			return navigator.mediaDevices.getUserMedia(constraints);

		return Promise.reject(DeviceManager.notSupportedDevice);  
    }    
    
	return DeviceManager;
}));
