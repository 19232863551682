import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { ChatPanelService } from 'src/app/core/services/chatPanel.service';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { UtilService } from 'src/app/core/services/util.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-p2p-chat-header-update-wallpaper',
    templateUrl: './update-wallpaper.component.html',
    styleUrls: ['./update-wallpaper.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})

export class P2PChatHeaderUpdateWallpaperComponent implements OnInit, AfterViewInit {

    backColor: string;
    backImage: string;
    backImagePreview: string;
    isThereBackImage: boolean;
    isBackColor: boolean;
    selectedValue: string;

    getBackgroundTypeSubscription: Subscription;

    fileData: File = null;
    backgroundObj = {};

    constructor(
        private chatPanelService: ChatPanelService,
        private indexedDBService: IndexedDBService,
        private utilService: UtilService,
        public panelManagerService: PanelManagerService
    ) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.getMessagePanelBackgroundType();
        this.getMessagePanelBackColor();
        this.getMessagePanelBackImage();
    }

    // #region Set Background Type

    getMessagePanelBackgroundType() {
        this.indexedDBService.getPrivateSetting(this.indexedDBService.settingsMessagePanelSelectedBackgroundPrivateKey)
            .then((isBackColor) => {
                if (!this.utilService.isNullOrEmtpyString(isBackColor))
                    this.isBackColor = isBackColor;
                else
                    throw 'not found'
            })
            .catch(error => {
                this.isBackColor = true;
                this.backColor = this.selectedValue = this.indexedDBService.settingsSelectedMessagePanelBackColorDefaultValue;
            });
    }

    updateIsSelectedBackColor(isBackColor: boolean) {
        this.indexedDBService.updatePrivateSetting(this.indexedDBService.settingsMessagePanelSelectedBackgroundPrivateKey, isBackColor)
            .then(() => {
                this.isBackColor = isBackColor;
                this.chatPanelService.setBackgroundType(isBackColor);
            });
    }

    selectBackColor($event) {
        this.isBackColor = true;
        this.updateMessagePanelBackColor(this.backColor);
        this.updateIsSelectedBackColor(true);
    }

    selectBackImage($event) {
        this.isBackColor = false;
        if (this.utilService.isNullOrEmtpyString(this.backImagePreview)) {
            this.openFileUpload(event);
        }
        else {
            this.backImage = this.backImagePreview;
            this.updateMessagePanelBackImage(this.backImagePreview);
        }
    }

    // #endregion

    // #region Back Color Operations

    getMessagePanelBackColor() {
        this.indexedDBService.getPrivateSetting(this.indexedDBService.settingsSelectedMessagePanelBackColorPrivateKey)
            .then(color => {
                if (!this.utilService.isNullOrEmtpyString(color)) {
                    this.backColor = color;
                }
                else
                    this.backColor = this.indexedDBService.settingsSelectedMessagePanelBackColorDefaultValue;

                if (this.isBackColor)
                    this.selectedValue = this.backColor;
            })
            .catch(error => {
                console.log(error);
            });
    }


    onChange($event) {
        this.updateMessagePanelBackColor($event.value)
        this.updateIsSelectedBackColor(true);
    }

    updateMessagePanelBackColor(colorCode) {
        this.indexedDBService.updatePrivateSetting(this.indexedDBService.settingsSelectedMessagePanelBackColorPrivateKey, colorCode)
            .then(() => {
                this.selectedValue = this.backColor = colorCode;
                this.chatPanelService.changeBackgroundColor(this.backgroundObj);
            });
    }

    // #endregion

    // #region Back Image Operations

    getMessagePanelBackImage() {
        this.indexedDBService.getPrivateSetting(this.indexedDBService.settingsSelectedMessagePanelBackImagePrivateKey)
            .then(image => {
                if (!this.utilService.isNullOrEmtpyString(image)) {
                    this.isThereBackImage = true;
                    this.backImagePreview = image;
                    this.backImage = image;

                    if (!this.isBackColor)
                        this.selectedValue = this.backImage;
                }
                else {
                    this.isThereBackImage = false;
                }
            })
            .catch(error => {
                this.isThereBackImage = false;
            });
    }

    openFileUpload($event) {
        $event.preventDefault();

        let element: HTMLElement = document.getElementById('wallpaperUpload') as HTMLElement;
        element.click();
    }

    fileProgress(fileInput: any) {
        this.fileData = <File>fileInput.target.files[0];
        this.preview();
    }

    preview() {
        var mimeType = this.fileData.type;
        if (mimeType.match(/image\/*/) == null) {
            return;
        }

        var reader = new FileReader();
        reader.readAsDataURL(this.fileData);
        reader.onload = (_event) => {
            this.updateMessagePanelBackImage(reader.result);
        }
    }

    updateMessagePanelBackImage(readerResult) {
        this.indexedDBService.updatePrivateSetting(this.indexedDBService.settingsSelectedMessagePanelBackImagePrivateKey, readerResult)
            .then(() => {
                this.isThereBackImage = true;
                this.backImage = this.backImagePreview = (readerResult).toString();
                this.selectedValue = this.backImage;
                this.updateIsSelectedBackColor(false);
                this.chatPanelService.changeBackgroundImage(readerResult);
            });
    }


    selectImage() {
        this.selectBackImage(event);
    }
    // #endregion

}