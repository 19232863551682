import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kFormatter'
})
export class KFormatterPipe implements PipeTransform {

  transform(value: number): string {
    if (!value) return '0';
    if (value >= 1000000) {
      return (value / 1000000).toFixed(value % 1000000 === 0 ? 0 : 1) + 'M';
    }
    if (value >= 1000) {
      return (value / 1000).toFixed(value % 1000 === 0 ? 0 : 1) + 'K';
    }
    return value.toString();
  }

}