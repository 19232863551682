
import { ContactType, MessageSendingType, ContactState, ChatType, ChatState, ConferenceType, BulkMessageType } from 'src/app/core/models/enums';
import { Constants } from 'src/app/core/models/constants';
import { Friend, UserFullInfo } from 'src/app/core/models/user';
import { ConferenceRequestResponse } from './chat-message';


export class ChatPanelProps {
    Channelname: string = null; //string
    UserId: number = 0; //int
    UserName: string = null; //string
    UserRubeDerece: number = 0; //int
    UserPhotoId: string = Constants.guidEmpty; //Guid
    //GroupId: 0; //int
    //GroupName: string = null; //string
    //GroupCreatorId: 0;  //int
    ChannelSessionId: string = Constants.guidEmpty; //Guid
    // GroupUsers : null; //UserClass[] 
    ContactType: ContactType = ContactType.Chat; //int _enums.ContactType
    MessageSendingType: MessageSendingType = MessageSendingType.Normal; //int _enums.MessageSendingType
    ChatType: ChatType = ChatType.SingleChat; //int _enums.ChatType
    IsTopluMesaj: boolean = false; //bool
}


export class ActiveChatPanel {
    user: Friend;
    conferenceRequestResponse: ConferenceRequestResponse = new ConferenceRequestResponse();
    display: boolean = true;
    chatPanelProps: ChatPanelProps = new ChatPanelProps();  
    videoContainer: HTMLElement;
    mediaSettingDisplay:boolean;
    conference: any;
    videoPanelIsMaximized:boolean;
}
