<div [attr.id]="videoPanelId" #videoPanel [rzMinWidth]="deviceService.isMobile?100:200"
    [rzMinHeight]="deviceService.isMobile?100:200" [rzHandles]="'s,e,se'" [ngDraggable]="!videoPanelIsMaximized"
    [ngResizable]="!videoPanelIsMaximized" [ngClass]="'videocontainer'" [inBounds]="true" [bounds]="roomPanelBounds"
    [class]="'videoContainer ' + (videoPanelIsMaximized?'videoContainerMaximized':'')"
    [style.display]="((deviceService.isMobile && panelManagerService.currentMobileActivePanelId === (constants.mainSwiperTag +''+ mainPanels.RoomPage)) || deviceService.isDesktop) && roomService.connectionProblem === false ?'block':'none'">
    <span class="videoContainerUserName" #userNameDiv>{{
        roomUserListService.userViewInRoom[roomUser?.KullaniciId]?.username }}</span>

    <fa-icon [icon]="faFlag" class="report-button" pTooltip="{{'report-user'|translate}}" tooltipPosition="top"
        (click)="complainUser()"></fa-icon>

    <fa-icon [icon]="faTimes" class="close-button" pTooltip="{{'close'|translate}}" tooltipPosition="top"
        (click)="hideElement()"></fa-icon>

        <fa-icon *ngIf="!videoPanelIsMaximized" [icon]="faExpandArrowsAlt" pTooltip="{{'maximize'|translate}}"
        tooltipPosition="top" class="maximize-button" (click)="requestFullscreen()">
        </fa-icon>

    <fa-icon *ngIf="videoPanelIsMaximized" [icon]="faCompressArrowsAlt" pTooltip="{{'minimize'|translate}}"
        tooltipPosition="top" class="minimize-button" (click)="videoPanelIsMaximized=false"></fa-icon>

    <video width="100%" [attr.id]="videoContainerId" autoplay="true" #videoContainer class="roomVideoControl"></video>

</div>