<div *ngIf="panelManagerService.youtubePlayerPopupDisplay" class="youtube" [inBounds]="false" [ngDraggable]="true"
  [style.z-index]="10000"
  [style.display]="panelManagerService.youtubePlayerPopupMinus ? 'none' : 'block'"
  [handle]="headerHandle" #youtube>

  <div class="header" #headerHandle>
    <span [ngStyle]="{'color': 'red', 'float': 'left', 'margin-left': '5px'}">{{ 'YouTube' | translate }}</span>
    <fa-icon [icon]="faMinus"
    [ngStyle]="{'cursor': 'pointer', 'z-index': '99999', 'color': 'red', 'margin-right': '15px'}"
    (click)="panelManagerService.youtubePlayerPopupMinus = true"></fa-icon>
    <fa-icon [icon]="faTimes"
      [ngStyle]="{'cursor': 'pointer', 'z-index': '99999', 'color': 'red', 'margin-right': '5px'}"
      (click)="panelManagerService.youtubePlayerPopupDisplay = false"></fa-icon>
  </div>

  <div class="ui-inputgroup">
    <input type="text" placeholder="{{ 'search-input' | translate }}" class="w-100" pInputText [(ngModel)]="searchText"
      (ngModelChange)="searchTextChange($event)" (keyup)="searchInYoutubeOnKeydown($event)" />
    <p-progressSpinner class="snipper" *ngIf="panelManagerService.findFriendLoading" fill="#EEEEEE"
      animationDuration=".5s">
    </p-progressSpinner>
    <!-- <button pButton type="button" icon="pi pi-search" label="{{ 'search' | translate }}"
      (click)="searchVideo()"></button> -->
  </div>

  <div [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [ngResizable]="true" *ngIf="youtubeVideoResultList.length > 0 || isOpenedVideo" [ngStyle]="{'height': '200px'}" [rzMinWidth]="176" class="resizable-area">
    <div class="result-list" *ngIf="youtubeVideoResultList.length > 0">
      <table class="table table-borderless">
        <tbody>
          <tr class="cursor" *ngFor="let video of youtubeVideoResultList" (click)="openVideo(video.id.videoId)">
            <td>
              <span class="video-title">{{video.snippet.title}}</span>
              <br />
              <span class="video-description">{{video.snippet.description}}</span>
            </td>
            <td><img src="{{video.snippet.thumbnails.default.url}}"></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="video-player" *ngIf="isOpenedVideo">
      <iframe [src]='safeUrl' class="video-player-iframe" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>


</div>