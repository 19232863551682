import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Papa } from 'ngx-papaparse';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class RoleAccessService {
  private tabPermissions: { [role: string]: string[] } = {};

  constructor(private http: HttpClient, private papa: Papa) {}

  loadPermissions(): Observable<any> {
    return new Observable((observer) => {
      this.http.get('assets/role-access/role-tab.csv', { responseType: 'text' }).subscribe((data) => {
        this.papa.parse(data, {
          header: true,
          complete: (result) => {
            this.tabPermissions = this.buildPermissionMap(result.data);
            observer.next(this.tabPermissions);
            observer.complete();
          },
          skipEmptyLines: true 
        });
      });
    });
  }

  private buildPermissionMap(data: any[]): { [role: string]: string[] } {
    const map: { [role: string]: string[] } = {};
    data.forEach((row) => {
      const role = row.Role.trim();
      const tab = row.Tab.trim();
      if (!map[role]) {
        map[role] = [];
      }
      map[role].push(tab);
    });
    return map;
  }

  canOpenTab(role: string, tab: string): boolean {
    return this.tabPermissions[role]?.includes(tab) || false;
  }
}
