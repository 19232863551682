import { Injectable } from '@angular/core';
import { RoomUser } from 'src/app/core/models/room-user';
import { UtilService } from 'src/app/core/services/util.service';
import { HttpClient } from "@angular/common/http";
import { ServiceInformation } from './serviceinformation.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { catchError } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { RankFullInfo } from 'src/app/core/models/rank';
import { UserFullInfo } from 'src/app/core/models/user';
import { Nicktype } from 'src/app/core/models/nick-type';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})

export class RoomUserListService extends ServiceInformation {

    roomUserAddedSource: Subject<RoomUser> = new Subject();
    roomUserAdded = this.roomUserAddedSource.asObservable();

    roomOnlineUserListAddedSource: Subject<RoomUser> = new Subject();
    roomOnlineUserListAdded = this.roomOnlineUserListAddedSource.asObservable();

    roomVipUserListAddedSource: Subject<RoomUser> = new Subject();
    roomVipUserListAdded = this.roomVipUserListAddedSource.asObservable();

    roomUserListRemovedSource: Subject<number[]> = new Subject();
    roomUserListRemoved = this.roomUserListRemovedSource.asObservable();

    roomOnlineUserListRemovedSource: Subject<number[]> = new Subject();
    roomOnlineUserListRemoved = this.roomOnlineUserListRemovedSource.asObservable();

    roomVipUserListRemovedSource: Subject<number[]> = new Subject();
    roomVipUserListRemoved = this.roomVipUserListRemovedSource.asObservable();

    roomUserRemovedSource: Subject<number> = new Subject();
    roomUserRemoved = this.roomUserRemovedSource.asObservable();

    roomUserUpdatedSource: Subject<RoomUser> = new Subject();
    roomUserUpdated = this.roomUserUpdatedSource.asObservable();

    roomUserCountUpdatedSource: Subject<number> = new Subject();
    roomUserCountUpdated = this.roomUserCountUpdatedSource.asObservable();

    userViewInRoom: { [userId: number]: { showJustSecondNick: boolean, username: string, rutbeAdi: string, rutbeDerece: number } } = {};

    constructor(
        private http: HttpClient,
        public utilService: UtilService,
        public messagePanelService: MessagePanelService,
        protected indexedDBService: IndexedDBService,
        private userService: UserService
    ) {
        super(messagePanelService, utilService, indexedDBService);
    }

    getUserNameForShow(kullaniciId: number, ikinciKullaniciAdi: string, kullaniciAdi: string, rutbeAdi: string, rutbeDerece: number) {
        if (this.utilService.isNullOrEmtpyString(ikinciKullaniciAdi)) {
            this.userViewInRoom[kullaniciId] = { showJustSecondNick: false, username: kullaniciAdi, rutbeAdi: rutbeAdi, rutbeDerece: rutbeDerece }; //setted for room
            return [kullaniciAdi, rutbeDerece]; //returned for p2p
        }

        if (kullaniciId === this.indexedDBService.userFullInfo.KullaniciId) {
            this.userViewInRoom[kullaniciId] = { showJustSecondNick: false, username: ikinciKullaniciAdi + "(" + kullaniciAdi + ")", rutbeAdi: rutbeAdi, rutbeDerece: rutbeDerece };
            return [ikinciKullaniciAdi + "(" + kullaniciAdi + ")", rutbeDerece];
        }

        if (rutbeDerece < this.indexedDBService.userFullInfo.RutbeFullInfo.DERECE) {
            this.userViewInRoom[kullaniciId] = { showJustSecondNick: false, username: ikinciKullaniciAdi + "(" + kullaniciAdi + ")", rutbeAdi: rutbeAdi, rutbeDerece: rutbeDerece };
            return [ikinciKullaniciAdi + "(" + kullaniciAdi + ")", rutbeDerece];
        }

        this.userViewInRoom[kullaniciId] = { showJustSecondNick: true, username: ikinciKullaniciAdi, rutbeAdi: "User", rutbeDerece: 0 };
        return [ikinciKullaniciAdi, 0];
    }

    getUserRoomNickTypeInternal(roomUser: RoomUser) {
        if (roomUser.Nicktype === null || roomUser.Nicktype === undefined)
            return this.getDefaultRoomNickType();

        return roomUser.Nicktype;
    }

    getDefaultRoomNickType() {
        return new Nicktype();
    }

    // #region Remove Ban
    public deleteBanBlackList(body) {
        body["ClientInfo"] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + 'DeleteBanBlackList';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }
    // #endregion

    public saveUserBan(body): Observable<any> {
        body["ClientInfo"] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + 'SaveUserBan';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public saveUserBanEmailOnly(body): Observable<any> {
        body["ClientInfo"] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + 'api/ban/account';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public saveUserMute(Mute: object): Observable<any> {
        let body = {
            'ClientInfo': this.Get_ClientInfo(),
            'Mute': Mute
        };
        const endpoint = this.ServiceUrl + 'api/user/mute/save';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public deleteMute(body): Observable<any> {
        body["ClientInfo"] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + 'api/user/mute/delete';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }



    public kickFromRoom(body): Observable<any> {
        body["ClientInfo"] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + 'KickFromRoom';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }


    convertRoomUserToPtoPChatUser(roomUser?: RoomUser): UserFullInfo {

        let RutbeFullInfo = new RankFullInfo();
        RutbeFullInfo.ID = roomUser?.RutbeId;
        RutbeFullInfo.DERECE = roomUser?.RutbeDerece;
        RutbeFullInfo.ADI = roomUser?.RutbeAdi;

        let userFullInfo = new UserFullInfo()

        userFullInfo.AltYetkiliLimiti = 0;
        userFullInfo.BanOdaList = [];
        userFullInfo.BanSite = false;
        userFullInfo.DurumMesaj = "";
        userFullInfo.Email = null;
        userFullInfo.FlashnickId = roomUser?.FlashnickId;
        userFullInfo.GeciciOperatorListesi = [];
        userFullInfo.GenelMesajSayisi = 0;
        userFullInfo.HasPhoneAccount = false;
        userFullInfo.IkinciKullaniciAdi = roomUser?.IkinciKullaniciAdi;
        userFullInfo.Ip = null;
        userFullInfo.Karaliste = roomUser?.Karaliste;
        userFullInfo.KullaniciAdi = roomUser?.KullaniciAdi;
        userFullInfo.KullaniciAdiRenk = "";
        userFullInfo.KullaniciId = roomUser?.KullaniciId;
        userFullInfo.LastPcimei = null;
        userFullInfo.Misafir = false;
        userFullInfo.MuteOdaList = [];
        userFullInfo.MuteSite = roomUser?.MuteSite;
        userFullInfo.NicktypeForRoom = roomUser?.Nicktype;
        userFullInfo.Password = null;
        userFullInfo.ProfilResmiId = roomUser?.ProfilResmiId;
        userFullInfo.RutbeAdi = roomUser?.RutbeAdi;
        userFullInfo.RutbeDerece = roomUser?.RutbeDerece;
        userFullInfo.RutbeFullInfo = RutbeFullInfo;
        userFullInfo.RutbeId = roomUser?.RutbeId;
        // userFullInfo.Secretkey = roomUser?.Secretkey;
        userFullInfo.StateId = roomUser?.StateId;
        userFullInfo.Telefon = "";
        userFullInfo.WebsyncClientID = "";
        userFullInfo.FromRoomChat = true;

        return userFullInfo;
    }
}
