import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceInformation } from './serviceinformation.service';
import { UtilService } from 'src/app/core/services/util.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { ChatMessage, FileMessageData, FileMessageUploadResponse, MessageRecords } from '../models/chat-message'; import { Friend, UserFullInfo } from 'src/app/core/models/user';
import { ChatState, ChatType, ContactState, ContactType, MessageBubbleType, MessageSendingType } from 'src/app/core/models/enums';
import { ChatPanelProps } from '../models/chat-panel-props';
import { Observable, Subject, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Constants } from 'src/app/core/models/constants';
import { EmojiService } from "@ctrl/ngx-emoji-mart/ngx-emoji";
import { OperationMessage } from '../models/operation-message';
import { Nicktype } from 'src/app/core/models/nick-type';
import { NotificationService } from './notification.service';
import { PanelManagerService } from './panel-manager.service';
import { NatsService } from '../nats/services/nats.service';
import { DynamicMethod } from '../models/dynamic-method';
import { RoomUserListService } from './room-user-list-service';
import { environment } from 'src/environments/environment';
import { AnimationData, AnimationModel } from '../models/animations';

@Injectable({
    providedIn: 'root'
})
export class ChatService extends ServiceInformation implements OnDestroy {
    apiUrl = `${environment.apiUrl}`
    sendedMessages = {};

    editorMessage = "Buraya yazın...";

    activeEditor: HTMLElement;

    newMessageNotificationTakenSource: Subject<ChatMessage> = new Subject();
    newMessageNotificationTaken = this.newMessageNotificationTakenSource.asObservable();

    newMessageNotificationTakenDoneSource: Subject<string> = new Subject(); //ChatMessage.LocalDBId
    newMessageNotificationTakenDone = this.newMessageNotificationTakenDoneSource.asObservable();



    editorFontChangedSource: Subject<Nicktype> = new Subject();
    editorFontChanged = this.editorFontChangedSource.asObservable();
    
    p2pFileMessageReceivedSource: Subject<FileMessageData> = new Subject();
    p2pFileMessageReceived = this.p2pFileMessageReceivedSource.asObservable();


    p2pAnimationSendSource: Subject<number> = new Subject();
    p2pAnimationSend = this.p2pAnimationSendSource.asObservable();

    newMessageNotificationTakenDoneSubscription: Subscription;
    p2pChatFocused: boolean = false;


    public userChannelMessageReceivedSource: Subject<ChatMessage> = new Subject();
    private userChannelSettingMessageReceivedSource: Subject<Array<DynamicMethod>> = new Subject();



    connettionSuccessSubscription: Subscription;
    offlineMessageCurrentIndex: number;
    offlineMessages: Array<any>;


    selectedUserPanelForSendingAnimation: number;
    isAiActive: boolean = false

    constructor(
        private http: HttpClient,
        protected utilService: UtilService,
        protected messagePanelService: MessagePanelService,
        protected indexedDBService: IndexedDBService,
        private emojiService: EmojiService,
        private notificationService: NotificationService,
        private panelManagerService: PanelManagerService,
        public natsService: NatsService,
        private roomUserListService: RoomUserListService
    ) {
        super(messagePanelService, utilService, indexedDBService);

        if (!this.newMessageNotificationTakenDoneSubscription) {
            this.newMessageNotificationTakenDoneSubscription = this.newMessageNotificationTakenDone.subscribe(localDBID => {
                this.offlineMessageCurrentIndex++;
                if (!isNaN(this.offlineMessageCurrentIndex))
                    this.runOneOfflineMessage(this.offlineMessages[this.offlineMessageCurrentIndex]);
            });
        }

    }
    ngOnDestroy(): void {
        if (this.connettionSuccessSubscription)
        this.connettionSuccessSubscription.unsubscribe();

        if (this.newMessageNotificationTakenDoneSubscription)
        this.newMessageNotificationTakenDoneSubscription.unsubscribe();
            
        
    }

    //#region rest operations 

    getEmojiGifs(pageSize: number, pageIndex: number){
        let body = {
            "pageSize":pageSize,
            "pageIndex":pageIndex
        }
        let newPath = this.apiUrl + "GetAnimations"
        return this.http.post<AnimationData>(newPath, body)
    }

    getAllAnimations(): Observable<any> {
        let body = {}
        let newPath = this.apiUrl + "GetAllAnimations"
        return this.http.post<AnimationData>(newPath,body)
    }
    
    public getConferance_Private(body): Observable<any> {
        /* return {
            "ClientInfo": _service.Get_ClientInfo(),
            "UserId": userId,
            "ContactType": contactType
        }    */

        body["ClientInfo"] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + 'GetConferance_Private';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }


    //#endregion

    // #region Webysnc Operations

    textChatOnReceivePtoP(chatMessage: ChatMessage, userImageUrl?: string, isOfflineMessage?: boolean) {
        try {

            chatMessage = this.cloneChatMessage(chatMessage);

            if (this.utilService.isNullOrUndefined(chatMessage.ChatType))
                chatMessage.ChatType = chatMessage["Chattype"];

            chatMessage.Time = new Date();
            chatMessage.MessageId = this.utilService.guid(); //this.getMessageTimeString(chatMessage.Time);
            //this.indexedDBService.setChatMessageLocalDbId(chatMessage);

            if (chatMessage === null || chatMessage === undefined)
                return;

            if (userImageUrl === undefined)
                userImageUrl = null;

            if (chatMessage.MessageBubbleType === MessageBubbleType.PtoPMe)
                chatMessage.MessageBubbleType = MessageBubbleType.PtoPOther;

            if (this.p2pChatFocused === false && isOfflineMessage === false) {
                let userName: string = chatMessage.SenderName;
                if (!this.indexedDBService.isMyFriend(chatMessage.SenderId)) {
                    userName = this.roomUserListService.getUserNameForShow(
                        chatMessage.SenderId,
                        chatMessage.SenderNameSecond,
                        chatMessage.SenderName,
                        "",
                        chatMessage.SenderRutbeDerece)[0].toString();
                }

                this.notificationService.showNewMessageNotification(
                    chatMessage.SenderId,
                    userName,
                    chatMessage.UserPhotoId,
                    () => {
                        this.panelManagerService.showP2PChatPanel(chatMessage.SenderId, true);
                    }
                );
            }

            /* if (this.panelManagerService.focusedPanel !== (Constants.chatPanelTag + chatMessage.SenderId.toString())) {
              this.indexedDBService.messageCounts[chatMessage.SenderId] = this.indexedDBService.messageCounts[chatMessage.SenderId] + 1;
                 //setTimeout(() => {
                     this.setUserChannelMessageReceivedSource(chatMessage);
                 //}, 1000);
             }
             else {
                 this.setUserChannelMessageReceivedSource(chatMessage);
             }*/

            this.newMessageNotificationTakenSource.next(chatMessage);

            setTimeout(() => {
                this.setUserChannelMessageReceivedSource(chatMessage);
            });
            
            if (this.indexedDBService.messageCounts[chatMessage.SenderId]) {
                if (!this.panelManagerService.p2pChatPanels.find(f => f.user.KullaniciId === chatMessage.SenderId).display) {
                  this.indexedDBService.messageCounts[chatMessage.SenderId] = this.indexedDBService.messageCounts[chatMessage.SenderId] + 1;
                }
              }
              else {
                if (!this.panelManagerService.p2pChatPanels.find(f => f.user.KullaniciId === chatMessage.SenderId).display) {
                  this.indexedDBService.messageCounts[chatMessage.SenderId] = 1;
                }
              }


        } catch (error) {
            console.log(error);
        }
    }

    textChatOnReceiveRoom(chatMessage: ChatMessage) {
        if (chatMessage === null || chatMessage === undefined)
            return;

        chatMessage.MessageBubbleType = this.setChatMessageBubbleType(chatMessage);

        this.natsService.roomMessagesReceivedSource.next(chatMessage);

    }

    setChatMessageBubbleType(chatMessage: ChatMessage): MessageBubbleType {

        var messageBubbleType = MessageBubbleType.RoomOther;
        if (chatMessage.ChatType === ChatType.RootAdminTopluMesaj || chatMessage.ChatType === ChatType.SystemMessages)
            messageBubbleType = MessageBubbleType.SystemMessages;
        else if (chatMessage.ChatType === ChatType.AdminChangesMessage) {
            if (chatMessage.SenderId !== this.indexedDBService.userFullInfo.KullaniciId && chatMessage.SenderRutbeDerece > this.indexedDBService.userFullInfo.RutbeDerece)
                return;
            messageBubbleType = MessageBubbleType.AdminChangesMessage;
        }
        else if (chatMessage.ChatType === ChatType.RoomActionMessage) {
            messageBubbleType = MessageBubbleType.RoomActionMessage;
        }
        else {
            if (chatMessage.SenderId === this.indexedDBService.userFullInfo.KullaniciId)
                return;
        }

        if (chatMessage.MessageSendingType == MessageSendingType.GenelAdmin) {
            if (this.indexedDBService.userFullInfo.RutbeId === Constants.UYERUTBEID)
                return;
        }

        if (chatMessage.MessageSendingType === MessageSendingType.GenelAdmin ||
            chatMessage.MessageSendingType === MessageSendingType.GenelHerkes)
            messageBubbleType = MessageBubbleType.GeneralMessage;

        chatMessage.MessageBubbleType = messageBubbleType;
        return chatMessage.MessageBubbleType;
    }

    isMessageSendedByWebysnc(currentIterationIndex, messageId, responseFunc) {

        try {
            setTimeout(function () {

                currentIterationIndex++;
                if (currentIterationIndex >= 10) {
                    responseFunc(false);
                    return;
                }

                var sendedMessage = this.SendedMessages[messageId];
                if (sendedMessage === undefined)
                    this.IsMessageSendedByWebysnc(currentIterationIndex, messageId, responseFunc);
                else
                    responseFunc(true);
            }, 1000);
        } catch (error) {

            responseFunc(false);
        }
    }

    increaseMessageCountOnLocalDatabase(onReceiveEventArgs) {

    }

    getChatMessage(chatPanelProperties: ChatPanelProps, html: string, contactType: ContactType, contactState: ContactState, senderUserInfo: UserFullInfo) {
        var chatMessage = new ChatMessage();

        chatMessage.SenderId = senderUserInfo.KullaniciId;
        chatMessage.SenderName = senderUserInfo.KullaniciAdi;
        chatMessage.SenderNameSecond = senderUserInfo.IkinciKullaniciAdi;
        chatMessage.SenderRutbeDerece = senderUserInfo.RutbeDerece;
        chatMessage.ReceiverId = chatPanelProperties.UserId;
        chatMessage.ReceiverName = chatPanelProperties.UserName;
        chatMessage.RtfText = html;
        chatMessage.Time = this.utilService.newDate();
        chatMessage.ContactType = contactType;
        chatMessage.Contactstate = contactState;
        chatMessage.Chatstate = ChatState.GonderilenMesaj;
        chatMessage.UserPhotoId = senderUserInfo.ProfilResmiId;
        chatMessage.ChatType = chatPanelProperties.ChatType;
        chatMessage.MessageId = this.utilService.guid();

        if (chatPanelProperties.ChatType === ChatType.SingleChat) {
            chatMessage.IndexName = chatPanelProperties.UserId.toString();
            chatMessage.Channelname = this.utilService.getUserChannelName(chatPanelProperties.UserId);
        }
        return chatMessage;
    }

    getChatMessageForRoom(chatPanelProperties: ChatPanelProps, rtf: string, text: string, roomId: number, senderUserInfo: UserFullInfo) {
        var chatMessage = new ChatMessage();

        chatMessage.SenderId = senderUserInfo.KullaniciId;
        chatMessage.SenderName = senderUserInfo.KullaniciAdi;
        chatMessage.SenderNameSecond = senderUserInfo.IkinciKullaniciAdi;
        chatMessage.SenderRutbeDerece = senderUserInfo.RutbeDerece;
        chatMessage.SenderStateId = senderUserInfo.StateId;
        chatMessage.ReceiverId = chatPanelProperties.UserId;
        chatMessage.ReceiverName = chatPanelProperties.UserName;
        chatMessage.RtfText = rtf;
        chatMessage.Text = text;
        chatMessage.Time = this.utilService.newDate();//this.utilService.getDateStringFull(this.utilService.newDate());
        chatMessage.ContactType = ContactType.Chat;
        chatMessage.Contactstate = ContactState.Hicbiri;
        chatMessage.Chatstate = ChatState.GonderilenMesaj;
        chatMessage.UserPhotoId = chatPanelProperties.UserPhotoId;
        chatMessage.ChatType = chatPanelProperties.ChatType;
        chatMessage.IndexName = roomId.toString();
        chatMessage.MessageId = this.utilService.guid();
        chatMessage.Channelname = this.utilService.getRoomChannelName(roomId);
        return chatMessage;
    }

    getChatMessageForConferenceInfo(senderId: number, message: string) {
        var chatMessage = new ChatMessage();
        chatMessage.NickType = this.indexedDBService.userFullInfo.NicktypeForRoom;
        chatMessage.SenderId = senderId;
        chatMessage.SenderName = Constants.APPLICATION_NAME;
        chatMessage.ReceiverId = this.indexedDBService.userFullInfo.KullaniciId;
        chatMessage.ReceiverName = this.indexedDBService.userFullInfo.KullaniciAdi;
        chatMessage.IndexName = senderId.toString();
        chatMessage.Text = message;
        chatMessage.RtfText = message;
        chatMessage.Time = this.utilService.newDate();//this.utilService.getDateStringFull(this.utilService.newDate());

        return chatMessage;

    }

    cloneChatMessage(chatMessage) {
        var newChatMessage = new ChatMessage();
        newChatMessage = Object.assign({}, chatMessage);
        newChatMessage.ChatType = newChatMessage.ChatType;
        newChatMessage.ContactType = newChatMessage.ContactType;
        return newChatMessage;
    }
    // #endregion

    //#region chat message operations 

    getUserImage(chatmessage: ChatMessage, userImageUrl: string) {

        try {

            if (userImageUrl !== null && userImageUrl !== undefined)
                return userImageUrl;

            if (chatmessage.ChatType === ChatType.SystemMessages ||
                chatmessage.ChatType === ChatType.RootAdminTopluMesaj ||
                chatmessage.ChatType === ChatType.AdminChangesMethod)
                return this.utilService.GetImageUrl(Constants.Veri24MessengerLogo70);

            return Constants.veri24MessengerUserPhotosUrl + chatmessage.UserPhotoId.toString().toLocaleLowerCase() + ".png";
        }
        catch (error) {

        }

        return this.utilService.GetImageUrl(Constants.Veri24MessengerDefaultUserPhoto);
    }

    replaceRtfHtmlMessageBeforeAfterReceived(text: string, userImageUrl: string) {


        if (!this.utilService.IsNullOrWhitespace(userImageUrl))
            text = this.utilService.ReplaceAll(text, Constants.USERIMAGETAG, userImageUrl);

        text = this.utilService.ReplaceAll(text, Constants.EMOJITAG, Constants.Veri24MessengerEmoji32Url);
        text = this.utilService.ReplaceAll(text, Constants.THEMETAG, userImageUrl);
        return text;

        //TODO kullanıcı dosyları ve tema dosyaları ne oalcak ??
        //.Replace(Constants.THEMETAG, themePath.Replace('\\', '/'))
        //.Replace(Constants.USERIDFORFILEDOWNLOADKEYTAG + Constants.USERIDFORFILEDOWNLOADVALUETAG, Constants.USERIDFORFILEDOWNLOADKEYTAG + userId);
    }

    replaceImageSymbolsToImageTags(html: string) {

        try {

            var regex = new RegExp(this.utilService.PrepareForRegexReplace(Constants.ImageTagStart) + "(.*?)" + this.utilService.PrepareForRegexReplace(Constants.ImageTagEnd), "g");

            var emojiSymbollist = html.match(regex);

            var onlyOnEmoticons = false;
            if (html.trim().startsWith(Constants.ImageTagStart) &&
                html.trim().endsWith(Constants.ImageTagEnd) &&
                emojiSymbollist.length === 1)
                onlyOnEmoticons = true;

            var emojiUrl = Constants.Veri24MessengerEmoji32Url;
            if (onlyOnEmoticons === true)
                emojiUrl = Constants.Veri24MessengerEmoji64Url;

            emojiSymbollist.forEach(word => {
                var emojiId = this.utilService.ReplaceAll(this.utilService.ReplaceAll(word, Constants.ImageTagStart, ""), Constants.ImageTagEnd, "");

                // 👍 emoji id has special regex character '+'
                // so we must replace it with a proper character
                // to prevent the conflict
                if (emojiId === '+1') {
                    html = html.replace(/\+1/g, '&#43;1');
                    word = word.replace(/\+1/g, '&#43;1');
                }

                var emojiHtml = this.createEmojiHtml(this.emojiService.getData(emojiId));
                if (this.utilService.IsNullOrWhitespace(emojiHtml) === false) {
                    html = this.utilService.ReplaceAll(html, word, emojiHtml);

                    // We have changed 👍 emoji id with a special character
                    // If there is a text which equals to 👍 emoji id
                    // so we must replace it back with its original value.
                    if (emojiId === '+1') {
                        html = html.replace(/&#43;1/g, '+1');
                    }
                }
                else
                    html = this.utilService.ReplaceAll(html, word, "");

            });

        }
        catch (error) {
        }
        return html;
    }

    createEmojiHtml(emoji) {
        const styles = this.emojiService.emojiSpriteStyles(emoji.sheet, "apple");
        const el = document.createElement("img");

        Object.assign(el.style, styles);

        el.className = "emoji";
        el.setAttribute("data-key", emoji.id);
        el.setAttribute(
            "src",
            "https://d1n9fljh5ycvwn.cloudfront.net/assets/transparent.png"
        );
        el.id = emoji.id;
        return el.outerHTML;
    }

    replaceImageTagToImageSymbols(html: string) {
        try {



            //TODO
            // $.each(EmojiHelper.EmojiSymbols, function (key, value) {
            //     if (html.indexOf(key))
            //         html = this.utilService.ReplaceAll(html, key, Constants.ImageTagStart + value + Constants.ImageTagEnd);
            // });

            // var htmlDocument = $("<div>" + html + "</div>");

            // htmlDocument.find("img").each(function () {
            //     $(Constants.ImageTagStart + value + Constants.ImageTagEnd).insertAfter($(this));
            //     $(this).remove();
            // });

            // return htmlDocument.html();
        }
        catch (error) {
        }
        return html;
    }

    replaceAnimationSymbolsToAnimationTags(html: string) {
        try {
            var regex = new RegExp(this.utilService.PrepareForRegexReplace(Constants.AnimationTagStart) + "(.*?)" + this.utilService.PrepareForRegexReplace(Constants.AnimationTagEnd), "g");

            var animationSymbollist = html.match(regex);


            var animationUrl = Constants.Veri24MessengerAnimationsUrl;

            animationSymbollist.forEach(word => {
                var animationId = this.utilService.ReplaceAll(this.utilService.ReplaceAll(word, Constants.AnimationTagStart, ""), Constants.AnimationTagEnd, "");

                html = this.utilService.ReplaceAll(html, word, "<img  class=\"" + animationId + "\" src=\"" + animationUrl + animationId + ".gif" + "\" />");
            });
        }
        catch (error) {
        }
        return html;
    }

    replaceEmojiGifSymbolsToEmojiGifTags(html) {
        try {
            var regex = new RegExp(this.utilService.PrepareForRegexReplace(Constants.EmojiGifTagStart) + "(.*?)" + this.utilService.PrepareForRegexReplace(Constants.EmojiGifTagEnd), "g");

            var emojigifSymbollist = html.match(regex);


            var emojigifUrl = Constants.Veri24MessengerEmojiGifUrl;

            emojigifSymbollist.forEach(word => {
                var emojigifId = this.utilService.ReplaceAll(this.utilService.ReplaceAll(word, Constants.EmojiGifTagStart, ""), Constants.EmojiGifTagEnd, "");

                html = this.utilService.ReplaceAll(html, word, "<img  class=\"" + emojigifId + "\" src=\"" + emojigifUrl + emojigifId + ".gif" + "\" />");

            });
        }
        catch (error) {
        }
        return html;
    }

    getMessageTimeString(date: Date) {
        if (!date)
            return "";

        var tempDate = new Date(date.getTime());
        return this.utilService.getDateStringFullWithoutSplitterForMessageId(tempDate);
    }

    replaceBadWordsInHtml(html: string) {
        if (this.isAiActive) {
            return html;
        }
        var newText = "";

        html.split(" ").forEach(word => {
            newText += this.replaceBadWordsInOneHtmlNodeOneWord(word) + " ";
        });

        return newText.trim();
    }

    replaceBadWordsInOneHtmlNodeOneWord(word) {
        var tempWord = this.utilService.clearRepeatChars2(word);

        var returnObj = this.isBadWord(tempWord);
        if (returnObj.isBannedWord) {
            word = returnObj.newWord;
            return word;
        }

        var tempWord = this.utilService.clearRepeatChars(word);

        returnObj = this.isBadWord(tempWord);
        if (returnObj.isBannedWord)
            word = returnObj.newWord;

        return word;
    }

    isBadWord(word: string) {
        var allowedWord = this.indexedDBService.BannedWords[word.toLowerCase()];
        if (!this.utilService.isNullOrUndefined(allowedWord))
            return { newWord: allowedWord, isBannedWord: true };

        return { newWord: word, isBannedWord: false };
    }

    clearFakeSpans(html) {
        html = this.utilService.ReplaceAll(html, `<span id="cs_editor_room"></span>`, "");
        html = this.utilService.ReplaceAll(html, `<span id="ce_editor_room"></span>`, "");
        return html;
    }

    controlHTmlIsOk(html) {
        try {
            var regex = new RegExp("<span>\\[IMG" + "(.*?)" + "IMG\\]</span>", "g");
            var matchs = html.match(regex);
            var matchLength = 0;

            if (matchs !== null && matchs !== undefined)
                matchLength = matchs.length;

            if (matchLength >= 10)
                return false;

            var newHtml = this.clearAllImages(html);

            if (newHtml.length > 250)
                return false;

            return true;
        }
        catch (error) {

            console.log(error);
            return false;
        }
    }

    clearAllImages(html) {
        try {
            var regex = new RegExp("<span>" + this.utilService.PrepareForRegexReplace(Constants.ImageTagStart) + "(.*?)" + this.utilService.PrepareForRegexReplace(Constants.ImageTagEnd) + "</span>", "g");

            var matchesArray = html.match(regex);
            if (matchesArray !== null && matchesArray !== undefined) {
                matchesArray.forEach(val => {
                    html = this.utilService.ReplaceAll(html, val, "");
                });
            }

            return html;
        }
        catch (error) {
            return html;
        }
    };

    getMessageId(chatMessage: ChatMessage) {
        return !this.utilService.IsNullOrWhitespace(chatMessage.MessageId)
            ? chatMessage.MessageId
            : this.utilService.guid();// this.getMessageTimeString(chatMessage.Time);
    }


    setChatMessageLocalDbId(chatMessage: ChatMessage) {
        var localDbSenderId;
        var localDbReceiverId;
        if (chatMessage.SenderId === this.indexedDBService.userFullInfo.KullaniciId) {
            localDbSenderId = chatMessage.ReceiverId;
            localDbReceiverId = chatMessage.SenderId;
        } else {
            localDbSenderId = chatMessage.SenderId;
            localDbReceiverId = chatMessage.ReceiverId;
        }
        // chatMessage.LocalDBId = localDbSenderId + "_" + localDbReceiverId + "_" + chatMessage.MessageId;
    }


    prepareChatMessageBeforeAddToBubble(userImageUrl: string, chatMessage: ChatMessage): Promise<ChatMessage> {
        userImageUrl = this.getUserImage(chatMessage, userImageUrl);
        chatMessage.RtfText = this.replaceRtfHtmlMessageBeforeAfterReceived(chatMessage.RtfText, userImageUrl);
        chatMessage.RtfText = this.replaceBadWordsInHtml(chatMessage.RtfText);
        chatMessage.RtfText = this.replaceImageSymbolsToImageTags(chatMessage.RtfText);
        chatMessage.RtfText = this.replaceAnimationSymbolsToAnimationTags(chatMessage.RtfText);
        chatMessage.RtfText = this.replaceEmojiGifSymbolsToEmojiGifTags(chatMessage.RtfText);

        // if (!this.utilService.IsNullOrWhitespace(chatMessage.RtfTextTranslated)) {
        //     chatMessage.RtfTextTranslated = this.replaceBadWordsInHtml(chatMessage.RtfTextTranslated);
        //     chatMessage.RtfTextTranslated = this.replaceImageSymbolsToImageTags(chatMessage.RtfTextTranslated);
        //     chatMessage.RtfTextTranslated = this.replaceAnimationSymbolsToAnimationTags(chatMessage.RtfTextTranslated);
        //     chatMessage.RtfTextTranslated = this.replaceEmojiGifSymbolsToEmojiGifTags(chatMessage.RtfTextTranslated);
        // }



        return Promise.resolve()
            .then(() => {
                if (!chatMessage.NickType)
                    return this.indexedDBService.userFullInfo.NicktypeForRoom
                else
                    return chatMessage.NickType

            })
            .then((nickType) => {

                chatMessage.NickType = nickType;
            })
            .then(() => {
                return chatMessage
            });

    };
    //#endregion


    chatMessageToSqlChatMessageTable(chatMessage: ChatMessage, userId: number, isOffline: boolean): any {

        var chatmessage: any = {};
        chatmessage.ID = this.utilService.guid();
        if (chatMessage.MessageId === undefined || chatMessage.MessageId === null || chatMessage.MessageId === '')
            chatmessage.MessageId = this.utilService.guid();
        else {
            chatmessage.MessageId = chatMessage.MessageId;
        }
        chatmessage.CHANNELNAME = chatMessage.Channelname;
        chatmessage.CHATSTATE = chatMessage.Chatstate !== undefined ? this.utilService.getEnumKeyString(ChatState, chatMessage.Chatstate) : this.utilService.getEnumKeyString(ChatState, ChatState.GonderilenMesaj);
        chatmessage.SENDERID = chatMessage.SenderId;
        chatmessage.SENDERNAME = chatMessage.SenderName;
        chatmessage.RECEIVERID = chatMessage.ReceiverId;
        chatmessage.RECEIVERNAME = chatMessage.ReceiverName;

        chatmessage.CHATTYPE = chatMessage.ChatType !== undefined ? this.utilService.getEnumKeyString(ChatType, chatMessage.ChatType) : this.utilService.getEnumKeyString(ChatType, ChatType.SingleChat);
        chatmessage.CONTACTSTATE = chatMessage.Contactstate !== undefined ? this.utilService.getEnumKeyString(ContactState, chatMessage.Contactstate) : this.utilService.getEnumKeyString(ContactState, ContactState.Hicbiri);
        chatmessage.CONTACTTYPE = chatMessage.ContactType !== undefined ? this.utilService.getEnumKeyString(ContactType, chatMessage.ContactType) : this.utilService.getEnumKeyString(ContactType, ContactType.Chat);

        chatmessage.RTFMESSAGE = chatMessage.RtfText;
        // chatmessage.TEXTIMAGECOUNT = chatMessage.TextImageCount;
        chatmessage.TEXTMESSAGE = chatMessage.Text;
        chatmessage.SENDINGTIME = chatMessage.Time;
        chatmessage.USERID = userId;
        chatmessage.OFFLINE = isOffline;
        chatmessage.FONT = chatMessage.NickType.F;
        chatmessage.COLOR = chatMessage.NickType.C;
        // chatmessage.RTFMESSAGETRANSLATED = chatMessage.RtfTextTranslated;

        // chatmessage.RECEIVER_SECRETKEY = chatMessage.ReceiverSecretKey;

        chatmessage.INDEXNAME = chatMessage.SenderName;

        return chatmessage;
    }

    getChatFormPropertiesForFriendChatPanel(userFullInfo: Friend, chatType: ChatType) {
        var chatPanelProps = new ChatPanelProps();

        chatPanelProps.UserId = userFullInfo.KullaniciId;
        chatPanelProps.UserName = userFullInfo.KullaniciAdi;
        chatPanelProps.UserRubeDerece = userFullInfo.RutbeDerece;
        chatPanelProps.Channelname = this.utilService.getUserChannelName(userFullInfo.KullaniciId);
        chatPanelProps.ChatType = chatType;
        chatPanelProps.UserPhotoId = userFullInfo.ProfilResmiId;

        return chatPanelProps;
    }


    runOneOfflineMessage(sqlChatMessage) {
        if (this.offlineMessages.length <= this.offlineMessageCurrentIndex) {
            this.deleteOfflineChatByChannel()
                .toPromise()
                .then(() => {
                }).catch((err) => {
                    console.log(err);
                })
        } else {
            this.SqlChatMessageTableToChatMessage(sqlChatMessage,
                this.indexedDBService.userFullInfo.KullaniciId,
                this.indexedDBService.userFullInfo.KullaniciAdi,
                this.getUserImageId(sqlChatMessage.SENDERID))
                .then((chatMessage: ChatMessage) => {
                    if (this.utilService.isNullOrUndefined(this.indexedDBService.messageCounts[chatMessage.SenderId]))
                        this.indexedDBService.messageCounts[chatMessage.SenderId] = 0;

                    this.textChatOnReceivePtoP(chatMessage, null, true);

                    if (this.offlineMessages.length === this.offlineMessageCurrentIndex + 1) {
                        let userName: string = chatMessage.SenderName;
                        if (!this.indexedDBService.isMyFriend(chatMessage.SenderId)) {
                            userName = this.roomUserListService.getUserNameForShow(
                                chatMessage.SenderId,
                                chatMessage.SenderNameSecond,
                                chatMessage.SenderName,
                                "",
                                chatMessage.SenderRutbeDerece)[0].toString();
                        }

                        this.notificationService.showNewMessageNotification(
                            chatMessage.SenderId,
                            userName,
                            chatMessage.UserPhotoId,
                            () => {
                                this.panelManagerService.showP2PChatPanel(chatMessage.SenderId, true);
                            }
                        );
                    }
                });
        }
    }


    getUserImageId(userId: number): string {
        var user = this.indexedDBService.getFriendById(userId);

        return this.utilService.isNullOrUndefined(user) ?
            this.utilService.GuidEmpty() :
            user.ProfilResmiId;
    }

    SqlChatMessageTableToChatMessage(sqlChatMessageTable, receiverId, receiverName, userPhotoId): Promise<ChatMessage> {
        return new Promise((resolve, reject) => {
            var Chatstate: ChatState;
            ChatState.GelenMesaj === sqlChatMessageTable.CHATSTATE ? Chatstate = ChatState.GelenMesaj : Chatstate = ChatState.GonderilenMesaj;

            var chatMessage: ChatMessage = new ChatMessage();

            chatMessage.Channelname = sqlChatMessageTable.CHANNELNAME;
            chatMessage.UserPhotoId = userPhotoId;
            chatMessage.Chatstate = Chatstate;
            chatMessage.SenderId = sqlChatMessageTable.SENDERID;
            chatMessage.SenderName = sqlChatMessageTable.SENDERNAME;
            chatMessage.MessageId = this.utilService.guid();
            //SENDER rütbe derece eksik???          

            if (sqlChatMessageTable.RECEIVERID !== null && sqlChatMessageTable.RECEIVERID !== undefined)
                chatMessage.ReceiverId = sqlChatMessageTable.RECEIVERID.Value;

            chatMessage.ReceiverName = sqlChatMessageTable.RECEIVERNAME;
            chatMessage.ReceiverId = receiverId;
            chatMessage.ReceiverName = receiverName;

            chatMessage.ChatType = sqlChatMessageTable.CHATTYPE;
            chatMessage.Contactstate = sqlChatMessageTable.CONTACTSTATE;
            chatMessage.ContactType = sqlChatMessageTable.CONTACTTYPE;

            if (sqlChatMessageTable.TEXTIMAGECOUNT !== null && sqlChatMessageTable.TEXTIMAGECOUNT !== undefined)
                // chatMessage.TextImageCount = sqlChatMessageTable.TEXTIMAGECOUNT;

            chatMessage.IndexName = sqlChatMessageTable.INDEXNAME;
            chatMessage.RtfText = sqlChatMessageTable.RTFMESSAGE;
            chatMessage.Text = sqlChatMessageTable.TEXTMESSAGE;
            // if (sqlChatMessageTable.SENDINGTIME !== null && sqlChatMessageTable.SENDINGTIME !== undefined)
            //     chatMessage.Time = this.utilService.getDateFromJson(sqlChatMessageTable.SENDINGTIME);



            // chatMessage.TextImageCount = 0;
            chatMessage.NickType = new Nicktype();
            chatMessage.NickType.C = sqlChatMessageTable.COLOR;
            chatMessage.NickType.F = sqlChatMessageTable.FONT;

            chatMessage.Time = new Date();
            chatMessage.MessageId = this.utilService.guid(); //this.getMessageTimeString(chatMessage.Time);
            // this.indexedDBService.setChatMessageLocalDbId(chatMessage);



            // chatMessage.RtfTextTranslated = sqlChatMessageTable.RTFMESSAGETRANSLATED;
            // chatMessage.ReceiverSecretKey = sqlChatMessageTable.RECEIVER_SECRETKEY;

            chatMessage.MessageBubbleType = MessageBubbleType.PtoPOther;

            chatMessage.ChatType = ChatType.SingleChat;
            chatMessage.ContactType = ContactType.Chat;
            chatMessage.Contactstate = ContactState.Hicbiri;


            resolve(chatMessage);
        })

    }

    public deleteOfflineChatByChannel(): Observable<any> {
        let body = {
            'ClientInfo': this.Get_ClientInfo(),
            'ChannelName': this.utilService.getUserChannelName(this.indexedDBService.userFullInfo.KullaniciId),
            'UserId': this.indexedDBService.userFullInfo.KullaniciId
        }

        const endpoint = this.ServiceUrl + 'DeleteOfflineChatByChannel';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }


    setUserChannelMessageReceivedSource(messageDetailForServer: ChatMessage) {
        this.userChannelMessageReceivedSource.next(messageDetailForServer);
    }


    getUserChannelSettingMessageReceivedSource(): Observable<Array<DynamicMethod>> {
        return this.userChannelSettingMessageReceivedSource.asObservable();
    }

    setUserChannelSettingMessageReceivedSource(dynmaicMethods: Array<DynamicMethod>) {
        this.userChannelSettingMessageReceivedSource.next(dynmaicMethods);
    }

    getP2PChatOldMessages(userId: number,next:string):Promise<MessageRecords>{
        let body = {
            "is_p2p_chat":true,
            "next":next,
            "size":100
        }
        const endpoint =`${environment.apiUrl}api/chat/${userId}`
        return this.http.post<any>(endpoint,body,this.options)
            .pipe(catchError(error => this.handleError(error)))
            .toPromise()
    }

    getRoomChatOldMessages(roomId: number,next:string):Promise<MessageRecords>{
        let body = {
            "is_p2p_chat":false,
            "next":next,
            "size":100
        }
        const endpoint =`${environment.apiUrl}api/chat/${roomId}`
        return this.http.post<any>(endpoint,body,this.options)
            .pipe(catchError(error => this.handleError(error)))
            .toPromise()
    }

    deleteRoomChatOldMessages(receiver:number):Promise<any>{
        let body = {
            "time": null,
            "channel":"",
            "is_p2p_chat":false,
            "receiver":receiver
        }
        const endpoint =`${environment.apiUrl}api/chat/delete`
        return this.http.post<any>(endpoint,body,this.options)
            .pipe(catchError(error => this.handleError(error)))
            .toPromise()
    }

    deleteP2PChatOldMessages(channel:string):Promise<any>{
        let body = {
            "channel":channel,
            "is_p2p_chat":true,
            "receiver":null,
            "time":null
        }
        const endpoint =`${environment.apiUrl}api/chat/delete`
        return this.http.post<any>(endpoint,body,this.options)
            .pipe(catchError(error => this.handleError(error)))
            .toPromise()
    }

}   