import { Injectable } from '@angular/core';
import { UserFullInfo } from 'src/app/core/models/user';
import { Font } from 'src/app/core/models/font';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    constructor() {

    }
    sessionStorageGet(key: string): string | null {
        return sessionStorage.getItem(key);
    }
    // search for 'NOTE: fix for tab close' comment to see affected points
    storageSetItem(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    storageGetItem(key: string): string {
        key = this.getKeyName(key);
        let data = localStorage.getItem(key);
        if (data == null) {
            return ''
        } else {
            return data
        }
    }

    storageRemoveItem(key: string) {
        key = this.getKeyName(key);
        localStorage.removeItem(key);
    }

    storageClear() {
        localStorage.clear();
    }

    // this method is written to change token key name
    // to solve the problem about finding right tab
    //TODO: one more control for token
    private getKeyName(key: string): string {
        return key
    }
}
